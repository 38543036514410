import axios from 'axios';
import { API_ENDPOINT, defaultResponses } from "../config/config";
import { GET_ADV_USERS, GET_ERRORS } from "./types";

 /**
 * AdvisorActions getUsers
 *
 * @method GET
 * @param {*} clientID
 */
export const getAdvisorUsers = () => async(dispatch) => {
    let ADVISOR_URL = `${API_ENDPOINT}/advisor`;
    await axios.get(ADVISOR_URL)
        .then(res => {
            dispatch({
                type: GET_ADV_USERS,
                payload1: res.data.users
            });
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: !!err.response
                ? err.response.data
                : {message: defaultResponses.noResponseFromApi}
            })
        });
};
