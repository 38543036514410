import { calculateIvaTax } from '../helpers/functions';
import {
    GET_DETAIL_SALE,
    DETAIL_SALE_REMOVE_PRODUCT,
    DETAIL_SALE_UPDATE_QUANTITY_PRODUCT,
    UPDATE_PRODUCT_LIST,
    BUY_PRODUCTS,
    GET_SHOPPING_USER,
    SEND_INVOICE,
    CLEAR_SHOPPING_CART,
    STORE_VOUCHER,
    SEND_QUOTATION,
    MODIFY_CART,
    UPDATE_TOTAL,
    GET_PSE_TOKEN,
    ERROR_FILE_SIZE,
    GET_REJECTED_SALE,
    MAJOR_CONTRIBUTOR_VERIFICATION,
    GET_DS_CLIENT,
    RESET_SENT_INVOICE_FLAG,
    SENT_TO_ONLINE_PAYMENT_VERIFICATION
} from '../actions/types';

const initialState = {
    products: [],
    successGetProducts: false,
    deleteProductData: [],
    successDeleteProduct: false,
    updateProductData: [],
    successUpdateProduct: false,
    buyProductsData: {},
    successBuyProducts: false,
    userData: null,
    successGetUser: false,
    sendInvoiceData: [],
    successSendInvoice: false,
    storeVoucherData: [],
    successStoreVoucher: false,
    sendQuotationData: [],
    successSendQuotation: false,
    successModifyCart: false,
    errorFileSize: false,
    total: 0,
    taxIva: 0,
    pseToken: null,
    saleReference: '',
    client: {},
    rejectedSale: false,
    data:[],
    majorContributor: null,
    dsClient: null,
    sentToOnlinePayment: false,
    isProductionEnv: false
};

/**
 * GET_DETAIL_SALE(get products and purchase features)
 * 
 * @export function
 * @param {*} [state=initialState]
 * @param {*} action
 */

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_DETAIL_SALE:
            return {
                ...initialState,
                products: action.payload,
                client: action.payload.client,
                successGetProducts: true,
                saleReference: `${action.payload.salesRef}${action.payload.salesCode}`
            };
        case DETAIL_SALE_REMOVE_PRODUCT:
            return {
                ...initialState,
                saleReference: state.saleReference,
                deleteProductData: action.payload,
                successDeleteProduct: true
            };
        case DETAIL_SALE_UPDATE_QUANTITY_PRODUCT:
            return {
                ...initialState,
                saleReference: state.saleReference,
                deleteProductData: action.payload,
                successDeleteProduct: true
            };
        case UPDATE_PRODUCT_LIST:
            return {
                ...initialState,
                total: state.total,
                taxIva: state.taxIva,
                saleReference: state.saleReference,
                updateProductData: action.payload,
                successUpdateProduct: true
            };
        case BUY_PRODUCTS:
            return {
                ...initialState,
                total: state.total,
                taxIva: state.taxIva,
                saleReference: state.saleReference,
                buyProductsData: action.payload,
                successBuyProducts: true
            };
        case GET_SHOPPING_USER:
            return {
                ...initialState,
                total: state.total,
                taxIva: state.taxIva,
                saleReference: state.saleReference,
                userData: action.payload,
                successGetUser: true
            };
        case SEND_INVOICE:
            return {
                ...initialState,
                sendInvoiceData: action.payload,
                successSendInvoice: true
            };
        case STORE_VOUCHER:
            return {
                ...initialState,
                storeVoucherData: action.payload,
                successStoreVoucher: true
            };
        case SEND_QUOTATION:
            return {
                ...initialState,
                sendQuotationData: action.payload,
                successSendQuotation: true
            };
        case MODIFY_CART:
            return {
                ...initialState,
                successModifyCart: true
            };
        case CLEAR_SHOPPING_CART:
            return initialState;
        case UPDATE_TOTAL:
            return {
                ...initialState,
                client: state.client,
                total: action.payload,
                taxIva: calculateIvaTax(action.payload),
                saleReference: state.saleReference
            };
        case GET_PSE_TOKEN:
            return {
                ...state,
                pseToken: action.payload.token,
                isProductionEnv: action.payload.isProductionEnv
            };
        case ERROR_FILE_SIZE:
            return {
                ...initialState,
                errorFileSize: true
            };
        case GET_REJECTED_SALE:
            return {
                ...initialState,
                rejectedSale: true,
                data: action.payload
            };
        case MAJOR_CONTRIBUTOR_VERIFICATION:
            return {
                ...initialState,
                total: state.total,
                taxIva: state.taxIva,
                saleReference: state.saleReference,
                majorContributor: action.payload
            };
        case GET_DS_CLIENT:
            return {
                ...initialState,
                total: state.total,
                taxIva: state.taxIva,
                saleReference: state.saleReference,
                dsClient: action.payload
            };
        case RESET_SENT_INVOICE_FLAG:
            return {
                ...initialState,
                sendInvoiceData: [],
                successSendInvoice: false
            };
        case SENT_TO_ONLINE_PAYMENT_VERIFICATION:
            return {
                ...initialState,
                total: state.total,
                taxIva: state.taxIva,
                saleReference: state.saleReference,
                sentToOnlinePayment: !action.payload
            };
        default:
            return state;
    }
}
