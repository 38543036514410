import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { sendPassword } from "../../actions/authActions";

import InputForm from '../../components/InputForm/InputForm';
import ModalMsg from '../../components/ModalMsg/ModalMsg';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';

import SimpleReactValidator from 'simple-react-validator';
import { reactValidatorOptions } from '../../helpers/simpleReactValidator';

import passwordx2 from '../../assets/img/icons/password/passwordx2.png';

/**
 * ChangePassword Component ( full view ChangePassword component, render a ChangePassword
 * form and make a post request for user password changing )
 *
 * @export Class Component
 * @class ChangePassword
 * @extends {Component}
 * @returns Redux connect
 */
export class ChangePassword extends Component {

    constructor(props) {
        super(props);

        this.state = {
            oldPassword: this.props.urlData ? this.props.urlData.password : '',
            newPassword: '',
            confirmPassword: '',
            modalMsg: [],
            textMinChars: {color:'',icon:''},
            textCapitalLetter: {color:'',icon:''},
            textLowercaseLetter: {color:'',icon:''},
            textNumber: {color:'',icon:''},
            // textSpecialCharacter: {color:'',icon:''}
        };

        this.changePasswordValidator = new SimpleReactValidator(reactValidatorOptions);
    }

    acceptMethods = (openModalMsg) => {
        this.openModalMsg = openModalMsg;
    };

    onChangeCustomInput = () => e => {
        if((e.target.id)==="newPassword"){
            const textMinCharsOk = (e.target.value).length < 10 && (e.target.id)==="newPassword" ? false : true;
            const regexUpperCase = new RegExp('[A-Z]');
            const regexLowerCase = new RegExp('[a-z]');
            const regexNumber = new RegExp('[0-9]');
            // const regexSpecialCharacter = new RegExp('[!"·\$%&\/()=?¿\^*+.;:_.,¡\-]');
            const textCapitalLetterOK = regexUpperCase.test(e.target.value) && (e.target.id)==="newPassword" ? true : false;
            const textLowercaseLetterOk = regexLowerCase.test(e.target.value) && (e.target.id)==="newPassword" ? true : false;
            const textNumberOk = regexNumber.test(e.target.value) && (e.target.id)==="newPassword" ? true : false;
            // const textSpecialCharacterOk = regexSpecialCharacter.test(e.target.value) && (e.target.id)==="newPassword" ? true : false;
            this.setState({
                textMinChars: {color:textMinCharsOk ? 'text-success': 'text-danger',icon:textMinCharsOk ? 'check': 'close'},
                textCapitalLetter: {color:textCapitalLetterOK ? 'text-success': 'text-danger',icon:textCapitalLetterOK ? 'check': 'close'},
                textLowercaseLetter: {color:textLowercaseLetterOk ? 'text-success': 'text-danger',icon:textLowercaseLetterOk ? 'check': 'close'},
                textNumber: {color:textNumberOk ? 'text-success': 'text-danger',icon:textNumberOk ? 'check': 'close'},
                // textSpecialCharacter: {color:textSpecialCharacterOk ? 'text-success': 'text-danger',icon:textSpecialCharacterOk ? 'check': 'close'},
            })
        }
        
        this.setState({ [e.target.id]: e.target.value });
    };

    changePassword = (e) => {
        e.preventDefault();
        if (this.changePasswordValidator.allValid()) {
            let data = {
                userName: this.props.userName,
                oldPassword: this.state.oldPassword,
                newPassword: this.state.newPassword
            }

            if (this.state.newPassword === this.state.confirmPassword) {
                this.props.sendPassword(data);
            } else {
                this.setState({
                    modalMsg: {
                        title: 'Error',
                        label: 'Las contraseñas no coinciden',
                        type: 'alert'
                    }
                })
                this.openModalMsg();
            }
        } else {
            this.changePasswordValidator.showMessages();
            this.forceUpdate();
        }
    }

    render() {
        const txtInitialChangePass = `La contraseña debe cumplir con las siguientes condiciones.`;
        const txtMinimumCharacters = `Mínimo 10 caracteres.                                      `;
        const txtCapitalLetter = `Una letra mayúscula.`;
        const txtLowercaseLetter = `Una letra minúscula.`;
        const txtNumber = `Un número.`;
        // const txtSpecialCharacter = `Un carácter especial como !"·$%&/()=?¿^*+-.;:_,¡`;
        return (
            <div>
                <div className="col-md-12 row text-center">
                    <div className="imgSucces">
                        <img className="SuccessIcon" src={passwordx2} alt="password" />
                    </div>
                    <div className="lbl-container">
                        <p className="text-center modal-form-lbl">{this.props.title}</p>
                    </div>
                </div>
                <br></br>
                <div className="form-container">
                    <form onSubmit={e => this.changePassword(e)}>
                        <div className="row">
                            <div className="col-sm-12 col-md-12">
                                <InputForm
                                    id="oldPassword"
                                    type={"password"}
                                    disabled={this.props.urlData ? true : false}
                                    placeholder={"Contraseña actual"}
                                    icon={"password"}
                                    className="form-control large"
                                    onChange={this.onChangeCustomInput()}
                                    value={this.state.oldPassword}
                                    validator={this.changePasswordValidator}
                                    validateOptions={'required|min:10'}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-12">
                                <InputForm
                                    id="newPassword"
                                    type={"password"}
                                    placeholder={"Nueva contraseña"}
                                    icon={"password"}
                                    className="form-control large"
                                    onChange={this.onChangeCustomInput()}
                                    value={this.state.newPassword}
                                    validator={this.changePasswordValidator}
                                    validateOptions={'required|password|min:10'}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-12">
                                <InputForm
                                    id="confirmPassword"
                                    type={"password"}
                                    placeholder={"Confirmar contraseña"}
                                    icon={"password"}
                                    className="form-control large"
                                    onChange={this.onChangeCustomInput()}
                                    value={this.state.confirmPassword}
                                    validator={this.changePasswordValidator}
                                    validateOptions={'required|password|min:10'}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-8 offset-2">
                                <p className="text-initialChangePass">{txtInitialChangePass}</p>
                            </div>
                            <br></br>
                            <br></br>
                            <div className="col-md-6 offset-3">
                                <p className={this.state.textMinChars.color}> <i className={`rs-icon rs-icon-${this.state.textMinChars.icon}`}></i> {txtMinimumCharacters}</p>
                            </div>
                            <div className="col-md-6 offset-3">
                                <p className={this.state.textCapitalLetter.color}> <i className={`rs-icon rs-icon-${this.state.textCapitalLetter.icon}`}></i> {txtCapitalLetter}</p>
                            </div>
                            <div className="col-md-6 offset-3">
                                <p className={this.state.textLowercaseLetter.color}> <i className={`rs-icon rs-icon-${this.state.textLowercaseLetter.icon}`}></i> {txtLowercaseLetter}</p>
                            </div>
                            <div className="col-md-6 offset-3">
                                <p className={this.state.textNumber.color}> <i className={`rs-icon rs-icon-${this.state.textNumber.icon}`}></i> {txtNumber}</p>
                            </div>
                            {/* <div className="col-md-6 offset-3">
                                <p className={this.state.textSpecialCharacter.color}> <i className={`rs-icon rs-icon-${this.state.textSpecialCharacter.icon}`}></i> {txtSpecialCharacter}</p>
                            </div> */}
                        </div>
                        <br></br>
                        <div className="row">
                            <div className="col col-12">
                                <PrimaryButton
                                    color={"green"}
                                    txtBtn={"Cambiar contraseña"}
                                    sizeWidth={""}
                                    className={"float-right with-icon"}
                                    icon={"Singin"}
                                >
                                </PrimaryButton>
                            </div>
                        </div>
                    </form>
                    <ModalMsg
                        shareMethods={this.acceptMethods}
                        title={this.state.modalMsg.title}
                        show={this.state.modalMsg.show}
                        label={this.state.modalMsg.label}
                        type={this.state.modalMsg.type}
                        redirect={this.state.modalMsg.redirect}
                        history={this.props.history}
                    ></ModalMsg>
                </div>
            </div>
        )
    }
}

ChangePassword.propTypes = {
    sendPassword: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    
})

export default connect(mapStateToProps, { sendPassword })(ChangePassword)
