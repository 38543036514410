import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { setAgreementClient } from '../../actions/businessAdvisorActions';
import { getUserByParameters } from '../../actions/userActions';

import InputForm from '../../components/InputForm/InputForm';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';

import SimpleReactValidator from 'simple-react-validator';
import { reactValidatorOptions } from '../../helpers/simpleReactValidator';

/**
 * Register Agreement Component ( full view Register Agreement component, render a Register Agreement)
 *
 * @export Class Component
 * @class RegisterAgreement
 * @extends {Component}
 * @returns Redux connect
 */
export class RegisterAgreement extends Component {

    constructor(props) {
        super(props);

        this.state = {
            nit: '',
            entityName: '',
            supervisorName: '',
            supervisorLastName: '',
            supervisorPhoneNumber: '',
            supervisorEmail: '',
            contractNumber: '',
            contractValue: '',
            observations: ''
        };

        this.registerValidator = new SimpleReactValidator(reactValidatorOptions);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.businessAdvisorReducer.data) {
            //Alert.success(nextProps.businessAdvisorReducer.data.msg, 3000);
        } else {
            if (!!nextProps.errorReducer) {
                
            }
        }

        let { userReducer } = nextProps;

        if (userReducer.obtainedUserByParameters === null) {
            this.resetForm();
        } else if (userReducer.obtainedUserByParameters.documentType==='NIT' && (userReducer.obtainedUserByParameters !== this.props.userReducer.obtainedUserByParameters)) {
            let {entityName, firstName: supervisorName, lastName: supervisorLastName, cellPhone: supervisorPhoneNumber, email: supervisorEmail, contractNumber, contractValue} = userReducer.obtainedUserByParameters;

            this.setState({ entityName, supervisorName, supervisorLastName, supervisorPhoneNumber, supervisorEmail, contractNumber, contractValue });
        }
    }

    onChangeCustomInput = () => e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    resetForm = () => {
        this.setState({
            entityName: '', supervisorName: '', supervisorLastName: '', supervisorPhoneNumber: '',
            supervisorEmail: '', contractNumber: '', contractValue: '', observations: ''
        });
    };

    findByNit = nitValue => {
        if (this.registerValidator.fieldValid('Nit')) {
            const data = {documentType: 'NIT', attrValue: nitValue, clientType: "Convenio"};

            this.props.getUserByParameters(data);
        } else {
            this.resetForm();
        }
    };

    setRegister = (e) => {
        e.preventDefault();
        if (this.registerValidator.allValid()) {            
            let userData = {
                type: "Convenio",
                documentType: "NIT",
                documentNumber: this.state.nit,
                firstName: this.state.supervisorName,
                lastName: this.state.supervisorLastName,
                cellPhone: this.state.supervisorPhoneNumber,
                email: this.state.supervisorEmail,
                entityName: this.state.entityName,
                contractNumber: this.state.contractNumber,
                contractValue: this.state.contractValue,
                observations: this.state.observations                
            };
            this.props.setAgreementClient(userData);
        } else {
            this.registerValidator.showMessages();
            this.forceUpdate();
        }
    };

    render() {
        return (
            <div className="form-container">
                <form action="" id="formAgreement" className="center-margin" onSubmit = {this.setRegister}>
                    <div className="row">
                        <div className="col-sm-12 col-md-6 ">
                            <InputForm
                                id="nit"
                                type={"text"}
                                maxLength="15"
                                placeholder={"Nit"}
                                icon={"number"}
                                className="form-control large"
                                onChange={this.onChangeCustomInput()}
                                onKeyDown = {"nit"}
                                value={this.state.nit}
                                validator={this.registerValidator}
                                validateOptions={'required|numeric|min:9|max:10'}
                                onBlur = {e => this.findByNit(e.target.value)}
                            />
                        </div>
                        <div className="col-sm-12 col-md-6 ">
                            <InputForm
                                id="entityName"
                                type={"text"}
                                placeholder={"Nombre de la entidad"}
                                icon={"entity"}
                                className="form-control large"
                                onChange={this.onChangeCustomInput()}
                                onKeyDown = {"alpha_num_space"}
                                value={this.state.entityName}
                                validator={this.registerValidator}
                                validateOptions={'required'}
                            />
                        </div>
                        <div className="col-sm-12 col-md-6 ">
                            <InputForm
                                id="supervisorName"
                                type={"text"}
                                placeholder={"Nombre Supervisor"}
                                icon={"user"}
                                className="form-control large"
                                onChange={this.onChangeCustomInput()}
                                onKeyDown = {"alpha_space"}
                                value={this.state.supervisorName}
                                validator={this.registerValidator}
                                validateOptions={'required|names'}
                            />
                        </div>
                        <div className="col-sm-12 col-md-6 ">
                            <InputForm
                                id="supervisorLastName"
                                type={"text"}
                                placeholder={"Apellido Supervisor"}
                                icon={"user"}
                                className="form-control large"
                                onChange={this.onChangeCustomInput()}
                                onKeyDown = {"alpha_space"}
                                value={this.state.supervisorLastName}
                                validator={this.registerValidator}
                                validateOptions={'required|names'}
                            />
                        </div>
                        <div className="col-sm-12 col-md-6 ">
                            <InputForm
                                id="supervisorPhoneNumber"
                                type={"text"}
                                maxLength="18"
                                placeholder={"Celular Supervisor"}
                                icon={"cellphone"}
                                className="form-control large"
                                onChange={this.onChangeCustomInput()}
                                onKeyDown = {"phone"}
                                value={this.state.supervisorPhoneNumber}
                                validator={this.registerValidator}
                                validateOptions={'required|numeric|min:7|max:18'}
                            />
                        </div>
                        <div className="col-sm-12 col-md-6 ">
                            <InputForm
                                id="supervisorEmail"
                                type={"text"}
                                placeholder={"Correo Supervisor"}
                                icon={"email"}
                                className="form-control large"
                                onChange={this.onChangeCustomInput()}
                                value={this.state.supervisorEmail}
                                validator={this.registerValidator}
                                validateOptions={'required|email|onlyTextEmail'}
                            />
                        </div>
                        <div className="col-sm-12 col-md-6 ">
                            <InputForm
                                id="contractNumber"
                                type={"text"}
                                placeholder={"Número Contrato"}
                                icon={"contractn"}
                                className="form-control large"
                                onChange={this.onChangeCustomInput()}
                                value={this.state.contractNumber}
                                validator={this.registerValidator}
                                validateOptions={'required|contractNumber'}
                            />
                        </div>
                        <div className="col-sm-12 col-md-6 ">
                            <InputForm
                                id="contractValue"
                                type={"text"}
                                placeholder={"Valor Contrato"}
                                icon={"cellphone"}
                                className="form-control large"
                                onChange={this.onChangeCustomInput()}
                                onKeyDown = {"phone"}
                                value={this.state.contractValue}
                                validator={this.registerValidator}
                                validateOptions={'required|numeric'}
                            />
                        </div>
                        <div className="col-sm-12 col-md-6 ">
                            <InputForm
                                id="observations"
                                type={"text"}
                                placeholder={"Observaciones"}
                                icon={"observations"}
                                className="form-control large"
                                onChange={this.onChangeCustomInput()}
                                onKeyDown = {"alpha_num_space"}
                                value={this.state.observations}
                                validator={this.registerValidator}
                                validateOptions={'max:250'}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col col-12">
                            <PrimaryButton
                                color={"green"}
                                txtBtn={this.props.formType === "agreement"?"Continuar":"Registrarse"}
                                sizeWidth={""}
                                className={"float-right with-icon"}
                                icon={this.props.formType === "agreement"?"ContinueIcon":"Singin"}
                            >
                            </PrimaryButton>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

RegisterAgreement.propTypes = {
    setAgreementClient: PropTypes.func.isRequired,
    businessAdvisorReducer: PropTypes.object.isRequired,
    userReducer: PropTypes.object.isRequired,
    errorReducer: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    businessAdvisorReducer: state.businessAdvisorReducer,
    userReducer: state.userReducer,
    errorReducer: state.errorReducer
});

export default connect(mapStateToProps, { setAgreementClient, getUserByParameters })(RegisterAgreement)
