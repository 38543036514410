import React, {Component} from 'react'
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import CloseIcon from "../../../assets/img/icons/close.png";
import CalendarIcon from "../../../assets/img/icons/validity/validity.png";
import FilterIcon from "../../../assets/img/icons/filter.png";
import moment from "moment";
import {connect} from "react-redux";
import {filterListProduct, filterListProductSold,filterPendingSiigoInvoices, getPendingFinancial} from "../../../actions/financialActions";
import DatePicker, {registerLocale} from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import calendatEs from 'date-fns/locale/es';
import SimpleReactValidator from 'simple-react-validator';
import { reactValidatorOptions } from '../../../helpers/simpleReactValidator';

registerLocale('es', calendatEs);

class ModalFilters extends Component {
    
    state = {
        filters: {
            since: '',
            until: '',
            client: '',
            typeDocument: '',
            document: ''
        },
        actualOperation: '',
        typeDocument: [
            { value: 'CC', label: 'Cédula de ciudadanía' },
            { value: 'NIT', label: 'NIT' },
            { value: 'PS', label: 'Pasaporte' },
            { value: 'CE', label: 'Cédula extranjería' }
        ],
    };    

    onChangeInputFilters = e => {
        let filters = this.state.filters;
        filters[e.target.id] = e.target.value;
        this.setState({filters});
    };

    onChangeInputCalendar = (date, state) => {
        let filters = this.state.filters;
        filters[state] = date;
        this.setState({filters});
    };

    onCalculateMinDateUntil = () => {
        if (this.state.filters.since !== '') {
            let resData = new Date(this.state.filters.since);
            resData.setDate(resData.getDate() + 30);
            resData = resData > new Date() ? new Date() : resData;
            return resData;
        } else {
            return new Date();
        }
    };

    onFilter = async (e) => {
        e.preventDefault();
        let isEmptyForm = true;
        for (let i in this.state.filters) {
            if (this.state.filters[i] !== '') {
                isEmptyForm = false;
                break;
            }
        }
        if (!isEmptyForm) {
            const {since, until, client, typeDocument, document} = this.state.filters;
            await this.props.getPendingFinancial();
            await this.setState(this.props.modalFilterType === 'modalFilters' ?
                {pays: this.props.listProduct,}
                : {paysSold: this.props.dataFilter},
                async () => {
                    let allItems = this.props.modalFilterType === 'modalFilters' ? this.props.listProduct : this.props.dataFilter;
                    let filteredAssignments;

                    if (since !== '') {
                        filteredAssignments = await allItems.filter(i => {
                            if (this.props.modalFilterType === 'modalFilters') {
                                let dateCovert = i.dateVoucher.split('/');
                                let newDate = new Date(dateCovert[2], dateCovert[1] - 1, dateCovert[0]);
                                return moment(newDate).diff(moment(since), 'days') >= 0;
                            } else {
                                let dateCovert = i.dateApprove.split('/');
                                let newDate = new Date(dateCovert[2], dateCovert[1] - 1, dateCovert[0]);
                                return moment(newDate).diff(moment(since), 'days') >= 0;
                            }
                        });
                        allItems = filteredAssignments;
                    }
                    if (until !== '') {
                        filteredAssignments = await allItems.filter(i => {
                            if (this.props.modalFilterType === 'modalFilters') {
                                let dateCovert = i.dateVoucher.split('/');
                                let newDate = new Date(dateCovert[2], dateCovert[1] - 1, dateCovert[0]);
                                return moment(newDate).diff(moment(until), 'days') <= 0;
                            } else {
                                let dateCovert = i.dateApprove.split('/');
                                let newDate = new Date(dateCovert[2], dateCovert[1] - 1, dateCovert[0]);
                                return moment(newDate).diff(moment(until), 'days') <= 0;
                            }
                        });
                        allItems = filteredAssignments;
                    }
                    if (client !== '') {
                        filteredAssignments = await allItems.filter(i => {
                            return i.client.toLowerCase().indexOf(client.toLowerCase()) > -1;
                        });
                        allItems = filteredAssignments;
                    }
                    if (typeDocument !== '') {
                        filteredAssignments = await allItems.filter(i => {
                            return i.documentType === typeDocument;
                        });
                        allItems = filteredAssignments;
                    }

                    if (document !== '') {
                        filteredAssignments = await allItems.filter(i => {
                            if (!!i.documentNumber && i.documentNumber !== '') {
                                return i.documentNumber.indexOf(document) > -1;
                            }
                        });
                        allItems = filteredAssignments;
                    }
                    if (this.props.modalFilterType === 'modalFilters') {
                        this.props.filterListProduct(filteredAssignments);
                        this.props.toggleModal('modalFilters');
                    } else {
                        if (this.props.typeModalFilter === 'siigoPendings') {
                            this.props.filterPendingSiigoInvoices(filteredAssignments);
                            this.props.toggleModal('modalFilters');
                        }
                        else if (this.props.typeModalFilter === 'approved') {
                            this.props.filterListProductSold(filteredAssignments);
                            this.props.toggleModal('modalFilters');
                        }
                    }
                }
            );
            
        } else {
            this.props.toggleAlertModal('completeOnField');
        }
    };

    render() {
        const {modalFilters} = this.props;
        this.validatorUserForm = new SimpleReactValidator(reactValidatorOptions);
        return (
            <Modal isOpen={modalFilters}>
                <form onSubmit={async e => {
                    await this.onFilter(e);
                    await this.setState({
                        filters: {
                            since: '',
                            until: '',
                            client: '',
                            typeDocument: '',
                            document: '',
                        }
                    });
                }}
                >
                    <ModalHeader className="header-modal">
                        FILTROS AVANZADOS
                        <Button className="modal-close" onClick={async e => {
                            await this.props.toggleModal('modalFilters');
                            await this.setState({
                                filters: {
                                    since: '',
                                    until: '',
                                    client: '',
                                    typeDocument: '',
                                    document: '',
                                }
                            });
                            window.location.reload(true);
                        }}><img src={CloseIcon} alt="icon product"/></Button>
                    </ModalHeader>
                    <ModalBody>
                        <div className="container">
                            <div className="row text-center">
                                <div className="col-md-6 text-left">
                                    <div className="form-group">
                                        <label htmlFor="certificate">Desde</label>
                                        <div className="CalendarContainer">
                                            <DatePicker
                                                className="form-control text-input calendarSizeInput"
                                                id="since"
                                                name="since"
                                                onChange={async e => {
                                                    this.state.filters['until'] = '';
                                                    await this.onChangeInputCalendar(e, 'since');
                                                }}
                                                selected={this.state.filters.since}
                                                locale="es"
                                                dateFormat="MMMM d, yyyy"
                                                maxDate={new Date()}
                                            />
                                            <div className="toolTipButton"
                                                style={{
                                                    marginTop: "-10px",
                                                    marginLeft: "5px",
                                                    //width: "20%",
                                                    alignItems: " flex-start"
                                                }}
                                                onClick={e => document.getElementById('since').click()}
                                            >
                                                <span className="buttonVoucher" aria-label="Desde"
                                                    style={{
                                                        "justify-content": "flex-end"
                                                    }}
                                                >
                                                    <img src={CalendarIcon} alt="Calendario"
                                                         style={{width: '65%'}}/>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 text-left">
                                    <div className="form-group">
                                        <label htmlFor="certificate">Hasta</label>
                                        <div className="CalendarContainer">
                                            <DatePicker
                                                className="form-control text-input calendarSizeInput"
                                                id="until"
                                                name="until"
                                                onChange={e => this.onChangeInputCalendar(e, 'until')}
                                                selected={this.state.filters.until}
                                                locale="es"
                                                dateFormat="MMMM d, yyyy"
                                                maxDate={this.onCalculateMinDateUntil()}
                                                minDate={this.state.filters.since}
                                            />
                                            <div className="toolTipButton"
                                                style={{
                                                    marginTop: "-10px",
                                                    marginLeft: "5px",
                                                    width: "20%",
                                                    alignItems: " flex-start"
                                                }}
                                                onClick={e => document.getElementById('until').click()}
                                            >
                                                <span className="buttonVoucher" aria-label="Hasta" 
                                                    style={{
                                                        "justify-content": "flex-end"
                                                    }}
                                                >
                                                    <img src={CalendarIcon} alt="Calendario"
                                                         style={{width: '65%'}}/>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 text-left">
                                    <div className="form-group">
                                        <label htmlFor="delivery">Cliente</label>
                                        <input
                                            id="client"
                                            name="client"
                                            type="text"
                                            className="form-control text-input"
                                            onChange={e => this.onChangeInputFilters(e)}
                                            value={this.state.filters.client}
                                        >
                                        </input>
                                        {this.validatorUserForm.message('cliente', this.state.filters.client, 'required')}
                                    </div>
                                </div>
                                <div className="col-md-6 text-left">
                                    <div className="form-group">
                                        <label htmlFor="cellPhone">Tipo Documento</label>
                                        <select id="typeDocument"
                                                name="typeDocument"
                                                className="form-control text-input"
                                                value={this.state.filters.typeDocument}
                                                onChange={e => this.onChangeInputFilters(e)}
                                        >
                                            <option value="more">-- Ninguno --</option>
                                            {this.state.typeDocument.map((document, key) => {
                                                return <option value={document.value}
                                                               key={key}>{document.label}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6 text-left">
                                    <div className="form-group">
                                        <label htmlFor="certificate">Documento</label>
                                        <input
                                            id="document"
                                            name="document"
                                            type="text"
                                            className="form-control text-input"
                                            onChange={e => this.onChangeInputFilters(e)}
                                            value={this.state.filters.document}
                                        >
                                        </input>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter className="text-center">
                        <div className="col-12">
                            <button type="submit" className="btn btn-outline-success btn-submit-product">
                                <img className="input-icon" src={FilterIcon} alt="Buscar"/>
                                Buscar
                            </button>
                        </div>
                    </ModalFooter>
                </form>
            </Modal>
        )
    }
}

export default connect(null, {filterListProduct, filterListProductSold,filterPendingSiigoInvoices, getPendingFinancial})(ModalFilters)