import React from "react"
import PropTypes from "prop-types"
import './textarea-form.css'

import user from '../../assets/img/icons/user/user.png';
import number from '../../assets/img/icons/number.png';
import password from '../../assets/img/icons/password/password.png';
import cellphone from '../../assets/img/icons/cellphone.png';
import email from '../../assets/img/icons/email.png';
import entity from '../../assets/img/icons/entity.png';
import contract from '../../assets/img/icons/contract-value.png';
import observations from '../../assets/img/icons/observations.png';
import position from '../../assets/img/icons/position/positionx2.png';

/**
 * TextAreaForm constant ( generic HTML textarea for recursive implementation in forms )
 *
 * @param {*} {...props}
 * @returns HTML object
 */
const TextAreaForm = ({...props}) => {
    const { icon, placeholder, maxLength, id, className, onChange, value, label, disabled, validator, validateOptions, onBlur, error} = props;

    const icons = {
        user,
        password,
        cellphone,
        email,
        number,
        entity,
        contract,
        observations,
        position
    };

    return (
        <div className="form-group">
            <div className="input-group">
                {!!icon && icon !== '' ?
                    <span className = "input-group-addon"><img className = "input-group-icon" src = {icons[icon]} alt = {icons[icon]}/></span>
                    : null
                }
                {!!label && label !== '' ?
                    <label htmlFor={id} className={error || !!validator ? validator.message('', value, validateOptions) ? "text-danger" : "" : ""} >{label}</label>
                    : null
                }
                <textarea
                    className={`${className} ${error || (!!validator && validator.message('', value, validateOptions)) ? 'is-invalid' : ''} ${icon ? icon : ''}`}
                    value={value}
                    maxLength={maxLength}
                    placeholder={placeholder}
                    id={id}
                    onChange={onChange}
                    disabled={disabled}
                    onBlur={onBlur}
                />
                {disabled ?
                    null
                    : !!validator
                        ? validator.message(label !== '' && label !== undefined ? label : placeholder, value, validateOptions)
                        : null
                }
                {error ?
                    <div className="invalid-feedback">{error}</div>
                    : null
                }
            </div>
        </div>
    )
};


TextAreaForm.propTypes = {
    labelProps: PropTypes.object,
    id: PropTypes.string,
};

export default TextAreaForm;