import React, { Component } from 'react'
import AddProduct from "../../assets/img/buttons/add-product2.png";
import Registry from "../../assets/img/buttons/registry.png";
import Singin from "../../assets/img/buttons/singin.png";
import ContinueIcon from "../../assets/img/buttons/continue.png";
import Quotation from "../../assets/img/buttons/quotation.png";
import Buy from "../../assets/img/buttons/buy.png";
import cashPayment from "../../assets/img/buttons/cashPayment.png";
import Button from 'react-bootstrap/Button'
import QuotationIcon from '../../assets/img/buttons/quotation.png';
import SingIn from '../../assets/img/buttons/singin.png';
import '../PrimaryButton/PrimaryButton.css'
import './inputFile.css'

class InputFile extends Component {
    ref = React.createRef();

    render() {
        const { onSelectFile, label, className, color, icon, id, disabled, validator, validateOptions, value, error } = this.props;
        const icons = {
            AddProduct,
            Registry,
            Singin,
            ContinueIcon,
            Quotation,
            Buy,
            cashPayment,
            QuotationIcon,
            SingIn
        };

        return (
            <div className="form-group">
                <div className="input-group cst-file">
                    <div>
                        <Button
                            className={`${className} ${color}`}
                            onClick={(e) => {
                                e.preventDefault();
                                this.ref.current.click()
                            }}>
                            <span className="btn-label">
                                <img className="iconbutton" src={icons[icon]} alt={icons[icon]} />
                            </span>
                            <div className="btn-text">
                                {label}
                            </div>
                        </Button>
                        <input
                            id={id}
                            ref={this.ref}
                            type="file"
                            hidden
                            onChange={onSelectFile}
                        />
                        { !disabled && validator && validator.message('archivo', value, validateOptions) }
                        {error ?
                            <div className="invalid-feedback">{error}err!!!!!</div>
                            : null
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default InputFile