import React from "react"
import PropTypes from "prop-types"
import Tooltip from '@material-ui/core/Tooltip';

import InputForm from "../../../components/InputForm/InputForm";
import SelectForm from "../../../components/SelectForm/SelectForm";

import warningIcon from '../../../assets/img/icons/warning.png';

export const MandatoryFields = ({ ...props }) => {
    const { selectedProduct, useInvoiceData, dataForm, formValidator, onBlurInput, onChangeInput, onChangeCheckbox, validateQuickEmail, compareEmail, mouseRighr} = props;
    const handleChange = (e) => {
        e.preventDefault();
    };
    return (
        <>
            <div className="text-center font-weight-bold mb-4 col-12">DATOS OBLIGATORIOS</div>
            {selectedProduct.invoiceData.personType !== 'Convenio' && //check solo visible para los clientes que no sean tipo convenio
                <>
                    {selectedProduct.invoiceData && selectedProduct.invoiceData.isLegalClient === true ?

                        <div className="form-group">
                            <Tooltip title="Este producto fue facturado a nombre de una persona Jurídica, no es posible utilizar esta información" placement="top-start" interactive>
                                <div className="custom-control custom-switch">
                                    <input
                                        id="useInvoiceData"
                                        name="useInvoiceData"
                                        type="checkbox"
                                        className="custom-control-input"
                                        disabled
                                    />
                                    <label className="custom-control-label" htmlFor="useInvoiceData">Utilizar datos de Factura</label>
                                </div>
                            </Tooltip>
                        </div>
                        :
                        <div className="form-group">
                            <div className="custom-control custom-switch">
                                <input
                                    id="useInvoiceData"
                                    name="useInvoiceData"
                                    type="checkbox"
                                    className="custom-control-input"
                                    checked={useInvoiceData}
                                    value={useInvoiceData}
                                    onChange={onChangeCheckbox}
                                />
                                <label className="custom-control-label" htmlFor="useInvoiceData">Utilizar datos de Factura</label>
                            </div>
                        </div>
                    }
                </>
            }
            <div className="row">
                <div className="d-grid col-md-6">
                    <SelectForm
                        id="documentType"
                        label={"Tipo Documento"}
                        placeholder={"Tipo Documento"}
                        className="form-control text-input"
                        onBlur={onBlurInput}
                        onChange={onChangeInput}
                        value={dataForm.documentType}
                        options={dataForm.documentTypes}
                        validator={formValidator}
                        validateOptions={'required'}
                    />
                </div>
                <div className="d-grid col-md-6">
                    <InputForm
                        id="documentNumber"
                        type={"text"}
                        maxLength="15"
                        label={"Número Documento"}
                        placeholder={"Número Documento"}
                        className="form-control text-input"
                        onBlur={onBlurInput}
                        onChange={onChangeInput}
                        onKeyDown={dataForm.documentType === 'PS' ? 'document' : 'number'}
                        value={dataForm.documentNumber}
                        validator={formValidator}
                        validateOptions={dataForm.documentType === 'PS' ? 'required|min:5|alpha_num|passportFormat' : 'required|min:5|numeric'}
                    />
                </div>
                <div className="d-grid col-md-6">
                    <InputForm
                        id="firstName"
                        type={"text"}
                        label={"Primer Nombre"}
                        placeholder={"Primer Nombre"}
                        className="form-control text-input"
                        onChange={props.onChangeInput}
                        onKeyDown={'alpha_space'}
                        value={dataForm.firstName}
                        validator={formValidator}
                        validateOptions={'required|names'}
                    />
                </div>
                <div className="d-grid col-md-6">
                    <InputForm
                        id="secondName"
                        type={"text"}
                        label={"Segundo Nombre"}
                        placeholder={"Segundo Nombre"}
                        className="form-control text-input"
                        onChange={props.onChangeInput}
                        onKeyDown={'alpha_space'}
                        value={dataForm.secondName}
                        validator={formValidator}
                        validateOptions={'names'}
                    />
                </div>
                <div className="d-grid col-md-6">
                    <InputForm
                        id="lastName"
                        type={"text"}
                        label={"Primer Apellido"}
                        placeholder={"Primer Apellido"}
                        className="form-control text-input"
                        onChange={onChangeInput}
                        onKeyDown={'alpha_space'}
                        value={dataForm.lastName}
                        validator={formValidator}
                        validateOptions={'required|names'}
                    />
                </div>
                <div className="d-grid col-md-6">
                    <InputForm
                        id="lastSurname"
                        type={"text"}
                        label={"Segundo Apellido"}
                        placeholder={"Segundo Apellido"}
                        className="form-control text-input"
                        onChange={onChangeInput}
                        onKeyDown={'alpha_space'}
                        value={dataForm.lastSurname}
                        validator={formValidator}
                        validateOptions={'names'}
                    />
                </div>
                <div className="d-grid col-md-6" onContextMenu={(e) => {
                        e.preventDefault();
                    }}> 
                    <InputForm
                        id="email"
                        type={"email"}
                        label={"Correo Electrónico"}
                        placeholder={"Correo Electrónico"}
                        className="form-control text-input"
                        onBlur={(e) => compareEmail(e)}
                        onChange={onChangeInput}
                        onKeyDown={'email'}
                        value={dataForm.email}
                        validator={formValidator}
                        validateOptions={'required|email'}
                        error={dataForm.emailError}
                    />
                </div>
                <div className="d-grid col-md-6" onContextMenu={(e) => {
                        e.preventDefault();
                    }}> 
                    <InputForm
                        id="confirmEmail"
                        type={"text"}
                        label={"Confirmar Correo Electrónico"}
                        placeholder={"Confirmar Correo Electrónico"}
                        className="form-control text-input"
                        value={dataForm.confirmEmail}
                        onChange={onChangeInput}
                        onKeyDown={'email'}
                        onBlur={(e) => validateQuickEmail(e)}
                        onPaste={handleChange}
                        validator={formValidator}
                        validateOptions={'required|email'}
                        error={dataForm.confirmEmailError}
                        autoComplete={"off"}
                    />       
                </div>
                <div className="d-grid col-md-6">
                    <InputForm
                        id="cellPhone"
                        type={"text"}
                        maxLength="18"
                        label={"Celular"}
                        placeholder={"Celular"}
                        className="form-control text-input"
                        onChange={onChangeInput}
                        onKeyDown={'phone'}
                        value={dataForm.cellPhone}
                        validator={formValidator}
                        validateOptions={'required|numeric|min:7'}
                    />
                </div>
                <div className="d-grid col-md-6">
                    <SelectForm
                        id="town"
                        label={"Municipio"}
                        placeholder={"Municipio"}
                        className="form-control text-input"
                        onChange={onChangeInput}
                        value={dataForm.town}
                        options={dataForm.towns}
                        validator={formValidator}
                        validateOptions={'required'}
                    />
                </div>
                {dataForm.currentUserEmail && dataForm.currentUserEmail !== dataForm.email &&
                    <div className="d-flex col-md-12">
                        <div className="warning-container mt-2">
                            <div className="icon-container">
                                <img className="warning-msg-icon" src={warningIcon} alt="warning msg icon" />
                            </div>
                            <div className="txt-container">
                                Ten en cuenta que si modificas el correo electrónico, toda la información llegará al nuevo correo que estás indicando.
                            </div>

                        </div>
                    </div>
                }
            </div>
        </>
    )
};

MandatoryFields.propTypes = {
    dataForm: PropTypes.object.isRequired,
    onChangeInput: PropTypes.func.isRequired,
    onChangeCheckbox: PropTypes.func.isRequired,
    formValidator: PropTypes.any.isRequired
};