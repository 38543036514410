import React from 'react';

import { BrowserRouter, Route, Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import Home from '../views/dashboard/Home';

import Login from "../views/auth/Login";
import Logout from "../views/auth/Logout";
import Products from "../views/products/Products";
import Purchases from "../views/purchases/Purchases";
import Cart from "../views/shopping_cart/Shopping_cart";
import AdminProducts from "../views/admin_products/Admin_products";
import Advisor from "../views/advisor/Advisor";
import BusinessAdvisor from "../views/businessAdvisor/BusinessAdvisor";
import PrivateRoute from "../components/PrivateRoute";
import PublicRoute from "../components/PublicRoute";
import Financial from "../views/financial/Financial";
import PaymentResponse from "../views/paymentResponse/PaymentResponse";

let history = createBrowserHistory();

/**
 * Browser Router (export a browser router with respective routes,
 * PrivateRoute middleware params(param1: Component, param2: 'accepted roles separated with colon eg. "Admin, Guest"')
 * can you send "" in param2 for accept all loggedIn users)
 *
 * @export class
 * @class Routes
 * @extends {React.Component}
 */
export default class Routes extends React.Component {
    render() {
        return (
            <BrowserRouter>
                <Router history = {history}>
                    <Route path = "/" component = {PublicRoute(Purchases)} exact></Route>
                    <Route path = "/changePassword/:base64Data" component = {PublicRoute(Purchases)} exact></Route>
                    <Route path = "/changePassword" component = {PublicRoute(Purchases)} exact></Route>
                    <Route path = "/login" component = {PublicRoute(Login)} exact></Route>
                    <Route path = "/logout" component = {PublicRoute(Logout)} exact></Route>

                    <Route path = "/home" component = {PrivateRoute(Home)} exact></Route>
                    <Route path = "/products" component = {PrivateRoute(Products)} exact></Route>
                    <Route path = "/products/:clientID" component = {PrivateRoute(Products, 'Advisor')} exact></Route>
                    <Route path = "/cart" component = {PrivateRoute(Cart)} exact></Route>
                    <Route path = "/admin" component = {PrivateRoute(AdminProducts)} exact></Route>
                    <Route path = "/admin/:clientID" component = {PrivateRoute(AdminProducts, 'Advisor')} exact></Route>
                    <Route path = "/cart/:clientID" component = {PrivateRoute(Cart, 'Advisor')} exact></Route>
                    <Route path = "/advisor" component = {PrivateRoute(Advisor)} exact></Route>
                    <Route path = "/businessAdvisor" component = {PrivateRoute(BusinessAdvisor)} exact></Route>
                    <Route path = "/financial" component = {PrivateRoute(Financial, 'Financial')} exact></Route>
                    <Route path = "/payment-response" component = {PublicRoute(PaymentResponse)} exact />
                </Router>
            </BrowserRouter>
        )
    }
}
