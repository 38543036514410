import React, { Fragment } from "react";
import './card_body.css'
import Card from 'react-bootstrap/Card'

/**
 * CardBody Component ( component usable as container, accept div tree as children, and set card appearance )
 * 
 * @export constant
 * @param {*} { ...props }
 * @returns React Bootstrap Card
 */
const CardBody = ({ ...props }) => {
    const { children } = props;

    return (
        <Fragment>
            <Card className='cardbody' style={{ maxWidth: props.maxWidth ? props.maxWidth : '70%' }}>
                <div className="card-header">
                    <h2 className="card-title">{props.title}</h2>
                    <samp className="card-description">{props.descriptionTitle}</samp>
                </div>
                <Card.Body>
                    {children}
                </Card.Body>
            </Card>
        </Fragment>
    );
};


export default CardBody;
