import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';

import adminIcon from '../../assets/img/icons/administration/administration_white.png';

const useStyles = makeStyles(theme => ({
    fab: {
        position: 'fixed',
        bottom: '5%',
        right: '3%',
        height: '63px',
        backgroundColor: '#fc9231',
        color: 'white',
        zIndex: 1251,
    },
    appBar: {
        
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    img: {
        width: '40px'
    }
}));

export default function FloatingButton(props) {
    const classes = useStyles();

    return (
        <Fab variant="extended" aria-label="Delete" className={classes.fab} onClick={e => props.clickEvent()}>
            <img src={adminIcon} className={classes.img} alt="administracion" />
            Administrar <br/> mis productos
        </Fab>
    );
}