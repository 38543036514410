import React, { Component } from "react";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import CloseIcon from "../../../assets/img/icons/close.png";
import AlertIcon from "../../../assets/img/icons/alert/alert.png";
import Suspend from "../../../assets/img/icons/suspend/suspend.png";

import SelectForm from "../../../components/SelectForm/SelectForm";
import TextAreaForm from "../../../components/TeatAreaForm/TextAreaForm";

import { getPendingFinancial, rejectPayment, postSuspendedUser } from "../../../actions/financialActions";
import { reactValidatorOptions } from "../../../helpers/simpleReactValidator";

/**
 * ModalAlertReject Component ( render a modal with a Sale-Rejection form )
 *
 * @export Class Component
 * @class ModalAlertReject
 * @extends {Component}
 * @returns Redux connect
 */
class ModalAlertReject extends Component {
    constructor(props) {
        super(props);

        this.state = {
            rejectDescription: '',
            additionalRejectDescription: '',
            areButtonsActive: true,
            errors: {}
        };

        this.modalAlertRejectValidator = new SimpleReactValidator(reactValidatorOptions);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({ areButtonsActive: nextProps.areButtonsActive });
    }

    onChangeCustomInput = e => {
        if (!!e.target.id) {
            this.setState({[e.target.id]: e.target.value, errors: {}}, () => {
                if (this.state.rejectDescription !== 'Otros') {
                    this.modalAlertRejectValidator = new SimpleReactValidator(reactValidatorOptions);
                }
            });
        } else {
            console.error('Error actualizando la informacion ingresada', 'error');
        }
    };

    cleanForm = () => {
        this.setState({ rejectDescription: '', additionalRejectDescription: '' }, () => {
            this.modalAlertRejectValidator = new SimpleReactValidator(reactValidatorOptions);
        });

        this.props.setFinancialState({
            modalAlertReject: false,
            modelAlertRejectTitle: '',
            modelAlertRejectMessage: ''
        });

        this.props.toggleModal('modalAlertReject');
    };

    onSubmit = (e) => {
        e.preventDefault();

        if (this.modalAlertRejectValidator.allValid()) {
            this.props.setFinancialState({
                modalSuccessfulTitle: 'RECHAZAR COMPRA',
                modalSuccessfulMessage: ' el pago satisfactoriamente',
                areButtonsActive: false
            });

            this.cleanForm();

            this.props.rejectPayment(this.props.modalAlertRejectId, this.state.rejectDescription, this.state.additionalRejectDescription);
            this.props.getPendingFinancial();
        } else {
            this.modalAlertRejectValidator.showMessages();
            this.forceUpdate();
        }
    };

    render() {
        return (
            <Modal isOpen={this.props.showModal}>
                <form onSubmit={e => this.onSubmit(e)}>
                    <ModalHeader className="header-modal text-center">
                        {this.props.title}
                        <Button className="modal-close" onClick={ e => this.cleanForm() }>
                            <img src={CloseIcon} alt="Cerrar"/>
                        </Button>
                    </ModalHeader>
                    <ModalBody>
                        <div className="container">
                            <div className="row">
                                <div className="col-10 offset-1 text-center">
                                    <img src={AlertIcon} alt="Logo Alerta"/>
                                    <br/><br/>
                                    <p> ¿<span style={{color: '#2C9C72'}}>Está seguro</span> {this.props.message} </p>
                                </div>

                                <div className="col-8 offset-2 text-left" style={{ marginTop: '15px' }}>
                                    <div className = "form-group">
                                        <SelectForm
                                            id="rejectDescription"
                                            label={"Motivo del rechazo"}
                                            placeholder={"Motivo del rechazo"}
                                            className="form-control text-input"
                                            onChange={e => this.onChangeCustomInput(e)}
                                            value={this.state.rejectDescription}
                                            options={this.props.rejectSaleOptions}
                                            validator={this.modalAlertRejectValidator}
                                            validateOptions={'required'}
                                            addDisabledOption={true}
                                        />
                                    </div>
                                </div>

                                {this.state.rejectDescription === 'Otros'
                                    ? <div className="col-8 offset-2 text-left" style={{ marginTop: '10px' }}>
                                        <TextAreaForm
                                            id="additionalRejectDescription"
                                            label={""}
                                            placeholder={"¿Cuál?"}
                                            className="form-control text-input"
                                            onChange={e => this.onChangeCustomInput(e)}
                                            value={this.state.additionalRejectDescription}
                                            validator={this.modalAlertRejectValidator}
                                            validateOptions={'required'}
                                        />
                                    </div>
                                    : null
                                }
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter className="text-center">
                        <div className="col-12">
                            <button
                                type="submit"
                                className="btn btn-outline-success btn-submit-product"
                                disabled={!this.state.areButtonsActive}
                            >
                                <img
                                    className="input-icon"
                                    src={Suspend}
                                    alt="Rechazar"
                                    style={this.state.areButtonsActive
                                        ? {marginRight: '15px', width: '30px', height: '25px'}
                                        : {marginRight: '15px', width: '30px', height: '25px', opacity: '0.5', cursor: 'not-allowed'}
                                    }
                                />
                                Rechazar
                            </button>
                        </div>
                    </ModalFooter>
                </form>
            </Modal>
        );
    };
}

const mapStateToProps = state => ({
    rejectSaleOptions: state.financialReducer.rejectSaleOptions
});

export default connect(mapStateToProps, { getPendingFinancial, rejectPayment, postSuspendedUser })(ModalAlertReject);
