import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { storeVoucher, errorFileSize } from '../../actions/shoping_cartActions';

import SimpleReactValidator from 'simple-react-validator';
import { reactValidatorOptions } from '../../helpers/simpleReactValidator';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';

/**
 * StoreVoucher Component ( full view StoreVoucher component, render a StoreVoucher
 * form and set an image to shopping cart register )
 *
 * @export Class Component
 * @class StoreVoucher
 * @extends {Component}
 * @returns Redux connect
 */
export class StoreVoucher extends Component {

    constructor(props) {
        super(props);

        this.state = {
            attachment: null,
            attachmentVal: '',
            modalMsg: [],
        };

        this.validatorVoucherForm = new SimpleReactValidator(reactValidatorOptions);
    }

    componentWillReceiveProps(nextProps) {

    }

    onChangeCustomFile = () => e => {
        if (!!e.target.id) {
            this.setState({ attachment: e.target.files[0], errors: {} });
            this.setState({ attachmentVal: e.target.value, errors: {} });
        } else {
            console.error('Error actualizando la informacion ingresada', 'error');
        }
    };

    storeVoucher = (event) => {
        event.preventDefault();
        if (this.validatorVoucherForm.allValid()) {
            if (this.state.attachment.size > 300000){
                this.props.errorFileSize();     
            }else{
                var data = new FormData();
    
                data.append('attachment', this.state.attachment);
                if (this.props.clientID !== null) data.append('clientID', this.props.clientID);
                
                const type = 'store';
                this.props.storeVoucher(data, type);            
            }
        } else {
            this.validatorVoucherForm.showMessages();
            this.forceUpdate();
        }
    }

    render() {
        return (
            <div id="attachmentForm">
                <div className="hl"></div>

                <div className="textIntro">
                    <p className="text-center sectionTitle">Adjuntar <span className="green-text">Comprobante</span> de pago</p>
                </div>

                <div className="col-10 offset-1">
                    <form onSubmit={event => this.storeVoucher(event)}>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <input type="file"
                                    className="form-control text-input cst-file-input"
                                    id="attachmentVal"
                                    placeholder="Adjunto"
                                    value={this.state.attachmentVal}
                                    onChange={this.onChangeCustomFile()}
                                >
                                </input>
                                {this.validatorVoucherForm.message('adjunto', this.state.attachmentVal, 'required')}
                            </div>

                            <div className="col-md-6 text-center">
                                <PrimaryButton
                                    color={"green"}
                                    txtBtn={"Adjuntar"}
                                    sizeWidth={""}
                                    className={"with-icon"}
                                    icon={"cashPayment"}
                                >
                                </PrimaryButton>
                                
                                
                                {/* <button type="submit" className="btn btn-primary pay btn-labeled btn-attachment">
                                    <span className="btn-label"><img className="iconbutton" src={cashPayment} alt="btn shop" /></span>
                                    Adjuntar
                            </button> */}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

StoreVoucher.propTypes = {
    storeVoucher: PropTypes.func.isRequired,
    errorFileSize: PropTypes.func.isRequired,
    shoppingCartReducer: PropTypes.object.isRequired,
    errorReducer: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    shoppingCartReducer: state.shoppingCartReducer,
    errorReducer: state.errorReducer
})

export default connect(mapStateToProps, { storeVoucher, errorFileSize })(StoreVoucher)
