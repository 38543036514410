import React from "react"
import PropTypes from "prop-types"
import AddressPersonalizedForm from '../../../components/AddressPersonalizedForm/AddressPersonalizedForm';
import SelectForm from "../../../components/SelectForm/SelectForm";

export const AditionalInfo = ( props ) => {
    return (
        <>
            <div className="row">
                <div className="text-center font-weight-bold mb-4 col-12">INFORMACIÓN ADICIONAL (OPCIONAL)</div>
                <div className="d-grid col-md-6">
                    <SelectForm
                        id="certificateUse"
                        label={"Uso del certificado"}
                        placeholder={"Uso del certificado"}
                        className="form-control text-input"
                        onChange={props.onChangeInput}
                        value={props.certificateUse}
                        options={props.certificateUses}
                    />
                </div>
                <div className="d-grid col-md-6">
                    <label htmlFor="certificateDelivery">Entrega del Certificado</label>
                    <div id="certificateDelivery" className="form-group">
                        <div className="custom-control custom-radio custom-control-inline">
                            <input name="certificateDelivery"
                                id="certificateDelivery_0"
                                type="radio"
                                className="custom-control-input"
                                value="clientAddress"
                                checked={props.certificateDelivery === "clientAddress"}
                                onChange={(value) => { props.handleCertificateDelivery(value) }}
                            >
                            </input>
                            <label htmlFor="certificateDelivery_0" className="custom-control-label">Dirección Cliente</label>
                        </div>
                        <div className="custom-control custom-radio custom-control-inline">
                            <input name="certificateDelivery"
                                id="certificateDelivery_1"
                                type="radio"
                                className="custom-control-input"
                                value="gseAddress"
                                checked={props.certificateDelivery === "gseAddress"}
                                onChange={(value) => { props.handleCertificateDelivery(value) }}
                            >
                            </input>
                            <label htmlFor="certificateDelivery_1" className="custom-control-label">Recoger en GSE</label>
                        </div>
                    </div>
                </div>


                <label className="d-grid col-md-6" htmlFor="direction">Dirección de Entrega </label>
                <div className="d-grid col-md-7">
                    <div className="custom-control custom-switch">
                        <input
                            id="directionAsistant"
                            name="directionAsistant"
                            type="checkbox"
                            className="custom-control-input"
                            checked={props.useDirectionAsist}
                            value={props.useDirectionAsist}
                            onChange={e =>  props.handleShowDirAssistant(e) }
                            disabled = {props.certificateDelivery  === "gseAddress" ? true : false}
                        >
                        </input>
                        <label className="custom-control-label" htmlFor="directionAsistant">
                            Utilizar el asistente para el ingreso de direcciones
                        </label>
                    </div>
                </div>
                {!props.useDirectionAsist  ?
                <div className=" mt-3 d-grid col-md-8">
                    <div style={{display:'flex'}}>
                        <label className="ml-n3 col-md-4" htmlFor="addresss">Dirección completa:</label>
                        <input
                            id="addressOnly"
                            maxLength="200"
                            class="ml-n4 form-control text-input  "
                            label={"Dirección completa"}
                            onChange={props.onChangeAddressFields()}
                            //value={props.addressForm.address}
                            disabled = {props.certificateDelivery  === "gseAddress" ? true : false}
                            value={props.certificateDelivery  === "gseAddress" ? props.GSEDirection : props.addressForm.address}
                        />
                    </div>
                </div>
                :
                <div className="col-md-12">
                    {props.certificateDelivery === 'clientAddress' &&
                        <div className="row">
                            <AddressPersonalizedForm
                                validatorUserForm={props.addressValidator}
                                onChangeAddressFields={props.onChangeAddressFields}
                                handleAddressReactSelect={props.handleAddressReactSelect}
                                address_field_1={props.addressForm.address_field_1}
                                address_field_2={props.addressForm.address_field_2}
                                address_field_3={props.addressForm.address_field_3}
                                address_field_4={props.addressForm.address_field_4}
                                address_field_5={props.addressForm.address_field_5}
                                address_field_6={props.addressForm.address_field_6}
                                address_field_7={props.addressForm.address_field_7}
                                address_field_8={props.addressForm.address_field_8}
                                address_field_9={props.addressForm.address_field_9}
                                address_field_10={props.addressForm.address_field_10}
                                address_complementary_field_1={props.addressForm.address_complementary_field_1}
                                address_complementary_field_2={props.addressForm.address_complementary_field_2}
                                address_complementary_field_3={props.addressForm.address_complementary_field_3}
                                address_complementary_field_4={props.addressForm.address_complementary_field_4}
                                address={props.addressForm.address}
                            >
                            </AddressPersonalizedForm>
                        </div>
                    }
                </div>
                }


            </div>
        </>
    )
};

AditionalInfo.propTypes = {
    onChangeInput: PropTypes.func.isRequired,
    handleCertificateDelivery: PropTypes.func.isRequired,
    validatorUserForm: PropTypes.func.isRequired,
    onChangeAddressFields: PropTypes.func.isRequired,
    handleAddressReactSelect: PropTypes.func.isRequired
};
