import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { loginUser } from "../../actions/authActions";

import ChangePassword from '../auth/ChangePassword.js';
import ForgotPassword from '../auth/ForgotPassword.js';
import InputForm from '../../components/InputForm/InputForm';
import ModalForm from '../../components/ModalForm/ModalForm';
import ModalMsg from '../../components/ModalMsg/ModalMsg';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import ValidateCode from '../auth/ValidateCode.js';

import SimpleReactValidator from 'simple-react-validator';
import { reactValidatorOptions } from '../../helpers/simpleReactValidator';

import forget from '../../assets/img/icons/forget.png';

/**
 * Login Component ( full view Login component, render a login
 * form and make a post request for user verification  )
 *
 * @export Class Component
 * @class Login
 * @extends {Component}
 * @returns Redux connect
 */
export class Login extends Component {

    constructor(props) {
        super(props);

        const modalData = {
            title: 'Cambio de contraseña',
            label: 'Ingrese su nueva contraseña en los campos vacíos.',
            type: 'changePassword',
            show: true
        };

        let params = null;

        if (this.props.base64URLData) {
            const data = Buffer.from(this.props.base64URLData, 'base64').toString('ascii');
            params = JSON.parse(data);
        }

        this.state = {
            username: params ? params.username : '',
            password: '',
            modalMsg: [],
            modalForm: params ? modalData : [],
            inputType: 'password',
            wording: 'ver',
            params
        };

        this.loginValidator = new SimpleReactValidator(reactValidatorOptions);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.authReducer.passChanged) {
            const res = nextProps.authReducer.passChangeResponse;
            const passTemp = nextProps.authReducer.newPassword;
            if (res.validated === true) {
                this.setState({
                    modalForm: {
                        title: 'Validación de correo',
                        label: res.msg,
                        type: 'validateCode',
                        show: true
                    }
                });
            } else {
                this.setState({
                    modalMsg: {
                        title: 'Contraseña cambiada',
                        label: res.msg,
                        type: 'check'
                    },
                    password: passTemp
                })
                this.setLogin(null, localStorage.getItem('loginMsg') ? localStorage.getItem('loginMsg') : null);
                localStorage.removeItem('loginMsg')
            }
        } else if (nextProps.authReducer.accountActivated) {
            const res = nextProps.authReducer.activateResponse;
            if (res.validated === true) {
                this.setState({
                    modalForm: {
                        title: 'Validación de correo',
                        label: res.msg,
                        type: 'validateCode',
                        show: true
                    }
                });
            } else {
                this.setState({
                    modalMsg: {
                        title: 'Contraseña cambiada',
                        label: res.msg,
                        type: 'check',
                        redirect: 'refresh'
                    }
                })
                this.openModalMsg();
            }
        } else if (nextProps.authReducer.accountRecovered) {
            const res = nextProps.authReducer.recoverResponse;
            this.setState({
                modalMsg: {
                    title: 'Recuperar contraseña',
                    label: res.msg,
                    type: 'check',
                    redirect: 'refresh'
                }
            })
            this.openModalMsg();
            this.setState({ modalForm: { show: false } })
        } else if (!!nextProps.errorReducer && nextProps.errorReducer.hasErrors) {
            const errReducer = nextProps.errorReducer.data;
            if (!!errReducer.changePassword && !!errReducer.changePassword === true) {
                this.setState({
                    modalForm: {
                        title: 'Cambio de contraseña',
                        label: errReducer.msg,
                        type: 'changePassword',
                        show: true
                    },
                    username: this.state.username
                })
            } else if (!!errReducer.validate && !!errReducer.validate === true) {
                this.setState({
                    modalForm: {
                        title: 'Validación de correo',
                        label: errReducer.msg,
                        type: 'validateCode',
                        show: true
                    }
                })
            } else {
                this.setState({
                    modalMsg: {
                        title: 'Error',
                        label: !!errReducer.msg ? errReducer.msg : errReducer.message,
                        type: 'alert'
                    }
                });
                this.openModalMsg();
            }
        }
    }

    acceptMethods = (openModalMsg) => {
        this.openModalMsg = openModalMsg;
    };

    toggleModalForm = () => {
        this.setState({
            modalForm: { show: !this.state.modalForm.show }
        });
    }

    onChangeCustomInput = () => e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    openRecoverModal() {
        this.setState({
            modalForm: {
                title: 'Recuperar contraseña',
                label: 'Ingrese su nombre de usuario',
                type: 'recover',
                show: true
            }
        });
    }

    setLogin = (e, msg = null) => {
        if (e) e.preventDefault();
        console.log("e");
        console.log(e);
        if (this.loginValidator.allValid()) {
            const { username, password } = this.state;
            let userData = {
                username: username,
                password
            };
            this.props.loginUser(this.props.history, userData, msg);
        } else {
            this.loginValidator.showMessages();
            this.forceUpdate();
        }
    }

    renderModalForm() {
        if (this.state.modalForm.type === 'changePassword') {
            return (
                <ChangePassword title={this.state.modalForm.label} userName={this.state.username} urlData={this.state.params}></ChangePassword>
            )
        } else if (this.state.modalForm.type === 'recover') {
            return (
                <ForgotPassword title={this.state.modalForm.label}></ForgotPassword>
            )
        } else if (this.state.modalForm.type === 'validateCode') {
            return (
                <ValidateCode title={this.state.modalForm.label} email={this.state.username}></ValidateCode>
            )
        }
    }

    changeState() {
        let oldState = this.state.inputType;
        let isTextOrHide = (oldState === 'password');
        let newState = (isTextOrHide) ? 'text' : 'password';
        let newWord = (isTextOrHide) ? 'ocultar' : 'ver';
        this.setState({
            inputType: newState,
            wording: newWord
        });
    }

    render() {
        return (
            <div className="form-container">
                <form action="" id="login-validation" className="center-margin" onSubmit={this.setLogin}>
                    <div className="row">
                        <div className="col-sm-12 col-md-6 ">
                            <InputForm
                                id="username"
                                type={"text"}
                                placeholder={"Usuario"}
                                icon={"user"}
                                className="form-control large"
                                onChange={this.onChangeCustomInput()}
                                onKeyDown="username"
                                value={this.state.username}
                                autoComplete="username"
                                validator={this.loginValidator}
                                validateOptions={'required|min:5|max:20|username'}
                            />

                        </div>
                        <div className="col-sm-12 col-md-6 ">
                            <InputForm
                                id="password"
                                type={this.state.inputType}
                                placeholder={"Contraseña"}
                                icon={"password"}
                                className="form-control large"
                                onChange={this.onChangeCustomInput()}
                                value={this.state.password}
                                validator={this.loginValidator}
                                validateOptions={'required|password|min:8'}
                                onClick={() => { this.changeState() }}
                                wording={this.state.wording}
                                viewPass="viewPass"
                            />
                        </div>
                    </div>

                    <div className="col-sm-12 text-right">
                        <div className="clickable d-inline-block"
                            onClick={() => { this.openRecoverModal() }}>
                            <p className="forgetPass text-right">
                                <img src={forget} alt="forget" />
                                Olvide mi contraseña
                            </p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col col-12">
                            <PrimaryButton
                                color={"green"}
                                txtBtn={"Ingresar"}
                                sizeWidth={""}
                                className={"float-right with-icon"}
                                icon={"Singin"}
                            >
                            </PrimaryButton>
                        </div>
                    </div>
                </form>

                <ModalMsg
                    shareMethods={this.acceptMethods}
                    title={this.state.modalMsg.title}
                    show={this.state.modalMsg.show}
                    label={this.state.modalMsg.label}
                    type={this.state.modalMsg.type}
                    redirect={this.state.modalMsg.redirect}
                    history={this.props.history}
                ></ModalMsg>

                <ModalForm
                    title={this.state.modalForm.title}
                    isOpen={this.state.modalForm.show}
                    toggle={this.toggleModalForm}
                    label={this.state.modalForm.label}>
                    {this.renderModalForm()}
                </ModalForm>
            </div>
        )
    }
}

Login.propTypes = {
    loginUser: PropTypes.func.isRequired,
    authReducer: PropTypes.object.isRequired,
    errorReducer: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer
})

export default connect(mapStateToProps, { loginUser })(Login)
