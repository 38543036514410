import axios from 'axios';
import { API_ENDPOINT, defaultResponses } from "../config/config";
import {
    GET_ERRORS,
    GET_DETAIL_SALE,
    DETAIL_SALE_REMOVE_PRODUCT,
    DETAIL_SALE_UPDATE_QUANTITY_PRODUCT,
    UPDATE_PRODUCT_LIST,
    CLEAR_ERRORS,
    GET_SHOPPING_USER,
    SEND_INVOICE,
    BUY_PRODUCTS,
    CLEAR_SHOPPING_CART,
    STORE_VOUCHER,
    SEND_QUOTATION,
    MODIFY_CART,
    UPDATE_TOTAL,
    GET_PSE_TOKEN,
    ERROR_FILE_SIZE,
    GET_REJECTED_SALE,
    MAJOR_CONTRIBUTOR_VERIFICATION,
    GET_DS_CLIENT,
    RESET_SENT_INVOICE_FLAG,
    SENT_TO_ONLINE_PAYMENT_VERIFICATION
} from "./types";

/**
 * shopping_cartAction getDetail ( list products in detail sale of client )
 *
 * @method GET
 * @param {url} url
 */

 export const getDetail = (url) => async (dispatch) => {
    let DETAIL_SALE_URL = `${API_ENDPOINT}${url}`;
    console.log(DETAIL_SALE_URL,'----------res.data');
    axios.get(DETAIL_SALE_URL)
        .then(res => {
            
                dispatch({
                    type: GET_DETAIL_SALE,
                    payload: res.data
                });
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: !!err.response
                ? err.response.data
                : {message: defaultResponses.noResponseFromApi}
            });
        });
 };

 /**
  * shopping_cartAction removeProduct ( delete product to detail sale )
  *
  * @method POST
  * @param {url} url
  */

 export const removeProduct = (obj) => async (dispatch) => {
     let DETAIL_SALE_REMOVE_PRODUCT_URL = `${API_ENDPOINT}/product/deleteProduct`;

     axios.post(DETAIL_SALE_REMOVE_PRODUCT_URL, obj)
        .then(res => {
                dispatch({
                    type: DETAIL_SALE_REMOVE_PRODUCT,
                    payload: res.data
                });
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: !!err.response
                ? err.response.data
                : {message: defaultResponses.noResponseFromApi}
            });
        });     
 }

 /**
 * AuthAction updateProductsList ( set shopping cart products payment )
 *
 * @method POST
 * @param {*} data
 */
export const updateProductsList = (data) => async(dispatch) => {
    await axios.post(API_ENDPOINT + '/product/updateProductsList', data)
        .then(res => {
            dispatch({
                type: UPDATE_PRODUCT_LIST,
                payload: res.data
            });
            dispatch({ type: CLEAR_ERRORS });
        })
        .catch(err => {
            console.log('err...', err)
            dispatch({
                type: GET_ERRORS,
                payload: !!err.response
                ? err.response.data
                : {message: defaultResponses.noResponseFromApi}
            });
        });
};


/**
 * AuthAction buyProducts ( set shopping cart products payment )
 *
 * @method POST
 * @param {*} data
 */
export const buyProducts = (data) => async(dispatch) => {
    await axios.post(API_ENDPOINT + '/sale/invoice', data)
        .then(res => {
            dispatch({
                type: BUY_PRODUCTS,
                payload: res.data
            });
            dispatch({ type: CLEAR_ERRORS });
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: !!err.response
                ? err.response.data
                : {message: defaultResponses.noResponseFromApi}
            });
        });
};


/**
 * getUserData getUser ( list products in detail sale of client )
 *
 * @method GET
 * @param {history} url
 */

export const getUser = (url) => async (dispatch) => {
    let DETAIL_SALE_URL = `${API_ENDPOINT}${url}`;

    axios.get(DETAIL_SALE_URL)
        .then(res => {
            dispatch({
                type: GET_SHOPPING_USER,
                payload: res.data
            });
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: !!err.response
                    ? err.response.data
                    : {message: defaultResponses.noResponseFromApi}
            });
        });
};

/**
 * UserAction getDSClient ( get user data based on his Document Number )
 *
 * @method POST
 * @param {*} data
 */
export const getDSClient = (data) => dispatch => {
    const url = `${API_ENDPOINT}/user/detailSaleClient`;

    axios.post(url, data)
        .then(async res => {
            dispatch({
                type: GET_DS_CLIENT,
                payload: res.data.dsClient
            });
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: !!err.response
                    ? err.response.data
                    : {message: defaultResponses.noResponseFromApi}
            })
        });
};


 /**
 * AuthAction sendInvoice ( set shopping cart products payment )
 *
 * @method POST
 * @param {*} data
 */
export const sendInvoice = (data) => async(dispatch) => {
    await axios.post(API_ENDPOINT + '/sale/sendInvoice', data)
        .then(res => {
            dispatch({
                type: SEND_INVOICE,
                payload: res.data
            });
            //dispatch({ type: CLEAR_ERRORS });
        })
        .catch(err => {
            console.log(err,'--------------------')
            dispatch({
                type: GET_ERRORS,
                payload: !!err.response
                ? err.response.data
                : {message: defaultResponses.noResponseFromApi}
            });
        });
};


/**
 * AuthAction storeVoucher ( store voucher img to client shopping cart )
 *
 * @method POST
 * @param {*} data
 */
export const storeVoucher = (data, type) => async(dispatch) => {
    let headers = {
        'Content-Type': 'multipart/form-data'
    };

    const url = type === 'rejected' ? '/sale/storeRejectedVoucher' :'/sale/storeVoucher';
    await axios.post(API_ENDPOINT + url, data, { headers })
        .then(res => {
            dispatch({
                type: STORE_VOUCHER,
                payload: res.data
            });
            dispatch({ type: CLEAR_ERRORS });
        })
        .catch(err => {
            dispatch({type: CLEAR_SHOPPING_CART})
            dispatch({
                type: GET_ERRORS,
                payload: !!err.response
                ? err.response.data
                : {message: defaultResponses.noResponseFromApi}
            });
        });
};

/**
 * AuthAction sendQuotation ( send quotation invoice via email )
 *
 * @method POST
 * @param {*} data
 */
export const sendQuotation = (data) => async(dispatch) => {
    await axios.post(API_ENDPOINT + '/sale/sendQuotation', data)
        .then(res => {
            dispatch({
                type: SEND_QUOTATION,
                payload: res.data
            });
            dispatch({ type: CLEAR_ERRORS });
        })
        .catch(err => {
            console.log(err.response,'--------------------')
            dispatch({type: CLEAR_SHOPPING_CART})
            dispatch({
                type: GET_ERRORS,
                payload: !!err.response
                ? err.response.data
                : {message: defaultResponses.noResponseFromApi}
            });
        });
};

/**
 * AuthAction modifyCart ( toggle shopping cart component for re send a user data, it for modifications after set billing form )
 *
 * @method POST
 * @param {*}
 */
export const modifyCart = () => async(dispatch) => {
    dispatch({ type: MODIFY_CART });
};

/**
 * ShoppingCartAction updateTotal ( update Total Amount of shopping cart )
 *
 * @method POST
 * @param {*} amount
 */
export const updateTotal = amount => async(dispatch) => {
    dispatch({
        type: UPDATE_TOTAL,
        payload: amount
    });
};

/**
 * ShoppingCartAction getPseToken ( get token for PSE payment )
 *
 * @method GET
 */
export const getPseToken = (buyerData) => async(dispatch) => {
    await axios.get(`${API_ENDPOINT}/getPseToken`)
        .then(async res => {

            axios.post(`${API_ENDPOINT}/sale/buyer`, buyerData).then().catch(err => console.error('ERROR SAVING BUYER: ', err));

            dispatch({
                type: GET_PSE_TOKEN,
                payload: res.data
            });
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: !!err.response
                    ? err.response.data
                    : {message: defaultResponses.noResponseFromApi}
            });
        });
};

/**
 * AuthAction errorFileSize ( toggle alert if file size is over )
 *
 * @method POST
 * @param {*}
 */
export const errorFileSize = () => async (dispatch) => {
    dispatch({ type: ERROR_FILE_SIZE });
};

/**
 * shopping_cartAction getRejectedVoucher ( detail sale rejected client )
 *
 * @method GET
 * @param {url} url
 */

export const getRejectedVoucher = (url) => async (dispatch) => {
    let REJECTED_SALE_URL = `${API_ENDPOINT}${url}`;
    axios.get(REJECTED_SALE_URL)
        .then(res => {
            if (res.data.message !== 'Ningún pago ha sido rechazado') {
                dispatch({
                    type: GET_REJECTED_SALE,
                    payload: res.data
                });
            }
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: !!err.response
                    ? err.response.data
                    : { message: defaultResponses.noResponseFromApi }
            });
        });
};

/**
 * UserAction verifyIfMajorContributor ( verify if the NIT number match with any Major Contributor )
 *
 * @method POST
 * @param {*} nit
 */
export const verifyIfMajorContributor = nit => dispatch => {
    axios.post(`${API_ENDPOINT}/sale/majorContributor/consult`, { nit })
        .then(res => {
            dispatch({
                type: MAJOR_CONTRIBUTOR_VERIFICATION,
                payload: res.data.majorContributor
            });
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: !!err.response
                    ? err.response.data
                    : {message: defaultResponses.noResponseFromApi}
            });
        });
};

/**
 * CartAction resetSentInvoiceFlag ( reset flag to prevent unnecessary modal apparitions )
 *
 * @method POST
 * @param {*}
 */
export const resetSentInvoiceFlag = () => async dispatch => {
    dispatch({ type: RESET_SENT_INVOICE_FLAG });
};

/**
 * CartAction verifyIfSentOnlinePayment ( verify if the sale has previously sent to Online Payment )
 *
 * @method POST
 */
export const verifyIfSentOnlinePayment = () => dispatch => {
    axios.post(`${API_ENDPOINT}/sale/onlinePayment/verify`, {})
        .then(res => {
            dispatch({
                type: SENT_TO_ONLINE_PAYMENT_VERIFICATION,
                payload: res.data.success
            });
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: !!err.response
                    ? err.response.data
                    : {message: defaultResponses.noResponseFromApi}
            });
        });
};

/**
  * shopping_cartAction updateQuantity ( update quantity product to detail sale )
  *
  * @method POST
  * @param {url} url
  */

 export const updateQuantityProduct = (obj) => async (dispatch) => {
    let DETAIL_SALE_UPDATE_QUANTITY_PRODUCT_URL = `${API_ENDPOINT}/product/updateQuantityProduct`;

    axios.post(DETAIL_SALE_UPDATE_QUANTITY_PRODUCT_URL, obj)
       .then(res => {
               dispatch({
                   type: DETAIL_SALE_UPDATE_QUANTITY_PRODUCT,
                   payload: res.data
               });
       })
       .catch(err => {
           dispatch({
               type: GET_ERRORS,
               payload: !!err.response
               ? err.response.data
               : {message: defaultResponses.noResponseFromApi}
           });
       });    
       
}