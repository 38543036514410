import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Alert } from 'rsuite';
import SimpleReactValidator from "simple-react-validator";
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import _ from "lodash"

import { reactValidatorOptions } from "../../../helpers/simpleReactValidator";
import { assignCertificate, resetAdminProductsReducer, clearDirectionGSE, validateEmail } from "../../../actions/adminProductsActions";
import { findUser, clearFoundUser } from "../../../actions/userActions";
import { fileToBase64 } from "../../../helpers/filesLib";

import { getDirectionGSE } from '../../../actions/adminProductsActions';

import { KEY_FILE_MAX_SIZE, KEY_FILE_TYPES, KEY_FILE_DELIVS, KEY_FILE_EXTS } from "../../../config/config";

import AssignWhiteIcon from "../../../assets/img/icons/Icon_asign.png";
import ModalAlert from "../Modal_alert";

import Xclose from '../../../assets/img/icons/close.png';

import { AditionalInfo } from "./AditionalInfo";
import { HeaderAssignment } from "./HeaderAssignment";
import { LoadKeyFile } from "./LoadKeyFile";
import { MandatoryFields } from "./MandatoryFieldsForm";
import { PreloadDocument } from "./PreloadDocument";
import { SendCredentials } from "./SendCredentials";

/**
 * AssignmentForm Component ( render a modal with Assignment Form and make a post request to
 * assign certificate to a user )
 *
 * @export Class Component
 * @class AssignmentForm
 * @extends {Component}
 * @returns Redux connect
 */
class AssignmentForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            useInvoiceData: false,
            useDirectionAsist: false,
            addresAssistant: 'off',
            modalAlert: false,
            addressForm: {
                address_field_1: '',
                address_field_2: '',
                address_field_3: '',
                address_field_4: '',
                address_field_5: '',
                address_field_6: '',
                address_field_7: '',
                address_field_8: '',
                address_field_9: '',
                address_field_10: '',
                address_complementary_field_1: '',
                address_complementary_field_2: '',
                address_complementary_field_3: '',
                address_complementary_field_4: '',
                address: ''
            },
            form: {
                firstName: '',
                secondName: '',
                lastName: '',
                lastSurname: '',
                email: '',
                confirmEmail: '',
                cellPhone: '',
                documentType: '',
                documentTypes: [
                    { key: 'none', val: '', lbl: 'Tipo de Documento' },
                    { key: 'CC', val: 'CC', lbl: 'Cédula de ciudadanía' },
                    { key: 'CE', val: 'CE', lbl: 'Cédula extranjería' },
                    { key: 'PS', val: 'PS', lbl: 'Pasaporte' },
                    { key: 'NIT', val: 'NIT', lbl: 'Nit' },
                    { key: 'PEP', val: 'PEP', lbl: 'Permiso Especial de Permanencia' },
                    { key: 'PPT', val: 'PPT', lbl: 'Permiso por protección Temporal' }

                ],
                documentNumber: '',
                town: '',
                towns: [],
                clientDocument: '',
                clientDocumentName: '',
                keyFile: '',
                keyFileName: '',
                keyFileMaxSize: KEY_FILE_MAX_SIZE,
                keyFileTypes: KEY_FILE_TYPES,
                keyFileExts: KEY_FILE_EXTS
            },
            certificateUse: '',
            certificateUses: [
                { key: 'none', val: '', lbl: 'Seleccione...' },
                { key: 'RUNT', val: 'RUNT', lbl: 'RUNT' },
                { key: 'SIIF_NACION', val: 'SIIF NACIÓN', lbl: 'SIIF NACIÓN' },
                { key: 'SUPERFINANCIERA', val: 'SUPERFINANCIERA', lbl: 'SUPERFINANCIERA' },
                { key: 'VUCE', val: 'VUCE', lbl: 'VUCE' },
                { key: 'SUPERSALUD', val: 'SUPERSALUD', lbl: 'SUPERSALUD' },
                { key: 'SICOQ', val: 'SICOQ', lbl: 'SICOQ' },
                { key: 'SUPERVIGILACIA', val: 'SUPERVIGILACIA', lbl: ' SUPERVIGILACIA' },
                { key: 'SECRETARIA_DISTRITAL_HACIENDA', val: 'SECRETARIA DISTRITAL DE HACIENDA', lbl: 'SECRETARIA DISTRITAL DE HACIENDA' },
                { key: 'MINISTERIO_COMERCIO', val: 'MINISTERIO DE COMERCIO', lbl: 'MINISTERIO DE COMERCIO' },
                { key: 'MINISTERIO_PROTECCION', val: 'MINISTERIO DE PROTECCIÓN', lbl: 'MINISTERIO DE PROTECCIÓN' },
                { key: 'AGENCIA_NACIONAL_DIGITAL', val: 'AGENCIA NACIONAL DIGITAL', lbl: 'AGENCIA NACIONAL DIGITAL' },
                { key: ' OTROS', val: ' OTROS', lbl: ' OTROS' }
            ],
            certificateDelivery: 'clientAddress',
            address: '',
            sendCredentials: 'subscriber',
            errors: {}
        };

        reactValidatorOptions.validators.documentNumber = {
            message: 'El formato del :attribute no es válido',
            rule: (val, params, validator) => {
                return validator.helpers.testRegex(val, this.state.documentType === 'NIT' ? /^\d{5,15}$/ : this.state.documentType === 'PS' ? /^\w{5,15}$/ : /^\d{5,15}$/);
            }
        };

        this.assignmentFormValidator = new SimpleReactValidator(reactValidatorOptions);
        this.addressValidator = new SimpleReactValidator(reactValidatorOptions);
        this.LoadKeyFileValidator = new SimpleReactValidator(reactValidatorOptions);
    }

    componentWillReceiveProps(nextProps) {
        let { locationReducer, userReducer, adminProductsReducer } = nextProps;
        this.setState({ useDirectionAsist: adminProductsReducer.valueAssistant });

        if (adminProductsReducer.gseDirection != this.props.adminProductsReducer.gseDirection) {
            this.setState((oldState) => {
                return {
                    addressForm: {
                        ...oldState.addressForm,
                        address: adminProductsReducer.gseDirection.GSEDate
                    }
                }
            })
        }

        if (adminProductsReducer.verifyEmailRes != this.props.adminProductsReducer.verifyEmailRes) {
            if(adminProductsReducer.verifyEmailRes.status != "success"){
                this.state.form.emailError = adminProductsReducer.verifyEmailRes.message;
            } else {
                this.state.form.emailError = null;
            }
            this.forceUpdate();
        }

        if (locationReducer.towns.length !== this.state.form.towns.length) {
            this.setState((prevState) => ({
                form: { ...prevState.form, towns: locationReducer.towns }
            }));
        }

        if (!!userReducer.usedFindUser) {
            const user = userReducer.foundUser;
            if (user) {
                this.setState((prevState) => ({
                    form: {
                        ...prevState.form,
                        firstName: user.firstName.split(' ')[0] || '',
                        secondName: user.firstName.split(' ')[1] || '',
                        lastName: user.lastName.split(' ')[0] || '',
                        lastSurname: user.lastName.split(' ')[0] || '',
                        email: user.email || '',
                        confirmEmail: '',
                        currentUserEmail: user.email,
                        cellPhone: user.cellPhone || '',
                        town: user.townCode || ''
                    }
                }));
                this.state.form.confirmEmailError = null;
            } else if(this.state.form.currentUserEmail) {
                this.cleanMandatoryFields()
                this.state.form.confirmEmailError = null;
            }
            this.props.clearFoundUser();
        }
    }

    onChangeFile = async (e) => {
        const targetID = e.target.id;
        if (e.target.files[0]) {
            const { name, type } = e.target.files[0]
            const ext = name.split('.')[1];
            const value = e.target.value;
            const size = e.target.files[0].size / 1000;//units in kB
            await fileToBase64(name, e.target.files[0]).then(async result => {
                if (result.split(',')[1] === undefined) {
                    await this.setState((oldState) => ({
                        errors: {
                            ...oldState.errors,
                            [targetID]: 'El documento no es valido',
                        }
                    }));
                    Alert.error( `Documento no válido`, 5000);
                } else {
                    const validType = this.state.form[targetID + 'Types'] && this.state.form[targetID + 'Types'].find(e => e === type)
                    const validExt = this.state.form[targetID + 'Exts'] && this.state.form[targetID + 'Exts'].find(e => e === ext)
                    if(!validType && !validExt) { Alert.error( `Tipo de archivo no permitido`, 5000); return null; }
                    
                    if(this.state.form[targetID + 'MaxSize'] && size > this.state.form[targetID + 'MaxSize']) {
                        Alert.error( `El peso máximo es de ${this.state.form[targetID + 'MaxSize']}kB`, 5000);
                        return null
                    }

                    delete this.state.errors[targetID];
                    
                    await this.setState((oldState) => ({
                        form: {
                            ...oldState.form,
                            [targetID]: result.split(',')[1],
                            [targetID + 'Name']: name,
                            [targetID + 'Value']: value
                        }
                    }));
                }
            })
        }
    };

    onChangeInput = e => {      
        this.setState({ [e.target.id]: e.target.value, errors: {} });
    };
    
    onChangeFormInput = async e => {
        const target = e.target.id;
        const value = e.target.value;

        console.log(target, value,'<<<<change input');
        

        await this.setState((oldState) => {
            return {
                form: {
                    ...oldState.form,
                    [target]: value
                }
            }
        });
        console.log(this.state,'<<<< state');
        
    };

    onBlurFormInput = e => {
        const target = e.target.id;
        const { documentType, documentNumber } = this.state.form
 
        if (target === 'documentType' || target === 'documentNumber') {
            if (documentType !== '' && documentNumber !== '') {
                const query = `userName=${documentType.toLowerCase()+documentNumber.toLowerCase()}`
                this.props.findUser(query, this.props.history);
            } else {
                this.cleanMandatoryFields()
            }
        }
    };

    onChangeAddressFields = () => async (e) => {
        const oldTarget = e.target.id;
        let target = oldTarget == "addressOnly" ? "address" : e.target.id;
        let value = e.target.value;
        await this.setState((oldState) => {
            return {
                addressForm: {
                    ...oldState.addressForm,
                    [target]: value
                }
            }
        })
        if(oldTarget !== "addressOnly") this.buildFullAddress();
    };

    /**
     * 
     * hangeAddressOnly = () => async e => {
        //const target = e.target.id;
        console.log("ENTRANDO AL changeAddressOnlychangeAddressOnlychangeAddressOnly")
        console.log(e.target);
        console.log(e.target.value);
        const value = e.target.value;
        await this.setState({
             addressForm: {
                 address: value
             }
        })
    };*/

    handleAddressReactSelect = async (selectedOption) => {
        await this.setState((oldState) => {
            return {
                addressForm: {
                    ...oldState.addressForm,
                    [selectedOption.id]: selectedOption.value
                }
            }
        })
        this.buildFullAddress();
    };

    handleCertificateDelivery(e) {
        this.setState({ certificateDelivery: e.target.value });
        this.cleanDirectionAssistant();
        if(e.target.value == "gseAddress"){
            this.props.getDirectionGSE();
        }
        this.setState({ useDirectionAsist: false });
    }

    handleSendCredentials(e) {
        this.setState({ sendCredentials: e.target.value });
    }

    handleShowDirAssistant = async (e) => {
        await this.setState({ useDirectionAsist: !this.state.useDirectionAsist });
        this.cleanDirectionAssistant();
    }  

    cleanDirectionAssistant = async () => {
        this.setState({
            addressForm: {
                address_field_1: '',
                address_field_2: '',
                address_field_3: '',
                address_field_4: '',
                address_field_5: '',
                address_field_6: '',
                address_field_7: '',
                address_field_8: '',
                address_field_9: '',
                address_field_10: '',
                address_complementary_field_1: '',
                address_complementary_field_2: '',
                address_complementary_field_3: '',
                address_complementary_field_4: '',
                address: '',
            }
        });
    }

    buildFullAddress = () => {
        const { address_field_1, address_field_2, address_field_3, address_field_4, address_field_5, address_field_6, address_field_7, address_field_8, address_field_9, address_field_10,
            address_complementary_field_1, address_complementary_field_2, address_complementary_field_3, address_complementary_field_4 } = this.state.addressForm;

        const address = (!!address_field_1 ? address_field_1 + ' ' : '') +
            (!!address_field_2 ? address_field_2 + ' ' : '') +
            (!!address_field_3 ? address_field_3 + ' ' : '') +
            (!!address_field_4 ? address_field_4 + ' ' : '') +
            (!!address_field_5 ? address_field_5 + ' ' : '') +
            (!!address_field_6 ? address_field_6 + ' ' : '') +
            (!!address_field_7 ? '# ' + address_field_7 + ' ' : '') +
            (!!address_field_8 ? address_field_8 + ' ' : '') +
            (!!address_field_9 ? address_field_9 + ' ' : '') +
            (!!address_field_10 ? address_field_10 + ' ' : '') +
            (!!address_complementary_field_1 ? address_complementary_field_1 + ' ' : '') +
            (!!address_complementary_field_2 ? address_complementary_field_2 + ' ' : '') +
            (!!address_complementary_field_3 ? address_complementary_field_3 + ' ' : '') +
            (!!address_complementary_field_4 ? address_complementary_field_4 : '');
        this.setState((oldState) => {
            return {
                addressForm: {
                    ...oldState.addressForm,
                    address
                },
                addresAssistant: 'on'
            }
        });
    };

    onChangeCheckbox = e => {
        let checked = e.target.value === "true";
        this.setState(
            { [e.target.id]: !checked, errors: {} },
            () => {
                if (checked === false) {
                    let { invoiceData } = this.props.selectedProduct;
                    let names = invoiceData.firstName.split(' ');
                    let lastnames = invoiceData.lastName.split(' ');

                    this.setState((oldState) => {
                        return {
                            form: {
                                ...oldState.form,
                                firstName: names.shift(),
                                secondName: names.join(' '),
                                lastName: lastnames.shift(),
                                lastSurname: lastnames.join(' '),
                                email: invoiceData.email,
                                cellPhone: !!invoiceData.cellPhoneNumber ? invoiceData.cellPhoneNumber : '',
                                documentType: invoiceData.documentType,
                                documentNumber: invoiceData.documentNumber,
                                town: !!invoiceData.city ? invoiceData.townCode : '',
                                address: invoiceData.address
                            },
                            addressForm: {
                                ...oldState.addressForm,
                                address: invoiceData.address
                            }
                        }
                    });
                } else {
                    this.cleanForm();
                }
            }
        );
    };

    toggleAlertModal = async () => {
        if (this.state.modalAlert === true) this.cleanForm();
        this.setState({ modalAlert: !this.state.modalAlert });
    };

    cleanForm = () => {
        this.assignmentFormValidator.hideMessages();
        this.addressValidator.hideMessages();
        this.LoadKeyFileValidator.hideMessages();
        this.state.form.confirmEmailError = null;
        this.state.form.emailError = null;

        if (this.state.useInvoiceData === true) this.setState({ useInvoiceData: false });
        this.setState((oldState) => {
            return {
                form: {
                    ...oldState.form,
                    firstName: '',
                    secondName: '',
                    lastName: '',
                    lastSurname: '',
                    email: '',
                    confirmEmail: '',
                    currentUserEmail: null,
                    cellPhone: '',
                    documentType: '',
                    documentNumber: '',
                    town: '',
                    clientDocument: '',
                    clientDocumentName: '',
                    keyFile: '',
                    keyFileName: '',
                    keyFileValue: ''
                },
                addressForm: {
                    address_field_1: '',
                    address_field_2: '',
                    address_field_3: '',
                    address_field_4: '',
                    address_field_5: '',
                    address_field_6: '',
                    address_field_7: '',
                    address_field_8: '',
                    address_field_9: '',
                    address_field_10: '',
                    address_complementary_field_1: '',
                    address_complementary_field_2: '',
                    address_complementary_field_3: '',
                    address_complementary_field_4: '',
                    address: ''
                },
                certificateDelivery: 'clientAddress',
                certificateUse: '',
                sendCredentials: 'subscriber'
            }
        });
        this.props.clearDirectionGSE();
    };

    cleanMandatoryFields = () => {
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                firstName: '',
                secondName: '',
                lastName: '',
                lastSurname: '',
                email: '',
                confirmEmail: '',
                currentUserEmail: null,
                cellPhone: '',
                town: ''
            }
        }));
    }

    compareEmail = (e) => {
        if (this.state.form.email.toLocaleLowerCase() != this.state.form.confirmEmail.toLocaleLowerCase()) {
            this.state.form.emailError = 'Los campos no coinciden';
            this.state.form.confirmEmailError = 'Los campos no coinciden';
        } else if (this.state.form.email != ''){
            this.props.validateEmail({email: this.state.form.email});
            this.state.form.confirmEmailError = null;
        } else {
            this.state.form.emailError = null;
            this.state.form.confirmEmailError = null;
        }
        this.forceUpdate();
    }
    
    validateQuickEmail = (e) => {
        if (this.state.form.email.toLocaleLowerCase() != this.state.form.confirmEmail.toLocaleLowerCase()) {
            this.state.form.confirmEmailError = 'Los campos no coinciden';
            this.state.form.emailError = 'Los campos no coinciden';
        } else if (this.state.form.confirmEmail != '') {
            this.props.validateEmail({email: this.state.form.confirmEmail});
            this.state.form.confirmEmailError = null;
        } else {
            this.state.form.emailError = null;
            this.state.form.confirmEmailError = null;
        }
        this.forceUpdate();
    }

    onSubmit = (e) => {
        if (this.assignmentFormValidator.allValid()) {
            const { firstName, secondName, lastName, lastSurname, email, cellPhone, documentType, documentNumber, town, clientDocument, clientDocumentName, keyFile, keyFileName } = this.state.form;
            let { address } = this.state.addressForm;
            const { selectedProduct } = this.props;
            let data = {
                assignmentID: _.isEmpty(selectedProduct.availableAssignmentsIDs) ? selectedProduct.assignmentID : selectedProduct.availableAssignmentsIDs[0],
                user: {
                    firstName,
                    secondName,
                    lastName,
                    lastSurname,
                    email,
                    cellPhone,
                    documentType,
                    documentNumber,
                    townCode: town,
                    address: address,
                    clientDocument,
                    clientDocumentName,
                    certificateUse: this.state.certificateUse
                },
                addressData: {
                    ...this.state.addressForm,
                    address: address
                },
                product: selectedProduct,
                keyFile,
                keyFileName,
                sendCredentials: this.state.sendCredentials, 
                assignmentFrom : selectedProduct.from && selectedProduct.from === "preloadAssignment" ? "preloadAssignment" : null
            };
            
            this.props.assignCertificate(this.props.clientID, data, this.props.history);

            this.toggleAlertModal().then();

            this.setState({ useDirectionAsist: false });
        } else {
            this.assignmentFormValidator.showMessages();
            this.forceUpdate();
        }
    };

    render() {
        return (
            <>
                <Modal isOpen={this.props.modalAssignProduct}>
                    <ModalHeader className="header-modal">
                        ASIGNAR PRODUCTO
                        <Button className="modal-close">
                            <img
                                src={Xclose}
                                onClick={e => {
                                    this.cleanForm();
                                    this.props.toggleModal('modalAssignProduct');
                                    this.assignmentFormValidator.hideMessages();
                                }}
                                alt="icon product"
                            />
                        </Button>
                    </ModalHeader>

                    <form onSubmit={e => {
                        e.preventDefault();
                        const validAddress = true;
                        const validKeyFile = this.props.selectedProduct && KEY_FILE_DELIVS.find(e => e === this.props.selectedProduct.delivery) ? this.LoadKeyFileValidator.allValid() : true;
                        const verifyEmail = this.state.form.email.toLocaleLowerCase() != this.state.form.confirmEmail.toLocaleLowerCase();
                        
                        if (!this.assignmentFormValidator.allValid() || !validAddress || !validKeyFile || verifyEmail || this.state.form.emailError != null) {
                            if(verifyEmail) this.state.form.confirmEmailError = 'Los campos no coinciden';
                            this.assignmentFormValidator.showMessages();
                            this.LoadKeyFileValidator.showMessages();
                            this.forceUpdate();
                            return null
                        } else {
                            this.props.toggleModal('modalAssignProduct');
                            this.toggleAlertModal().then();
                        }
                    }}>
                        <HeaderAssignment selectedProduct={this.props.selectedProduct} />

                        <div className="col-md-12 preload-message">
                            <div className="container p-3">
                                <p> Diligencie los campos obligatorios, la información adicional es opcional,
                                    ingrese solo lo que usted conozca, esta información se precargará en la solicitud
                                    del suscriptor, sin embargo, él podrá modificarla si lo considera necesario. </p>
                            </div>
                        </div>

                        <ModalBody>
                            <div className="container pt-2 pl-4 pr-4">
                                <MandatoryFields
                                    useInvoiceData={this.state.useInvoiceData}
                                    onChangeCheckbox={e => this.onChangeCheckbox(e)}
                                    selectedProduct={this.props.selectedProduct}
                                    dataForm={this.state.form}
                                    onBlurInput={e => this.onBlurFormInput(e)}
                                    onChangeInput={e => this.onChangeFormInput(e)}
                                    formValidator={this.assignmentFormValidator}
                                    validateQuickEmail={this.validateQuickEmail}
                                    compareEmail={this.compareEmail}
                                />
                            </div>
                        </ModalBody>
                        
                        {this.props.selectedProduct && this.props.selectedProduct.delivery === 'P10' && this.props.selectedProduct.management === 'Normal' &&
                        <>
                            <div className="col-md-12 preload-message">
                                <div className="container p-3">
                                    <p>Al momento de adjuntar el archivo de formato <strong>.CSR </strong>se debe nombrar 
                                    con el número de identificación de la presente solicitud. Ejemplo: 900345678</p>
                                </div>
                            </div>

                            <ModalBody>
                                <div className="container pt-2 pl-4 pr-4">
                                    <LoadKeyFile
                                        keyFileName={this.state.form.keyFileName}
                                        onChangeFile={this.onChangeFile}
                                        errors={this.state.errors}
                                        validator={this.LoadKeyFileValidator}
                                        value={this.state.form.keyFileValue}
                                    />
                                </div>
                            </ModalBody>
                        </>
                        }
                        

                        <ModalBody>
                            <div className="container pt-2 pl-4 pr-4">
                                <div className="divider mt-0 mb-4" />
                                <AditionalInfo
                                    onChangeInput={e => this.onChangeInput(e)}
                                    certificateUse={this.state.certificateUse}
                                    certificateUses={this.state.certificateUses}
                                    certificateDelivery={this.state.certificateDelivery}
                                    handleCertificateDelivery={e => this.handleCertificateDelivery(e)}
                                    addressValidator={this.addressValidator}
                                    onChangeAddressFields={this.onChangeAddressFields}
                                    handleAddressReactSelect={this.handleAddressReactSelect}
                                    addressForm={this.state.addressForm}
                                    useDirectionAsist={this.state.useDirectionAsist}
                                    GSEDirection={this.state.addressForm.address}
                                    handleShowDirAssistant={e => this.handleShowDirAssistant(e)}
                                    changeAddressOnly={e => this.changeAddressOnly(e)}
                                />
                            </div>
                        </ModalBody>

                        <div className="col-md-12 preload-message">
                            <div className="container p-3">
                                <p>Nota: no olvide que a esta dirección se enviará el certificado</p>
                            </div>
                        </div>

                        <ModalBody>
                            <div className="container pt-2 pl-4 pr-4">
                                <PreloadDocument
                                    clientDocumentName={this.state.form.clientDocumentName}
                                    onChangeFile={this.onChangeFile}
                                    errors={this.state.errors}
                                />

                                {this.props.clientType === 'Convenio' &&
                                    <React.Fragment>
                                        <div className="divider mt-0 mb-4" />
                                        
                                        <SendCredentials
                                            sendCredentials={this.state.sendCredentials}
                                            handleSendCredentials={e => this.handleSendCredentials(e)}
                                        />
                                    </React.Fragment>
                                }

                                <div className="divider mt-4 mb-4" />
                                <div className="col-md-12 text-center">
                                    <button type="submit" className="btn btn-outline-success btn-submit-product">
                                        <img className="input-icon" src={AssignWhiteIcon} alt="Asignar" />
                                        Asignar
                                    </button>
                                </div>
                            </div>
                        </ModalBody>

                    </form>
                </Modal>

                <ModalAlert
                    showModal={this.state.modalAlert}
                    title="ASIGNAR CERTIFICADO"
                    message="¿Está seguro que desea asignar el certificado? Una vez lo haga, no podrá anular la asignación."
                    toggleAlertModal={this.toggleAlertModal}
                    onSubmit={this.onSubmit}
                    actualOperation="assignment"
                />
            </>
        );
    };
}

AssignmentForm.propTypes = {
    assignCertificate: PropTypes.func.isRequired,
    findUser: PropTypes.func.isRequired,
    getDirectionGSE: PropTypes.func.isRequired,
    clearDirectionGSE: PropTypes.func.isRequired,
    clearFoundUser: PropTypes.func.isRequired,
    validateEmail: PropTypes.func.isRequired,
    resetAdminProductsReducer: PropTypes.func.isRequired,
    adminProductsReducer: PropTypes.object.isRequired,
    locationReducer: PropTypes.object.isRequired,
    userReducer: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    adminProductsReducer: state.adminProductsReducer,
    locationReducer: state.locationReducer,
    userReducer: state.userReducer
});

const mapDispatchToProps = {
    assignCertificate,
    findUser,
    getDirectionGSE,
    clearFoundUser,
    resetAdminProductsReducer,
    clearDirectionGSE,
    validateEmail
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignmentForm);
