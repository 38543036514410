import React from 'react'
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import Spinner from 'react-bootstrap/Spinner'

const ModalLoader = props => {
    const { showModal, type, title, message } = props;

    return (
        <Modal isOpen={showModal}>
            <ModalHeader className="header-modal">
                {title}
            </ModalHeader>
            <ModalBody>
                <div className="container">
                    <div className="row">
                        <div className="col-10 offset-1 text-center">
                            <Spinner animation="border" variant="success" />
                        </div>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
};

export default ModalLoader;