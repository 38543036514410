import { combineReducers } from "redux/es/redux";
import authReducer from './authReducer';
import errorReducer from './errorReducer';
import userReducer from './userReducer';
import productsReducer from './productsReducer';
import adminProductsReducer from './adminProductsReducer';
import advisorReducer from './advisorReducer';
import businessAdvisorReducer from './businessAdvisorReducer';
import shoppingCartReducer from "./shoppingCartReducer";
import financialReducer from "./financialReducer";
import locationReducer from "./locationReducer";

export default combineReducers({
    authReducer,
    errorReducer,
    userReducer,
    productsReducer,
    adminProductsReducer,
    advisorReducer,
    businessAdvisorReducer,
    shoppingCartReducer,
    financialReducer,
    locationReducer
});
