import React from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

export const AntTabs = withStyles({
    root: { borderBottom: '1px solid #fff', },
    indicator: { backgroundColor: '#2C9C72', },
})(Tabs)

export const useStyles = makeStyles(theme => ({
    root: { flexGrow: 1, },
    padding: { padding: theme.spacing(3), },
    vinkel: { backgroundColor: '#fff', },
}))

export const AntTab = withStyles(theme => ({
    root: {
        textTransform: 'none',
        width: '100%',
        fontSize: theme.typography.pxToRem(19),
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            color: '#2C9C72',
            opacity: 1,
        },
        '&$selected': {
            color: '#2C9C72',
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&:focus': {
            color: '#2C9C72',
        },
    },
    selected: {},
}))(props => <Tab disableRipple {...props} />)

export function TabPanel(props) {
    const { children, value, index, ...other } = props
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={2}>{children}</Box>}
        </Typography>
    )
}