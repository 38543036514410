import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { setRegister, getUserByParameters } from '../../actions/userActions';
import { setStandardClient } from '../../actions/businessAdvisorActions';
import { getTown } from '../../actions/locationActions';

import InputForm from '../../components/InputForm/InputForm';
import ModalMsg from '../../components/ModalMsg/ModalMsg';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import SelectForm from '../../components/SelectForm/SelectForm';

import SimpleReactValidator from 'simple-react-validator';
import { reactValidatorOptions } from '../../helpers/simpleReactValidator';
// import { TOWNS } from '../../helpers/towns';
import { DOCUMENT_TYPES } from '../../helpers/documentTypes';

/**
 * Register Component ( full view Register component, render a Register
 * form and make a post request for user registration  )
 *
 * @export Class Component
 * @class Register
 * @extends {Component}
 * @returns Redux connect
 */
export class Register extends Component {

    constructor(props) {
        super(props);

        this.state = {
            username: '',
            name: '',
            lastName: '',
            documentType: '',
            documentNumber: '',
            cellPhoneNumber: '',
            registerEmail: '',
            documentTypes: DOCUMENT_TYPES,
            modalMsg: [],
            town:'',
            towns: [],
            address:'' 
        };

        this.registerValidator = new SimpleReactValidator(reactValidatorOptions);
        this.props.getTown();
    }

    componentWillReceiveProps(nextProps) {
        let { userReducer, locationReducer } = nextProps;

        if (userReducer.data) {
            this.setState({
                modalMsg: {
                    title: 'Usuario registrado',
                    label: nextProps.userReducer.data.msg,
                    type: 'check',
                    redirect: 'refresh'
                }
            });
            this.openModalMsg();
        } else {
            if (!!nextProps.errorReducer && nextProps.errorReducer.hasErrors) {
                //alert(nextProps.errorReducer.data.msg);
            }
        }

        if (locationReducer.towns.length !== this.state.towns.length) {
            this.setState({ towns: locationReducer.towns });
        }

        if (userReducer.obtainedUserByParameters === null) {
            this.resetForm();
        } else if (userReducer.obtainedUserByParameters.documentType!=='NIT' && (userReducer.obtainedUserByParameters !== this.props.userReducer.obtainedUserByParameters)) {
            let { firstName: name, lastName, cellPhone: cellPhoneNumber, email: standardEmail, documentNumber, documentType, town, address, userName } = userReducer.obtainedUserByParameters;
        
            console.log(userReducer.obtainedUserByParameters,'userReducer.obtainedUserByParameters<<<<<<<<<<<<<<<<')

            this.setState({ name, lastName, cellPhoneNumber, standardEmail, documentNumber, documentType, town, address, username:userName });
        }
    }

    acceptMethods = (openModalMsg) => {
        this.openModalMsg = openModalMsg;
    };

    onChangeCustomInput = () => e => {
        this.setState({ [e.target.id]: e.target.value });
        
    };

    onChangeCustomInputUsername = () => e => {
        this.setState({ [e.target.id]: this.clearUserName(e.target.value) });
    };

    clearUserName(value) {
        let newText = ''
        for (let i = 0; i < value.length; i++) {
            if (/^[a-zA-Z0-9._-]$/.test(value.charAt(i))) newText += value.charAt(i)
        }
        return newText.toLowerCase();
    }

    handleDocumentType(event) {
        this.setState({ documentType: event.target.value });
    }

    handletowns(event) {
        this.setState({ 
            town: event.target.value });
    }

    resetForm = () => {
        this.setState({ username: '', documentType: '', documentNumber: '', cellPhoneNumber: '', name: '', lastName: '', town: '', address: ''});
    };

    findByEmail = emailValue => {
        if (this.registerValidator.fieldValid('Correo electrónico')) {
            const data = { attrValue: emailValue, clientType: "Estándar" };

            this.props.getUserByParameters(data);
        } else {
            this.resetForm();
        }
    };

    setRegister = (e) => {
        e.preventDefault();
        if (this.registerValidator.allValid()) {
            let userData = {
                userName: this.state.username,
                name: this.state.name,
                lastName: this.state.lastName,
                documentType: this.state.documentType,
                documentNumber: this.state.documentNumber,
                cellPhoneNumber: this.state.cellPhoneNumber,
                registerEmail: this.state.registerEmail,
                town: this.state.town,
                address: this.state.address
            };
            if(this.props.formType==="standard"){
                let userData = {
                    userName: this.state.username,
                    type: "Standard",
                    firstName: this.state.name,
                    lastName: this.state.lastName,
                    documentType: this.state.documentType,
                    documentNumber: this.state.documentNumber,
                    cellPhoneNumber: this.state.cellPhoneNumber,
                    email: this.state.registerEmail,
                    town: this.state.town,
                    address: this.state.address
                };
                this.props.setStandardClient(userData);
            }else{
                this.props.setRegister(userData);
            }
                
        } else {
            this.registerValidator.showMessages();
            this.forceUpdate();
        }
    };

    render() {
        return (
            <div className="form-container">
                <form action="" id="login-validation" className="center-margin" onSubmit={this.setRegister}>
                    <div className="row">
                        <div className="col-sm-12 col-md-6 ">
                            <InputForm
                                id="registerEmail"
                                type={"text"}
                                placeholder={"Correo electrónico"}
                                icon={"email"}
                                className="form-control large"
                                onChange={this.onChangeCustomInput()}
                                onKeyDown="username"
                                value={this.state.registerEmail}
                                validator={this.registerValidator}
                                validateOptions={'required|email|onlyTextEmail'}
                                onBlur = {e => this.findByEmail(e.target.value)}
                            />
                        </div>
                        <div className="col-sm-12 col-md-6 ">
                            <InputForm
                                id="username"
                                type={"text"}
                                placeholder={"Usuario"}
                                icon={"user"}
                                className="form-control large"
                                onKeyDown="username"
                                onChange={this.onChangeCustomInputUsername()}
                                value={this.state.username}
                                validator={this.registerValidator}
                                validateOptions={'required|min:5|max:20|username'}
                            />
                        </div>
                        <div className="col-sm-12 col-md-6 ">
                            <InputForm
                                id="name"
                                type={"text"}
                                placeholder={"Nombres"}
                                icon={"user"}
                                className="form-control large"
                                onChange={this.onChangeCustomInput()}
                                onKeyDown = {'alpha_space'}
                                value={this.state.name}
                                validator={this.registerValidator}
                                validateOptions={'required|min:3'}
                            />
                        </div>
                        <div className="col-sm-12 col-md-6 ">
                            <InputForm
                                id="lastName"
                                type={"text"}
                                placeholder={"Apellidos"}
                                icon={"user"}
                                className="form-control large"
                                onChange={this.onChangeCustomInput()}
                                onKeyDown = {'alpha_space'}
                                value={this.state.lastName}
                                validator={this.registerValidator}
                                validateOptions={'required|min:3'}
                            />
                        </div>
                        <div className="col-sm-12 col-md-6 ">
                            <SelectForm
                                id="documentType"
                                icon={"document"}
                                placeholder={"Tipo Documento"}
                                className="form-control large"
                                onChange={(e) => this.handleDocumentType(e)}
                                value={this.state.documentType}
                                options={this.state.documentTypes}
                                validator={this.registerValidator}
                                validateOptions={'required'}
                            />
                        </div>
                        <div className="col-sm-12 col-md-6 ">
                            <InputForm
                                id="documentNumber"
                                type={"text"}
                                maxLength="15"
                                placeholder={"Número Documento"}
                                icon={"number"}
                                className="form-control large"
                                onChange={this.onChangeCustomInput()}
                                onKeyDown={this.state.documentType === 'PS'?'document':'number'}
                                value={this.state.documentNumber}
                                validator={this.registerValidator}
                                validateOptions={this.state.documentType === 'PS' ? 'required|min:6|alpha_num|passportFormat' : 'required|min5|numeric'}
                            />
                        </div>
                        <div className="col-sm-12 col-md-6 ">
                            <InputForm
                                id="cellPhoneNumber"
                                type={"text"}
                                maxLength="18"
                                placeholder={"Celular"}
                                icon={"cellphone"}
                                className="form-control large"
                                onChange={this.onChangeCustomInput()}
                                onKeyDown={'phone'}
                                value={this.state.cellPhoneNumber}
                                validator={this.registerValidator}
                                validateOptions={'required|min:7'}
                            />
                        </div>
                        <div className="col-sm-12 col-md-6 ">
                            <SelectForm
                                id="town"
                                icon={"position"}
                                placeholder={"Municipio"}
                                className="form-control large"
                                onChange={(e) => this.handletowns(e)}
                                value={this.state.town}
                                options={this.state.towns}
                                validator={this.registerValidator}
                                validateOptions={'required'}
                            />
                        </div>
                        <div className="col-sm-12 col-md-6 ">
                            <InputForm
                                id="address"
                                type={"text"}
                                placeholder={"Dirección Residencia"}
                                icon={"position"}
                                className="form-control large"
                                onChange={this.onChangeCustomInput()}
                                onKeyDown={'address'}
                                value={this.state.address}
                                validator={this.registerValidator}
                                validateOptions={'required|min:5'}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col col-12">
                            <PrimaryButton
                                color={"green"}
                                txtBtn={this.props.formType==="standard"?"Continuar":"Registrarse"}
                                sizeWidth={""}
                                className={"float-right with-icon"}
                                icon={this.props.formType==="standard"?"ContinueIcon":"Singin"}
                            >
                            </PrimaryButton>
                        </div>
                    </div>
                </form>
                <ModalMsg
                    shareMethods={this.acceptMethods}
                    title={this.state.modalMsg.title}
                    show={this.state.modalMsg.show}
                    label={this.state.modalMsg.label}
                    type={this.state.modalMsg.type}
                    redirect={this.state.modalMsg.redirect}
                    history={this.props.history}
                >
                </ModalMsg>
            </div>
        )
    }
}

Register.propTypes = {
    setRegister: PropTypes.func.isRequired,
    setStandardClient: PropTypes.func.isRequired,
    getUserByParameters: PropTypes.func.isRequired,
    userReducer: PropTypes.object.isRequired,
    locationReducer: PropTypes.object.isRequired,
    errorReducer: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    userReducer: state.userReducer,
    locationReducer: state.locationReducer,
    errorReducer: state.errorReducer
});

export default connect(mapStateToProps, { setRegister, setStandardClient, getUserByParameters, getTown })(Register)
