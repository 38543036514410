import React, { Component } from 'react'
import MaterialTable, { MTableToolbar } from 'material-table';
import { Button } from "reactstrap";

import suportView from '../../assets/img/icons/suportView.png';
import hcount from '../../assets/img/icons/approve_payment.png'
import refuse from '../../assets/img/icons/refuse_payment.png';
import FilterIcon from '../../assets/img/icons/filter.png';

import { API_ENDPOINT } from '../../config/config';
import { formatToThousandWithPoints } from '../../helpers/functions';

class Pending extends Component {
    state = {
        pays: [],
        areButtonsActive: true
    };

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.listProduct !== nextProps.listProduct) {
            this.setState({ pays: nextProps.listProduct });
        }

        this.setState({ areButtonsActive: nextProps.areButtonsActive });
    }

    render() {
        const tableColumns = [
            {
                title: "Referencia",
                field: "salesCode",
                render: rowData => <p style={{ color: '#00ac6c' }}>{rowData.salesRef}{rowData.salesCode}</p>
            },
            { title: "Fecha Soporte", field: "dateVoucher", type: 'date' },
            { title: "Cliente", field: "client" },
            { title: "Tipo Documento", field: "documentType" },
            { title: "Documento", field: "documentNumber" },
            {
                title: "Valor a Pagar",
                field: "subTotal",
                render: rowData => <p style={{ color: '#00ac6c' }}>${formatToThousandWithPoints(rowData.subTotal + rowData.taxIva - rowData.taxReteFuente - rowData.taxReteIva - rowData.taxReteIca)}</p>
            },
            {
                title: "Comprobante",
                field: "voucher",
                render: rowData =>
                    <div className="toolTipButton"
                        onClick={e => this.state.areButtonsActive
                            ? window.open(`${API_ENDPOINT}/${rowData.voucher}`)
                            : null
                        }
                    >
                        <span className="buttonVoucher" aria-label="Ver soporte">
                            <img src={suportView}
                                alt="Comprobante"
                                style={this.state.areButtonsActive
                                    ? { width: '50%' }
                                    : { width: '50%', opacity: '0.5', cursor: 'not-allowed' }
                                }
                            />
                        </span>
                    </div>
            },
            {
                title: "Acciones",
                field: "actions",
                render: rowData =>
                    <div className="row">
                        <div className="col-md-6">
                            {this.state.areButtonsActive
                                ? <div className="toolTipButton" onClick={async e => {
                                    await this.props.setFinancialState({
                                        modalAlertApproveSelect: rowData.actions,
                                        modelAlertApproveTitle: 'APROBAR COMPRA',
                                        modelAlertApproveMessage: 'que desea aprobar la compra?',
                                    });
                                    await this.props.toggleModal('modalAlertApprove');
                                }}>
                                    <span className="buttonVoucher" aria-label="Aprobar">
                                        <img src={hcount} alt="Comprobante" style={{ width: '50%' }} />
                                    </span>
                                </div>
                                :
                                <div className="toolTipButton">
                                    <span className="buttonVoucher" aria-label="Aprobar">
                                        <img src={hcount} alt="Comprobante" style={{ width: '50%', opacity: '0.5', cursor: 'not-allowed' }} />
                                    </span>
                                </div>
                            }
                        </div>
                        <div className="col-md-6">
                            {this.state.areButtonsActive
                                ? <div className="toolTipButton" onClick={async e => {
                                    await this.props.setFinancialState({
                                        modalAlertRejectId: rowData.actions,
                                        modelAlertRejectTitle: 'RECHAZAR COMPRA',
                                        modelAlertRejectMessage: 'que desea rechazar el pago?',
                                    });
                                    await this.props.toggleModal('modalAlertReject');
                                }}>
                                    <span className="buttonVoucher" aria-label="Rechazar">
                                        <img src={refuse} alt="Rechazar" style={{ width: '50%' }} />
                                    </span>
                                </div>
                                :
                                <div className="toolTipButton">
                                    <span className="buttonVoucher" aria-label="Rechazar">
                                        <img src={refuse} alt="Rechazar" style={{ width: '50%', opacity: '0.5', cursor: 'not-allowed' }} />
                                    </span>
                                </div>
                            }
                        </div>
                    </div>
            },
        ];
        return (
            <div className="cardsFinancial">
                <MaterialTable
                    title=""
                    columns={tableColumns}
                    data={this.state.pays}
                    components={{
                        Toolbar: props => (
                            <div className="row p-3">
                                <div className="col-md-9">
                                    <Button className="table-filter-button btn-outline-warning"
                                        onClick={async e => {
                                            await this.props.setFinancialState({
                                                modalFilterType: 'modalFilters'
                                            });
                                            await this.props.toggleModal('modalFilters');
                                        }}>
                                        <img src={FilterIcon} alt="Filtros" />
                                        Filtros
                                    </Button>
                                </div>
                                <div className="col-md-3">
                                    <MTableToolbar {...props} />
                                </div>
                            </div>
                        )
                    }}
                    options={{
                        actionsColumnIndex: -1,
                        headerStyle: {
                            backgroundColor: '#0bac72',
                            color: '#FFF',
                        },
                        pageSize: 10
                    }}
                    localization={{
                        pagination: {
                            labelDisplayedRows: '{from}-{to} de {count}',
                            labelRowsSelect: 'filas'
                        },
                        toolbar: {
                            nRowsSelected: '{0} registro(s) seleccionado(s)',
                            searchPlaceholder: 'Buscar'
                        },
                        body: {
                            emptyDataSourceMessage: 'No hay registros para mostrar',
                            filterRow: {
                                filterTooltip: 'Filtrar'
                            }
                        }
                    }}
                />
            </div>
        );
    }
}

export default Pending
