import {
    SET_REGISTER,
    GET_USER,
    GET_USER_BY_PARAMETERS,
    GET_FORWARDING_CREDENTIAL_MAIL,
    FIND_USER,
    CLEAR_FOUND_USER
} from '../actions/types';

const initialState = {
    data: null,
    obtainedUser: null,
    obtainedUserByParameters: null,
    visibleShoppingCart: null,
};

/**
 * UserReducer (set server response message after succesfull register)
 *
 * @export function
 * @param {*} [state=initialState]
 * @param {*} action
 * @returns {state, boolean, user}
 */
export default function (state = initialState, action) {
    switch (action.type) {
        case SET_REGISTER:
            return {
                ...state,
                data: action.payload
            };
        case GET_USER:
            return {
                ...state,
                obtainedUser: action.payload,
                visibleShoppingCart: action.payload2,
            };
        case GET_USER_BY_PARAMETERS:
            return {
                ...state,
                obtainedUserByParameters: action.payload
            };
        case GET_FORWARDING_CREDENTIAL_MAIL:
            return {
                ...state,
                forwarding: action.payload
            }
        case FIND_USER:
            return {
                ...state,
                foundUser: action.payload,
                usedFindUser: true
            }
        case CLEAR_FOUND_USER:
            return {
                ...state,
                foundUser: null,
                usedFindUser: null
            }
        default:
            return state;
    }
}
