import axios from 'axios';
import { API_ENDPOINT, defaultResponses } from "../config/config";

import {
    SET_REGISTER,
    GET_ERRORS,
    CLEAR_AUTH,
    GET_USER,
    CLEAR_ERRORS,
    GET_USER_BY_PARAMETERS,
    FIND_USER,
    CLEAR_FOUND_USER
} from "./types";

/**
 * AuthAction setRegister ( set user data for registration )
 *
 * @method POST
 * @param {*} userData
 */
export const setRegister = (userData) => async(dispatch) => {
    await axios.post(API_ENDPOINT + '/user', userData)
        .then(res => {
            dispatch({
                type: SET_REGISTER,
                payload: res.data
            });
            dispatch({ type: CLEAR_ERRORS });
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: !!err.response
                ? err.response.data
                : {message: defaultResponses.noResponseFromApi}
            });
            dispatch({ type: CLEAR_AUTH });
        });
};

/**
 * UserAction getUser ( get user data )
 *
 * @method GET
 * @param {*} isContributor
 */
export const getUser = (isContributor = false) => async (dispatch) => {
    let url = isContributor
        ? `${API_ENDPOINT}/user/getContributorUser/`
        : `${API_ENDPOINT}/user/getUser/`;
    await axios.get(url)
        .then(async res => {
            dispatch({
                type: GET_USER,
                payload: res.data.user,
                payload2: res.data.visibleShoppingCart
            });
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: !!err.response
                    ? err.response.data
                    : {message: defaultResponses.noResponseFromApi}
            })
        });
};

/**
 * UserAction getUserByParameters ( get user data based on his Email or Nit number )
 *
 * @method POST
 * @param {*} data
 */
export const getUserByParameters = (data) => dispatch => {
    const url = `${API_ENDPOINT}/advisor/client`;

    axios.post(url, data)
        .then(async res => {
            dispatch({
                type: GET_USER_BY_PARAMETERS,
                payload: res.data.user
            });
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: !!err.response
                    ? err.response.data
                    : {message: defaultResponses.noResponseFromApi}
            })
        });
};


/**
 * Forwarding credential mail
 *
 * @method GET
 * @param {*} id
 */
export const getForwardingCredentialMail = (id) => dispatch => {
    const url = `${API_ENDPOINT}/user/forwardingCredentialMail/${id}`;
    axios.get(url)
        .then(async res => {
            console.log(res)
            dispatch({
                type: GET_USER_BY_PARAMETERS,
                payload: res.data.user
            });
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: !!err.response
                    ? err.response.data
                    : {message: defaultResponses.noResponseFromApi}
            })
        })
}

/**
 * findUser (find in 'User' model)
 *
 * @method GET
 * @param {*} query
 */
export const findUser = (query) => dispatch => {
    const url = `${API_ENDPOINT}/user/find?${query}`;
    axios.get(url)
        .then(async res => {
            console.log(res.data, '<<<foundUSer action')
            dispatch({
                type: FIND_USER,
                payload: res.data.user
            });
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: !!err.response
                    ? err.response.data
                    : {message: defaultResponses.noResponseFromApi}
            })
        })
}


/**
 * clearFoundUser (clear data from reducer)
 *
 * @method GET
 * @param {*}
 */
export const clearFoundUser = () => dispatch => {
    dispatch({
        type: CLEAR_FOUND_USER
    });
}
