import React from "react"
import PropTypes from "prop-types"
import InputFile from "../../../components/InputFile/InputFile";

export const LoadKeyFile = ({ ...props }) => {
    const { keyFileName, onChangeFile, validator, value, errors } = props;
    return (
        <>
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-12">
                        <p className="mt-2 mb-2">Archivo (.csr)</p>
                    </div>
                    <div className="col-md-6 alert alert-secondary" role="alert">
                        {keyFileName}
                    </div>
                    <div className="col-md-6">
                        <InputFile
                            id="keyFile"
                            className={"with-icon"}
                            color={"green"}
                            label="Adjuntar"
                            icon={'QuotationIcon'}
                            onSelectFile={onChangeFile}
                            error={errors["keyFile"]}
                            validator={validator}
                            validateOptions={'required'}
                            value={value}
                        />
                    </div>
                </div>
            </div>
        </>
    )
};

LoadKeyFile.propTypes = {
    onChangeFile: PropTypes.func.isRequired,
    validator: PropTypes.any.isRequired
};