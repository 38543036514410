import { IVA_PERCENTAGE, RETEFUENTE_PERCENTAGE, RETEIVA_PERCENTAGE, RETEICA_PERCENTAGE } from "../config/config";
import { DEPARTMENTS } from './departments';
import { TOWNS } from './towns';

export const formatToThousand =(value) => {
    if(value > 0) return value.toLocaleString('es', {maximumFractionDigits: 20});
    else return value
};

export const replaceAll = (str, find, replace) => {
    return str.replace(new RegExp(find, 'g'), replace);
};

export const parseUrlString = text => {
    if (text.includes('%20'))
        text = replaceAll(text, '%20', ' ');
    if (text.includes('%C3%B3'))
        text = replaceAll(text, '%C3%B3', 'ó');

    return text;
};

export const calculateIvaTax = mount => {
    return (mount * IVA_PERCENTAGE)/100;
};

export const calculateReteFuenteTax = mount => {
    return (mount * RETEFUENTE_PERCENTAGE)/100;
};

export const calculateReteIvaTax = ivaMount => {
    return (ivaMount * RETEIVA_PERCENTAGE)/100;
};

export const calculateReteIcaTax = mount => {
    return (mount * RETEICA_PERCENTAGE)/100;
};

export const extractNumbersOfString = txt => {
    if (!!txt) {
        let numb = txt.match(/\d/g);
        numb = numb.join("");
        return numb;
    }

    return '';
};

export const calculateVerificationDigit = nitNumber => {
    let vpri;
    let x;
    let y;
    let z;

    // Nit cleaning
    nitNumber = nitNumber.replace(/\s/g, ""); // Spaces
    nitNumber = nitNumber.replace(/,/g,  ""); // Commas
    nitNumber = nitNumber.replace(/\./g, ""); // Points
    nitNumber = nitNumber.replace(/-/g,  ""); // Dashes

    if (isNaN(nitNumber)) {
        console.log("El nit " + nitNumber + " no es válido");
        return "";
    }

    vpri = new Array(16);
    z = nitNumber.length;

    vpri[1] = 3;
    vpri[2] = 7;
    vpri[3] = 13;
    vpri[4] = 17;
    vpri[5] = 19;
    vpri[6] = 23;
    vpri[7] = 29;
    vpri[8] = 37;
    vpri[9] = 41;
    vpri[10] = 43;
    vpri[11] = 47;
    vpri[12] = 53;
    vpri[13] = 59;
    vpri[14] = 67;
    vpri[15] = 71;

    x = 0;
    y = 0;

    for (let i=0; i<z; i++) {
        y = (nitNumber.substr(i,1));
        x += (y * vpri[z-i]);
    }

    y = x%11;

    return (y > 1) ? 11 - y : y;
};

export const getTownsByDepartment = departmentName => {
    for (let d of DEPARTMENTS) {
        if (d.val === departmentName) {
            let townsOfThatDepartment = [TOWNS[0]];
            for (let t of TOWNS) {
                if (t.departmentID === d.key) {
                    townsOfThatDepartment.push(t);
                }
            }

            return townsOfThatDepartment;
        }
    }
};

export function isEmpty(obj) {
    return !Object.keys(obj).length
}

export const formatToThousandWithPoints = value => {
    if(value > 0) {
        let isDecimal = value % 1 !== 0;
        if (isDecimal) value = Math.round(value);

        return replaceAll(value.toLocaleString('es-ES', {maximumFractionDigits: 20}), ',', '.');
    }

    else return value
};

export const translateTimeUnit = (unit, plural) =>{
    const units = {
        A: {
            S: " año",
            P: " años"
         },
         M: {
            S: " mes",
            P: " meses"
         },
         D: {
            S: " día",
            P: " días"
         },
    }
    return unit ? units[unit][plural]: "";
};