import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import queryString from 'stringquery';

import CardBody from "../../components/CardBody/CardBody";

import './paymentResponse.css'

import checkIcon from "../../assets/img/icons/check/check.png";
import alertIcon from "../../assets/img/icons/alert/alert.png";
import PrimaryButton from "../../components/PrimaryButton/PrimaryButton";

import { parseUrlString } from '../../helpers/functions';

/**
 * PaymentResponse Page ( renders payment response from PagueATiempo service )
 *
 * @export Class Component
 * @class PaymentResponse
 * @extends {Component}
 * @returns Redux connect
 */
export class PaymentResponse extends Component {
    constructor(props) {
        super(props);

        this.state = {
            response: {}
        };
    }

    componentWillMount() {
        const response = queryString(this.props.location.search);

        console.log('queryResponse: ', response);

        if (
            !!response.comercio && response.comercio !== 'undefined'
            && !!response.codigoUnico && response.codigoUnico !== 'undefined'
            && !!response.fechaHora && response.fechaHora !== 'undefined'
            && !!response.transaccion && response.transaccion !== 'undefined'
            && !!response.total && response.total !== 'undefined'
            && !!response.iva && response.iva !== 'undefined'
            && !!response.purchaseIpAddress && response.purchaseIpAddress !== 'undefined'
            && !!response.status) {

            response.comercio = parseUrlString(response.comercio);
            response.fechaHora = parseUrlString(response.fechaHora);

            this.setState({ response });
        } else {
            this.props.history.push({pathname: '/'})
        }
    }

    render() {
        let { codigoUnico, fechaHora, transaccion, total, iva, status, comercio, purchaseIpAddress } = this.state.response;

        return (
            <CardBody title="VOUCHER" descriptionTitle="Resultado de la transacción">
                <div>
                    <div className="col-sm text-center">
                        <img src={status === 'success' ? checkIcon : alertIcon} alt={status === 'success' ? 'check' : 'alert'} />
                    </div>
                    <br/>
                    <div className="main-text">
                        <p className="text-center container-descript">
                            {status === 'success'
                                ? <span><samp className="green-text">¡¡Perfecto!!</samp> El pago se ha registrado exitosamente, ahora podrás administrar tus productos adquiridos; la factura será enviada a tu correo electrónico.</span>
                                : status === 'pending'
                                    ? <span><samp className="green-text">¡¡Mmmm!!</samp> La transacción ha sido autorizada, pero se encuentra sujeta a evaluación, no te preocupes, lo resolveremos en breve.</span>
                                    : <span><samp className="green-text">¡¡Oops!!</samp> Ha ocurrido un error y no se ha podido efectuar el pago, generaremos un nuevo número de referencia para tu pedido; por favor intenta en unos minutos.</span>
                            }
                        </p>
                    </div>

                    <hr className="divider" />

                    <div className = "container">
                        <div className = "row">
                            <div className = "col-md-8 col-sm-12 offset-md-2 text-center">
                                <table style = {{width: '100%', border: 'none'}}>
                                    <tbody className="secondary-text">
                                        <tr>
                                            <td><strong>Descripción Respuesta:</strong></td>
                                            <td>{status === 'success' ? 'Aprobada' : status === 'pending' ? 'Pendiente' : 'Rechazada'}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Comercio:</strong></td>
                                            <td>{comercio}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Código Único:</strong></td>
                                            <td>{codigoUnico}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Fecha-Hora:</strong></td>
                                            <td>{fechaHora}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Transacción:</strong></td>
                                            <td>{transaccion}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Total:</strong></td>
                                            <td>{total}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>IVA:</strong></td>
                                            <td>{iva}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>IP Usuario:</strong></td>
                                            <td>{purchaseIpAddress}</td>
                                        </tr>
                                    </tbody>
                                </table>

                                <hr className="divider-minor" />

                                <Link to={status === 'success' ? '/admin' : status === 'pending' ? '/products' : '/cart'}>
                                    <PrimaryButton color={"green"} txtBtn={"Continuar"} sizeWidth={""} className={"float-center with-icon"} icon={"ContinueIcon"}>
                                    </PrimaryButton>
                                </Link>

                            </div>
                        </div>
                    </div>
                </div>
            </CardBody>
        )
    }
}

export default PaymentResponse;