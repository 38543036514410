import React, { Component } from 'react'
import MaterialTable, { MTableToolbar } from 'material-table';
import suspend from "../../assets/img/icons/suspend.png";
import ignore from "../../assets/img/icons/ignore.png";
import active from "../../assets/img/icons/active.png";


class Suspension extends Component {
    state = {
        eccommerceUsers: [],
    };

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.listUserEccommerce !== nextProps.listUserEccommerce) {
            this.setState({ eccommerceUsers: nextProps.listUserEccommerce });
        }
    }

    render() {
        const tableColumnsSuspended = [
            { title: "Cliente", field: "entityName", },
            { title: "NIT", field: "documentNumber", },
            { title: "Supervisor", field: "supervisor" },
            { title: "Correo", field: "email" },
            { title: "Observaciones", field: "observation" },
            {
                title: "Acciones", field: "actions",
                headerStyle: {
                    textAlign: 'center',
                },
                render: rowData =>
                    <div className="actionSuspended">
                        <div className="toolTipButton"
                            onClick={async e => {
                                rowData.status === 'enable' ?
                                    await this.props.setFinancialState({
                                        modalAlertApproveSelect: rowData.actions,
                                        modelAlertApproveTitle: 'SUSPENDER CONVENIO',
                                        modelAlertApproveMessage: 'que desea suspender el convenio?',
                                        modalAlertApproveType: 'suspended',
                                        modalApproveSuspended: rowData.status,
                                    }) :
                                    await this.props.setFinancialState({
                                        modalAlertApproveSelect: rowData.actions,
                                        modelAlertApproveTitle: 'ACTIVAR CONVENIO',
                                        modelAlertApproveMessage: 'que desea activar el convenio?',
                                        modalAlertApproveType: 'suspended',
                                        modalApproveSuspended: rowData.status,
                                    });
                                await this.props.toggleModal('modalAlertApprove');
                            }}>
                            {rowData.status === 'enable' ?
                                <span className="buttonVoucher" aria-label="Suspender">
                                    <img src={suspend} alt="Comprobante" style={{ width: '50%' }} />
                                </span>
                                :
                                <span className="buttonVoucher" aria-label="Activar">
                                    <img src={active} alt="Comprobante" style={{ width: '50%' }} />
                                </span>
                            }
                        </div>
                        <div className="toolTipButton"
                            onClick={async e => {
                                await this.props.setFinancialState({
                                    modalAlertApproveSelect: rowData.actions,
                                    modelAlertApproveTitle: 'IGNORAR CONVENIO',
                                    modelAlertApproveMessage: 'que desea ignorar el convenio?',
                                    modalAlertApproveType: 'ignore',
                                    modalApproveSuspended: rowData.status,
                                });
                                await this.props.toggleModal('modalAlertApprove');
                            }}>
                            <span className="buttonVoucher" aria-label="Ignorar">
                                <img src={ignore} alt="Comprobante" style={{ width: '50%' }} />
                            </span>
                        </div>
                    </div>
            }
        ];

        return (
            <MaterialTable
                title=''
                columns={tableColumnsSuspended}
                data={this.state.eccommerceUsers}
                components={{
                    Toolbar: props => (
                        <div className="row p-3">
                            <div className="col-md-12">
                                <MTableToolbar {...props} />
                            </div>
                        </div>
                    )
                }}
                options={{
                    actionsColumnIndex: -1,
                    headerStyle: {
                        backgroundColor: '#0bac72',
                        color: '#FFF',
                    },
                    pageSize: 10
                }}
                localization={{
                    pagination: {
                        labelDisplayedRows: '{from}-{to} de {count}',
                        labelRowsSelect: 'filas'
                    },
                    toolbar: {
                        nRowsSelected: '{0} registro(s) seleccionado(s)',
                        searchPlaceholder: 'Buscar'
                    },
                    body: {
                        emptyDataSourceMessage: 'No hay registros para mostrar',
                        filterRow: {
                            filterTooltip: 'Filtrar'
                        }
                    }
                }}
            />
        );
    }
}

export default Suspension