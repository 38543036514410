import React from 'react'
import InputForm from "../../../components/InputForm/InputForm";

export const CertificateQuantityForm = ({...props}) => {
    const {dataForm, formValidator, onChangeInput, selectedProduct} = props;
    return (
        <div className="divider mt-4 mb-4 container">
            <div className="row pb-3">
                <div className="col-md-12 text-left title-modal-preload">
                    ¿A cuantos certificados desea aplicar esta configuración?
                </div>
            </div>
            <div className="row">
                <div className="form-group col-md-4">
                    <label htmlFor="quantity" className="col-xs-3 col-form-label mr-2">Certificados
                        Disponibles: </label>
                    <span>{selectedProduct.available}</span>
                </div>
                <div className="col-md-8">
                    <div className="form-group row">
                        <label htmlFor="quantity" className="col-xs-3 col-form-label mr-2">Cantidad: </label>
                        <div className="col-xs-9">
                            <InputForm
                                id="quantity"
                                type={"number"}
                                placeholder={""}
                                className="form-control text-input"
                                onChange={onChangeInput}
                                onKeyDown={'number'}
                                value={selectedProduct.available === 0 ? 0 : dataForm.quantity}
                                disabled={selectedProduct.available === 0}
                                min={"1"}
                                max={`${selectedProduct.available}`}
                                validator={formValidator}
                                validateOptions={`required|max:${selectedProduct.available}`}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};