import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody} from 'reactstrap';
import './modalMsg.css';
import Xclose from '../../assets/img/icons/close.png';
import Check from '../../assets/img/icons/check/check.png';
import Alert from '../../assets/img/icons/alert/alert.png';


class ModalMsg extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false
        };

        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
    }

    componentDidMount() {
        //Child passes its method to the parent
        this.props.shareMethods(this.open.bind(this))
    }

    close() {
        this.setState({ show: false });
        if(this.props.redirect){
            const redirect = this.props.redirect.charAt(0) == '/' ? this.props.redirect.substr(1) : this.props.redirect;
            this.props.redirect==='refresh'? window.location.reload() : this.props.history.push('/'+redirect);
        }
    }

    open() {
        this.setState({ show: true });
    }

    render() {
        return (
            <Modal isOpen={this.state.show}>
                <ModalHeader className="header-modal">
                    {this.props.title}
                    <Button className="modal-close" ><img src={Xclose} onClick={this.close} alt="icon product" /></Button>
                </ModalHeader>
                <ModalBody>
                    <div className="container">
                        <div className="col-md-12 row text-center" >
                            <div className="imgSucces">
                                {
                                    this.props.type === 'check' ?
                                        <img className="SuccessIcon" src={Check} alt="icon check"/> : <img className="SuccessIcon" src={Alert} alt="icon success" />
                                }
                            </div>
                            <div className="lbl-container">
                                <p className="text-center textIntro">{this.props.label}</p>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        );
    };
};

export default ModalMsg;

