//Produccion

/*export const PROTOCOL = 'https://'
export const USERNAME = 'consultaVinKel'
export const PASSWORD = 'pmChrpmNb3fdsOLijs1m'
export const URL = '@rs.gsecontrol.gse.com.co:60443/ReportServer/Pages/ReportViewer.aspx?/GSEControl/VinKel/Estado%20Contrato%20Vinkel&Supervisor='
export const CONFIG_FRONT = '&rc:Toolbar=true&rs:ClearSession=True'*/

//QA

/*export const PROTOCOL = 'http://'
export const USERNAME = 'consultaVinKel'
export const PASSWORD = 'pmChrpmNb3fdsOLijs1m'
export const URL = '@192.168.150.7/ReportServer/Pages/ReportViewer.aspx?%2fGSEControl%2fVinKel%2fEstado+Contrato+Vinkel&Supervisor='
export const CONFIG_FRONT = '&rc:Toolbar=true&rs:ClearSession=True'*/

//PRE

export const PROTOCOL = 'https://'
export const USERNAME = 'consultaVinKel'
export const PASSWORD = 'pmChrpmNb3fdsOLijs1m'
export const URL = '@192.168.163.6:60443/ReportServer/Pages/ReportViewer.aspx?%2fVinKel%2fEstado+Contrato+Vinkel&Supervisor='
export const CONFIG_FRONT = '&rc:Toolbar=true&rs:ClearSession=True'
