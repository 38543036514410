export const field_1_options = [
    { key: '', val: '', lbl: 'Seleccione...' },
    {lbl: 'Avenida calle', val: 'AC', key: 'AC'},
    {lbl: 'Administración', val: 'AD', key: 'AD'},
    {lbl: 'Adelante', val: 'ADL', key: 'ADL'},
    {lbl: 'Aeropuerto', val: 'AER', key: 'AER'},
    {lbl: 'Agencia', val: 'AG', key: 'AG'},
    {lbl: 'Agrupación', val: 'AGP', key: 'AGP'},
    {lbl: 'Avenida carrera', val: 'AK', key: ' AK'},
    {lbl: 'Altillo', val: 'AL', key: 'AL'},
    {lbl: 'Al lado', val: 'ALD', key: 'ALD'},
    {lbl: 'Almacén', val: 'ALM', key: 'ALM'},
    {lbl: 'Apartamento', val: 'AP', key: 'AP'},
    {lbl: 'Apartado', val: 'APTDO', key: 'APTDO'},
    {lbl: 'Atrás', val: 'ATR', key: 'ATR'},
    {lbl: 'Autopista', val: 'AUT', key: 'AUT'},
    {lbl: 'Avenida', val: 'AV', key: 'AV'},
    {lbl: 'Anillo vial', val: 'AVIAL', key: 'AVIAL'},
    {lbl: 'Bodega', val: 'BG', key: 'BG'},
    {lbl: 'Bloque', val: 'BL', key: 'BL'},
    {lbl: 'Boulevard', val: 'BLV', key: 'BLV'},
    {lbl: 'Barrio', val: 'BRR', key: 'BRR'},
    {lbl: 'Corregimiento', val: 'C', key: 'C'},
    {lbl: 'Casa', val: 'CA', key: 'CA'},
    {lbl: 'Caserío', val: 'CAS', key: 'CAS'},
    {lbl: 'Centro comercial', val: 'CC', key: 'CC'},
    {lbl: 'Ciudadela', val: 'CD', key: 'CD'},
    {lbl: 'Célula', val: 'CEL', key: 'CEL'},
    {lbl: 'Centro', val: 'CEN', key: 'CEN'},
    {lbl: 'Circular', val: 'CIR', key: 'CIR'},
    {lbl: 'Calle', val: 'CL', key: 'CL'},
    {lbl: 'Callejón', val: 'CLJ', key: 'CLJ'},
    {lbl: 'Camino', val: 'CN', key: 'CN'},
    {lbl: 'Conjunto residencial', val: 'CON', key: 'CON'},
    {lbl: 'Conjunto', val: 'CONJ', key: 'CONJ'},
    {lbl: 'Carrera', val: 'CR', key: 'CR'},
    {lbl: 'Carretera', val: 'CRT', key: 'CRT'},
    {lbl: 'Circunvalar', val: 'CRV', key: 'CRV'},
    {lbl: 'Consultorio', val: 'CS', key: 'CS'},
    {lbl: 'Diagonal', val: 'DG', key: 'DG'},
    {lbl: 'Depósito', val: 'DP', key: 'DP'},
    {lbl: 'Departamento', val: 'DPTO', key: 'DPTO'},
    {lbl: 'Depósito sótano', val: 'DS', key: 'DS'},
    {lbl: 'Edificio', val: 'ED', key: 'ED'},
    {lbl: 'Entrada', val: 'EN', key: 'EN'},
    {lbl: 'Escalera', val: 'ES', key: 'ES'},
    {lbl: 'Esquina', val: 'ESQ', key: 'ESQ'},
    {lbl: 'Este', val: 'ESTE', key: 'ESTE'},
    {lbl: 'Etapa', val: 'ET', key: 'ET'},
    {lbl: 'Exterior', val: 'EX', key: 'EX'},
    {lbl: 'Finca', val: 'FCA', key: 'FCA'},
    {lbl: 'Garaje', val: 'GJ', key: 'GJ'},
    {lbl: 'Garaje sótano', val: 'GS', key: 'GS'},
    {lbl: 'Glorieta', val: 'GT', key: 'GT'},
    {lbl: 'Hacienda', val: 'HC', key: 'HC'},
    {lbl: 'Hangar', val: 'HG', key: 'HG'},
    {lbl: 'Interior', val: 'IN', key: 'IN'},
    {lbl: 'Inspección de Policía', val: 'IP', key: 'IP'},
    {lbl: 'Inspección Departamental', val: 'IPD', key: 'IPD'},
    {lbl: 'Inspección Municipal', val: 'IPM', key: 'IPM'},
    {lbl: 'Kilómetro', val: 'KM', key: 'KM'},
    {lbl: 'Local', val: 'LC', key: 'LC'},
    {lbl: 'Local mezzanine', val: 'LM', key: 'LM'},
    {lbl: 'Lote', val: 'LT', key: 'LT'},
    {lbl: 'Módulo', val: 'MD', key: 'MD'},
    {lbl: 'Mojón', val: 'MJ', key: 'MJ'},
    {lbl: 'Muelle', val: 'MLL', key: 'MLL'},
    {lbl: 'Mezzanine', val: 'MN', key: 'MN'},
    {lbl: 'Manzana', val: 'MZ', key: 'MZ'},
    {lbl: 'Vías de nombre común', val: 'VIA', key: 'VIA'},
    {lbl: 'Norte', val: 'NORTE', key: 'NORTE'},
    {lbl: 'Oriente', val: 'O', key: 'O'},
    {lbl: 'Occidente', val: 'OCC', key: 'OCC'},
    {lbl: 'Oeste', val: 'OESTE', key: 'OESTE'},
    {lbl: 'Oficina', val: 'OF', key: 'OF'},
    {lbl: 'Piso', val: 'P', key: 'P'},
    {lbl: 'Parcela', val: 'PA', key: 'PA'},
    {lbl: 'Parque', val: 'PAR', key: 'PAR'},
    {lbl: 'Predio', val: 'PD', key: 'PD'},
    {lbl: 'Penthouse', val: 'PH', key: 'PH'},
    {lbl: 'Pasaje', val: 'PJ', key: 'PJ'},
    {lbl: 'Planta', val: 'PL', key: 'PL'},
    {lbl: 'Puente', val: 'PN', key: 'PN'},
    {lbl: 'Portería', val: 'POR', key: 'POR'},
    {lbl: 'Poste', val: 'POS', key: 'POS'},
    {lbl: 'Parqueadero', val: 'PQ', key: 'PQ'},
    {lbl: 'Paraje', val: 'PRJ', key: 'PRJ'},
    {lbl: 'Paseo', val: 'PS', key: 'PS'},
    {lbl: 'Puesto', val: 'PT', key: 'PT'},
    {lbl: 'Park Way', val: 'PW', key: 'PW'},
    {lbl: 'Round Point', val: 'RP', key: 'RP'},
    {lbl: 'Salón', val: 'SA', key: 'SA'},
    {lbl: 'Salón comunal', val: 'SC', key: 'SC'},
    {lbl: 'Salida', val: 'SD', key: 'SD'},
    {lbl: 'Sector', val: 'SEC', key: 'SEC'},
    {lbl: 'Solar', val: 'SL', key: 'SL'},
    {lbl: 'Súper manzana', val: 'SM', key: 'SM'},
    {lbl: 'Semisótano', val: 'SS', key: 'SS'},
    {lbl: 'Sótano', val: 'ST', key: 'ST'},
    {lbl: 'Suite', val: 'SUITE', key: 'SUITE'},
    {lbl: 'Sur', val: 'SUR', key: 'SUR'},
    {lbl: 'Terminal', val: 'TER', key: 'TER'},
    {lbl: 'Terraplén', val: 'TERPLN', key: 'TERPLN'},
    {lbl: 'Torre', val: 'TO', key: 'TO'},
    {lbl: 'Transversal', val: 'TV', key: 'TV'},
    {lbl: 'Terraza', val: 'TZ', key: 'TZ'},
    {lbl: 'Unidad', val: 'UN', key: 'UN'},
    {lbl: 'Unidad residencial', val: 'UR', key: 'UR'},
    {lbl: 'Urbanización', val: 'URB', key: 'URB'},
    {lbl: 'Vereda', val: 'VRD', key: 'VRD'},
    {lbl: 'Variante', val: 'VTE', key: 'VTE'},
    {lbl: 'Zona franca', val: 'ZF', key: 'ZF'},
    {lbl: 'Zona', val: 'Z', key: 'ZN'}
];

export const field_3_options = [
    { key: '', val: '', lbl: 'Seleccione...' },
    {key: 'A', val: 'A', lbl: 'A'},
    {key: 'B', val: 'B', lbl: 'B'},
    {key: 'C', val: 'C', lbl: 'C'},
    {key: 'D', val: 'D', lbl: 'D'},
    {key: 'E', val: 'E', lbl: 'E'},
    {key: 'F', val: 'F', lbl: 'F'},
    {key: 'G', val: 'G', lbl: 'G'},
    {key: 'H', val: 'H', lbl: 'H'},
    {key: 'I', val: 'I', lbl: 'I'},
    {key: 'J', val: 'J', lbl: 'J'},
    {key: 'K', val: 'K', lbl: 'K'},
    {key: 'L', val: 'L', lbl: 'L'},
    {key: 'M', val: 'M', lbl: 'M'},
    {key: 'N', val: 'N', lbl: 'N'},
    {key: 'O', val: 'O', lbl: 'O'},
    {key: 'P', val: 'P', lbl: 'P'},
    {key: 'Q', val: 'Q', lbl: 'Q'},
    {key: 'R', val: 'R', lbl: 'R'},
    {key: 'S', val: 'S', lbl: 'S'},
    {key: 'T', val: 'T', lbl: 'T'},
    {key: 'U', val: 'U', lbl: 'U'},
    {key: 'V', val: 'V', lbl: 'V'},
    {key: 'W', val: 'W', lbl: 'W'},
    {key: 'X', val: 'X', lbl: 'X'},
    {key: 'Y', val: 'Y', lbl: 'Y'},
    {key: 'Z', val: 'Z', lbl: 'Z'}
];

export const field_4_options = [
    { key: '', val: '', lbl: 'Seleccione...' },
    {key: 'Bis', val: 'Bis', lbl: 'Bis'}
];

export const field_6_options = [
    { key: '', val: '', lbl: 'Seleccione...' },
    {key: 'Este', val: 'Este', lbl: 'Este'},
    {key: 'Sur', val: 'Sur', lbl: 'Sur'}
];