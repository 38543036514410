import React from 'react'
import {ModalBody} from "reactstrap";
import {API_ENDPOINT} from "../../../config/config";
import PropTypes from "prop-types";

export const HeaderAssignment = ({...props}) => {
    const {selectedProduct} = props;
    return (
        <>
            <ModalBody>
                <div className="container">
                    <div className="col-md-12 row text-center">
                        <div className="col-md-2">
                            <div className="icon-product m-0 p-0">
                                <img src={API_ENDPOINT + selectedProduct.icon} alt="icon product"/>
                            </div>
                        </div>
                        <div className="col-md-10">
                            <div className="text-left">
                                <div className="title-modal-preload">
                                    {selectedProduct.productName}
                                </div>
                                <div className="certificate-info">
                                    {selectedProduct.preloadID ? 
                                        <>
                                            Medio Entrega: {selectedProduct.delivery} /
                                            Vigencia: {selectedProduct.validity} / Tiempo de
                                            Entrega: {selectedProduct.deliveryTime}
                                            <br/>
                                            NIT: {selectedProduct.preloadID.nit ? selectedProduct.preloadID.nit : '---'} /
                                            Nombre o Razón Social: {selectedProduct.preloadID.name ? selectedProduct.preloadID.name : '---'} /
                                            Dirección: {selectedProduct.preloadID.address ? selectedProduct.preloadID.address : '---'} /
                                            Cargo: {selectedProduct.preloadID.charge ? selectedProduct.preloadID.charge : '---'} /
                                            Dependencia: {selectedProduct.preloadID.dependence ? selectedProduct.preloadID.dependence : '---'} /
                                            Departamento: {selectedProduct.preloadID.department ? selectedProduct.preloadID.department : '---'} /
                                            Municipio: {selectedProduct.preloadID.city ? selectedProduct.preloadID.city : '---'} /
                                            Teléfono: {selectedProduct.preloadID.phone ? selectedProduct.preloadID.phone : '---'} /
                                            Indicativo: {selectedProduct.preloadID.indicative ? selectedProduct.preloadID.indicative : '---'} /
                                            Extensión: {selectedProduct.preloadID.extension ? selectedProduct.preloadID.extension : '---'} 
                                        </>
                                        :
                                        <>
                                            Medio Entrega: {selectedProduct.delivery} /
                                            Vigencia: {selectedProduct.validity} / Tiempo de
                                            Entrega: {selectedProduct.deliveryTime}
                                            <br/>
                                            Cantidad Disponible: {selectedProduct.available}
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalBody>
        </>
    )
};

HeaderAssignment.propTypes = {
    selectedProduct: PropTypes.object.isRequired
};