import { SET_STANDARD_CLIENT, SET_AGREEMENT_CLIENT } from '../actions/types';

const initialState = {
    successStandard: false,
    data: null
};

/**
 * UserReducer (set server response message after succesfull register)
 *
 * @export function
 * @param {*} [state=initialState]
 * @param {*} action
 * @returns {state, boolean, user}
 */
export default function (state = initialState, action) {
    switch (action.type) {
        case SET_STANDARD_CLIENT:
            return {
                ...state,
                successStandard: true,
                data: action.payload
            };
        case SET_AGREEMENT_CLIENT:
            return {
                ...state,
                successAgreement: true,
                data: action.payload
            };
        default:
            return state;
    }
}
