import React, {Component} from 'react'
import {connect} from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import CardBody from "../../components/CardBody/CardBody";
import check from "../../assets/img/icons/check/check.png";
import hcount from "../../assets/img/icons/have-count.png";

import Pending from "./Pending";
import Approved from "./Approved";
import Suspension from "./Suspension";
import SiigoPendings from "./sections/SiigoPendings";
import ModalFilters from "./modals/ModalFilters";
import ModalAlert from "./modals/ModalAlert";
import ModalAlertApprove from "./modals/ModalAlertApprove";
import ModalAlertReject from "./modals/ModalAlertReject";
import ModalSuccessful from "./modals/ModalSuccessful";

import { getPendingFinancial } from "../../actions/financialActions";
import {reactValidatorOptions} from "../../helpers/simpleReactValidator";

class Financial extends Component {
    constructor(props) {
        super(props);
        this.props.getPendingFinancial();

        this.validator = new SimpleReactValidator(reactValidatorOptions);
    }

    state = {
        modalAlert: false,
        modalAlertTitle: '',
        modalAlertMessage: '',
        actualOperation: '',

        modalFilters: false,
        modalFilterType: '',

        modalSuccessful: false,
        modalSuccessfulTitle: '',
        modalSuccessfulMessage: '',

        modalAlertApprove: false,
        modalAlertApproveSelect: '',
        modelAlertApproveTitle: '',
        modelAlertApproveMessage: '',
        modalAlertApproveType: '',
        modalApproveSuspended: 'enable',

        modalAlertReject: false,
        modelAlertRejectTitle: '',
        modelAlertRejectMessage: '',
        modalAlertRejectId: '',
        rejectSaleOptions: [],
        dataFilter: [],
        typeModalFilter: '',

        areButtonsActive: true
    };

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.modalSuccessful !== nextProps.modalSuccessful) {
            this.setState(() => {
                return {modalSuccessful: nextProps.modalSuccessful}
            })
        }

        if (this.props.rejectSaleOptions !== nextProps.rejectSaleOptions) {
            this.setState({ rejectSaleOptions: nextProps.rejectSaleOptions });
        }

        if (this.props.listProduct !== nextProps.listProduct) {
            this.setState({ areButtonsActive: true });
        }
    }

    toggleModal = (modalType,typeFilter) => {
        if (modalType === 'modalFilters' && typeFilter === 'siigoPendings') {
            this.setState({
                [modalType]: !this.state[modalType],
                dataFilter: this.props.pendingInvoices,
                typeModalFilter : 'siigoPendings'
            });
        } else if (modalType === 'modalFilters' && typeFilter === 'approved') {
            this.setState({
                [modalType]: !this.state[modalType],
                dataFilter: this.props.listProductSold,
                typeModalFilter : 'approved'
            });
        }
        else{
            this.setState({
                [modalType]: !this.state[modalType]
            });    
        }
    };

    toggleAlertModal = (alertType = null) => {
        this.setState({modalAlert: !this.state.modalAlert});
        if (alertType === 'completeOnField') {
            this.setState({
                actualOperation: alertType,
                modalAlertTitle: 'REGISTRAR UN CAMPO',
                modalAlertMessage: 'Debe completar por lo menos un campo.'
            })
        } else {
            this.setState({actualOperation: '', modalAlertTitle: '', modalAlertMessage: ''})
        }
    };

    setFinancialState = (state) => {
        this.setState(state)
    };

    onChangeCustomInput = () => e => {
        if (!!e.target.id) {
            this.setState({[e.target.id]: e.target.value, errors: {}});
        } else {
            console.error('Error actualizando la informacion ingresada', 'error');
        }
    };

    render() {
        let titleCard = "PAGOS POR APROBAR";
        let descriptionTitleCard = "Listado de pagos";
        return (
            <div>
                <CardBody title={titleCard} descriptionTitle={descriptionTitleCard}>
                    <div className="textIntro">
                        <p className="text-center">Apruebe cada una de las <span
                            className="green-text">compras en efectivo</span> de sus clientes de manera fácil y rápida
                        </p>
                    </div>
                    <Pending
                        setFinancialState={this.setFinancialState}
                        listProduct={this.props.listProduct}
                        toggleModal={this.toggleModal}
                        areButtonsActive={this.state.areButtonsActive}
                    />
                    <div className="root">
                        <ExpansionPanel className="cardsFinancial">
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                href="#"
                            >
                                <div className="heading">
                                    <div className="row">
                                        <div className="img-container">
                                            <img className="hcount" src={check} alt="hcount"/>
                                        </div>
                                        <p className="section-title text-center">PAGOS APROBADOS</p>
                                    </div>
                                </div>
                            </ExpansionPanelSummary>
                            <div className="hl-green">
                            </div>
                            <br/><br/>
                            <ExpansionPanelDetails className="detailTables">
                                <div className="col-12">
                                    <Approved
                                        setFinancialState={this.setFinancialState}
                                        listProductSold={this.props.listProductSold}
                                        toggleModal={this.toggleModal}
                                    />
                                </div>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        {/* Siigo pending invoices block */}
                        <ExpansionPanel className="cardsFinancial">
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1a-content"
                                id="panel3a-header"
                                href="#"
                            >
                                <div className="heading">
                                    <div className="row">
                                        <div className="img-container">
                                            <img className="hcount" src={check} alt="hcount"/>
                                        </div>
                                        <p className="section-title text-center">PAGOS PENDIENTES POR REGISTRAR EN SIIGO</p>
                                    </div>
                                </div>
                            </ExpansionPanelSummary>
                            <div className="hl-green">
                            </div>
                            <br/><br/>
                            <ExpansionPanelDetails className="detailTables">
                                <div className="col-12">
                                    <SiigoPendings
                                        setFinancialState={this.setFinancialState}
                                        toggleModal={this.toggleModal}
                                        history={this.props.history}
                                    />
                                </div>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        {/* End Siigo pending invoices block */}
                        <ExpansionPanel className="cardsFinancial">
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                                href="#"
                            >
                                <div className="heading">
                                    <div className="row">
                                        <div className="img-container">
                                            <img className="hcount" src={hcount} alt="hcount"/>
                                        </div>
                                        <p className="section-title text-center">SUSPENSIÓN/ACTIVACIÓN CONVENIOS</p>
                                    </div>
                                </div>
                            </ExpansionPanelSummary>
                            <div className="hl-green">
                            </div>
                            <br/><br/>
                            <ExpansionPanelDetails className="detailTables">  
                                <div className="col-12">
                                    <Suspension
                                        setFinancialState={this.setFinancialState}
                                        listUserEccommerce={this.props.listUserEccommerce}
                                        toggleModal={this.toggleModal}
                                    />
                                </div>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </div>
                </CardBody>
                <ModalAlertApprove
                    setFinancialState={this.setFinancialState}
                    toggleModal={this.toggleModal}
                    modalAlertApprove={this.state.modalAlertApprove}
                    modelAlertApproveTitle={this.state.modelAlertApproveTitle}
                    modelAlertApproveMessage={this.state.modelAlertApproveMessage}
                    modalAlertApproveType={this.state.modalAlertApproveType}
                    modalApproveSuspended={this.state.modalApproveSuspended}
                    modalAlertApproveSelect={this.state.modalAlertApproveSelect}
                    areButtonsActive={this.state.areButtonsActive}
                />
                <ModalAlertReject
                    setFinancialState={this.setFinancialState}
                    toggleModal={this.toggleModal}
                    showModal={this.state.modalAlertReject}
                    title={this.state.modelAlertRejectTitle}
                    message={this.state.modelAlertRejectMessage}
                    modalAlertRejectId={this.state.modalAlertRejectId}
                    rejectSaleOptions={this.state.rejectSaleOptions}
                    areButtonsActive={this.state.areButtonsActive}
                />
                <ModalSuccessful
                    modalSuccessful={this.state.modalSuccessful}
                    modalSuccessfulTitle={this.state.modalSuccessfulTitle}
                    modalSuccessfulMessage={this.state.modalSuccessfulMessage}
                />
                <ModalFilters
                    modalFilterType={this.state.modalFilterType}
                    modalFilters={this.state.modalFilters}
                    listProduct={this.props.listProduct}
                    dataFilter={this.state.dataFilter}
                    setFinancialState={this.setFinancialState}
                    toggleModal={this.toggleModal}
                    toggleAlertModal={this.toggleAlertModal}
                    typeModalFilter={this.state.typeModalFilter}
                />
                <ModalAlert
                    modalAlert={this.state.modalAlert}
                    modalAlertTitle={this.state.modalAlertTitle}
                    actualOperatio={this.state.actualOperation}
                    modalAlertMessage={this.state.modalAlertMessage}
                    toggleAlertModal={this.toggleAlertModal}
                />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    listProduct: state.financialReducer.listProduct,
    listProductSold: state.financialReducer.listProductSold,
    listUserEccommerce: state.financialReducer.listUserEccommerce,
    modalSuccessful: state.financialReducer.modalSuccessful,
    rejectSaleOptions: state.financialReducer.rejectSaleOptions,
    pendingInvoices: state.financialReducer.pendingInvoices
});

export default connect(mapStateToProps, {getPendingFinancial})(Financial)
