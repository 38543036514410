import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { recoverAccount } from "../../actions/authActions";

import InputForm from '../../components/InputForm/InputForm';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';

import SimpleReactValidator from 'simple-react-validator';
import { reactValidatorOptions } from '../../helpers/simpleReactValidator';

import passwordx2 from '../../assets/img/icons/password/passwordx2.png';

/**
 * ForgotPassword Component ( full view ForgotPassword component, render a ForgotPassword
 * form and make a post request for send recover password via email )
 *
 * @export Class Component
 * @class ForgotPassword
 * @extends {Component}
 * @returns Redux connect
 */
export class ForgotPassword extends Component {

    constructor(props) {
        super(props);

        this.state = {
            recoverUserName: '',
            modalMsg: []
        };

        this.ForgotPasswordValidator = new SimpleReactValidator(reactValidatorOptions);
    }

    acceptMethods = (openModalMsg) => {
        this.openModalMsg = openModalMsg;
    };

    onChangeCustomInput = () => e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    ForgotPassword = (e) => {
        e.preventDefault();
        if (this.ForgotPasswordValidator.allValid()) {
            let data = {
                recoverUserName: this.state.recoverUserName
            }
            this.props.recoverAccount(data);
        } else {
            this.ForgotPasswordValidator.showMessages();
            this.forceUpdate();
        }
    }

    render() {
        return (
            <div>
                <div className="col-md-12 row text-center">
                    <div className="imgSucces">
                        <img className="SuccessIcon" src={passwordx2} alt="password" />
                    </div>
                    <div className="lbl-container">
                        <p className="text-center modal-form-lbl">{this.props.title}</p>
                    </div>
                </div>
                <div className="form-container">
                    <form onSubmit={e => this.ForgotPassword(e)}>
                        <div className="row">
                            <div className="col-sm-12 col-md-12">
                                <InputForm
                                    id="recoverUserName"
                                    type={"text"}
                                    placeholder={"Usuario"}
                                    icon={"user"}
                                    className="form-control large"
                                    onChange={this.onChangeCustomInput()}
                                    value={this.state.recoverUserName}
                                    validator={this.ForgotPasswordValidator}
                                    validateOptions={'required'}
                                />
                            </div>
                        </div>
                       
                        <div className="row">
                            <div className="col col-12">
                                <PrimaryButton
                                    color={"green"}
                                    txtBtn={"Correo de recuperación"}
                                    sizeWidth={""}
                                    className={"float-right with-icon"}
                                    icon={"registry"}
                                >
                                </PrimaryButton>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

ForgotPassword.propTypes = {
    recoverAccount: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    
})

export default connect(mapStateToProps, { recoverAccount })(ForgotPassword)
