import React from 'react'
import InputFile from "../../../components/InputFile/InputFile";

const DocumentsPreloadForm = ({...props}) => {
    const {onChangeFile, dataForm, selectedProduct, errorForm} = props;
    const firstDocumentTextByProduct = (selectedProduct) => {
        if (selectedProduct.ref !== 'FP')
            return <p className="mt-2 mb-2">Documento de Existencia y Representación Legal de la Empresa (no mayor
                a treinta (30) días)</p>;
        else
            return <p className="mt-2 mb-2">Acta de Posesión / Contrato Prestación de Servicios</p>;
    };
    return (
        <div className="container">
            <div className="row pb-3">
                <div className="col-md-12 text-center title-modal-preload">
                    PRECARGA DE DOCUMENTOS COMUNES
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    {firstDocumentTextByProduct(selectedProduct)}
                </div>
                <div className="col-md-6 alert alert-secondary" role="alert">
                    {dataForm.firstDocumentName}
                </div>
                <div className="col-md-6">
                    <InputFile
                        id="firstDocument"
                        className={"with-icon"}
                        color={"green"}
                        label="Adjuntar"
                        icon={'QuotationIcon'}
                        onSelectFile={onChangeFile}
                        error={errorForm["firstDocument"]}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <p className="mt-2 mb-2">Registro Único Tributario RUT</p>
                </div>
                <div className="col-md-6 alert alert-secondary" role="alert">
                    {dataForm.secondDocumentName}
                </div>
                <div className="col-md-6">
                    <InputFile
                        id="secondDocument"
                        className={"with-icon"}
                        color={"green"}
                        label="Adjuntar"
                        icon={'QuotationIcon'}
                        onSelectFile={onChangeFile}
                        error={errorForm["secondDocument"]}
                    />
                </div>
            </div>
        </div>
    );
};

export default DocumentsPreloadForm