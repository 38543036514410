import axios from 'axios';
import { API_ENDPOINT, defaultResponses } from "../config/config";
import { SET_STANDARD_CLIENT, SET_AGREEMENT_CLIENT, GET_ERRORS } from "./types";

/**
 * AuthAction setRegister ( set user data for registration )
 *
 * @method POST
 * @param {*} userData
 */

export const setStandardClient = (userData) => async(dispatch) => {
    await axios.post(API_ENDPOINT + '/advisor/save/client', userData)
        .then(res => {
            dispatch({
                type: SET_STANDARD_CLIENT,
                payload: res.data
            });
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: !!err.response
                ? err.response.data
                : {message: defaultResponses.noResponseFromApi}
            })
        });
};

export const setAgreementClient = (userData) => async(dispatch) => {
    await axios.post(API_ENDPOINT + '/advisor/save/client', userData)
        .then(res => {
            dispatch({
                type: SET_AGREEMENT_CLIENT,
                payload: res.data
            });
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: !!err.response
                ? err.response.data
                : {message: defaultResponses.noResponseFromApi}
            })
        });
};


