export const fileToBase64Clean = (filename, file, e) => {
    return new Promise(resolve => {
        let reader = new FileReader();
        reader.onload = function (event) {
            resolve(event.target.result)
        };
        reader.readAsDataURL(file);
        e.target.value = null
    })
};

export const fileToBase64 = (filename, file) => {
    return new Promise(resolve => {
        let reader = new FileReader();
        reader.onload = function (event) {
            resolve(event.target.result)
        };
        reader.readAsDataURL(file)
    })
};

export const base64MimeType = (encoded) => {
    let result = null;
    if (typeof encoded !== 'string') return result;
    let mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
    if (mime && mime.length) result = mime[1];
    return result;
};