/**	
 * List Document types to select input
 * 
 */

export const DOCUMENT_TYPES = [
    { key: '', val: '', lbl: 'Tipo Documento...' },
	{ key: 'CC', val: 'CC', lbl: 'Cédula de ciudadanía' },
    { key: 'CE', val: 'CE', lbl: 'Cédula extranjería' },
    { key: 'PS', val: 'PS', lbl: 'Pasaporte' },
    { key: 'NIT', val: 'NIT', lbl: 'Nit' }
];