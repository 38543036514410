import React, { Component } from 'react'
import '../../styles2.css';
import { TERMS_CONDITIONS_URL } from '../../config/config';

class TermsAndConditionsRegister extends Component {

    render() {
        const { termsAndConditionsProduct } = this.props;

        return (
            <div class="footer-container p-3" >
                <span
                    dangerouslySetInnerHTML={{ __html: `${termsAndConditionsProduct}` }}
                />
            </div>
        )
    }
}

export default TermsAndConditionsRegister;