export const regexNumber = (e) => {
    if (
        (e.keyCode >= 48 && e.keyCode <= 57 && !e.shiftKey) || //keyboard 0-9
        (e.keyCode >= 96 && e.keyCode <= 105) || //numpad 0-9
        (e.keyCode === 8 || e.keyCode === 9 || e.keyCode === 116) || //backspace, tab, f5
        (e.keyCode === 39 || e.keyCode === 37 || e.keyCode === 46) // left and right arrows, Supr key
    ) { }
    else { e.preventDefault() }
};

export const regexPhone = (e) => {
    if (
        (e.keyCode >= 48 && e.keyCode <= 57 && !e.shiftKey) || //keyboard 0-9
        (e.keyCode >= 96 && e.keyCode <= 105) || //numpad 0-9
        (e.keyCode === 8 || e.keyCode === 9 || e.keyCode === 116) || //backspace, tab, f5
        (e.keyCode === 39 || e.keyCode === 37 || e.keyCode === 46) // left and right arrows, Supr key
    ) { }
    else { e.preventDefault() }
};

export const regexNit = (e) => {
    if (
        (e.keyCode >= 48 && e.keyCode <= 57 && !e.shiftKey) || //keyboard 0-9
        (e.keyCode >= 96 && e.keyCode <= 105) || //numpad 0-9
        (e.keyCode === 109) || //NumpadSubtract
        (e.keyCode === 8 || e.keyCode === 9 || e.keyCode === 116 || e.keyCode === 189) || //backspace, tab, f5, slash
        (e.keyCode === 39 || e.keyCode === 37 || e.keyCode === 46) // left and right arrows, Supr key
    ) { }
    else { e.preventDefault() }
};

export const regexDocument = (e) => {
    //0-9 delete tab
    if (
        (e.keyCode >= 48 && e.keyCode <= 57 && !e.shiftKey) || //keyboard 0-9
        (e.keyCode >= 65 && e.keyCode <= 90) || //keyboard a-z
        (e.keyCode >= 96 && e.keyCode <= 105) || //numpad 0-9
        (e.keyCode === 8 || e.keyCode === 9 || e.keyCode === 116) || //backspace, tab, f5
        (e.keyCode === 39 || e.keyCode === 37 || e.keyCode === 46) // left and right arrows, Supr key
    ) { }
    else { e.preventDefault() }
};

export const regexAddress = (e) => {
    //0-9 delete tab
    if (
        (e.keyCode >= 48 && e.keyCode <= 50 && !e.shiftKey) || //keyboard 0-2
        (e.keyCode >= 52 && e.keyCode <= 57 && !e.shiftKey) || //keyboard 4-9
        (e.keyCode === 51) || //keyboard 3
        (e.keyCode >= 65 && e.keyCode <= 90) || //keyboard a-z
        (e.keyCode >= 96 && e.keyCode <= 105) || //numpad 0-9
        (e.keyCode === 8 || e.keyCode === 9 || e.keyCode === 32 || e.keyCode === 116 ||  e.keyCode === 173 || e.keyCode === 188 || e.keyCode === 189 || e.keyCode === 190 || e.keyCode === 51) || //backspace, tab, minus, comma, space, f5, Slash, dot, #
        (e.keyCode === 39 || e.keyCode === 37 || e.keyCode === 46) || // left and right arrows, Supr key
        (e.keyCode === 109) || // -
        (e.keyCode === 220) // °
    ) { }
    else { e.preventDefault() }
};

export const regexAlphaSpace = (e) => {
    //0-9 delete tab
    if (
        (e.keyCode >= 65 && e.keyCode <= 90) || //keyboard a-z
        (e.keyCode === 8 || e.keyCode === 9 || e.keyCode === 32 || e.keyCode === 116) || //backspace, tab, space, f5
        (e.keyCode === 39 || e.keyCode === 37 || e.keyCode === 46) || // left and right arrows, Supr key
        (e.keyCode === 192) // ñ and Ñ keyCode
    ) { }
    else { e.preventDefault() }
};

export const regexAlphaNumSpace = (e) => {
    
    //0-9 delete tab
    if (
        (e.keyCode >= 48 && e.keyCode <= 57 && !e.shiftKey) || //keyboard 0-9
        (e.keyCode >= 65 && e.keyCode <= 90) || //keyboard a-z
        (e.keyCode >= 96 && e.keyCode <= 105) || //numpad 0-9
        (e.keyCode === 8 || e.keyCode === 9 || e.keyCode === 32 || e.keyCode === 116) || //backspace, tab, space, f5
        (e.keyCode === 39 || e.keyCode === 37 || e.keyCode === 46) || // left and right arrows, Supr key
        (e.keyCode === 59) // ñ and Ñ keyCode
    ) { }
    else { e.preventDefault() }
};

export const regexLegalName = (e) => {
    if (
        (e.keyCode >= 48 && e.keyCode <= 57 && !e.shiftKey) || //keyboard 0-9
        (e.keyCode >= 65 && e.keyCode <= 90) || //keyboard a-z
        (e.keyCode >= 96 && e.keyCode <= 105) || //numpad 0-9
        (
            e.keyCode === 8 || e.keyCode === 9 || e.keyCode === 32 || e.keyCode === 116 || e.keyCode === 110
            || e.keyCode === 16 || e.keyCode === 192
            || e.keyCode === 49 || e.keyCode === 51 || e.keyCode === 54 || e.keyCode === 56 || e.keyCode === 57 || e.keyCode === 61
            || e.keyCode === 188 || e.keyCode === 173 || e.keyCode === 190 || e.keyCode === 222 
        ) || //backspace, tab, space, f5, point, and other special characters
        (e.keyCode === 39 || e.keyCode === 37 || e.keyCode === 46) // left and right arrows, Supr key
    ) { }
    else { e.preventDefault() }
};

export const regexCheckUsername = (e) => {
    //0-9 delete tab
    if (
        (e.keyCode >= 48 && e.keyCode <= 57 && !e.shiftKey) || //keyboard 0-9
        (e.keyCode >= 65 && e.keyCode <= 90) || //keyboard a-z A-Z
        (e.keyCode >= 96 && e.keyCode <= 105) || //numpad 0-9
        (e.keyCode === 189) || //_ -
        ((e.keyCode === 110 || e.keyCode === 173 || e.keyCode === 190)  && !e.shiftKey) || //line, dot, decimal dot
        (e.keyCode === 8 || e.keyCode === 9 || e.keyCode === 116) || //backspace, tab, f5
        (e.keyCode === 39 || e.keyCode === 37 || e.keyCode === 46) // left and right arrows, Supr key

    ) { }
    else { e.preventDefault() }
};

export const regexCheckPassword = (e) => {
    //0-9 delete tab
    //params (at least one num)(at least one a-z)(at least one A-Z)(at least one char)(non accepted chars)
    let regx = /^(?=.*?[0-9])(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[-_.,+])(?!.*?[ ]).+$/;

    if (e.target.value.match(regx)) {
    } else { e.preventDefault() }
};


export const preventCtrlCV = (e) => {
    //ctrl, cmd, c, v
    if ((e.ctrlKey) && (e.keyCode === 86 || e.keyCode === 67)) {
        e.preventDefault()
    }
};

export const preventSpace = (e) => {
    //Space
    if ((e.ctrlKey) && e.keyCode === 86 || e.keyCode === 32) {
        e.preventDefault()
    }
};