export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

//auth reducer 
export const ACTIVATE_ACCOUNT = 'ACTIVATE_ACCOUNT';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CLEAR_AUTH = 'CLEAR_AUTH';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_REGISTER = 'SET_REGISTER';
export const RECOVER_ACCOUNT = 'RECOVER_ACCOUNT';
export const GET_USER = 'GET_USER';
export const GET_USER_BY_PARAMETERS = 'GET_USER_BY_PARAMETERS';
export const GET_FORWARDING_CREDENTIAL_MAIL = 'GET_FORWARDING_CREDENTIAL_MAIL';
export const FIND_USER = 'FIND_USER';
export const CLEAR_FOUND_USER = 'CLEAR_FOUND_USER';


//auth reducers end

// products reducer
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const SHOW_PRODUCT_DATA = 'SHOW_PRODUCT_DATA';
export const SET_PRODUCT = 'SET_PRODUCT';
// products reducer end

// advisor reducer
export const GET_ADV_USERS = 'GET_ADV_USERS';
// advisor reducer end

//bussines advisor reducer
export const SET_STANDARD_CLIENT = 'SET_STANDARD_CLIENT';
export const SET_AGREEMENT_CLIENT = 'SET_AGREEMENT_CLIENT';
//bussines advisor reducer end

// shopping cart reducer
export const GET_DETAIL_SALE = 'GET_DETAIL_SALE';
export const DETAIL_SALE_REMOVE_PRODUCT = 'DETAIL_SALE_REMOVE_PRODUCT';
export const DETAIL_SALE_UPDATE_QUANTITY_PRODUCT = 'DETAIL_SALE_UPDATE_QUANTITY_PRODUCT';
export const UPDATE_PRODUCT_LIST = 'UPDATE_PRODUCT_LIST';
export const UPDATE_TOTAL = 'UPDATE_TOTAL';
export const BUY_PRODUCTS = 'BUY_PRODUCTS';
export const GET_SHOPPING_USER = 'GET_SHOPPING_USER';
export const SEND_INVOICE = 'SEND_INVOICE';
export const CLEAR_SHOPPING_CART = 'CLEAR_SHOPPING_CART';
export const STORE_VOUCHER = 'STORE_VOUCHER';
export const SEND_QUOTATION = 'SEND_QUOTATION';
export const MODIFY_CART = 'MODIFY_CART';
export const GET_PSE_TOKEN = 'GET_PSE_TOKEN';
export const ERROR_FILE_SIZE ='ERROR_FILE_SIZE';
export const GET_REJECTED_SALE = 'GET_REJECTED_SALE';
export const MAJOR_CONTRIBUTOR_VERIFICATION = 'MAJOR_CONTRIBUTOR_VERIFICATION';
export const GET_DS_CLIENT = 'GET_DS_CLIENT';
export const RESET_SENT_INVOICE_FLAG = 'RESET_SENT_INVOICE_FLAG';
export const SENT_TO_ONLINE_PAYMENT_VERIFICATION = 'SENT_TO_ONLINE_PAYMENT_VERIFICATION';
// shopping cart reducer end

// admin products reducer
export const GET_ASSIGNMENTS = 'GET_ASSIGNMENTS';
export const GET_STATUS_HISTORY = 'GET_STATUS_HISTORY';
export const GENERATE_REPORT = 'GENERATE_REPORT';
export const ASSIGN_CERTIFICATE = 'ASSIGN_CERTIFICATE';
export const REASSIGN_CERTIFICATE = 'REASSIGN_CERTIFICATE';
export const VOID_CERTIFICATE = 'VOID_CERTIFICATE';
export const GENERATE_REVOCATION_CODE = 'GENERATE_REVOCATION_CODE';
export const REVOKE_CERTIFICATE = 'REVOKE_CERTIFICATE';
export const CHANGE_STATUS_AGREEMENT = 'CHANGE_STATUS_AGREEMENT';
export const RESET_ADMIN_PRODUCTS = 'RESET_ADMIN_PRODUCTS';
export const SWITCH_ADMIN_PRODUCTS_FILTERS = 'SWITCH_ADMIN_PRODUCTS_FILTERS';
export const GET_GROUPED_ASSIGNMENTS = 'GET_GROUPED_ASSIGNMENTS';
export const POST_PRE_LOAD = 'POST_PRE_LOAD';
export const GET_TERMS_CONDITIONS = 'GET_TERMS_CONDITIONS'
export const GET_TERMS_CONDITIONS_REGISTER = 'GET_TERMS_CONDITIONS_REGISTER'
export const GET_TERMS_CONDITIONS_PRODUCT = 'GET_TERMS_CONDITIONS_PRODUCT'
export const CLEAR_PRODUCT = 'CLEAR_PRODUCT'
export const GET_DIRECTION_GSE = 'GET_DIRECTION_GSE'
export const GET_VALUE_ASSISTANT = 'GET_VALUE_ASSISTANT'
export const CLEAR_DIRECTION_GSE = 'CLEAR_DIRECTION_GSE'
export const GET_VALIDATE_EMAIL = 'GET_VALIDATE_EMAIL'

// admin products reducer end

/*Financial*/
export const GET_PENDING_FINANCIAL = 'GET_PENDING_FINANCIAL';
export const SET_MODAL_SUCCESSFUL = 'SET_MODAL_SUCCESSFUL';
export const FILTER_LIST_PRODUCT = 'FILTER_LIST_PRODUCT';
export const FILTER_LIST_PRODUCT_SOLD = 'FILTER_LIST_PRODUCT_SOLD';
export const GET_SIIGO_PENDING_INVOICES = 'GET_SIIGO_PENDING_INVOICES';
export const FORWARD_SIIGO_INVOICE = 'FORWARD_SIIGO_INVOICE';


/*Location*/
export const TOWN_LIST = 'TOWN_LIST';
export const DEPARTMENT_LIST = 'DEPARTMENT_LIST';
export const CLEAN_TOWNS = 'CLEAN_TOWNS';
