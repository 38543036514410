import React from "react";
import Button from 'react-bootstrap/Button'
import './PrimaryButton.css'

import Singin from '../../assets/img/buttons/singin.png';
import ContinueIcon from '../../assets/img/buttons/continue.png';
import AddProduct from '../../assets/img/buttons/add-product2.png';
import Registry from '../../assets/img/buttons/registry.png';
import Quotation from '../../assets/img/buttons/quotation.png';
import Buy from '../../assets/img/buttons/buy.png';
import cashPayment from '../../assets/img/buttons/cashPayment.png';
import QuotationIcon from '../../assets/img/buttons/quotation.png';
import SingIn from '../../assets/img/buttons/singin.png';

/**
 * PrimaryButton constant ( function component used as generic primary button )
 *
 * @exports constant
 * @param {*} { ...props }
 * @returns HTML object
 */
const PrimaryButton = ({ ...props }) => {
    const { color, txtBtn, sizeWidth, onPress, type, className, icon} = props;

    const icons = {
        AddProduct,
        Registry,
        Singin,
        ContinueIcon,
        Quotation,
        Buy,
        cashPayment,
        QuotationIcon,
        SingIn
    }

    return (
        <Button
            type={type === '' || type === undefined ? "submit" : type}
            className={`${className} ${color}`}
            style={{ width: sizeWidth }}
            onClick={onPress}
        >
        <span className = "btn-label">
            <img className = "iconbutton" src = {icons[icon]} alt = {icons[icon]}/>
        </span>
        <div className ="btn-text">
            {txtBtn}
        </div>
        </Button>
    );
};

export default PrimaryButton;
