import React, { Component } from 'react';
import PropTypes from "prop-types";
import MaterialTable, { MTableToolbar } from 'material-table';
import { connect } from "react-redux";
import { Button } from "reactstrap";

import ApproveIcon from '../../../assets/img/icons/approve_payment.png';
import FilterIcon from '../../../assets/img/icons/filter.png';

import ModalSiigoForward from "../modals/ModalSiigoForward";

import { formatToThousandWithPoints } from '../../../helpers/functions';
import { getPendingSiigoInvoices, forwardInvoice } from "../../../actions/financialActions";

class SiigoPendings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pendingInvoices: [],
            forwardedReference: null,
            areButtonsActive: true,
            showModal: false,
            modalMessage: '',
            isModalSuccess: false
        };

        this.props.getPendingSiigoInvoices();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const { financialReducer } = nextProps;

        if (this.state.pendingInvoices !== financialReducer.pendingInvoices) {
            this.setState({ pendingInvoices: financialReducer.pendingInvoices, areButtonsActive: true });
        }

        if (financialReducer.forwardedReference !== null && (this.state.forwardedReference !== financialReducer.forwardedReference)) {
            const isModalSuccess = financialReducer.forwardedSuccess;
            const modalMessage = isModalSuccess
                ? '¡¡Perfecto!! la factura se ha enviado satisfactoriamente'
                : '¡¡Ups!! Siigo está fuera de servicio. Intenta más tarde';
            const forwardedReference = isModalSuccess
                ? financialReducer.forwardedReference
                : null;

            this.setState({ forwardedReference, modalMessage, isModalSuccess, showModal: true }, () => {
                this.props.getPendingSiigoInvoices();
            });
        }
    }

    forwardInvoiceToSiigo = (fullReference) => {
        this.setState({ areButtonsActive: false });
        this.props.forwardInvoice(fullReference, this.props.history);
    };

    toggleAlertModal = () => {
        this.setState({ showModal: !this.state.showModal });
    };

    render() {
        const tableTitles = [
            {
                title: "Factura",
                field: "salesCode",
                render: rowData => <p style={{ color: '#00ac6c' }}>#{rowData.salesCode}</p>
            },
            {
                title: "Referencia",
                field: "salesRef",
                render: rowData => <p style={{ color: '#00ac6c' }}>{rowData.fullReference}</p>
            },
            { title: "Forma de pago", field: "paymentType" },
            { title: "Fecha Aprobado", field: "dateApprove", type: 'date' },
            { title: "Cliente", field: 'client' },
            { title: "Tipo Documento", field: 'documentType' },
            { title: "Documento", field: 'documentNumber' },
            {
                title: "Valor a Pagar",
                field: "totalValue",
                render: rowData => <p style={{ color: '#00ac6c' }}>${formatToThousandWithPoints(rowData.totalValue)}</p>
            },
            {
                title: "Reenviar",
                field: "forwardInvoice",
                render: rowData =>
                    <div
                        className="toolTipButton"
                        onClick={e => this.state.areButtonsActive ? this.forwardInvoiceToSiigo(rowData.fullReference) : null}
                        style={this.state.areButtonsActive ? {} : { opacity: '0.5', cursor: 'not-allowed' }}
                    >
                        <span className="buttonVoucher" aria-label="Reenviar factura">
                            <img src={ApproveIcon} alt="Reenviar factura" style={{ width: '50%' }} />
                        </span>
                    </div>
            }
        ];

        return (
            <div>
                <MaterialTable
                    title=""
                    columns={tableTitles}
                    data={this.state.pendingInvoices}
                    components={{
                        Toolbar: props => (
                            <div className="row p-3">
                                <div className="col-md-9">
                                    <Button className="table-filter-button btn-outline-warning"
                                        onClick={async e => {
                                            await this.props.setFinancialState({
                                                modalFilterType: 'modalFiltersPays'
                                            });
                                            await this.props.toggleModal('modalFilters', 'siigoPendings');
                                        }}>
                                        <img src={FilterIcon} alt="Filtros" />
                                        Filtros
                                    </Button>
                                </div>
                                <div className="col-md-3">
                                    <MTableToolbar {...props} />
                                </div>
                            </div>
                        )
                    }}
                    options={{
                        actionsColumnIndex: -1,
                        headerStyle: {
                            backgroundColor: '#0bac72',
                            color: '#FFF',
                        },
                        pageSize: 10
                    }}
                    localization={{
                        pagination: {
                            labelDisplayedRows: '{from}-{to} de {count}',
                            labelRowsSelect: 'filas'
                        },
                        toolbar: {
                            nRowsSelected: '{0} registro(s) seleccionado(s)',
                            searchPlaceholder: 'Buscar'
                        },

                        body: {
                            emptyDataSourceMessage: 'No hay registros para mostrar',
                            filterRow: {
                                filterTooltip: 'Filtrar'
                            }
                        }
                    }}
                />

                <ModalSiigoForward
                    show={this.state.showModal}
                    title={''}
                    message={this.state.modalMessage}
                    toggleModal={this.toggleAlertModal}
                    isSuccess={this.state.isModalSuccess}
                />
            </div>
        );
    }
}

SiigoPendings.propTypes = {
    getPendingSiigoInvoices: PropTypes.func.isRequired,
    forwardInvoice: PropTypes.func.isRequired,
    financialReducer: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    financialReducer: state.financialReducer
});

export default connect(mapStateToProps, { getPendingSiigoInvoices, forwardInvoice })(SiigoPendings);
