import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { activateAccount } from "../../actions/authActions";

import InputForm from '../../components/InputForm/InputForm';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';

import SimpleReactValidator from 'simple-react-validator';
import { reactValidatorOptions } from '../../helpers/simpleReactValidator';

import passwordx2 from '../../assets/img/icons/password/passwordx2.png';

/**
 * ValidateCode Component ( full view ValidateCode component, render a ValidateCode
 * form and make a post request for user account validation )
 *
 * @export Class Component
 * @class ValidateCode
 * @extends {Component}
 * @returns Redux connect
 */
export class ValidateCode extends Component {

    constructor(props) {
        super(props);

        this.state = {
            verificationCode: ''
        };

        this.validatorCode = new SimpleReactValidator(reactValidatorOptions);
    }

    onChangeCustomInput = () => e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    validateCode = (e) => {
        e.preventDefault();
        if (this.validatorCode.allValid()) {
            let data = {
                email: this.props.email,
                code: this.state.verificationCode
            }

            console.log(data)

            this.props.activateAccount(data);
        } else {
            this.validatorCode.showMessages();
            this.forceUpdate();
        }
    }

    render() {
        return (
            <div>
                <div className="col-md-12 row text-center">
                    <div className="imgSucces">
                        <img className="SuccessIcon" src={passwordx2} alt="code" />
                    </div>
                    <div className="lbl-container">
                        <p className="text-center modal-form-lbl">{"Contraseña cambiada."}</p>
                        <br/>
                    </div>
                </div>
                <div className="form-container">
                    <form onSubmit={e => this.validateCode(e)}>
                        <div className="row">
                            <div className="col-sm-12 col-md-12">
                                <InputForm
                                    id="verificationCode"
                                    type={"text"}
                                    placeholder={"Código de verificación"}
                                    icon={"password"}
                                    className="form-control large"
                                    onChange={this.onChangeCustomInput()}
                                    value={this.state.verificationCode}
                                    validator={this.validatorCode}
                                    validateOptions={'required'}
                                />
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="col col-12">
                                <PrimaryButton
                                    color={"green"}
                                    txtBtn={"Validar"}
                                    sizeWidth={""}
                                    className={"float-right with-icon"}
                                    icon={"Singin"}
                                >
                                </PrimaryButton>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

ValidateCode.propTypes = {
    activateAccount: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({

})

export default connect(mapStateToProps, { activateAccount })(ValidateCode)
