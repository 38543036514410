import React from 'react'
import {ModalBody} from "reactstrap";
import {API_ENDPOINT} from "../../../config/config";
import PropTypes from "prop-types";

export const HeaderPreloadInfo = ({...props}) => {
    const {selectedProduct} = props;
    return (
        <>
            <ModalBody>
                <div className="container">
                    <div className="col-md-12 row text-center">
                        <div className="col-md-2">
                            <div className="icon-product m-0 p-0">
                                <img src={API_ENDPOINT + selectedProduct.icon} alt="icon product"/>
                            </div>
                        </div>
                        <div className="col-md-10">
                            <div className="text-left">
                                <div className="title-modal-preload">
                                    {selectedProduct.productName}
                                </div>
                                <div className="certificate-info">
                                    Medio Entrega: {selectedProduct.delivery} /
                                    Vigencia: {selectedProduct.validity} / Tiempo de
                                    Entrega: {selectedProduct.deliveryTime}
                                    <br/>
                                    Cantidad Disponible: {selectedProduct.available}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalBody>
            <div className="col-md-12 preload-message">
                <div className="container p-3">
                    <p>
                        Diligencie los campos comunes que tienen los suscriptores a los cuales va a asignar
                        los certificados, no es necesario que los especifique todos, solo los que usted
                        considere. La información que usted ingrese se precargará en la solicitud de cada
                        certificado y no podrá ser modificada por el suscritor.
                    </p>
                </div>
            </div>
        </>
    )
};

HeaderPreloadInfo.propTypes = {
    selectedProduct: PropTypes.object.isRequired
};