import React from 'react'
import {Button, Modal, ModalBody, ModalHeader} from "reactstrap";
import CloseIcon from "../../../assets/img/icons/close.png";
import check from "../../../assets/img/icons/check/check.png";
import {setModalSuccessful} from "../../../actions/financialActions";
import {connect} from "react-redux";

const ModalSuccessful = props => {
    const {
        modalSuccessful,
        modalSuccessfulTitle,
        modalSuccessfulMessage,
        setModalSuccessful
    } = props;
    return (
        <Modal isOpen={modalSuccessful}>
            <form onSubmit={e => setModalSuccessful()}>
                <ModalHeader className="header-modal">
                    {modalSuccessfulTitle}
                    <Button className="modal-close" onClick={e => {
                        setModalSuccessful();
                    }}><img src={CloseIcon} alt="icon product"/></Button>
                </ModalHeader>
                <ModalBody>
                    <div className="container">
                        <div className="row">
                            <div className="col-10 offset-1 text-center">
                                <img src={check} alt="Logo Seccess"/>
                                <br/>
                                <br/>
                                <p>
                                    {modalSuccessfulTitle !== 'RECHAZAR COMPRA'
                                        ? <span style={{color: '#2C9C72'}}>¡¡Perfecto!!</span>
                                        : <span>Has <span style={{color: '#2C9C72'}}>Rechazado</span></span>
                                    }
                                    {modalSuccessfulMessage}
                                </p>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </form>
        </Modal>
    )
};

export default connect(null, {setModalSuccessful})(ModalSuccessful)