import React from 'react'
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";

import CloseIcon from "../../../assets/img/icons/close.png";
import CheckIcon from "../../../assets/img/icons/check/check.png";
import AlertIcon from "../../../assets/img/icons/alert/alert.png";

const ModalSiigoForward = ({ show, title, message, toggleModal, isSuccess }) => {
    return (
        <Modal isOpen={show}>
            <ModalHeader className="header-modal">
                {title}
                <Button className="modal-close" onClick={e => toggleModal()}>
                    <img src={CloseIcon} alt="icon product"/>
                </Button>
            </ModalHeader>
            <ModalBody>
                <div className="container">
                    <div className="row">
                        <div className="col-10 offset-1 text-center">
                            <img src={isSuccess ? CheckIcon : AlertIcon} alt="Logo"/>
                            <br/>
                            <br/>
                            <p>{message}</p>
                        </div>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
};

export default ModalSiigoForward;
