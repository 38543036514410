import React from 'react';
import Header from "./Header/Header";

/**
 * PublicRoute function (Render structure for public routes)
 *
 * @export function
 * @param {Component} Component
 * @param {String} roleNames
 * @param {String} location
 * @returns React.Fragment
 */
export default function PublicRoute(Component, location) {
    return class extends React.Component {
        render() {
            return (
                <React.Fragment>
                    {/* <SideNav location={location} className="col" /> */}
                    <div className="page-container">
                        <Header {...this.props} />
                        <Component {...this.props} />
                    </div>
                </React.Fragment>
            );
        }
    }
}