import { GET_PRODUCTS, SHOW_PRODUCT_DATA, SET_PRODUCT, CLEAR_PRODUCT } from '../actions/types';

const initialState = {
    products: [],
    productData: [],
    successProductsData: false,
    isPurchased: false,
    client: null,
    successSetProduct: false,
    setProductResponse: null
};

/**
 * GET_PRODUCTS ( set server response message after succesfull get products )
 * SHOW_PRODUCT_DATA ( set full data of one of the products )
 * SET_PRODUCT ( set response after client product adding )
 *
 * @export function
 * @param {*} [state=initialState]
 * @param {*} action
 * @returns {state, Products array, boolean, Client Array}
 */
export default function (state = initialState, action) {
    switch (action.type) {
        case GET_PRODUCTS:
            return {
                products: action.payload1,
                isPurchased: action.payload2,
                client: action.payload3
            };
        case SHOW_PRODUCT_DATA:
            return {
                successProductsData: true,
                productData: action.payload
            };
        case SET_PRODUCT:
            return {
                successSetProduct: true,
                setProductResponse: action.payload
            };
        case CLEAR_PRODUCT:
            return {
            successSetProduct: false,
            setProductResponse: null
            };
        default:
            return state;
    }
}
