import React, { Component } from 'react'
import MaterialTable, { MTableToolbar } from 'material-table';
import { Button } from "reactstrap";

import suportView from '../../assets/img/icons/suportView.png';
import FilterIcon from '../../assets/img/icons/filter.png';

import { API_ENDPOINT } from '../../config/config';
import { formatToThousandWithPoints } from '../../helpers/functions';

class Approved extends Component {
    state = {
        paysSold: [],
    };

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.listProductSold !== nextProps.listProductSold) {
            this.setState({ paysSold: nextProps.listProductSold });
        }
    }

    render() {
        const tableColumnsSold = [
            {
                title: "Factura",
                field: "salesRef",
                render: rowData => <p style={{ color: '#00ac6c' }}>#{rowData.salesCode}</p>
            },
            {
                title: "Referencia",
                field: "salesCode",
                render: rowData => <p style={{ color: '#00ac6c' }}>{rowData.salesRef}{rowData.salesCode}</p>
            },
            { title: "Fecha Aprobado", field: "dateApprove", type: 'date' },
            { title: "Cliente", field: "client" },
            { title: "Tipo Documento", field: "documentType" },
            { title: "Documento", field: "documentNumber" },
            {
                title: "Valor a Pagar",
                field: "subTotal",
                render: rowData => <p style={{ color: '#00ac6c' }}>${formatToThousandWithPoints(rowData.subTotal + rowData.taxIva - rowData.taxReteFuente - rowData.taxReteIva - rowData.taxReteIca)}</p>
            },
            {
                title: "Comprobante",
                field: "voucher",
                render: rowData =>
                    <div className="toolTipButton"
                        onClick={e => window.open(`${API_ENDPOINT}/${rowData.voucher}`)}>
                        <span className="buttonVoucher" aria-label="Ver soporte">
                            <img src={suportView} alt="Comprobante" style={{ width: '50%' }} />
                        </span>
                    </div>
            }
        ];
        return (
            <MaterialTable
                title=""
                columns={tableColumnsSold}
                data={this.state.paysSold}
                components={{
                    Toolbar: props => (
                        <div className="row p-3">
                            <div className="col-md-9">
                                <Button className="table-filter-button btn-outline-warning"
                                    onClick={async e => {
                                        await this.props.setFinancialState({
                                            modalFilterType: 'modalFiltersPays'
                                        });
                                        await this.props.toggleModal('modalFilters', 'approved');
                                    }}>
                                    <img src={FilterIcon} alt="Filtros" />
                                    Filtros
                                </Button>
                            </div>
                            <div className="col-md-3">
                                <MTableToolbar {...props} />
                            </div>
                        </div>
                    )
                }}
                options={{
                    actionsColumnIndex: -1,
                    headerStyle: {
                        backgroundColor: '#0bac72',
                        color: '#FFF',
                    },
                    pageSize: 10
                }}
                localization={{
                    pagination: {
                        labelDisplayedRows: '{from}-{to} de {count}',
                        labelRowsSelect: 'filas'
                    },
                    toolbar: {
                        nRowsSelected: '{0} registro(s) seleccionado(s)',
                        searchPlaceholder: 'Buscar'
                    },

                    body: {
                        emptyDataSourceMessage: 'No hay registros para mostrar',
                        filterRow: {
                            filterTooltip: 'Filtrar'
                        }
                    }
                }}
            />
        );
    }
}

export default Approved