import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Alert } from "rsuite";

import CardBody from '../../components/CardBody/CardBody';
import Register from '../auth/Register.js';
import RegisterAgreement from '../auth/RegisterAgreement.js';

import agreementc from '../../assets/img/icons/agreement-customer.png';
import standardc from '../../assets/img/icons/user/user.png';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

/**
 * BusinessAdvisor Component ( full view BusinessAdvisor component )
 *
 * @export Class Component
 * @class BusinessAdvisor
 * @extends {Component}
 * @returns Redux connect
 */
export class BusinessAdvisor extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showAgreement: false
        };
    }

    componentWillMount() {
        if (localStorage.getItem('jwtToken')) {

        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.businessAdvisorReducer.successStandard) {
            Alert.success(nextProps.businessAdvisorReducer.data.message, 5000);
            this.props.history.push(`/products/${nextProps.businessAdvisorReducer.data.userID}`);
        } else if (nextProps.businessAdvisorReducer.successAgreement) {
            Alert.success(nextProps.businessAdvisorReducer.data.message, 5000);
            this.props.history.push(`/products/${nextProps.businessAdvisorReducer.data.userID}`);
        } else if (!!nextProps.errorReducer && nextProps.errorReducer.hasErrors) {
            Alert.error(nextProps.errorReducer.data.message, 5000);
        }

    }

    onChangeCustomInput = () => e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    render() {
        let titleCard = "NUEVA COMPRA";
        let descriptionTitleCard = "Realice nuevas compras para sus clientes";
        return (
            <div className="cst-body-height">
                <CardBody title={titleCard} descriptionTitle={descriptionTitleCard}>

                    <div className="textIntro">
                        <p className="text-center">
                            Este es el
                            <span className="green-text"> asistente comercial </span> de compras, desde acá podrá ayudar a sus clientes a realizar sus compras.
                        </p>
                        <p className="text-center">
                            Para iniciar, seleccione el
                            <span className="green-text"> tipo de cliente </span>
                        </p>
                    </div>

                    <div className="root">
                        <ExpansionPanel>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <div className="heading">
                                    <div className="row">
                                        <div className="img-container">
                                            <img className="standardc" src={standardc} alt="standardc" />
                                        </div>
                                        <p className="section-title text-center">CLIENTE ESTÁNDAR</p>
                                    </div>
                                </div>
                            </ExpansionPanelSummary>
                            <div className="hl-green"></div>
                            <br /><br />
                            <ExpansionPanelDetails>
                                <Register history={this.props.history} formType={"standard"}></Register>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        {this.state.showAgreement ?
                            <ExpansionPanel>
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                    <div className="heading">
                                        <div className="row">
                                            <div className="img-container">
                                                <img className="agreementc" src={agreementc} alt="agreementc" />
                                            </div>
                                            <p className="section-title text-center">CLIENTE CONVENIO</p>
                                        </div>
                                    </div>
                                </ExpansionPanelSummary>
                                <div className="hl-green"></div>
                                <br /><br />
                                <ExpansionPanelDetails>
                                    <RegisterAgreement history={this.props.history} formType={"agreement"}></RegisterAgreement>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            : null}
                    </div>
                </CardBody>
            </div>
        )
    }
}

BusinessAdvisor.propTypes = {
    businessAdvisorReducer: PropTypes.object.isRequired,
    errorReducer: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    businessAdvisorReducer: state.businessAdvisorReducer,
    errorReducer: state.errorReducer
})

export default connect(mapStateToProps, {})(BusinessAdvisor)
