import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { API_ENDPOINT, defaultResponses } from "../config/config";
import { GET_ERRORS, SET_CURRENT_USER, CHANGE_PASSWORD, ACTIVATE_ACCOUNT, RECOVER_ACCOUNT, CLEAR_ERRORS, CLEAR_AUTH } from "./types";
import setAuthToken from "../utils/setAuthToken";
import { Alert } from 'rsuite';

/**
 * AuthAction loginUser ( set user credentials to server for login attempt )
 *
 * @method POST
 * @param {*} userData
 */
export const loginUser = (history, userData, message) => async (dispatch) => {
    await axios.post(API_ENDPOINT + '/auth/login', userData)
        .then(res => {
            const { token } = res.data;
            setAuthToken(token, res.data.user.role.name, res.data.user.type);
            const decoded = jwt_decode(token);
            dispatch(setCurrentUser(decoded));
            dispatch({ type: CLEAR_ERRORS });
            dispatch({ type: CLEAR_AUTH });
            if (history) {
                if (res.data.user.role.name === 'Advisor') history.push({ pathname: '/advisor' })
                else if (res.data.user.role.name === 'Financial') history.push({ pathname: '/financial' })
                else if (res.data.currentProvider === 'DIAN' || res.data.currentProvider === 'P10') history.push({ pathname: '/cart' })
                else if (res.data.user.type === 'Convenio') history.push({ pathname: '/admin' })
                else history.push({ pathname: '/products', state: { prevPath: 'login' } });
            }
            if (message) Alert.info(message, 5000);
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: !!err.response
                    ? err.response.data
                    : { message: defaultResponses.noResponseFromApi }
            })
        });
};

export const setCurrentUser = decoded => {
    return {
        type: SET_CURRENT_USER,
        payload: decoded
    };
};

/**
 * AuthAction logoutUser ( set token for close session, use history param for redirect to login page  )
 *
 * @method GET
 * @param {history} history
 */
export const logoutUser = (history) => async (dispatch) => {
    await axios.get(API_ENDPOINT + '/auth/logout')
        .then(res => {
            console.log('ok')
        })
        .catch(err => {
            console.log(err)
        });
        setAuthToken(null, null);
        dispatch(setCurrentUser({}));
        dispatch({ type: CLEAR_ERRORS });
        if (history) history.push({pathname: '/', state: {message: 'Sesión finalizada'}})
};


/**
 * AuthAction sendPassword ( set old, new, and confirm password to verify and change password  )
 *
 * @method POST
 * @param {data} data
 */
export const sendPassword = (data) => async(dispatch) => {
    console.log('send password', data);
    await axios.post(API_ENDPOINT + '/user/changePassword', data)
        .then(res => {
            dispatch({
                type: CHANGE_PASSWORD,
                payload: res.data,
                payload2: data.newPassword
            });
            dispatch({ type: CLEAR_ERRORS });
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: !!err.response
                ? err.response.data
                : {message: defaultResponses.noResponseFromApi}
            })
        });
};


/**
 * AuthAction activateAccount ( set activation code for verification and account activation  )
 *
 * @method POST
 * @param {data} data
 */
export const activateAccount = (data) => async(dispatch) => {
    await axios.post(API_ENDPOINT + '/user/activate', data)
        .then(res => {
            dispatch({ type: CLEAR_ERRORS });
            dispatch({
                type: ACTIVATE_ACCOUNT,
                payload: res.data
            });
        })
        .catch(err => {
            dispatch({ type: CLEAR_AUTH });
            dispatch({
                type: GET_ERRORS,
                payload: !!err.response
                ? err.response.data
                : {message: defaultResponses.noResponseFromApi}
            })
        });
};


/**
 * AuthAction recoverAccount ( set recover account via email to user  )
 *
 * @method POST
 * @param {data} data
 */
export const recoverAccount = (data) => async(dispatch) => {
    await axios.post(API_ENDPOINT + '/user/recoverAccount', data)
        .then(res => {
            dispatch({
                type: RECOVER_ACCOUNT,
                payload: res.data
            });
            dispatch({ type: CLEAR_ERRORS });
        })
        .catch(err => {
            console.log(err, 'err...')
            dispatch({
                type: GET_ERRORS,
                payload: !!err.response
                ? err.response.data
                : {message: defaultResponses.noResponseFromApi}
            })
        });
};

