import React, { Fragment } from "react";

import ReactSelect from "../SelectFormWithSearch/ReactSelect";
import InputForm from "../InputForm/InputForm";

import { field_1_options, field_3_options, field_4_options, field_6_options } from "../../config/personalized_address_options";

const AddressPersonalizedForm = ({ validatorUserForm, onChangeAddressFields, handleAddressReactSelect, address_field_1, address_field_2, address_field_3, address_field_4, address_field_5, address_field_6, address_field_7, address_field_8, address_field_9, address_field_10, address_complementary_field_1, address_complementary_field_2, address_complementary_field_3, address_complementary_field_4, address }) => {
    return (
        <Fragment>
            <div className="form-group col-md-12" style={{ marginTop: '15px' }}>
                <h3><strong>Dirección de entrega</strong></h3>
                <p>Asistente para el ingreso de dirección</p>
            </div>

            <div className="form-group col-md-3 mb-0">
                <ReactSelect
                    id="address_field_1"
                    icon={""}
                    label={""}
                    placeholder={"Seleccione un valor para el campo 1"}
                    // className="form-control"
                    onChange={(e) => handleAddressReactSelect(e)}
                    value={address_field_1}
                    options={field_1_options}
                    validator={validatorUserForm}
                    validateOptions={'required'}
                />
            </div>
            <div className="form-group col-md-2">
                <InputForm
                    id="address_field_2"
                    type="number"
                    min={1}
                    max={999}
                    placeholder={"Digite un valor para el campo 2"}
                    icon={""}
                    // label={"Dígito de Verificación"}
                    className="form-control"
                    onChange={onChangeAddressFields()}
                    // onKeyDown = {'document'}
                    value={address_field_2}
                    validator={validatorUserForm}
                    validateOptions={'required|number'}
                />
            </div>
            <div className="form-group col-md-2 mb-0">
                <ReactSelect
                    id="address_field_3"
                    icon={""}
                    label={""}
                    placeholder={"Seleccione un valor para el campo 3"}
                    // className="form-control"
                    onChange={(e) => handleAddressReactSelect(e)}
                    value={address_field_3}
                    options={field_3_options}
                    validator={validatorUserForm}
                    validateOptions={'max:1'}
                />
            </div>
            <div className="form-group col-md-3 mb-0">
                <ReactSelect
                    id="address_field_4"
                    icon={""}
                    label={""}
                    placeholder={"Seleccione un valor para el campo 4"}
                    // className="form-control"
                    onChange={(e) => handleAddressReactSelect(e)}
                    value={address_field_4}
                    options={field_4_options}
                    validator={validatorUserForm}
                    validateOptions={'max:3'}
                />
            </div>
            <div className="form-group col-md-2 mb-0">
                <ReactSelect
                    id="address_field_5"
                    icon={""}
                    label={""}
                    placeholder={"Seleccione un valor para el campo 5"}
                    // className="form-control"
                    onChange={(e) => handleAddressReactSelect(e)}
                    value={address_field_5}
                    options={field_3_options}
                    validator={validatorUserForm}
                    validateOptions={'max:1'}
                />
            </div>

            <div className="form-group col-md-3 mb-0">
                <ReactSelect
                    id="address_field_6"
                    icon={""}
                    label={""}
                    placeholder={"Seleccione un valor para el campo 6"}
                    // className="form-control"
                    onChange={(e) => handleAddressReactSelect(e)}
                    value={address_field_6}
                    options={field_6_options}
                    validator={validatorUserForm}
                    validateOptions={'max:999'}
                />
            </div>
            <div className="form-group mt-2 pr-0 pl-0" style={{flex:'0 0 1.333333%'}}>
                <p>#</p>
            </div>
            <div className="form-group col-md-2">
                <InputForm
                    id="address_field_7"
                    type="number"
                    min={1}
                    max={999}
                    placeholder={"Digite un valor para el campo 7"}
                    icon={""}
                    // label={"#"}
                    className="form-control"
                    onChange={onChangeAddressFields()}
                    // onKeyDown = {'document'}
                    value={address_field_7}
                    validator={validatorUserForm}
                    validateOptions={'required|number'}
                />
            </div>
            <div className="form-group col-md-2 mb-0">
                <ReactSelect
                    id="address_field_8"
                    icon={""}
                    label={""}
                    placeholder={"Seleccione un valor para el campo 8"}
                    // className="form-control"
                    onChange={(e) => handleAddressReactSelect(e)}
                    value={address_field_8}
                    options={field_3_options}
                    validator={validatorUserForm}
                    validateOptions={'max:1'}
                />
            </div>
            <div className="form-group col-md-2">
                <InputForm
                    id="address_field_9"
                    type="number"
                    min={1}
                    max={999}
                    placeholder={"Digite un valor para el campo 9"}
                    icon={""}
                    // label={"Dígito de Verificación"}
                    className="form-control"
                    onChange={onChangeAddressFields()}
                    // onKeyDown = {'document'}
                    value={address_field_9}
                    validator={validatorUserForm}
                    validateOptions={'required|number'}
                />
            </div>
            <div className="form-group col-md-2 mb-0">
                <ReactSelect
                    id="address_field_10"
                    icon={""}
                    label={""}
                    placeholder={"Seleccione un valor para el campo 10"}
                    // className="form-control"
                    onChange={(e) => handleAddressReactSelect(e)}
                    value={address_field_10}
                    options={field_6_options}
                    validator={validatorUserForm}
                    validateOptions={'max:999'}
                />
            </div>

            <div className="form-group col-md-12">
                <p>Complemento</p>
            </div>

            <div className="form-group col-md-3 mb-0">
                <ReactSelect
                    id="address_complementary_field_1"
                    icon={""}
                    label={""}
                    placeholder={"Seleccione un valor para el campo complementario 1"}
                    // className="form-control"
                    onChange={(e) => handleAddressReactSelect(e)}
                    value={address_complementary_field_1}
                    options={field_1_options}
                    validator={validatorUserForm}
                    validateOptions={'max:999'}
                />
            </div>
            <div className="form-group col-md-3 mb-0">
                <InputForm
                    id="address_complementary_field_2"
                    type={"text"}
                    maxLength="200"
                    placeholder={"Digite un valor para el campo complementario 2"}
                    icon={""}
                    // label={""}
                    className="form-control"
                    onChange={onChangeAddressFields()}
                    // onKeyDown = {''}
                    value={address_complementary_field_2}
                    validator={validatorUserForm}
                    validateOptions={'max:200'}
                />
            </div>
            <div className="form-group col-md-3 mb-0">
                <ReactSelect
                    id="address_complementary_field_3"
                    icon={""}
                    label={""}
                    placeholder={"Seleccione un valor para el campo complementario 3"}
                    // className="form-control"
                    onChange={(e) => handleAddressReactSelect(e)}
                    value={address_complementary_field_3}
                    options={field_1_options}
                    validator={validatorUserForm}
                    validateOptions={'max:999'}
                />
            </div>
            <div className="form-group col-md-3 mb-0">
                <InputForm
                    id="address_complementary_field_4"
                    type={"text"}
                    maxLength="200"
                    placeholder={"Digite un valor para el campo complementario 4"}
                    icon={""}
                    // label={""}
                    className="form-control"
                    onChange={onChangeAddressFields()}
                    // onKeyDown = {''}
                    value={address_complementary_field_4}
                    validator={validatorUserForm}
                    validateOptions={'max:200'}
                />
            </div>

            <div className="form-group col-md-12">
                <InputForm
                    id="address"
                    type={"text"}
                    maxLength="200"
                    placeholder={"Dirección"}
                    icon={""}
                    label={"Dirección completa"}
                    className="form-control"
                    disabled
                    // onChange={this.onChangeCustomInput()}
                    onKeyDown = {'address'}
                    value={address}
                    validator={validatorUserForm}
                    validateOptions={'required'}
                />
            </div>
        </Fragment>
    );
};

export default AddressPersonalizedForm;