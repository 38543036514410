import React, { Component } from 'react'
import { connect } from 'react-redux'

import CardBody from '../../components/CardBody/CardBody';
import Login from '../auth/Login.js';
import Register from '../auth/RegisterUser.js';

import quotation from '../../assets/img/icons/quotation/quotation.png';
import cart from '../../assets/img/icons/chart/icon-chart.png';
import administration from '../../assets/img/icons/administration/administration.png';
import hcount from '../../assets/img/icons/have-count.png';
import newUser from '../../assets/img/icons/user-new.png';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

/**
 * Purchases Component ( full view Purchases component, Login, Register, Forgot Password, Verify Code components )
 *
 * @export Class Component
 * @class Purchases
 * @extends {Component}
 * @returns Redux connect
 */
export class Purchases extends Component {

    constructor(props) {
        super(props);

        this.state = {

        };
    }
        
    componentWillMount() {
        if (localStorage.getItem('jwtToken')) {
            if(localStorage.getItem('role')  === 'Advisor') this.props.history.push({pathname: '/advisor'})
            else if(localStorage.getItem('role')  === 'Financial') this.props.history.push({pathname: '/financial'})
            //else if (localStorage.getItem('type') === 'Estándar DIAN') this.props.history.push('/cart')
            else this.props.history.push({pathname: '/products'});
        }
    }

    componentWillReceiveProps(nextProps) {

    }

    onChangeCustomInput = () => e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    render() {
        let titleCard = "MÓDULO DE COTIZACIÓN Y COMPRAS";
        let descriptionTitleCard = "Aquí podrás realizar tus compras y solicitar tus cotizaciones de certificados digitales";
        return (
            <CardBody title={titleCard} descriptionTitle={descriptionTitleCard}>
                <div className="textIntro">
                    <p className="text-center container-descript"><samp className="green-text">Vinkel</samp> está comprometido con hacer tu proceso <samp
                        className="green-text"> más fácil</samp>, desde aquí podrás realizar.</p>
                </div>

                <div className="container">
                    <div className="row eDescription">
                        <div className="col-sm text-center">
                            <img src={quotation} alt="quotation" />
                            <p className="text-center">Cotizaciones en tiempo real.</p>
                        </div>
                        <div className="col-sm text-center">
                            <img src={cart} alt="cart" />
                            <p className="text-center">Compras de todos los certificados digitales que
                                                             necesitas.</p>
                        </div>
                        <div className="col-sm text-center">
                            <img src={administration} alt="administration" />
                            <p className="text-center">Administración de tus certificados.</p>
                        </div>
                    </div>
                </div>

                <div className="root">
                    <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <div className="heading">
                                <div className="row">
                                    <div className="img-container">
                                        <img className="hcount" src={hcount} alt="hcount" />
                                    </div>
                                    <p className="section-title text-center">YA TENGO CUENTA</p>
                                </div>
                            </div>
                        </ExpansionPanelSummary>
                        <div className="hl-green"></div>
                        <br /><br />
                        <ExpansionPanelDetails>
                            <Login history={this.props.history} base64URLData={this.props.match.params.base64Data}></Login>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <div className="heading">
                                <div className="row">
                                    <div className="img-container">
                                        <img className="newUser" src={newUser} alt="newUser" />
                                    </div>
                                    <p className="section-title text-center">SOY USUARIO NUEVO Y NECESITO REGISTRARME</p>
                                </div>
                            </div>
                        </ExpansionPanelSummary>
                        <div className="hl-green"></div>
                        <br /><br />
                        <ExpansionPanelDetails>
                            <Register history={this.props.history}></Register>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </div>
            </CardBody>
        )
    }
}

Purchases.propTypes = {

};

const mapStateToProps = (state) => ({

})

export default connect(mapStateToProps, {})(Purchases)
