import isEmpty from '../utils/isEmpty';
import { SET_CURRENT_USER, CHANGE_PASSWORD, ACTIVATE_ACCOUNT, RECOVER_ACCOUNT, CLEAR_AUTH } from '../actions/types';

const initialState = {
    isAuthenticated: false,
    passChanged: false,
    passChangeResponse: [],
    accountActivated: false,
    activateResponse: [],
    accountRecovered: false,
    recoverResponse: [],
    user: {}
};

/**
 * AuthReducer (set loggedIn user or void data)
 *
 * @export function
 * @param {*} [state=initialState]
 * @param {*} action
 * @returns {state, boolean, user}
 */
export default function (state = initialState, action) {
    switch (action.type) {
        case SET_CURRENT_USER:
            return {
                ...initialState,
                isAuthenticated: !isEmpty(action.payload),
                user: action.payload.user
            };
        case CHANGE_PASSWORD:
            localStorage.setItem('loginMsg', 'Contraseña Actualizada Exitosamente');
            return {
                ...initialState,
                passChanged: true,
                passChangeResponse: action.payload,
                newPassword: action.payload2
            };
        case ACTIVATE_ACCOUNT:
            return {
                ...initialState,
                accountActivated: true,
                activateResponse: action.payload
            };
        case RECOVER_ACCOUNT:
            return {
                ...initialState,
                accountRecovered: true,
                recoverResponse: action.payload
            };
        case CLEAR_AUTH:
                return initialState;
        default:
            return state;
    }
}
