import React, { Component } from 'react'
import '../../../styles2.css';

class TermsAndConditions extends Component {

    render() {
        const { termsAndConditions } = this.props;
        
        return (
            <div className="terms">
                <div dangerouslySetInnerHTML={{ __html: `<div>${termsAndConditions}</div>` }} />
            </div>
        )
    }
}

export default TermsAndConditions;