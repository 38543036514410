import React, { Component, Fragment } from "react";
import { APP_NAME } from '../../config/config';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { logoutUser } from '../../actions/authActions';
import { Alert } from "rsuite";
import { Nav, Navbar, Icon } from 'rsuite';
import './header.css'
import logo from '../../assets/img/logo.png'
import logovinkel from '../../assets/img/logovinkel2.png'
import logogse from '../../assets/img/logogse.png'

/**
 * Class Header ( component for page header, make a top navbar in application )
 *
 * @export redux connect
 * @class Header
 * @extends {Component}
 */
class Header extends Component {
    constructor(props) {
        super(props);
        const role = localStorage.getItem('role');
        const type = localStorage.getItem('type');

        let homeRedirect = '/';
        if (role && (role === 'Advisor' || role === 'Financial')) homeRedirect += role.toLowerCase();
        if (type && (type === 'Convenio')) homeRedirect += 'admin';
        let currentClientID = localStorage.getItem('currentClientID');
        this.state = {
            redirect: homeRedirect,
            cartUrl: currentClientID ? '/cart/'+currentClientID : '/cart',
            showCart: this.props.showCart === true && ((role === 'Advisor' && currentClientID) || (role !== 'Advisor' && type !== 'Convenio')), 
            // showCart: false
        };
    }

    onLogout = e => {
        e.preventDefault();
        this.props.logoutUser(this.props.history);
        Alert.info('Sesión finalizada', 3000);
    };

    toggleSidenav = e => {
        this.props.toggleSidenav();
    }

    render() {
        return (
            <div className="header-container">
                <div className="header-logo">
                    <img className="cst-logo1" src={logogse} alt="Vinkel" />
                    <img className="cst-logo2" src={logovinkel} alt="Vinkel" />
                </div>
                <Navbar className="header">
                    <Navbar.Header>
                        <a href="#home" className="navbar-brand logo">{APP_NAME}</a>
                    </Navbar.Header>
                    <Navbar.Body>
                        <Nav pullRight className="nav-icons">
                            <Nav.Item icon={<Icon icon="home" />} href={this.state.redirect} className="header-icon"></Nav.Item>
                            {localStorage.getItem('jwtToken') ?
                                <Fragment>
                                    {this.state.showCart ?
                                        <Nav.Item icon={<Icon icon="shopping-cart" />} href={this.state.cartUrl} className="header-icon"></Nav.Item>
                                        : null
                                    }
                                    <Nav.Item icon={<Icon icon="sign-out" />} href='/' onClick={this.onLogout} className="header-icon"></Nav.Item>
                                </Fragment>
                            : null}
                        </Nav>
                    </Navbar.Body>
                </Navbar>
            </div>
        );
    }
}


Header.propTypes = {
    logoutUser: PropTypes.func.isRequired
};

export default connect(null, { logoutUser })(Header);
