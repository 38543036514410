import React from "react";
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import './modalForm.css';
import Xclose from '../../assets/img/icons/close.png';


const ModalForm = ({ ...props }) => {
    let { children, ...rest } = props;

    return (
        <Modal {...rest} isOpen={props.isOpen}>
            <ModalHeader className="header-modal">
                {props.title}
                <Button className="modal-close"><img src={Xclose} onClick={props.toggle} alt="icon product" /></Button>
            </ModalHeader>
            <ModalBody>
                <div className="container">
                    {children}
                </div>
            </ModalBody>
        </Modal>
    );
};

export default ModalForm;

