import React from "react"
import PropTypes from "prop-types"
import InputFile from "../../../components/InputFile/InputFile";

export const PreloadDocument = ({ ...props }) => {
    const { clientDocumentName, onChangeFile, errors } = props;
    return (
        <>
            <div className="col-md-12">
                <div className="text-center font-weight-bold mb-4">PRECARGA DE DOCUMENTOS (OPCIONAL)</div>

                <div className="row">
                    <div className="col-md-12">
                        <p className="mt-2 mb-2">Fotocopia del documento de identidad del suscriptor</p>
                    </div>
                    <div className="col-md-6 alert alert-secondary" role="alert">
                        {clientDocumentName}
                    </div>
                    <div className="col-md-6">
                        <InputFile
                            id="clientDocument"
                            className={"with-icon"}
                            color={"green"}
                            label="Adjuntar"
                            icon={'QuotationIcon'}
                            onSelectFile={onChangeFile}
                            error={errors["clientDocument"]}
                        />
                    </div>
                </div>
            </div>
        </>
    )
};

PreloadDocument.propTypes = {
    onChangeFile: PropTypes.func.isRequired
};