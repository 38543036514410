import React from 'react';

export default function SimpleAppBar(props) {
    return (
        <div className="col-10">
            <div className="row">
                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-3">
                    <img className="icon-product-table" src={props.iconProduct} alt="icon product"/>
                </div>
                <div className="col-sm-12 col-md-8 col-lg-8 col-xl-9 text-left">
                    <div className="nameProduct-shop">
                        {props.nameProduct}
                    </div>
                    <div className="descriptionProduct">
                        Tiempo de Entrega: {props.deliveryTime}
                        <br/>Medio de Entrega: {props.deliveryMedium}
                        <br/>Vigencia: {props.validity} Año(s)
                        <br/>Reasignaciones: {props.reAsign}
                    </div>
                </div>
            </div>
        </div>
    )
}