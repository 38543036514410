import React from "react";
import Select from 'react-select';
import PropTypes from "prop-types";
import './react-select.css';

import user from '../../assets/img/icons/user/user.png';
import document from '../../assets/img/icons/document.png';
import map from '../../assets/img/icons/document.png';
import position from '../../assets/img/icons/position/positionx2.png';

/**
 * ReactSelect constant ( react-select input for recursive implementation in forms )
 *
 * @param {*} {...props}
 * @returns HTML object
 */
const ReactSelect = ({ ...props }) => {
    const { id, onChange, options, className, icon, label, placeholder, disabled, error, validator, validateOptions, value } = props;

    const icons = {
        user,
        document,
        map,
        position
    };

    let filterValues = options.filter(op => {
        return op.val === value
    });

    let matchedValue = filterValues.length > 0 ? {value: filterValues[0].val, label: filterValues[0].lbl} : {value: '', label: ''};

    return (
        <div className="form-group">
            {icon !== '' ?
                <span className="input-group-addon"><img className="input-group-icon" src={icons[icon]} alt={icons[icon]} /></span>
                : null
            }
            {label !== '' ?
                <label htmlFor={id} className={error || validator.message('', value, validateOptions) ? "text-danger" : ""}>{label}</label>
                : null
            }
            <Select
                value={matchedValue}
                onChange={e => onChange({...e, id})}
                isDisabled={disabled}
                isSearchable={true}
                className={`${icon && !label ? 'icon':''} ${className} ${error || (!!validator && validator.message('', value, validateOptions)) ? 'is-invalid' : ''}`}
                options={options.map(op => {
                    return { value: op.val, label: op.lbl }
                })}
                placeholder={'Seleccione...'}
                noOptionsMessage={() => 'No se encontraron resultados'}
            />
            {disabled ?
                null
                : validator.message(placeholder !== '' && placeholder !== undefined ? placeholder : 'select', value, validateOptions)
            }
            {error ?
                <div className="invalid-feedback">{error}</div>
                : null
            }
        </div>
    );
};


ReactSelect.propTypes = {
    labelProps: PropTypes.object,
    id: PropTypes.string,
};

export default ReactSelect;