import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types';
import SimpleReactValidator from 'simple-react-validator';
import { connect } from 'react-redux'

import { buyProducts, getUser, getPseToken, verifyIfMajorContributor, getDSClient } from '../../actions/shoping_cartActions';
import { getTown, getDepartment } from '../../actions/locationActions';

import InputForm from '../../components/InputForm/InputForm';
import TextAreaForm from '../../components/TeatAreaForm/TextAreaForm';
import ModalMsg from '../../components/ModalMsg/ModalMsg';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import ReactSelect from '../../components/SelectFormWithSearch/ReactSelect';

import PseForm from './sections/PseForm';
import AddressPersonalizedForm from './sections/AddressPersonalizedForm';

import './Shopping_cart_styles.css';

import { reactValidatorOptions } from '../../helpers/simpleReactValidator';
import {
    calculateVerificationDigit,
    calculateReteFuenteTax,
    calculateReteIvaTax,
    calculateReteIcaTax,
    // getTownsByDepartment
} from '../../helpers/functions';

import PayOnlineIcon from '../../assets/img/buttons/online-payment.png';
import WarningIcon from "../../assets/img/icons/warning.png";

// import { DEPARTMENTS } from '../../helpers/departments';
import { DOCUMENT_TYPES } from '../../helpers/documentTypes';
import { BOGOTA_CODE } from '../../config/config';

/**
 * BillingForm Component ( full view BillingForm component, render a BillingForm
 * form and make a post request for user registration  )
 *
 * @export Class Component
 * @class BillingForm
 * @extends {Component}
 * @returns Redux connect
 */
export class BillingForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            firstName: '',
            lastName: '',
            legalName: '',
            documentType: '',
            documentNumber: '',
            verificationDigit: '',
            cellPhoneNumber: '',
            email: '',
            address_field_1: '',
            address_field_2: '',
            address_field_3: '',
            address_field_4: '',
            address_field_5: '',
            address_field_6: '',
            address_field_7: '',
            address_field_8: '',
            address_field_9: '',
            address_field_10: '',
            address_complementary_field_1: '',
            address_complementary_field_2: '',
            address_complementary_field_3: '',
            address_complementary_field_4: '',
            address: '',
            additionalInformation: '',
            city: '',
            department: '',
            modalMsg: [],
            personType: 'Natural',
            paymentType: 'Efectivo',
            clientID: this.props.clientID,
            bought: false,
            pseToken: '',
            client: null,
            majorContributor: null,
            taxReteFuente: 0,
            taxReteIva: 0,
            taxReteIca: 0,
            towns: [{ key: '', val: '', lbl: 'Seleccione un departamento primero' }],
            departments: [],
            documentTypes: DOCUMENT_TYPES,
            hasTowns: false,
            isProductionEnv: false
        };

        this.validatorUserForm = new SimpleReactValidator(reactValidatorOptions);

        this.submitPseButton = React.createRef();
        this.props.getDepartment();
    }

    componentWillReceiveProps(nextProps) {
        let { shoppingCartReducer, locationReducer } = nextProps;

        if (!!shoppingCartReducer.userData && (shoppingCartReducer.userData !== this.props.shoppingCartReducer.userData)) {
            const data = shoppingCartReducer.userData;

            this.setState({
                firstName: !!data.user.firstName ? data.user.firstName : '',
                lastName: !!data.user.lastName ? data.user.lastName : '',
                cellPhoneNumber: !!data.user.cellPhone ? data.user.cellPhone : '',
                email: !!data.user.email ? data.user.email : '',
                documentNumber: !!data.user.documentNumber ? data.user.documentNumber : '',
                documentType: !!data.user.documentType ? data.user.documentType : '',
                department: !!data.user.departmentCode ? data.user.departmentCode : '',
                city: !!data.user.townCode ? data.user.townCode : ''
            })
        } else if (shoppingCartReducer.successSendInvoice) {
            this.clearForm();
        } else if (shoppingCartReducer.successUpdateProduct) {
            if(this.props.client && this.props.client.type === 'Convenio') {
                if(!this.state.bought){ this.buyProducts();}
            }
        }

        if (shoppingCartReducer.pseToken !== null) {
            this.setState({ pseToken: shoppingCartReducer.pseToken, isProductionEnv: shoppingCartReducer.isProductionEnv }, () => {
                this.submitPseButton.click();
            });
        }

        if (!!shoppingCartReducer.client && Object.keys(shoppingCartReducer.client).length > 0 && (shoppingCartReducer.client !== this.state.client)) {
            this.setState({ client: shoppingCartReducer.client })
        }

        this.setState({ majorContributor: shoppingCartReducer.majorContributor }, () => {
            if (!!this.state.majorContributor) {
                this.setState({ legalName: this.state.majorContributor.name });
            }
        });

        if (!!shoppingCartReducer.dsClient && (shoppingCartReducer.dsClient !== this.props.shoppingCartReducer.dsClient)) {
            console.log('dsClient.fullReference: ', shoppingCartReducer.dsClient.fullReference);
            let { documentType, firstName, lastName, legalName, verificationDigit, address, cellPhoneNumber, email, department, departmentCode, townCode, address_fields } = shoppingCartReducer.dsClient;

            this.setState({ documentType, firstName, lastName, legalName, verificationDigit, address, cellPhoneNumber, email }, () => {
                if (!!department) {
                    this.handleReactSelect({ id: 'department', value: departmentCode });
                    
                    this.setState({ city: townCode })
                }

                if (!!address_fields && !!address_fields._id) {
                    let { address_field_1, address_field_2, address_field_3, address_field_4, address_field_5, address_field_6,
                        address_field_7, address_field_8, address_field_9, address_field_10, address_complementary_field_1,
                        address_complementary_field_2, address_complementary_field_3, address_complementary_field_4 } = address_fields;

                    this.setState({
                        address_field_1, address_field_2, address_field_3, address_field_4, address_field_5, address_field_6,
                        address_field_7, address_field_8, address_field_9, address_field_10, address_complementary_field_1,
                        address_complementary_field_2, address_complementary_field_3, address_complementary_field_4
                    });
                }
            });
        }

        if (locationReducer.departments.length !== this.state.departments.length) {
            this.setState({
                departments: locationReducer.departments
            });
        }

        if (locationReducer.towns.length !== this.state.towns.length) {
            this.setState({
                towns: locationReducer.towns,
                hasTowns:locationReducer.hasTowns
            });
        }
    }

    acceptMethods = (openModalMsg) => { this.openModalMsg = openModalMsg; };

    onChangeCustomInput = () => e => {
        e.persist();

        this.setState({ [e.target.id]: e.target.value}, () => {
            if (e.target.id === 'documentNumber' && this.state.documentType === 'NIT') { // VerificationDigit calculation
                let { documentNumber } = this.state;

                let isNitValid = documentNumber >>> 0 === parseFloat(documentNumber); // Validate a positive integer

                if (isNitValid) {
                    const verificationDigit = calculateVerificationDigit(documentNumber);
                    this.setState({ verificationDigit });
                }
            }
        });
    };

    onChangeAddressFields = () => e => {
        this.setState({ [e.target.id]: e.target.value}, () => {
            this.buildFullAddress();
        });
    };

    handleAddressReactSelect = selectedOption => {
        this.setState({ [selectedOption.id]: selectedOption.value }, () => {
            this.buildFullAddress();
        });
    };

    buildFullAddress = () => {
        const { address_field_1, address_field_2, address_field_3, address_field_4, address_field_5, address_field_6, address_field_7, address_field_8, address_field_9, address_field_10,
            address_complementary_field_1, address_complementary_field_2, address_complementary_field_3, address_complementary_field_4 } = this.state;

        const address = (!!address_field_1 ? address_field_1+' ' : '') +
            (!!address_field_2 ? address_field_2+' ' : '') +
            (!!address_field_3 ? address_field_3+' ' : '') +
            (!!address_field_4 ? address_field_4+' ' : '') +
            (!!address_field_5 ? address_field_5+' ' : '') +
            (!!address_field_6 ? address_field_6+' ' : '') +
            (!!address_field_7 ? '# '+address_field_7+' ' : '') +
            (!!address_field_8 ? address_field_8+' ' : '') +
            (!!address_field_9 ? address_field_9+' ' : '') +
            (!!address_field_10 ? address_field_10+' ' : '') +
            (!!address_complementary_field_1 ? address_complementary_field_1+' ' : '') +
            (!!address_complementary_field_2 ? address_complementary_field_2+' ' : '') +
            (!!address_complementary_field_3 ? address_complementary_field_3+' ' : '') +
            (!!address_complementary_field_4 ? address_complementary_field_4 : '');
        this.setState({ address });
    };

    handleReactSelect = selectedOption => {
        this.setState({ [selectedOption.id]: selectedOption.value }, () => {
            if (selectedOption.id === 'department') {
                this.props.getTown(selectedOption.value);
            }

            if (selectedOption.id === 'documentType' && this.state.personType === 'Natural') {
                if (selectedOption.value === 'NIT') {
                    const verificationDigit = calculateVerificationDigit(this.state.documentNumber);
                    this.setState({ verificationDigit });
                } else {
                    this.setState({ verificationDigit: '' });
                }
            }
        });
    };

    handlePersonType(e) {
        this.setState({ personType: e.target.value, documentType: (e.target.value === 'Jurídica') ? 'NIT' : '' }, () => {
            if (this.state.personType === 'Jurídica' && !!this.state.documentNumber) {
                const verificationDigit = calculateVerificationDigit(this.state.documentNumber);
                this.setState({ verificationDigit });
            } else {
                this.setState({ verificationDigit: '' });
            }
            this.validatorUserForm = new SimpleReactValidator(reactValidatorOptions);
            this.forceUpdate();
        });
    }
    handlePaymentType(e) { this.setState({ paymentType: e.target.value }) }

    verifyIfMajorContributor = nit => {
        if (this.validatorUserForm.fieldValid('NIT')) {
            this.props.verifyIfMajorContributor(nit);
        } else if (this.state.majorContributor !== null) {
            this.setState({ majorContributor: null });
        }
    };

    findByDocumentNumber = documentNumber => {
        if (this.validatorUserForm.fieldValid('Número Documento') && this.state.personType === 'Natural') {
            const data = { documentNumber };
            this.props.getDSClient(data);

        } else if (this.state.personType === 'Jurídica') {
            this.verifyIfMajorContributor(documentNumber);

        } else {
            // Do nothing...
            // this.clearForm();
        }
    };

    sendToPse = () => {
        alert("Servicio PSE no disponible");
        return null;
        let isValidForm = (this.props.client && this.props.client.type === 'Convenio') ? true: this.validatorUserForm.allValid();
        if (isValidForm) {
            this.setState({ paymentType: 'PSE' }, () => {
                let { personType, documentNumber, cellPhoneNumber, email, department, city, address, paymentType, firstName, lastName, documentType, legalName, verificationDigit } = this.state;

                let { address_field_1, address_field_2, address_field_3, address_field_4, address_field_5, address_field_6, address_field_7, address_field_8, address_field_9,
                    address_field_10, address_complementary_field_1, address_complementary_field_2, address_complementary_field_3, address_complementary_field_4 } = this.state;

                let address_fields = { address_field_1, address_field_2, address_field_3, address_field_4, address_field_5, address_field_6, address_field_7, address_field_8, address_field_9,
                    address_field_10, address_complementary_field_1, address_complementary_field_2, address_complementary_field_3, address_complementary_field_4 };

                let obj = {
                    personType, documentNumber, cellPhoneNumber, email,
                    address, paymentType,
                    departmentCode: department,
                    townCode: city,
                    clientID: this.props.clientID,
                    isLegalClient: personType === 'Jurídica',
                    address_fields,
                    sentToPagueATiempo: true
                };

                if (obj.isLegalClient === false) {
                    obj['firstName'] = firstName;
                    obj['lastName'] = lastName;
                    obj['legalName'] = null;
                    obj['documentType'] = documentType;
                    if (documentType === 'NIT') obj['verificationDigit'] = verificationDigit;
                } else {
                    this.setState({
                        taxReteFuente: calculateReteFuenteTax(this.props.shoppingCartReducer.total),
                        taxReteIva: !!this.state.majorContributor ? calculateReteIvaTax(this.props.shoppingCartReducer.taxIva) : 0,
                        taxReteIca: (this.state.city === BOGOTA_CODE)
                            ? calculateReteIcaTax(this.props.shoppingCartReducer.total)
                            : 0
                    });

                    obj['firstName'] = null;
                    obj['lastName'] = null;
                    obj['legalName'] = legalName;
                    obj['documentType'] = 'NIT';
                    obj['verificationDigit'] = verificationDigit;
                }

                this.props.getPseToken(obj);
            });

        } else {
            this.validatorUserForm.showMessages();
            this.forceUpdate();
        }
    };

    buyProducts = async (event) => {
        if(event) event.preventDefault();
        let validate = (this.props.client && this.props.client.type === 'Convenio') ? true: this.validatorUserForm.allValid();
        if (validate) {
            if (this.state.client == null && this.state.paymentType !== "Efectivo") {
                this.setState({ paymentType: 'Efectivo' });
            }

            let { address_field_1, address_field_2, address_field_3, address_field_4, address_field_5, address_field_6, address_field_7, address_field_8, address_field_9,
                address_field_10, address_complementary_field_1, address_complementary_field_2, address_complementary_field_3, address_complementary_field_4 } = this.state;

            let address_fields = { address_field_1, address_field_2, address_field_3, address_field_4, address_field_5, address_field_6, address_field_7, address_field_8, address_field_9,
                address_field_10, address_complementary_field_1, address_complementary_field_2, address_complementary_field_3, address_complementary_field_4 };

            let obj = {
                personType: this.state.personType,
                documentNumber: this.state.documentNumber,
                cellPhoneNumber: this.state.cellPhoneNumber,
                email: this.state.email,
                department: this.state.department,
                city: this.state.city,
                address: this.state.address,
                clientID: this.props.clientID,
                paymentType: this.state.paymentType,
                isLegalClient: this.state.personType === 'Jurídica',
                additionalInformation: this.state.additionalInformation,
                address_fields
            };

            if (this.state.personType === 'Natural') {
                obj['firstName'] = this.state.firstName;
                obj['lastName'] = this.state.lastName;
                obj['legalName'] = null;
                obj['documentType'] = this.state.documentType;
                if (this.state.documentType === 'NIT') obj['verificationDigit'] = this.state.verificationDigit;
            } else if (this.state.personType === 'Jurídica') {
                obj['firstName'] = null;
                obj['lastName'] = null;
                obj['legalName'] = this.state.legalName;
                obj['documentType'] = 'NIT';
                obj['verificationDigit'] = this.state.verificationDigit;
            }

            await this.setState({ bought: true });
            this.props.buyProducts(obj);
            
        } else {
            this.validatorUserForm.showMessages();
            this.forceUpdate();
        }
    };

    handleInputGetUser = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        let url = this.state.clientID ? `/user/getUser/client/${this.state.clientID}` : `/user/getUser/`;

        if (value === true) {
            this.props.getUser(url);

            if (this.state.personType === 'Jurídica' || this.state.documentType === 'NIT') {
                const verificationDigit = calculateVerificationDigit(this.state.documentNumber);
                this.setState({ verificationDigit });
            }
        } else {
            this.clearForm();
        }
    };

    clearForm() {
        this.setState({
            firstName: '', lastName: '', cellPhoneNumber: '', email: '', documentNumber: '', documentType: '', city: '', address: '',
            address_field_1: '', address_field_2: '', address_field_3: '', address_field_4: '', address_field_5: '', address_field_6: '',
            address_field_7: '', address_field_8: '', address_field_9: '', address_field_10: '', address_complementary_field_1: '',
            address_complementary_field_2: '', address_complementary_field_3: '', address_complementary_field_4: ''
        })
    }

    render() {
        return (
            <div id="billing" >
                {
                    this.props.showForm ?
                        <Fragment>
                            <div className="hl"></div>

                            <div className="textIntro">
                                <p className="text-center sectionTitle">Datos de <span className="green-text">facturación</span></p>
                            </div>

                            <div className="col-10 offset-1">
                                <div className="custom-control custom-switch">
                                    <input className="custom-control-input"
                                        type="checkbox"
                                        id="customSwitch1"
                                        value={""}
                                        onChange={this.handleInputGetUser}
                                    >
                                    </input>
                                    <label className="custom-control-label" htmlFor="customSwitch1">Utilizar datos de Usuario</label>
                                </div><br />

                                <form onSubmit={event => this.buyProducts(event)}>
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label>Tipo de Persona</label>
                                            <div>
                                                <div className="custom-control custom-radio custom-control-inline">
                                                    <input name="personType"
                                                        id="personType_0"
                                                        type="radio"
                                                        className="custom-control-input"
                                                        value="Natural"
                                                        checked={this.state.personType === "Natural"}
                                                        onChange={(value) => { this.handlePersonType(value) }}
                                                    >
                                                    </input>
                                                    <label htmlFor="personType_0" className="custom-control-label">Natural</label>

                                                </div>
                                                <div className="custom-control custom-radio custom-control-inline">
                                                    <input name="personType"
                                                        id="personType_1"
                                                        type="radio"
                                                        className="custom-control-input"
                                                        value="Jurídica"
                                                        checked={this.state.personType === "Jurídica"}
                                                        onChange={(value) => { this.handlePersonType(value) }}
                                                    >
                                                    </input>
                                                    <label htmlFor="personType_1" className="custom-control-label">Jurídica</label>
                                                </div>
                                            </div>
                                        </div>

                                    </div><br />

                                    <div className="form-row">
                                        {this.state.personType === 'Natural'
                                            ? <div className="form-group col-md-6 mb-0">
                                                <ReactSelect
                                                    id="documentType"
                                                    icon={""}
                                                    label={"Tipo Documento"}
                                                    placeholder={"Tipo Documento"}
                                                    // className="form-control"
                                                    onChange={(e) => this.handleReactSelect(e)}
                                                    value={this.state.documentType}
                                                    options={this.state.documentTypes}
                                                    validator={this.validatorUserForm}
                                                    validateOptions={'required'}
                                                />
                                            </div>
                                            : null
                                        }

                                        <div className={this.state.personType === 'Jurídica'
                                            ? "form-group col-md-6 mb-0"
                                            : this.state.documentType === 'NIT'
                                                ? "form-group col-md-4 mb-0"
                                                : "form-group col-md-6 mb-0"
                                        }>
                                            <InputForm
                                                id="documentNumber"
                                                type={this.state.personType === 'Natural' ? "text" : "number"}
                                                maxLength={this.state.personType === 'Natural' ? "15" : "10"}
                                                placeholder={this.state.personType === 'Natural' ? 'Documento' : "NIT"}
                                                icon={""}
                                                label={this.state.personType === 'Natural' ? "Número Documento" : "NIT"}
                                                className="form-control"
                                                onChange={this.onChangeCustomInput()}
                                                onKeyDown = {'document'}
                                                value={this.state.documentNumber}
                                                validator={this.validatorUserForm}
                                                validateOptions={this.state.documentType === 'PS' ? 'required|min:5|passportFormat' : 'required|min:5|number'}
                                                onBlur = {e => this.findByDocumentNumber(e.target.value)}
                                            />
                                            { !!this.state.majorContributor ? <div style={{ color: 'green' }}>Sr usuario, usted ha sido identificado como un gran contribuyente</div> : null }
                                        </div>

                                        {this.state.documentType === 'NIT' || this.state.personType === 'Jurídica'
                                            ? <Fragment>
                                                {this.state.personType === 'Jurídica'
                                                    ? <div className="form-group mt-5 pr-2 pl-2" style={{flex:'0 0 1.333333%'}}>
                                                        <p>-</p>
                                                    </div>
                                                : null}
                                                <div className={this.state.personType === 'Jurídica' ? "form-group col-md-3 mb-0" : "form-group col-md-2 mb-0"}>
                                                    <InputForm
                                                        id="verificationDigit"
                                                        type="number"
                                                        placeholder={""}
                                                        icon={""}
                                                        label={this.state.personType === 'Jurídica' ? "Dígito de Verificación" : "DV"}
                                                        className="form-control"
                                                        onChange={e => console.log('Campo no modificable')}
                                                        value={this.state.verificationDigit}
                                                        validator={this.validatorUserForm}
                                                        validateOptions={'required|number'}
                                                    />
                                                </div>
                                            </Fragment>
                                            : null
                                        }

                                        {this.state.personType === 'Natural'
                                            ? <>
                                                <div className="form-group col-md-6 mb-0">
                                                    <InputForm
                                                        id="firstName"
                                                        type={"text"}
                                                        maxLength="50"
                                                        placeholder={"Nombres"}
                                                        icon={""}
                                                        label={"Nombres Titular"}
                                                        className="form-control"
                                                        onChange={this.onChangeCustomInput()}
                                                        onKeyDown = {'alpha_space'}
                                                        value={this.state.firstName}
                                                        validator={this.validatorUserForm}
                                                        validateOptions={'required|names|min:3'}
                                                    />
                                                </div>

                                                <div className="form-group col-md-6 mb-0">
                                                    <InputForm
                                                        id="lastName"
                                                        type={"text"}
                                                        maxLength="50"
                                                        placeholder={"Apellidos"}
                                                        icon={""}
                                                        label={"Apellidos Titular"}
                                                        className="form-control"
                                                        onChange={this.onChangeCustomInput()}
                                                        onKeyDown = {'alpha_space'}
                                                        value={this.state.lastName}
                                                        validator={this.validatorUserForm}
                                                        validateOptions={'required|names|min:3'}
                                                    />
                                                </div>
                                            </>
                                            : null
                                        }

                                        {this.state.personType === 'Jurídica'
                                            ? <div className="form-group col-md-12 mb-0">
                                                <InputForm
                                                    id="legalName"
                                                    type={"text"}
                                                    maxLength="200"
                                                    placeholder={"Nombre o Razón Social"}
                                                    icon={""}
                                                    label={"Nombre o Razón Social"}
                                                    className="form-control"
                                                    onChange={this.onChangeCustomInput()}
                                                    onKeyDown = {'legal_name'}
                                                    value={this.state.legalName}
                                                    validator={this.validatorUserForm}
                                                    validateOptions={'required|min:3'}
                                                />
                                            </div>
                                            : null
                                        }
                                        
                                        <div className="form-group col-md-6 mb-0">
                                            <InputForm
                                                id="cellPhoneNumber"
                                                type={"text"}
                                                maxLength="15"
                                                placeholder={"Celular"}
                                                icon={""}
                                                label={"Celular"}
                                                className="form-control"
                                                onChange={this.onChangeCustomInput()}
                                                onKeyDown = {'phone'}
                                                value={this.state.cellPhoneNumber}
                                                validator={this.validatorUserForm}
                                                validateOptions={'required|numeric|min:7'}
                                            />
                                        </div>
                                        <div className="form-group col-md-6 mb-0">
                                            <InputForm
                                                id="email"
                                                type={"text"}
                                                maxLength="100"
                                                placeholder={"Correo electrónico"}
                                                icon={""}
                                                label={"Correo electrónico para facturación"}
                                                className="form-control"
                                                onChange={this.onChangeCustomInput()}
                                                onKeyDown = {''}
                                                value={this.state.email}
                                                validator={this.validatorUserForm}
                                                validateOptions={'required|email|onlyTextEmail|min:10'}
                                            />
                                        </div>

                                        <div className="form-group col-md-6 mb-0">
                                            <ReactSelect
                                                id="department"
                                                icon={""}
                                                label={"Departamento"}
                                                placeholder={"Departamento"}
                                                // className="form-control"
                                                onChange={(e) => this.handleReactSelect(e)}
                                                value={this.state.department}
                                                options={this.state.departments}
                                                validator={this.validatorUserForm}
                                                validateOptions={'required'}
                                            />
                                        </div>
                                        
                                        <div className="form-group col-md-6 mb-0">
                                            <ReactSelect
                                                id="city"
                                                icon={""}
                                                label={"Ciudad"}
                                                placeholder={"Ciudad"}
                                                // className="form-control"
                                                onChange={(e) => this.handleReactSelect(e)}
                                                value={this.state.city}
                                                options={this.state.towns}
                                                validator={this.validatorUserForm}
                                                validateOptions={'required'}
                                            />
                                        </div>

                                        {(this.state.city === 169088564 || this.state.city === 169088001)
                                            ? <div className="taxException row">
                                                <div className="col-md-2" style={{ padding: '15px 15px 15px 30px' }}>
                                                    <img src={WarningIcon} alt="Alerta"/>
                                                </div>
                                                <div className="col-md-10">
                                                    <p className="text-center">Señor usuario, usted está ubicado en una ciudad exenta de IVA, por favor comuníquese a nuestras líneas de atención 518 5158 opción 1</p>
                                                </div>
                                            </div>
                                            : null
                                        }

                                        <AddressPersonalizedForm
                                            validatorUserForm={this.validatorUserForm}
                                            onChangeAddressFields={this.onChangeAddressFields}
                                            handleAddressReactSelect={this.handleAddressReactSelect}
                                            address_field_1={this.state.address_field_1}
                                            address_field_2={this.state.address_field_2}
                                            address_field_3={this.state.address_field_3}
                                            address_field_4={this.state.address_field_4}
                                            address_field_5={this.state.address_field_5}
                                            address_field_6={this.state.address_field_6}
                                            address_field_7={this.state.address_field_7}
                                            address_field_8={this.state.address_field_8}
                                            address_field_9={this.state.address_field_9}
                                            address_field_10={this.state.address_field_10}
                                            address_complementary_field_1={this.state.address_complementary_field_1}
                                            address_complementary_field_2={this.state.address_complementary_field_2}
                                            address_complementary_field_3={this.state.address_complementary_field_3}
                                            address_complementary_field_4={this.state.address_complementary_field_4}
                                            address={this.state.address}
                                        >
                                        </AddressPersonalizedForm>

                                        <div className="form-group col-md-12">
                                            <TextAreaForm
                                                id="additionalInformation"
                                                label={"Información adicional"}
                                                placeholder={""}
                                                maxLength="500"
                                                className="form-control text-input"
                                                onChange={this.onChangeCustomInput()}
                                                value={this.state.additionalInformation}
                                                validator={this.validatorUserForm}
                                                validateOptions={'max:500'}
                                            />
                                        </div>
                                    </div>
                                    <br />
                                    {!!this.state.client ?
                                        <Fragment>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label>Medio de pago de preferencia</label>
                                                    <div>
                                                        <div className="custom-control custom-radio custom-control-inline">
                                                            <input name="paymentType"
                                                                id="paymentType_0"
                                                                type="radio"
                                                                className="custom-control-input"
                                                                value="PSE"
                                                                checked={this.state.paymentType === "PSE"}
                                                                onChange={(value) => { this.handlePaymentType(value) }}
                                                            >
                                                            </input>
                                                            <label htmlFor="paymentType_0" className="custom-control-label">PSE</label>
                                                        </div>
                                                        <div className="custom-control custom-radio custom-control-inline">
                                                            <input name="paymentType"
                                                                id="paymentType_1"
                                                                type="radio"
                                                                className="custom-control-input"
                                                                value="Efectivo"
                                                                checked={this.state.paymentType === "Efectivo"}
                                                                onChange={(value) => { this.handlePaymentType(value) }}
                                                            >
                                                            </input>
                                                            <label htmlFor="paymentType_1" className="custom-control-label">Pago en Efectivo</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <div className="col-sm-12">
                                                    <PrimaryButton
                                                        color={"green"}
                                                        txtBtn={this.props.client ? "Enviar Ref. Pago" : "Pago en Efectivo"}
                                                        sizeWidth={""}
                                                        className={"float-right with-icon"}
                                                        icon={"cashPayment"}
                                                    >
                                                    </PrimaryButton>

                                                </div>
                                            </div>
                                        </Fragment>
                                        :
                                        <div className="form-group row">
                                            <div className="col-sm-4 offset-1">
                                                <a href={"#send"} onClick={this.sendToPse}>
                                                    <img className="pseImage" src={PayOnlineIcon} alt="pseImage" />
                                                </a>
                                            </div>

                                            <div className="col-sm-6">
                                                <PrimaryButton
                                                    color={"green"}
                                                    txtBtn={this.props.client ? "Enviar Ref. Pago" : "Pago en Efectivo"}
                                                    sizeWidth={""}
                                                    className={"float-right with-icon"}
                                                    icon={this.props.client ? "ContinueIcon" : "cashPayment"}
                                                >
                                                </PrimaryButton>

                                            </div>
                                        </div>
                                    }
                                </form>
                            </div>
                            <ModalMsg
                                shareMethods={this.acceptMethods}
                                title={this.state.modalMsg.title}
                                show={this.state.modalMsg.show}
                                label={this.state.modalMsg.label}
                                type={this.state.modalMsg.type}
                                redirect={this.state.modalMsg.redirect}
                                history={this.props.history}
                            >
                            </ModalMsg>
                        </Fragment>
                        : null}

                <PseForm
                    token={this.state.pseToken}
                    total={Math.round(this.props.shoppingCartReducer.total + this.props.shoppingCartReducer.taxIva - this.state.taxReteFuente - this.state.taxReteIva - this.state.taxReteIca)}
                    iva={Math.round(this.props.shoppingCartReducer.taxIva)}
                    reference={this.props.shoppingCartReducer.saleReference}
                    formData={{ ...this.state }}
                >
                    <button ref={elem => this.submitPseButton = elem} type="submit">Enviar</button>
                </PseForm>
            </div>

        )
    }
}

BillingForm.propTypes = {
    getUser: PropTypes.func.isRequired,
    buyProducts: PropTypes.func.isRequired,
    getPseToken: PropTypes.func.isRequired,
    getDSClient: PropTypes.func.isRequired,
    shoppingCartReducer: PropTypes.object.isRequired,
    locationReducer: PropTypes.object.isRequired,
    errorReducer: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    shoppingCartReducer: state.shoppingCartReducer,
    locationReducer: state.locationReducer,
    errorReducer: state.errorReducer
});

export default connect(mapStateToProps, { buyProducts, getUser, getPseToken, verifyIfMajorContributor, getDSClient, getTown, getDepartment })(BillingForm)
