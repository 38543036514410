import React, {Component} from "react";
import SimpleReactValidator from "simple-react-validator";
import {reactValidatorOptions} from "../../../helpers/simpleReactValidator";
import "../adminProducts.css"
import {Button, Modal, ModalBody, ModalHeader} from "reactstrap";
import Xclose from "../../../assets/img/icons/close.png";
import ModalAlert from "../Modal_alert";
import {connect} from "react-redux";
import PropTypes from "prop-types"
import {HeaderPreloadInfo} from "./HeaderPreloadInfo";
import {DataPreloadInfo} from "./DataPreloadForm";
import DocumentsPreloadForm from "./DocumentsPreloadForm";
import {CertificateQuantityForm} from "./CertificateQuantityForm";
import {base64MimeType, fileToBase64} from "../../../helpers/filesLib";
import PreloadGreen from "../../../assets/img/buttons/preload-green.png";
import {createPreLoad} from '../../../actions/adminProductsActions';
import {calculateVerificationDigit, isEmpty} from "../../../helpers/functions";
import {cleanTowns, getDepartment, getTown} from "../../../actions/locationActions";

const IconPreloadGreen = () => {
    return <img className="iconbutton" src={PreloadGreen} title="Precargar" alt="Precargar"/>;
};

/**
 * AssignmentForm Component ( render a modal with Assignment Form and make a post request to
 * assign certificate to a user )
 *
 * @export Class Component
 * @class AssignmentForm
 * @extends {Component}
 * @returns Redux connect
 */
class ModalPreloadInfo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalAlert: false,
            towns: [],
            departments: [],
            form: {
                nit: '',
                checkDigit: '',
                businessName: '',
                address: '',
                jobTitle: '',
                dependency: '',
                department: '',
                town: '',
                phone: '',
                indicative: '',
                extension: '',
                quantity: '',
                firstDocument: '',
                firstDocumentName: '',
                firstDocumentMimeType: '',
                secondDocument: '',
                secondDocumentName: '',
                secondDocumentMimeType: '',
            },
            selectedProduct: {},
            errors: {},
        };
        this.formValidator = new SimpleReactValidator(reactValidatorOptions);
    }

    componentDidMount() {
        this.props.getDepartment()
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.state.selectedProduct !== nextProps.selectedProduct)
            this.setState(() => ({selectedProduct: nextProps.selectedProduct}));
        if (this.state.departments !== nextProps.location.departments) {
            this.setState(() => ({departments: nextProps.location.departments}));
        }
        if (this.state.towns !== nextProps.location.towns)
            this.setState(() => ({towns: nextProps.location.towns}));
    }

    onChangeInput = e => {
        const target = e.target.id;
        const value = e.target.value;
        this.setState((oldState) => {
            if (target === 'nit')
                return {
                    form: {
                        ...oldState.form,
                        [target]: value.length <= 10 ? value : oldState.form[target],
                        checkDigit: calculateVerificationDigit(value)
                    }
                };
            else if (target === 'phone')
                return {
                    form: {
                        ...oldState.form,
                        [target]: value.length <= 10 ? value : oldState.form[target],
                    }
                };
            else if (target === 'indicative')
                return {
                    form: {
                        ...oldState.form,
                        [target]: value.length <= 3 ? value : oldState.form[target],
                    }
                };
            else if (target === 'extension')
                return {
                    form: {
                        ...oldState.form,
                        [target]: value.length <= 5 ? value : oldState.form[target],
                    }
                };
            else {
                return {
                    form: {
                        ...oldState.form,
                        [target]: value
                    }
                };
            }
        });
    };

    handleSelect = e => {
        const target = e.target.id;
        const value = e.target.value;
        this.setState((oldState) => {
            if (target === 'department') {
                this.props.cleanTowns();
                this.props.getTown(value);
                return {form: {...oldState.form, [target]: value}}
            } else {
                return {form: {...oldState.form, [target]: value}};
            }
        });
    };

    toggleAlertModal = async () => {
        if (this.state.modalAlert === true) this.cleanForm();
        this.setState({modalAlert: !this.state.modalAlert});
    };

    cleanForm = () => {
        this.setState(() => ({
            form: {
                nit: '',
                checkDigit: '',
                businessName: '',
                address: '',
                jobTitle: '',
                dependency: '',
                department: '',
                town: '',
                phone: '',
                indicative: '',
                extension: '',
                quantity: '',
                firstDocument: '',
                firstDocumentName: '',
                firstDocumentMimeType: '',
                secondDocument: '',
                secondDocumentName: '',
                secondDocumentMimeType: '',
            },
            errors: {}
        }));
    };

    onChangeFile = async (e) => {
        const targetID = e.target.id;
        if (e.target.files[0]) {
            let name = e.target.files[0].name;
            await fileToBase64(name, e.target.files[0]).then(async result => {
                if (result.split(',')[1] === undefined) {
                    this.setState((oldState) => ({
                        errors: {
                            ...oldState.errors,
                            [targetID]: 'El documento no es valido',
                        }
                    }));
                    console.error("Documento no valido")
                } else {
                    delete this.state.errors[targetID];
                    await this.setState((oldState) => ({
                        form: {
                            ...oldState.form,
                            [targetID]: result.split(',')[1],
                            [targetID + 'Name']: name,
                            [targetID + 'MimeType']: base64MimeType(result)
                        }
                    }));
                }
            })
        }
    };
    
    onSubmit = () => {
        if (this.formValidator.allValid() && isEmpty(this.state.errors)) {
            this.props.createPreLoad({
                nit: this.state.form.nit,
                checkDigit: this.state.form.checkDigit,
                name: this.state.form.businessName,
                address: this.state.form.address,
                charge: this.state.form.jobTitle,
                dependence: this.state.form.dependency,
                department: this.state.form.department,
                city: this.state.form.town,
                phone: this.state.form.phone,
                extension: this.state.form.extension,
                indicative: this.state.form.indicative,
                acta: {
                    fileName: this.state.form.firstDocumentName,
                    base64: this.state.form.firstDocument,
                    mimeType: this.state.form.firstDocumentMimeType
                },
                RUT: {
                    fileName: this.state.form.secondDocumentName,
                    base64: this.state.form.secondDocument,
                    mimeType: this.state.form.secondDocumentMimeType
                },
                quantityRequested: this.state.form.quantity,
                availableCurrent: this.state.selectedProduct.available,
                idProductGSE: this.state.selectedProduct.idProductGSE,
                productID: this.state.selectedProduct.productID,
                invoiceData: this.state.selectedProduct.invoiceData,
                userID: this.state.selectedProduct.userID,
                totalQuantity: this.state.selectedProduct.quantity
            }, this.props.history);
            this.toggleAlertModal().then();
        } else {
            this.formValidator.showMessages();
            this.forceUpdate();
        }
    };

    render() {
        return (
            <>
                <Modal isOpen={this.props.modalPreloadInfo}>
                    <ModalHeader className="header-modal">
                        PRECARGAR INFORMACIÓN GENERAL
                        <Button className="modal-close"><img src={Xclose} onClick={() => {
                            this.cleanForm();
                            this.props.toggleModal('modalPreloadInfo');
                            this.formValidator.hideMessages();
                        }} alt="icon product"/></Button>
                    </ModalHeader>
                    <HeaderPreloadInfo selectedProduct={this.state.selectedProduct}/>
                    <ModalBody>
                        <form onSubmit={e => {
                            e.preventDefault();
                            if (this.formValidator.allValid() && isEmpty(this.state.errors)) {
                                this.props.toggleModal('modalPreloadInfo');
                                this.toggleAlertModal().then();
                            } else {
                                this.formValidator.showMessages();
                                this.forceUpdate();
                                return null
                            }
                        }}>
                            <div className="container pt-2 pl-4 pr-4">
                                <DataPreloadInfo
                                    dataForm={this.state.form}
                                    onChangeInput={this.onChangeInput}
                                    handleSelect={this.handleSelect}
                                    formValidator={this.formValidator}
                                    departments={this.state.departments}
                                    towns={this.state.towns}/>
                                <div className="divider mt-4 mb-4"/>
                                <DocumentsPreloadForm
                                    dataForm={this.state.form}
                                    errorForm={this.state.errors}
                                    onChangeFile={this.onChangeFile}
                                    formValidator={this.formValidator}
                                    selectedProduct={this.state.selectedProduct}/>
                                <div className="divider mt-4 mb-4"/>
                                <CertificateQuantityForm
                                    dataForm={this.state.form}
                                    onChangeInput={this.onChangeInput}
                                    formValidator={this.formValidator}
                                    selectedProduct={this.state.selectedProduct}/>
                                <div className="divider mt-4 mb-4"/>
                                <div className="col-12 text-center">
                                    <button type="submit" className="with-icon btn btn-outline-success">
                                    <span className="btn-label-preload">
                                        <IconPreloadGreen/>
                                    </span>
                                        <div className="btn-text-preload">
                                            Precargar
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </ModalBody>
                </Modal>
                <ModalAlert
                    showModal={this.state.modalAlert}
                    title="PRECARGAR INFORMACIÓN"
                    message="¿Está seguro que quiere precargar esta información? Una vez lo haga, no podrá anular esta acción."
                    toggleAlertModal={this.toggleAlertModal}
                    onSubmit={this.onSubmit}
                    actualOperation="preload"
                />
            </>
        );
    };
}

ModalPreloadInfo.propTypes = {
    adminProductsReducer: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    adminProductsReducer: state.adminProductsReducer,
    location: state.locationReducer
});

export default connect(mapStateToProps, {createPreLoad, getDepartment, getTown, cleanTowns})(ModalPreloadInfo);
