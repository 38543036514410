import React, { Component } from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

import InputForm from "../../components/InputForm/InputForm";

import { switchAdminProductsFilters } from '../../actions/adminProductsActions';

import CloseIcon from "../../assets/img/icons/close.png";
import FilterIcon from "../../assets/img/icons/filter.png";
import PropTypes from "prop-types";
import {connect} from "react-redux";

/**
 * ModalFilters Component ( render a modal with Filters Form and returns information to filter assignments )
 *
 * @export Class Component
 * @class ModalFilters
 * @extends {Component}
 * @returns class
 */
class Modal_filter_grouped_assignment extends Component {
    constructor(props) {
        super(props);

        this.state = {
            assignments: [],
            certificate: '',
            delivery: '',
            validity: '',
            errors: {}
        };
    }

    onChangeInput = e => {
        this.setState({ [e.target.id]: e.target.value, errors: {} });
    };

    toggleAlertModal = async () => {
        this.setState({ modalAlert: !this.state.modalAlert });
    };

    onFilter = e => {
        e.preventDefault();

        const { certificate, delivery, validity } = this.state;

        let isEmptyForm = (certificate === '' && delivery === '' && validity  === '');

        if (!isEmptyForm) {
            this.setState(
                { assignments: this.props.assignments },
                async () => {
                    let allItems = this.state.assignments;
                    let groupedAssignments;

                    if (certificate !== '') {
                        groupedAssignments = await allItems.filter(i => {
                            return i.productName.toLowerCase().indexOf(certificate.toLowerCase()) > -1;
                        });
                        allItems = groupedAssignments;
                    }
                    if (delivery !== '') {
                        groupedAssignments = await allItems.filter(i => {
                            return i.delivery.toLowerCase().indexOf(delivery.toLowerCase()) > -1;
                        });
                        allItems = groupedAssignments;
                    }
                    if (validity !== '') {
                        groupedAssignments = await allItems.filter(i => {
                            return i.validity.toLowerCase().indexOf(validity.toLowerCase()) > -1;
                        });
                    }

                    this.props.onFilter({groupedAssignments});
                }
            );
        } else {
            this.props.refreshTable();
        }

        this.props.switchAdminProductsFilters(isEmptyForm);
        this.props.toggleModal('modalFilterGroupedAssignment');
    };

    render() {
        return (
            <Modal isOpen = {this.props.showModal}>
                <form onSubmit = {e => this.onFilter(e)}>
                    <ModalHeader className = "header-modal">
                        FILTROS AVANZADOS
                        <Button className = "modal-close" onClick = {e => {
                            this.props.toggleModal('modalFilterGroupedAssignment');
                            this.setState({
                                certificate: '', subscriber: '', delivery: '', deliveryTime: '',
                                status: '', document: '', serial: '', email: ''
                            });
                        }}><img src = {CloseIcon} alt = "icon product"/></Button>
                    </ModalHeader>

                    <ModalBody>
                        <div className = "container">
                            <div className = "row text-center">
                                <div className = "col-md-6 text-left">
                                    <div className = "form-group">
                                        <InputForm
                                            id="certificate"
                                            type={"text"}
                                            placeholder={"Certificado"}
                                            className="form-control text-input"
                                            onChange={e => this.onChangeInput(e)}
                                            value={this.state.certificate}
                                        />
                                    </div>
                                    <div className = "form-group">
                                        <InputForm
                                            id="validity"
                                            type={"text"}
                                            placeholder={"Vigencia"}
                                            className="form-control text-input"
                                            onChange={e => this.onChangeInput(e)}
                                            value={this.state.validity}
                                        />
                                    </div>
                                </div>
                                <div className = "col-md-6 text-left">
                                    <div className = "form-group">
                                        <InputForm
                                            id="delivery"
                                            type={"text"}
                                            placeholder={"Entrega"}
                                            className="form-control text-input"
                                            onChange={e => this.onChangeInput(e)}
                                            value={this.state.delivery}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>

                    <ModalFooter className = "text-center">
                        <div className = "col-12">
                            <button type = "submit" className = "btn btn-outline-success btn-submit-product">
                                <img className = "input-icon" src = {FilterIcon} alt = "Buscar"/>
                                Buscar
                            </button>
                        </div>
                    </ModalFooter>
                </form>
            </Modal>
        );
    };
}

Modal_filter_grouped_assignment.propTypes = {
    switchAdminProductsFilters: PropTypes.func.isRequired,
    adminProductsReducer: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    adminProductsReducer: state.adminProductsReducer
});

export default connect(mapStateToProps, { switchAdminProductsFilters })(Modal_filter_grouped_assignment)