import React from 'react';
import { Redirect } from 'react-router-dom';
import axios from "axios";
import { API_ENDPOINT } from '../config/config';
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import setAuthToken from "../utils/setAuthToken";
import SideNav from "./SideNav/SideNav";
import { Alert } from 'rsuite';

/**
 * PrivateRoute function (Intercepts all private routes with token verification service consumption,
 * on success and valid response, return a React Fragment with component and sidenav,
 * on fail, return a login redirect, on loading return a centered spinner)
 *
 * @export function
 * @param {Component} Component
 * @param {String} roleNames
 * @param {String} location
 * @returns React.Fragment, Redirect
 */
export default function PrivateRoute(Component, roleNames, location) {
    return class extends React.Component {
        state = {
            loading: true,
            isAuthenticated: false,
            typeUser: '',
            showCart: true,
        }

        async componentDidMount() {
            let headers = {'Authorization': localStorage.getItem('jwtToken')}
            if (roleNames) headers['rolenames'] = roleNames
            //Agregar propiedad de mostrar boton
            await axios.get(`${API_ENDPOINT}/auth/refresh-token`,{headers})
                .then(response => {
                    setAuthToken(response.data.jwt);
                    this.setState({
                        loading: false,
                        isAuthenticated: true,
                        typeUser: localStorage.type,
                        showCart: response.data.viewShoppingCart,
                        footerData: response.data.footerData
                    });
                })
                .catch(error => {
                    this.setState({
                        loading: false,
                        isAuthenticated: false,
                    });
                    if(error.response && error.response.data && error.response.data.unusualAccess)
                    Alert.info( error.response.data.message, 600000);
                });
        }

        render() {
            if (this.state.loading) {
                return (
                    <React.Fragment>
                        <div className="spinner-border cst-spinner" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </React.Fragment>
                )
            }
            if (!this.state.isAuthenticated) {
                return <Redirect to="/logout" />;
            }
            if (this.state.typeUser != '' && this.props.location.pathname == '/products' && this.state.typeUser == 'Convenio') {
                return <Redirect to="/admin" />;
            }
            return (
                <React.Fragment>
                    {/* <SideNav location={location} className="col" /> */}
                    <div className="page-container">
                        <Header {...this.props} showCart={this.state.showCart}/>
                        <Component {...this.props} />
                        <Footer {...this.props} footerData={this.state.footerData}/>
                    </div>
                </React.Fragment>
            );
        }
    }
}