import {
    ASSIGN_CERTIFICATE,
    CHANGE_STATUS_AGREEMENT,
    GENERATE_REPORT,
    GET_ASSIGNMENTS,
    GET_GROUPED_ASSIGNMENTS,
    GET_STATUS_HISTORY,
    GET_TERMS_CONDITIONS,
    GET_TERMS_CONDITIONS_REGISTER,
    GET_TERMS_CONDITIONS_PRODUCT,
    POST_PRE_LOAD,
    REASSIGN_CERTIFICATE,
    RESET_ADMIN_PRODUCTS,
    GENERATE_REVOCATION_CODE,
    REVOKE_CERTIFICATE,
    SWITCH_ADMIN_PRODUCTS_FILTERS,
    VOID_CERTIFICATE,
    GET_DIRECTION_GSE,
    GET_VALUE_ASSISTANT,
    CLEAR_DIRECTION_GSE,
    GET_VALIDATE_EMAIL
} from '../actions/types';

const initialState = {
    client: null,
    assignments: [],
    groupedAssignments: [],
    reportPath: null,
    assignedCertificate: false,
    reassignedCertificate: false,
    voidCertificate: false,
    revokeEmailStatus: null,
    revocationStatus: null,
    statusAgreementChanged: false,
    areEmptyFilters: true,
    createPreload: true,
    termsAndConditions: null,
    termsAndConditionsRegister: null,
    termsAndConditionsProduct: null,
    gseDirection: "",
    valueAssistant: false,
    verifyEmailRes: null
};

/**
 * AdminProductsReducer
 *
 * @export function
 * @param {*} [state=initialState]
 * @param {*} action
 */
export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ASSIGNMENTS:
            return {
                ...state,
                client: action.payload1,
                assignments: action.payload2,
                contractNumberList: action.payload3
            };
        case GET_STATUS_HISTORY:
            return {
                ...state,
                statusHistory: action.payload
            };

        case GET_DIRECTION_GSE:
            return {
                ...state,
                gseDirection: action.payload
            };

        case CLEAR_DIRECTION_GSE:
            return {
                ...state,
                gseDirection: ""
            };
        case GET_VALIDATE_EMAIL:
            return {
                ...state,
                verifyEmailRes: action.payload,
            };
        case GET_VALUE_ASSISTANT:
            return {
                ...state,
                valueAssistant: action.payload
            };

        case GET_TERMS_CONDITIONS:
            return {
                ...state,
                termsAndConditions: action.payload
            };
        case GET_TERMS_CONDITIONS_REGISTER:
            return {
                ...state,
                termsAndConditionsRegister: action.payload
            };
        case GET_TERMS_CONDITIONS_PRODUCT:
            return {
                ...state,
                termsAndConditionsProduct: action.payload
            };

        case GENERATE_REPORT:
            return {
                ...state,
                reportPath: action.payload
            };
        case ASSIGN_CERTIFICATE:
            return {
                ...state,
                assignedCertificate: action.payload
            };
        case REASSIGN_CERTIFICATE:
            return {
                ...state,
                reassignedCertificate: action.payload
            };
        case VOID_CERTIFICATE:
            return {
                ...state,
                voidCertificate: action.payload
            };
        case GENERATE_REVOCATION_CODE:
            return {
                ...state,
                revokeEmailStatus: action.payload
            };
        case REVOKE_CERTIFICATE:
            return {
                ...state,
                revocationStatus: action.payload
            };
        case CHANGE_STATUS_AGREEMENT:
            return {
                ...state,
                statusAgreementChanged: action.payload
            };
        case RESET_ADMIN_PRODUCTS:
            return {
                ...state,
                assignedCertificate: false,
                reassignedCertificate: false,
                voidCertificate: false,
                revokeEmailStatus: null,
                statusAgreementChanged: false,
                resetFilters: false
            };
        case SWITCH_ADMIN_PRODUCTS_FILTERS:
            return {
                ...state,
                areEmptyFilters: action.payload
            };
        case GET_GROUPED_ASSIGNMENTS:
            return {
                ...state,
                groupedAssignments: action.payload.assignments
            };
        case POST_PRE_LOAD:
            return {
                ...state,
                createPreload: !state.createPreload
            };
        default:
            return state;
    }
}
