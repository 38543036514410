import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getProducts, showProductData, clearErrors, clearProduct } from "../../actions/productsActions";
import { getTermsAndConditionsProduct } from "../../actions/adminProductsActions";
import { API_ENDPOINT, TERMS_CONDITIONS_URL } from '../../config/config';
import './products.css';
import _ from 'lodash';

import CardBody from '../../components/CardBody/CardBody';
import FloatingButton from '../../components/FloatingButton/FloatingButton';
import ModalMsg from '../../components/ModalMsg/ModalMsg';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import ProductDetails from './ProductDetails';

import { formatToThousand } from '../../helpers/functions'
import TermsAndConditionsProduct from "./TermsAndConditionsProduct";

/**
 * Products Component ( client products list, show the available products and make to add items to cart )
 *
 * @export Class Component
 * @class Products
 * @extends {Component}
 * @returns Redux connect
 */
export class Products extends Component {
    constructor(props) {
        super(props);

        this.state = {
            products: [],
            isPurchased: false,
            modalMsg: [],
            client: null,
            termsAndConditionsProduct: ''
        };
        this.props.clearErrors();
        this.props.clearProduct();
        this.props.getTermsAndConditionsProduct(this.props.history);
    }

    async componentDidMount() {
        const { clientID } = this.props.match.params;
        this.props.getProducts(clientID);        
    }

    componentWillReceiveProps(nextProps) {
        let { termsAndConditionsProduct } = nextProps.adminProductsReducer;
        if (nextProps.productsReducer.products) {
            if ((this.props.location.state && this.props.location.state.prevPath === 'login') && !this.state.client
                && nextProps.productsReducer.isPurchased) this.redirectToAdmin()
            
            this.setState({
                products: nextProps.productsReducer.products,
                isPurchased: nextProps.productsReducer.isPurchased,
                client: nextProps.productsReducer.client || null
            });
        } else if (nextProps.productsReducer.successSetProduct) {
            this.state.client == null ?
                this.props.history.push('/cart') : this.props.history.push('/cart/' + this.state.client._id);
        } else if (!!nextProps.errorReducer && nextProps.errorReducer.hasErrors) {
            let { data } = nextProps.errorReducer;
            console.log(nextProps.errorReducer);
            this.setState({
                modalMsg: {
                    title: 'Error',
                    label: !!data.msg ? data.msg : data.message,
                    type: 'alert',
                    redirect: data.expiredToken ? 'refresh' : null
                }
            });
            this.openModalMsg();
            this.props.clearErrors()
        }
        if (!!termsAndConditionsProduct && (termsAndConditionsProduct !== this.props.adminProductsReducer.termsAndConditionsProduct)) {
            this.setState({termsAndConditionsProduct: termsAndConditionsProduct.data.termsAndConditions})
        }
    }

    acceptMethods = (openModalMsg) => {
        this.openModalMsg = openModalMsg;
    };

    redirectToAdmin = () => {
        this.props.history.push('/admin');
    };

    clickShowProduct = async (e, product) => {
        let minTariff = product.tariff[0];

        _.each(product.tariff, (tariff) => {
            if (tariff.price < minTariff.price) {
                minTariff = tariff;
            }
        });

        this.props.showProductData({
            product: product,
            subtotal: product.price,
            productID: product._id,
            unitPrice: product.price,
            serial: product.serial,
            modalForm: {
                title: 'AGREGAR PRODUCTO',
                show: true
            },
            minTariff: minTariff
        });
    }

    render() {
        let titleCard = "NUESTROS PRODUCTOS";
        let descriptionTitleCard = "Descripción de nuestros productos y proceso de compra";
        let url = API_ENDPOINT;

        return (
            <Fragment>
                <CardBody title={titleCard} descriptionTitle={descriptionTitleCard}>
                    <div className="textIntro">
                        <p className="text-center">Qué productos tenemos <span
                            className="green-text"> para ti</span></p>
                    </div>
                    <div className="container">
                        <div className="row mb-5">
                            {this.state.products.map((product, key) =>
                                product.visible === true ?
                                    <div className={"col-md-4 text-center product-info"} key={key}>
                                        <div className={(key % 3 !== 0 && key < 7 ? "card-separator" : "")}></div>
                                        <div className="icon-product">
                                            <img src={url + product.icon} alt="icon product" />
                                        </div>
                                        <div className="nameProduct">
                                            {product.productName}
                                        </div>
                                        <div className="descriptionProduct">
                                            {product.descriptionProduct}
                                        </div>
                                        {this.state.client ? this.state.client.type !== 'Convenio' ?
                                            <div className="priceProduct">
                                                Desde {formatToThousand(product.price)} c/u (+ IVA)
                                                </div>
                                            : '' :
                                            <div className="priceProduct">
                                                Desde {formatToThousand(product.price)} c/u (+ IVA)
                                                </div>
                                        }
                                        <div className="btnAdd">
                                            <PrimaryButton
                                                color={"green"}
                                                txtBtn={"Agregar"}
                                                sizeWidth={""}
                                                className={"with-icon small-btn"}
                                                icon={"AddProduct"}
                                                onPress={(e) => this.clickShowProduct(e, product)}
                                            >
                                            </PrimaryButton>
                                        </div>
                                    </div>
                                    : null
                            )}
                        </div>
                    </div>

                    <div className="textIntro">
                        <p className="text-center">Los precios pueden variar <span
                            className="green-text"> según especificaciones</span></p>
                    </div>
                </CardBody>
                
                <div class="footer-container p-3" >
                    <p class='text-xl-center'>Consulte aquí los <span> <a class='green-text' target={'_blank'} href={this.state.termsAndConditionsProduct}><u>términos y condiciones</u></a> </span></p>
                </div>
                
                {/* <TermsAndConditionsProduct  termsAndConditionsProduct={this.state.termsAndConditionsProduct}/> */}

                <ProductDetails history={this.props.history} />
                {(this.state.isPurchased && !this.state.client)
                    ? <FloatingButton clickEvent={this.redirectToAdmin} />
                    : null
                }

                <ModalMsg
                    shareMethods={this.acceptMethods}
                    title={this.state.modalMsg.title}
                    show={this.state.modalMsg.show}
                    label={this.state.modalMsg.label}
                    type={this.state.modalMsg.type}
                    redirect={this.state.modalMsg.redirect}
                    history={this.props.history}
                >
                </ModalMsg>
            </Fragment>
        )
    }
}

Products.propTypes = {
    getProducts: PropTypes.func.isRequired,
    showProductData: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired,
    getTermsAndConditionsProduct: PropTypes.func.isRequired,
    clearProduct: PropTypes.func.isRequired,
    productsReducer: PropTypes.object.isRequired,
    errorReducer: PropTypes.object.isRequired,
    adminProductsReducer: PropTypes.object.isRequired 
};

const mapStateToProps = (state) => ({
    productsReducer: state.productsReducer,
    errorReducer: state.errorReducer,
    adminProductsReducer: state.adminProductsReducer    
})


const mapDispatchToProps = {
  getProducts, showProductData, clearErrors, getTermsAndConditionsProduct, clearProduct 
}

export default connect(mapStateToProps, mapDispatchToProps)(Products)
