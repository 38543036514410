import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import SimpleReactValidator from "simple-react-validator";
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';

import ModalAlert from "../Modal_alert";
import { MandatoryFields } from "./MandatoryFieldsForm";
import { Header } from "./Header";
import { PreloadDocument } from "./PreloadDocument";
import { AditionalInfo } from "./AditionalInfo";

import { reassignCertificate, resetAdminProductsReducer, getDirectionGSE, clearDirectionGSE} from "../../../actions/adminProductsActions";
import { reactValidatorOptions } from "../../../helpers/simpleReactValidator";
import { fileToBase64 } from "../../../helpers/filesLib";

import AssignWhiteIcon from "../../../assets/img/icons/Icon_asign.png";
import CloseIcon from '../../../assets/img/icons/close.png';

/**
 * ReassignmentForm Component ( render a modal with ReassignmentForm Form and make a post request to
 * reassign certificate to a user )
 *
 * @export Class Component
 * @class ReassignmentForm
 * @extends {Component}
 * @returns Redux connect
 */
class ReassignmentForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            useInvoiceData: false,
            useDirectionAsist: false,
            addresAssistant: 'off',
            modalAlert: false,
            addressForm: {
                address_field_1: '',
                address_field_2: '',
                address_field_3: '',
                address_field_4: '',
                address_field_5: '',
                address_field_6: '',
                address_field_7: '',
                address_field_8: '',
                address_field_9: '',
                address_field_10: '',
                address_complementary_field_1: '',
                address_complementary_field_2: '',
                address_complementary_field_3: '',
                address_complementary_field_4: '',
                address: ''
            },
            form: {
                firstName: '',
                secondName: '',
                lastName: '',
                lastSurname: '',
                email: '',
                cellPhone: '',
                documentType: '',
                documentTypes: [
                    { key: 'none', val: '', lbl: 'Tipo de Documento' },
                    { key: 'CC', val: 'CC', lbl: 'Cédula de ciudadanía' },
                    { key: 'CE', val: 'CE', lbl: 'Cédula extranjería' },
                    { key: 'PS', val: 'PS', lbl: 'Pasaporte' },
                    { key: 'NIT', val: 'NIT', lbl: 'Nit' },
                    { key: 'PEP', val: 'PEP', lbl: 'Permiso Especial de Permanencia'},
                    { key: 'PPT', val: 'PPT', lbl: 'Permiso por protección Temporal' }
                ],
                documentNumber: '',
                town: '',
                towns: [],
                clientDocument: '',
                clientDocumentName: ''
            },
            certificateUse: '',
            certificateUses: [
                { key: 'none', val: '', lbl: 'Seleccione...' },
                { key: 'RUNT', val: 'RUNT', lbl: 'RUNT' },
                { key: 'SIIF_NACION', val: 'SIIF NACIÓN', lbl: 'SIIF NACIÓN' },
                { key: 'SUPERFINANCIERA', val: 'SUPERFINANCIERA', lbl: 'SUPERFINANCIERA' },
                { key: 'VUCE', val: 'VUCE', lbl: 'VUCE' },
                { key: 'SUPERSALUD', val: 'SUPERSALUD', lbl: 'SUPERSALUD' },
                { key: 'SICOQ', val: 'SICOQ', lbl: 'SICOQ' },
                { key: 'SUPERVIGILACIA', val: 'SUPERVIGILACIA', lbl: ' SUPERVIGILACIA' },
                { key: 'SECRETARIA_DISTRITAL_HACIENDA', val: 'SECRETARIA DISTRITAL DE HACIENDA', lbl: 'SECRETARIA DISTRITAL DE HACIENDA' },
                { key: 'MINISTERIO_COMERCIO', val: 'MINISTERIO DE COMERCIO', lbl: 'MINISTERIO DE COMERCIO' },
                { key: 'MINISTERIO_PROTECCION ', val: 'MINISTERIO DE PROTECCIÓN ', lbl: 'MINISTERIO DE PROTECCIÓN ' },
                { key: ' OTROS', val: ' OTROS', lbl: ' OTROS' }
            ],
            certificateDelivery: 'clientAddress',
            address: '',
            errors: {}
        };

        reactValidatorOptions.validators.documentNumber = {
            message: 'El formato del :attribute no es válido',
            rule: (val, params, validator) => {
                return validator.helpers.testRegex(val, this.state.documentType === 'NIT' ? /^\d{9,13}$/ : this.state.documentType === 'PS' ? /^\w{5,15}$/ : /^\d{7,12}$/);
            }
        };

        this.reassignmentFormValidator = new SimpleReactValidator(reactValidatorOptions);
        this.addressReassignmentValidator = new SimpleReactValidator(reactValidatorOptions);
    }

    componentWillReceiveProps(nextProps) {
        let { locationReducer, adminProductsReducer } = nextProps;

        this.setState({ useDirectionAsist: adminProductsReducer.valueAssistant });
        
        if (adminProductsReducer.gseDirection != this.props.adminProductsReducer.gseDirection) {
            this.setState((oldState) => {
                return {
                    addressForm: {
                        ...oldState.addressForm,
                        address : adminProductsReducer.gseDirection.GSEDate
                    }
                }
            })
        }


        if (locationReducer.towns.length !== this.state.form.towns.length) {
            this.setState((prevState) => ({
                form: { ...prevState.form, towns: locationReducer.towns }
            }));
        }
    }

    onChangeFile = async (e) => {
        const targetID = e.target.id;
        if (e.target.files[0]) {
            let name = e.target.files[0].name;
            await fileToBase64(name, e.target.files[0]).then(async result => {
                if (result.split(',')[1] === undefined) {
                    this.setState((oldState) => ({
                        errors: {
                            ...oldState.errors,
                            [targetID]: 'El documento no es valido',
                        }
                    }));
                    console.error("Documento no valido")
                } else {
                    delete this.state.errors[targetID];
                    await this.setState((oldState) => ({
                        form: {
                            ...oldState.form,
                            [targetID]: result.split(',')[1],
                            [targetID + 'Name']: name
                        }
                    }));
                }
            })
        }
    };

    onChangeInput = e => {
        this.setState({ [e.target.id]: e.target.value, errors: {} });
    };

    onChangeFormInput = e => {
        const target = e.target.id;
        const value = e.target.value;
        this.setState((oldState) => {
            return {
                form: {
                    ...oldState.form,
                    [target]: value
                }
            }
        });
    };

    onChangeAddressFields = () => async e => {
        const oldTarget = e.target.id;
        let target = oldTarget == "addressOnly" ? "address" : e.target.id;
        const value = e.target.value;
        await this.setState((oldState) => {
            return {
                addressForm: {
                    ...oldState.addressForm,
                    [target]: value
                }
            }
        });
        if(oldTarget !== "addressOnly") this.buildFullAddress();
    };
    handleShowDirAssistant = async (e) => {
        await this.setState({ useDirectionAsist: !this.state.useDirectionAsist });
        this.cleanDirectionAssistant();
    }


    handleAddressReactSelect = async (selectedOption) => {
        await this.setState((oldState) => {
            return {
                addressForm: {
                    ...oldState.addressForm,
                    [selectedOption.id]: selectedOption.value
                }
            }
        });
        this.buildFullAddress();
    };

    handleCertificateDelivery(e) {
        this.setState({ certificateDelivery: e.target.value });
        this.cleanDirectionAssistant();
        if(e.target.value == "gseAddress"){
            this.props.getDirectionGSE();
        }
        this.setState({ useDirectionAsist: false });

    }

    cleanDirectionAssistant = async () => {
        this.setState({
            addressForm: {
                address_field_1: '',
                address_field_2: '',
                address_field_3: '',
                address_field_4: '',
                address_field_5: '',
                address_field_6: '',
                address_field_7: '',
                address_field_8: '',
                address_field_9: '',
                address_field_10: '',
                address_complementary_field_1: '',
                address_complementary_field_2: '',
                address_complementary_field_3: '',
                address_complementary_field_4: '',
                address: '',
            }
        });
    }

    buildFullAddress = () => {
        const { address_field_1, address_field_2, address_field_3, address_field_4, address_field_5, address_field_6, address_field_7, address_field_8, address_field_9, address_field_10,
            address_complementary_field_1, address_complementary_field_2, address_complementary_field_3, address_complementary_field_4 } = this.state.addressForm;

        const address = (!!address_field_1 ? address_field_1 + ' ' : '') +
            (!!address_field_2 ? address_field_2 + ' ' : '') +
            (!!address_field_3 ? address_field_3 + ' ' : '') +
            (!!address_field_4 ? address_field_4 + ' ' : '') +
            (!!address_field_5 ? address_field_5 + ' ' : '') +
            (!!address_field_6 ? address_field_6 + ' ' : '') +
            (!!address_field_7 ? '# ' + address_field_7 + ' ' : '') +
            (!!address_field_8 ? address_field_8 + ' ' : '') +
            (!!address_field_9 ? address_field_9 + ' ' : '') +
            (!!address_field_10 ? address_field_10 + ' ' : '') +
            (!!address_complementary_field_1 ? address_complementary_field_1 + ' ' : '') +
            (!!address_complementary_field_2 ? address_complementary_field_2 + ' ' : '') +
            (!!address_complementary_field_3 ? address_complementary_field_3 + ' ' : '') +
            (!!address_complementary_field_4 ? address_complementary_field_4 : '');
        this.setState((oldState) => {
            return {
                addressForm: {
                    ...oldState.addressForm,
                    address
                },
                addresAssistant: 'on'
            }
        });
    };

    onChangeCheckbox = e => {
        let checked = e.target.value === "true";
        this.setState(
            { [e.target.id]: !checked, errors: {} },
            () => {
                if (checked === false) {
                    let { invoiceData } = this.props.selectedProduct;
                    let names = invoiceData.firstName.split(' ');
                    let lastnames = invoiceData.lastName.split(' ');

                    this.setState((oldState) => {
                        return {
                            form: {
                                ...oldState.form,
                                firstName: names.shift(),
                                secondName: names.join(' '),
                                lastName: lastnames.shift(),
                                lastSurname: lastnames.join(' '),
                                email: invoiceData.email,
                                cellPhone: !!invoiceData.cellPhoneNumber ? invoiceData.cellPhoneNumber : '',
                                documentType: invoiceData.documentType,
                                documentNumber: invoiceData.documentNumber,
                                town: !!invoiceData.city ? invoiceData.townCode : '',
                                address: invoiceData.address
                            },
                            addressForm: {
                                ...oldState.addressForm,
                                address: invoiceData.address
                            }
                        }
                    });
                } else {
                    this.cleanForm();
                }
            }
        );
    };

    toggleAlertModal = async () => {
        if (this.state.modalAlert === true) this.cleanForm();
        this.setState({ modalAlert: !this.state.modalAlert });
    };

    cleanForm = () => {
        this.reassignmentFormValidator.hideMessages();
        this.addressReassignmentValidator.hideMessages();

        if (this.state.useInvoiceData === true) this.setState({ useInvoiceData: false });
        this.setState((oldState) => {
            return {
                form: {
                    ...oldState.form,
                    firstName: '',
                    secondName: '',
                    lastName: '',
                    lastSurname:'',
                    email: '',
                    cellPhone: '',
                    documentType: '',
                    documentNumber: '',
                    town: '',
                    clientDocument: '',
                    clientDocumentName: ''
                },
                addressForm: {
                    address_field_1: '',
                    address_field_2: '',
                    address_field_3: '',
                    address_field_4: '',
                    address_field_5: '',
                    address_field_6: '',
                    address_field_7: '',
                    address_field_8: '',
                    address_field_9: '',
                    address_field_10: '',
                    address_complementary_field_1: '',
                    address_complementary_field_2: '',
                    address_complementary_field_3: '',
                    address_complementary_field_4: '',
                    address: ''
                },
                certificateDelivery: 'clientAddress',
                certificateUse: ''
            }
        });

        this.props.clearDirectionGSE();
    };

    onSubmit = (e) => {
        if (this.reassignmentFormValidator.allValid()) {

            const { firstName, secondName, lastName, lastSurname, email, cellPhone, documentType, documentNumber, town,
                clientDocument, clientDocumentName } = this.state.form;
            const { address } = this.state.addressForm;
            const { selectedProduct } = this.props;
            let data = {
                assignmentID: selectedProduct.assignmentID,
                user: {
                    firstName,
                    secondName,
                    lastName,
                    lastSurname,
                    email,
                    cellPhone,
                    documentType,
                    documentNumber,
                    townCode: town,
                    address: address,
                    clientDocument,
                    clientDocumentName,
                    certificateUse: this.state.certificateUse
                },
                addressData: {
                    ...this.state.addressForm,
                    address: address
                },
                productID: selectedProduct.productID
            };

            this.props.reassignCertificate(this.props.clientID, data, this.props.history);

            this.toggleAlertModal().then();

            this.setState({ useDirectionAsist: false });
        } else {
            this.reassignmentFormValidator.showMessages();
            this.forceUpdate();
        }
    };

    render() {
        return (
            <>
                <Modal isOpen={this.props.modalReassignProduct}>
                    <ModalHeader className="header-modal">
                        REASIGNAR PRODUCTO
                        <Button className="modal-close">
                            <img
                                src={CloseIcon}
                                onClick={e => {
                                    this.cleanForm();
                                    this.props.toggleModal('modalReassignProduct');
                                    this.reassignmentFormValidator.hideMessages();
                                }}
                                alt="icon product"
                            />
                        </Button>
                    </ModalHeader>

                    <form onSubmit={e => {
                        e.preventDefault();
                        const validAddress = true;
                        if (!this.reassignmentFormValidator.allValid() || !validAddress) {
                            this.reassignmentFormValidator.showMessages();
                            this.forceUpdate();
                            return null
                        } else {
                            this.props.toggleModal('modalReassignProduct');
                            this.toggleAlertModal().then();
                        }
                    }}>
                        <Header selectedProduct={this.props.selectedProduct}/>

                        <ModalBody>
                            <div className="container pt-2 pl-4 pr-4">
                                <MandatoryFields
                                    useInvoiceData={this.state.useInvoiceData}
                                    onChangeCheckbox={e => this.onChangeCheckbox(e)}
                                    selectedProduct={this.props.selectedProduct}
                                    dataForm={this.state.form}
                                    onChangeInput={e => this.onChangeFormInput(e)}
                                    formValidator={this.reassignmentFormValidator}
                                />
                                <div className="divider mt-4 mb-4" />
                                <AditionalInfo
                                    onChangeInput={e => this.onChangeInput(e)}
                                    certificateUse={this.state.certificateUse}
                                    certificateUses={this.state.certificateUses}
                                    certificateDelivery={this.state.certificateDelivery}
                                    handleCertificateDelivery={e => this.handleCertificateDelivery(e)}
                                    addressValidator={this.addressReassignmentValidator}
                                    onChangeAddressFields={this.onChangeAddressFields}
                                    handleAddressReactSelect={this.handleAddressReactSelect}
                                    addressForm={this.state.addressForm}
                                    useDirectionAsist={this.state.useDirectionAsist}
                                    GSEDirection={this.state.addressForm.address}
                                    handleShowDirAssistant={e => this.handleShowDirAssistant(e)}
                                    changeAddressOnly={e => this.changeAddressOnly(e)}
                                />
                            </div>
                        </ModalBody>

                        <div className="col-md-12 preload-message">
                            <div className="container p-3">
                                <p>Nota: no olvide que a esta dirección se enviará el certificado</p>
                            </div>
                        </div>

                        <ModalBody>
                            <div className="container pt-2 pl-4 pr-4">
                                <PreloadDocument
                                    clientDocumentName={this.state.form.clientDocumentName}
                                    onChangeFile={this.onChangeFile}
                                    errors={this.state.errors}
                                />
                                <div className="divider mt-4 mb-4" />
                                <div className="col-md-12 text-center">
                                    <button type="submit" className="btn btn-outline-success btn-submit-product">
                                        <img className="input-icon" src={AssignWhiteIcon} alt="Reasignar" />
                                        Reasignar
                                    </button>
                                </div>
                            </div>
                        </ModalBody>
                    </form>
                </Modal>

                <ModalAlert
                    showModal={this.state.modalAlert}
                    title="REASIGNAR CERTIFICADO"
                    message="¿Está seguro que desea reasignar el certificado? Una vez lo haga, no podrá anular la reasignación."
                    toggleAlertModal={this.toggleAlertModal}
                    onSubmit={this.onSubmit}
                    actualOperation="reassignment"
                />
            </>
        );
    };
}

ReassignmentForm.propTypes = {
    reassignCertificate: PropTypes.func.isRequired,
    getDirectionGSE: PropTypes.func.isRequired,
    clearDirectionGSE: PropTypes.func.isRequired,
    resetAdminProductsReducer: PropTypes.func.isRequired,
    adminProductsReducer: PropTypes.object.isRequired,
    locationReducer: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    adminProductsReducer: state.adminProductsReducer,
    locationReducer: state.locationReducer
});

const mapDispatchToProps = { 
    reassignCertificate, 
    resetAdminProductsReducer, 
    getDirectionGSE, 
    clearDirectionGSE 
};

export default connect(mapStateToProps, mapDispatchToProps )(ReassignmentForm);
