import React, {Fragment} from 'react';

import { extractNumbersOfString } from '../../../helpers/functions';
import { PAGUEATIEMPO_RETURN_URL, PAGUEATIEMPO_AUTOMATIC_RETURN_URL, PSE_TEST_MODE } from '../../../config/config';

const PseForm = ({ children, token, total, formData, reference, iva }) => {
    return (
        <Fragment>
            <form hidden method="post" action="https://api.pagueatiempo.com/api/v1/solicitud-de-pago">
                <input type="number" name="nit_comercio" value="900204272" onChange={e => alert('No debe ser modificado')}/>
                <input type="text" name="token" value={token} onChange={e => console.log('')}/>
                <input type="text" name="clt_url_retorno" value={PAGUEATIEMPO_RETURN_URL} onChange={e => alert('No debe ser modificado')}/>
                <input type="text" name="clt_url_retorno_automatico" value={PAGUEATIEMPO_AUTOMATIC_RETURN_URL} onChange={e => alert('No debe ser modificado')}/>
                {formData.isProductionEnv === true
                    ? <input type="text" name="clt_purchase_operation_number" value={extractNumbersOfString(reference)} onChange={e => alert('No debe ser modificado')}/>
                    : <input type="text" name="clt_purchase_operation_number" value={parseInt(extractNumbersOfString(reference)) + 100000000} onChange={e => alert('No debe ser modificado')}/>
                }
                <input type="text" name="clt_purchase_amount" value={PSE_TEST_MODE === true ? `1000.00` : `${total}.00`} onChange={e => console.log('')} />
                <input type="text" name="clt_purchase_iva" value={PSE_TEST_MODE === true ? `0.00` : `${iva}.00`} onChange={e => console.log('')} />
                <input type="text" name="clt_purchase_currency_id" value="COP" onChange={e => alert('No debe ser modificado')}/>
                <input type="text" name="clt_billing_first_name" value={!!formData.firstName ? formData.firstName.substring(0, 29) : !!formData.legalName ? formData.legalName.substring(0, 29) : ''} onChange={e => console.log('')}/>
                <input type="text" name="clt_billing_last_name" value={!!formData.lastName ? formData.lastName.substring(0, 29) : '(' + formData.city + ')'} onChange={e => console.log('')}/>
                <input type="text" name="clt_billing_country" value="CO" onChange={e => alert('No debe ser modificado')}/>
                <input type="text" name="clt_billing_city" value={formData.city} onChange={e => console.log('')}/>
                <input type="text" name="clt_billing_address" value={formData.address.substring(0, 49)} onChange={e => console.log('')}/>
                <input type="text" name="clt_billing_phone_number" value={formData.cellPhoneNumber} onChange={e => console.log('')}/>
                <input type="text" name="clt_billing_celphone_number" value={formData.cellPhoneNumber} onChange={e => console.log('')}/>
                <input type="text" name="clt_billing_gender" value="M" onChange={e => alert('No debe ser modificado')}/>
                <input type="text" name="clt_billing_email" value={formData.email} onChange={e => console.log('')}/>
                <input type="text" name="clt_billing_nationality" value="CO" onChange={e => alert('No debe ser modificado')}/>
                <input type="text" name="clt_additional_observations" value={!!formData.additionalInformation ? formData.additionalInformation.substring(0, 49) : ''} onChange={e => console.log('')}/>
                {formData.isProductionEnv === true
                    ? null
                    : <input type="text" name="clt_informacion_adicional" value="Transacción de prueba GSE" onChange={e => console.log('')}/>
                }
                {children}
            </form>
        </Fragment>
    )
};

export default PseForm;
