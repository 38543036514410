import React from "react"
import InputForm from "../../../components/InputForm/InputForm";
import PropTypes from "prop-types"
import SelectForm from "../../../components/SelectForm/SelectForm";

export const DataPreloadInfo = ({...props}) => {
    const {dataForm, formValidator, onChangeInput, handleSelect, departments, towns} = props;
    return (
        <>
            <div className="row pb-3">
                <div className="col-md-12 text-center title-modal-preload">
                    PRECARGA DE DATOS COMUNES
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 form-group d-grid">
                    <InputForm
                        id="nit"
                        type={"number"}
                        label={"Nit"}
                        placeholder={""}
                        className="form-control text-input"
                        onChange={onChangeInput}
                        onKeyDown={'nit'}
                        value={dataForm.nit}
                        validator={formValidator}
                        validateOptions={'min:5|max:15'}
                    />
                </div>
                <div className="col-md-6 form-group d-grid">
                    <InputForm
                        id="checkDigit"
                        type={"number"}
                        label={"Dígito de verificación"}
                        placeholder={""}
                        className="form-control text-input"
                        onChange={onChangeInput}
                        onKeyDown={'number'}
                        value={dataForm.checkDigit}
                        disabled
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 form-group d-grid">
                    <InputForm
                        id="businessName"
                        type={"text"}
                        maxLength="300"
                        label={"Nombre o Razón Social"}
                        placeholder={""}
                        className="form-control text-input"
                        onChange={onChangeInput}
                        onKeyDown={'legal_name'}
                        value={dataForm.businessName}
                        validator={formValidator}
                        validateOptions={'legalname|min:3'}
                    />
                </div>
                <div className="col-md-6 form-group d-grid">
                    <InputForm
                        id="address"
                        type={"text"}
                        label={"Dirección"}
                        placeholder={""}
                        className="form-control text-input"
                        onChange={onChangeInput}
                        onKeyDown={'address'}
                        value={dataForm.address}
                        validator={formValidator}
                        validateOptions={'address'}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 form-group d-grid">
                    <InputForm
                        id="jobTitle"
                        type={"text"}
                        maxLength="50"
                        label={"Cargo"}
                        placeholder={""}
                        className="form-control text-input"
                        onChange={onChangeInput}
                        onKeyDown={'alpha_num_space'}
                        value={dataForm.jobTitle}
                        validator={formValidator}
                        validateOptions={'jobName|min:3'}
                    />
                </div>
                <div className="col-md-6 form-group d-grid">
                    <InputForm
                        id="dependency"
                        type={"text"}
                        maxLength="50"
                        label={"Dependencia"}
                        placeholder={""}
                        className="form-control text-input"
                        onChange={onChangeInput}
                        onKeyDown={'alpha_num_space'}
                        value={dataForm.dependency}
                        validator={formValidator}
                        validateOptions={'jobName|min:3'}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 form-group d-grid">
                    <SelectForm
                        id="department"
                        label={"Departamento"}
                        placeholder={""}
                        className="form-control text-input"
                        onChange={handleSelect}
                        value={dataForm.department}
                        options={departments}
                    />
                </div>
                <div className="col-md-6 form-group d-grid">
                    <SelectForm
                        id="town"
                        label={"Municipio"}
                        placeholder={""}
                        className="form-control text-input"
                        onChange={handleSelect}
                        value={dataForm.town}
                        options={towns}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 form-group d-grid">
                    <InputForm
                        id="phone"
                        type={"number"}
                        label={"Teléfono"}
                        placeholder={""}
                        className="form-control text-input"
                        onChange={onChangeInput}
                        onKeyDown={'number'}
                        value={dataForm.phone}
                        validator={formValidator}
                        validateOptions={'min:3|max:18'}
                    />
                </div>
                <div className="col-md-6 form-group d-grid">
                    <InputForm
                        id="indicative"
                        type={"number"}
                        label={"Indicativo"}
                        placeholder={""}
                        className="form-control text-input"
                        onChange={onChangeInput}
                        onKeyDown={'number'}
                        value={dataForm.indicative}
                        validator={formValidator}
                        validateOptions={'max:3'}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 form-group d-grid">
                    <InputForm
                        id="extension"
                        type={"number"}
                        label={"Extensión"}
                        placeholder={""}
                        className="form-control text-input"
                        onChange={onChangeInput}
                        onKeyDown={'number'}
                        value={dataForm.extension}
                        validator={formValidator}
                        validateOptions={'max:5'}
                    />
                </div>
            </div>
        </>
    )
};

DataPreloadInfo.propTypes = {
    dataForm: PropTypes.object.isRequired,
    onChangeInput: PropTypes.func.isRequired,
    formValidator: PropTypes.any.isRequired
};
