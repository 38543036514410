import React from "react";
import PropTypes from "prop-types";
import './select-form.css'

import user from '../../assets/img/icons/user/user.png';
import document from '../../assets/img/icons/document.png';
import map from '../../assets/img/icons/document.png';
import position from '../../assets/img/icons/position/positionx2.png';

/**
 * SelectForm constant ( generic HTML input for recursive implementation in forms )
 *
 * @param {*} {...props}
 * @returns HTML object
 */
const SelectForm = ({ ...props }) => {
    const { id, onChange, options, className, icon, label, placeholder, disabled, error, validator, validateOptions, value, addDisabledOption } = props;

    const icons = {
        user,
        document,
        map,
        position
    };

    let errorMessage = null;
    if (validator && validateOptions) errorMessage = validator.message('', value, validateOptions);

    return (
        <div className="form-group">
            {(icon !== '' && icon) &&
            <span className="input-group-addon"><img className="input-group-icon" src={icons[icon]} alt={icons[icon]}/></span>
            }
            {label !== '' ?
                <label htmlFor={id} className={error || errorMessage ? "text-danger" : ""}>{label}</label>
                : null
            }
            <select
                id={id}
                className={`${icon && !label ? 'icon':''} ${className} ${error || errorMessage ? 'is-invalid' : ''}`}
                value={value}
                onChange={onChange}
                disabled={disabled}
            >
                {addDisabledOption
                    ? <option disabled value=''>Seleccione...</option>
                    : null
                }
                {
                    options ? options.map((value) => {
                        return (
                            <option key={value.key} value={value.val} disabled={value.val === '' ? true : false}>{value.lbl}</option>
                        )
                    }) : ''
                }
            </select>
            {disabled ?
                null
                : !!validator
                    ? validator.message(placeholder !== '' && placeholder !== undefined ? placeholder : 'select', value, validateOptions)
                    : null
            }
            {error ?
                <div className="invalid-feedback">{error}</div>
                : null
            }
        </div>
    );
};


SelectForm.propTypes = {
    labelProps: PropTypes.object,
    id: PropTypes.string,
};
export default SelectForm;
