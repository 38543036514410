import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { storeVoucher, errorFileSize } from '../../actions/shoping_cartActions';

import SimpleReactValidator from 'simple-react-validator';
import { reactValidatorOptions } from '../../helpers/simpleReactValidator';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import warning from '../../assets/img/icons/warning.png';

/**
 * ResendVoucher Component ( full view ResendVoucher component, render a ResendVoucher
 * form and set an image to shopping cart register )
 *
 * @export Class Component
 * @class ResendVoucher
 * @extends {Component}
 * @returns Redux connect
 */
export class ResendVoucher extends Component {

    constructor(props) {
        super(props);

        this.state = {
            attachment: null,
            attachmentVal: '',
            modalMsg: [],
        };

        this.validatorVoucherForm = new SimpleReactValidator(reactValidatorOptions);
    }

    componentWillReceiveProps(nextProps) {

    }

    onChangeCustomFile = () => e => {
        if (!!e.target.id) {
            this.setState({ attachment: e.target.files[0], errors: {} });
            this.setState({ attachmentVal: e.target.value, errors: {} });
        } else {
            console.error('Error actualizando la informacion ingresada', 'error');
        }
    };

    storeVoucher = (event) => {
        event.preventDefault();
        if (this.validatorVoucherForm.allValid()) {
            if (this.state.attachment.size > 300000) {
                this.props.errorFileSize();
            } else {
                var data = new FormData();

                data.append('attachment', this.state.attachment);
                data.append('detailSaleID', this.props.detailSaleID);
                const type = 'rejected';
                this.props.storeVoucher(data, type);
            }
        } else {
            this.validatorVoucherForm.showMessages();
            this.forceUpdate();
        }
    }

    render() {
        return (
            <div id="attachmentForm">
                <div className="hl"></div>

                <div className="resendAlert row">
                    <div className="col-md-1" style={{ padding: '15px 15px 15px 30px' }}>
                            <img src={warning} alt="Comprobante"/>
                        </div>
                        <div className="col-md-11">
                        <p className="text-center sectionResendAlert">Señor usuario, su pago para la referencia {this.props.fullReference} fue rechazado, por favor adjunte nuevamente el <span className="green-text">comprobante de pago</span></p>
                        </div>
                </div>

                <div className="col-10 offset-1">
                    <form onSubmit={event => this.storeVoucher(event)}>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <input type="file"
                                    className="form-control text-input cst-file-input"
                                    id="attachmentVal"
                                    placeholder="Adjunto"
                                    value={this.state.attachmentVal}
                                    onChange={this.onChangeCustomFile()}
                                >
                                </input>
                                {this.validatorVoucherForm.message('adjunto', this.state.attachmentVal, 'required')}
                            </div>

                            <div className="col-md-6 text-center">
                                <PrimaryButton
                                    color={"green"}
                                    txtBtn={"Adjuntar"}
                                    sizeWidth={""}
                                    className={"with-icon"}
                                    icon={"Quotation"}
                                >
                                </PrimaryButton>
                                
                            </div>
                        </div>
                    </form>
                </div>
                <hr />

                <div className="textInfoHelp">
                    <p className="text-center">Si requiere ayuda, comuníquese con nuestra línea de soporte <span className="green-text">(57 1) 518 51 58 Opc:2</span></p>
                </div>

            </div>
        )
    }
}

ResendVoucher.propTypes = {
    storeVoucher: PropTypes.func.isRequired,
    errorFileSize: PropTypes.func.isRequired,
    shoppingCartReducer: PropTypes.object.isRequired,
    errorReducer: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    shoppingCartReducer: state.shoppingCartReducer,
    errorReducer: state.errorReducer
})

export default connect(mapStateToProps, { storeVoucher, errorFileSize })(ResendVoucher)