import React from "react"
import PropTypes from "prop-types"

export const SendCredentials = ({ ...props }) => {
    const {
        sendCredentials,
        handleSendCredentials
    } = props;
    
    return (
        <>
            <div className="row">
                <div className="text-center font-weight-bold mb-4 col-12">ENVIO DE CREDENCIALES</div>

                <div className="col-md-12 preload-message">
                    <div className="container p-3">
                        <p>¿Desea que las credenciales sean enviadas al suscriptor? Si escoge que “Si” el suscriptor será quien ingrese a la plataforma de Vinkel a realizar el diligenciamiento de la información.</p>
                    </div>
                </div>
                
                <div className="d-grid col-md-6">
                    <label htmlFor="sendCredentials">Enviar credenciales al Suscriptor</label>
                    <div id="sendCredentials" className="form-group">
                        <div className="custom-control custom-radio custom-control-inline">
                            <input name="sendCredentials"
                                id="sendCredentials_0"
                                type="radio"
                                className="custom-control-input"
                                value="subscriber"
                                checked={sendCredentials === "subscriber"}
                                onChange={(value) => { handleSendCredentials(value) }}
                            >
                            </input>
                            <label htmlFor="sendCredentials_0" className="custom-control-label">SI</label>
                        </div>
                        <div className="custom-control custom-radio custom-control-inline">
                            <input name="sendCredentials"
                                id="sendCredentials_1"
                                type="radio"
                                className="custom-control-input"
                                value="supervisor"
                                checked={sendCredentials === "supervisor"}
                                onChange={(value) => { handleSendCredentials(value) }}
                            >
                            </input>
                            <label htmlFor="sendCredentials_1" className="custom-control-label">NO</label>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

SendCredentials.propTypes = {
    handleSendCredentials: PropTypes.func.isRequired
};