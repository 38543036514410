import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import SimpleReactValidator from "simple-react-validator";

import InputForm from "../../../components/InputForm/InputForm";
import SelectForm from "../../../components/SelectForm/SelectForm";
import AreaTextForm from "../../../components/AreaTextForm/AreaTextForm";
import ModalForm from "../../../components/ModalForm/ModalForm";
import ModalAlert from "../../../components/ModalAlert/ModalAlert";
import TermsAndConditions from "./TermsAndConditions";

import { reactValidatorOptions } from "../../../helpers/simpleReactValidator";
import { reassignCertificate, resetAdminProductsReducer, generateRevocationCode, revokeCertificate, getTermsAndConditions } from "../../../actions/adminProductsActions";

import ActiveIcon from "../../../assets/img/icons/active_inverse.png";
import SuspendIcon from "../../../assets/img/icons/suspend/suspend.png";

/**
 * RevocationCodeForm Component ( render a modal with Revocation Form and make a post request to
 * revoke certificate to a user )
 *
 * @export Class Component
 * @class RevocationCodeForm
 * @extends {Component}
 * @returns Redux connect
 */
class RevocationCodeForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalAlert: false,
            filledForm: false,
            revocationCode: '',
            revocationReason: '',
            revocationReasons: [],
            observations: '',
            acceptTerms: false,
            revocationServerStatus: 'success',
            revocationServerMessage: '',
            errors: {},
            termsAndConditions: ''
        };

        this.revocationFormValidator = new SimpleReactValidator(reactValidatorOptions);
        this.props.getTermsAndConditions(this.props.history);
    }

    componentWillReceiveProps(nextProps) {
        let { revocationStatus, termsAndConditions } = nextProps.adminProductsReducer;

        if (!!revocationStatus && (revocationStatus !== this.props.adminProductsReducer.revocationStatus)) {
            this.setState({ revocationServerMessage: revocationStatus.message, revocationServerStatus: revocationStatus.success === true ? 'success' : 'error' }, () => {
                this.hideModal();
                this.toggleAlertModal();
            });
        }
        if (!!termsAndConditions && (termsAndConditions !== this.props.adminProductsReducer.termsAndConditions)) {
            this.setState({termsAndConditions: termsAndConditions.data.termsAndConditions})
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.revocationCauses.length > 0 && prevProps.revocationCauses !== prevState.revocationReasons) {
            this.setState({ revocationReasons: prevProps.revocationCauses });
        }
    }

    onChangeInput = e => {
        this.setState({ [e.target.id]: e.target.value, errors: {} }, () => {
            const filledForm = this.revocationFormValidator.allValid() && this.state.acceptTerms === true;
            this.setState({ filledForm });
        });
    };

    onChangeCheckbox = e => {
        let checked = e.target.value === "true";
        this.setState({[e.target.id]: !checked}, () => {
            const filledForm = this.revocationFormValidator.allValid() && this.state.acceptTerms === true;
            this.setState({ filledForm });
        });
    };

    toggleAlertModal = () => {
        if (this.state.modalAlert === true) this.cleanForm();
        this.setState({ modalAlert: !this.state.modalAlert });
    };

    cleanForm = () => {
        this.setState({ revocationCode: '', revocationReason: '', observations: '', acceptTerms: false });
    };

    hideModal = () => {
        this.cleanForm();
        this.props.toggleModal('modalRevocationForm');
        this.revocationFormValidator.hideMessages();
    };

    resendCode = () => {
        this.props.generateRevocationCode(this.props.selectedProduct.assignmentID, this.props.history);
    };

    onSubmit = (e) => {
        e.preventDefault();

        let { revocationCode, revocationReason, observations } = this.state;
        let { selectedProduct } = this.props;

        let data = { assignmentID: selectedProduct.assignmentID, revocationCode, revocationReason, observations };

        this.props.revokeCertificate(data, this.props.history);
    };

    render() {
        return (
            <div>
                <ModalForm isOpen={ this.props.showModal } title="REVOCACIÓN CERTIFICADO" toggle={e => this.hideModal()}>
                    <form onSubmit={e => this.onSubmit(e)}>
                        <div className="row">
                            <div className = "col-md-12 text-center">
                                <div className = "text-center product-info">
                                    <div className = "descriptionProduct">
                                        <p className="modal-msg-large">Solicitud generada exitosamente, <br/>
                                            para continuar con la revocación, deberás ingresar el <span className="green-text">código de seguridad</span> que fue enviado a tu correo electrónico.</p>
                                    </div>
                                </div>
                                {this.props.selectedProduct.leftDaysToExpire < 30 &&
                                    <div className="text-red text-center">El certificado cuenta con menos de 30 días de vigencia, por lo tanto el certificado quedara revocado sin posibilidad de reposición.</div>
                                }
                                <hr/>
                            </div>

                            <div className="d-grid col-md-6">
                                <InputForm
                                    id="revocationCode"
                                    type={"text"}
                                    maxLength="10"
                                    label={"Código de seguridad para la revocación"}
                                    placeholder={"Código de seguridad para la revocación"}
                                    className="form-control text-input"
                                    onChange={e => this.onChangeInput(e)}
                                    value={this.state.revocationCode}
                                    validator={this.revocationFormValidator}
                                    validateOptions={'required|revocationCode'}
                                />
                                <div className="resend-advice">No me ha llegado nada - <a href="#" onClick={e => this.resendCode()}><span className="green-text">Reenviar Código</span></a></div>
                            </div>

                            <div className="d-grid col-md-6">
                                <SelectForm
                                    id="revocationReason"
                                    label={"Causal de Revocación"}
                                    placeholder={"Seleccione"}
                                    className="form-control text-input"
                                    onChange={e => this.onChangeInput(e)}
                                    value={this.state.revocationReason}
                                    options={this.state.revocationReasons}
                                    validator={this.revocationFormValidator}
                                    validateOptions={'required'}
                                />
                            </div>

                            <div className="d-grid col-md-12">
                                <AreaTextForm
                                    id="observations"
                                    label={"Observación"}
                                    placeholder={"Observación"}
                                    className="form-control text-input"
                                    onChange={e => this.onChangeInput(e)}
                                    value={this.state.observations}
                                    validator={this.revocationFormValidator}
                                    validateOptions={'required'}
                                />
                            </div>

                            <div className="col-md-12">
                                <TermsAndConditions termsAndConditions={this.state.termsAndConditions}/>

                                <div className="form-group">
                                    <div className="custom-control custom-switch">
                                        <input
                                            id="acceptTerms"
                                            name="acceptTerms"
                                            type="checkbox"
                                            className="custom-control-input"
                                            checked={this.state.acceptTerms}
                                            value={this.state.acceptTerms}
                                            onChange={e => this.onChangeCheckbox(e)}
                                        />
                                        <label className="custom-control-label" htmlFor="acceptTerms">Acepto los términos y condiciones</label>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12">
                                <hr/>

                                <div className="row">
                                    <div className="col-md-6 text-right">
                                        <a className="btn btn-outline-success btn-submit-product" href="#" onClick={e => this.hideModal()}>
                                            <img className="input-icon" src={SuspendIcon} alt="Cancelar revocación"/>{' '}
                                            Cancelar revocación
                                        </a>
                                    </div>
                                    <div className="col-md-6 text-left">
                                        <button type="submit" className="btn btn-outline-success btn-submit-product" disabled={!this.state.filledForm}>
                                            <img className="input-icon" src={ActiveIcon} alt="Revocar certificado"/>{' '}
                                            Revocar certificado
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </ModalForm>

                <ModalAlert
                    showModal={this.state.modalAlert}
                    type={this.state.revocationServerStatus}
                    title="REVOCACIÓN CERTIFICADO"
                    message={this.state.revocationServerMessage}
                    toggle={this.toggleAlertModal}
                />
            </div>
        );
    };
}

RevocationCodeForm.propTypes = {
    reassignCertificate: PropTypes.func.isRequired,
    resetAdminProductsReducer: PropTypes.func.isRequired,
    generateRevocationCode: PropTypes.func.isRequired,
    revokeCertificate: PropTypes.func.isRequired,
    getTermsAndConditions: PropTypes.func.isRequired,
    adminProductsReducer: PropTypes.object.isRequired    
};

const mapStateToProps = (state) => ({
    adminProductsReducer: state.adminProductsReducer
});

export default connect(mapStateToProps, { reassignCertificate, resetAdminProductsReducer, generateRevocationCode, revokeCertificate, getTermsAndConditions})(RevocationCodeForm);
