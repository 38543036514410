import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { API_ENDPOINT } from "../../config/config";
import {
    getDetail,
    removeProduct,
    updateProductsList,
    sendQuotation,
    modifyCart,
    updateTotal,
    updateQuantityProduct
} from '../../actions/shoping_cartActions';

import Product from './Shop-product-card';
import { formatToThousand, calculateIvaTax } from '../../helpers/functions';

import addProduct from '../../assets/img/icons/addProducts.png';
import AlertIcon from '../../assets/img/icons/alert/alert.png';
import deleted from '../../assets/img/icons/delete.png';

import ModalForm from '../../components/ModalForm/ModalForm';
import ModalMsg from '../../components/ModalMsg/ModalMsg';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';


/**
 * Detail sale products Component( list of products that the customer will buy)
 *
 * @export Class Component
 * @class Detail_sale_products
 * @extends {Component}
 * @returns Redux connect
 */

export class Detail_sale_products extends Component {

    constructor(props) {
        super(props);

        this.state = {
            products: [],
            totals: [],
            mount: 0,
            taxIva: 0,
            showBilling: false,
            showAttachment: false,
            showAddProdsBtn: true,
            client: null,
            clientID: this.props.clientID,
            modalFormChangeQuot: [],
            modalMsg: [],
            noneLockedProduct: true
        };

        if (this.props.clientID) {
            this.props.getDetail(`/product/getDetail/client/${this.props.clientID}`);
        } else {
            this.props.getDetail(`/product/getDetail/`);
        }

        this.addProducts = this.addProducts.bind(this);
    }

    async componentWillReceiveProps(nextProps) {
        if (nextProps.shoppingCartReducer.successGetProducts) {
            let totals = [];
            let mount = this.state.mount;
            for (let i = 0; i < nextProps.shoppingCartReducer.products.productsList.length; i++) {                
                const product = nextProps.shoppingCartReducer.products.productsList[i];
                totals.push(product.quantity * product.unitPrice);
                if (!product.productID.editable) this.setState({ showAddProdsBtn: false, noneLockedProduct: false });
                else if (product.delivery === 'P10' && product.origin === 'GSE_CONTROL' ) this.setState({ showAddProdsBtn: false, noneLockedProduct : false });
                //verificar que no sea editable(DIAN), o que si lo es, que su delivery no sea P10 creado en GSE
            }
            for (let j = 0; j < totals.length; j++) {
                mount += totals[j];
            }
            
            await this.setState({
                products: nextProps.shoppingCartReducer.products.productsList,
                totals: totals,
                mount: mount,
                taxIva: calculateIvaTax(mount)
            });

            this.props.updateTotal(mount);

            if (nextProps.shoppingCartReducer.client) {
                this.setState({
                    client: nextProps.shoppingCartReducer.client
                });
            }

        } else if (nextProps.shoppingCartReducer.successDeleteProduct) {
            const data = nextProps.shoppingCartReducer.deleteProductData;
            let totals = [];
            let sum = 0;
            let productsArray = data.productsArray;
            for (let i = 0; i < productsArray.length; i++) {
                totals.push(productsArray[i].quantity * productsArray[i].unitPrice);
            }
            for (let j = 0; j < totals.length; j++) {
                sum = sum + totals[j];
            }
            this.setState({
                products: productsArray,
                totals: totals,
                mount: sum,
                taxIva: calculateIvaTax(sum),
                showAttachment: false,
                showBilling: false
            });

            this.props.updateTotal(sum);

            this.clearForm();
        } else if (nextProps.shoppingCartReducer.successUpdateProduct) {
            const data = nextProps.shoppingCartReducer.updateProductData
            this.setState({
                products: data.productList
            })
        }
    }

    acceptMethods = (openModalMsg) => {
        this.openModalMsg = openModalMsg;
    };

    clearForm() {
        this.setState({
            firstName: '',
            lastName: '',
            cellPhoneNumber: '',
            email: '',
            documentNumber: '',
            documentType: ''
        });
    }

    toggleModalFormChangeQuot = () => {
        this.setState({ modalFormChangeQuot: { show: !this.state.modalFormChangeQuot.show } });
    };

    execModalFormChangeQuot = (type, objectID, value) => {
        this.setState({
            modalFormChangeQuot: { show: true, type, objectID, value, title: 'NOTIFICACIÓN DE SUSPENSIÓN' }
        });
    };

    submitModalFormChangeQuot() {
        if (this.state.modalFormChangeQuot.type === 'add_prod') {
            this.addProducts();
        } if (this.state.modalFormChangeQuot.type === 'delete_prod') {
            this.removeProduct(this.state.modalFormChangeQuot.objectID);
        } if (this.state.modalFormChangeQuot.type === 'update_prod') {
            this.countValue(this.state.modalFormChangeQuot.value, this.state.modalFormChangeQuot.objectID);
        }
        this.setState({ modalFormChangeQuot: { show: false } });
        this.props.modifyCart();
    }

    countValue(quantity, key) {
        let products = this.state.products;
        let totals = this.state.totals;
        let sum = 0;

        this.updateQuantityProduct(products[key]);

        products[key].quantity = parseInt(quantity);
        totals[key] = products[key].quantity * products[key].unitPrice;
        for (var i = 0; i < totals.length; i++) {
            sum = sum + totals[i];
        }
        this.setState({
            products: this.state.products,
            totals: this.state.totals,
            mount: sum,
            taxIva: calculateIvaTax(sum),
            showAttachment: false,
            showBilling: false
        });
        this.clearForm();
    }

    updateQuantityProduct(updateProduct){

        let obj = {
            updateProduct: updateProduct,
            clientID: this.state.client === null ?
                null : this.state.client._id
        };
        this.props.updateQuantityProduct(obj);
    }

    removeProduct(deleteProduct) {
        let obj = {
            deleteProduct: deleteProduct,
            clientID: this.state.client === null ?
                null : this.state.client._id
        };
        this.props.removeProduct(obj);
    }

    addProducts = (e) => {
        this.state.clientID ? this.props.history.push('/products/' + this.state.clientID) : this.props.history.push('/products');
    };

    updateProducts() {
        let obj = {
            products: this.state.products,
            mount: this.state.mount,
            taxIva: this.state.taxIva,
            clientID: this.props.clientID === null
                ? null
                : this.props.clientID
        };
        this.props.updateProductsList(obj);
    }

    sendQuotation = (event) => {
        event.preventDefault();
        let obj = {
            clientID: this.props.clientID === null ?
                null : this.props.clientID
        };

        this.props.sendQuotation(obj);
    };

    notifyNotEditable = () => {
        this.setState({
            modalMsg: {
                title: 'Error',
                label: 'Señor usuario, este certificado fue solicitado por medio de la plataforma DIAN, no es posible modificar sus características',
                type: 'alert',
            }
        });
        this.openModalMsg();
    };

    render() {
        let url = API_ENDPOINT;
        return (
            < Fragment >
                <div className="table-responsive">
                    {this.state.products.length > 0 ?
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th className="text-center" scope="col">Producto</th>
                                    {this.props.clientFullFeatures === true ?
                                        <th className="text-center" scope="col">Precio Und.</th> : null}
                                    <th className="text-center" scope="col">Cantidad</th>
                                    {this.props.clientFullFeatures === true ?
                                        <th className="text-center" scope="col">Total</th> : null}
                                    <th className="text-center" scope="col">Eliminar</th>
                                </tr>
                            </thead>
                            <tbody className="text-center">
                                {this.state.products.map((product, key) =>
                                    <tr key={key} className={this.state.noneLockedProduct === true ? '' : 'bg-light'}>
                                        <td className="minW-18">
                                            <Product nameProduct={product.productID.productName}
                                                deliveryTime={product.deliveryTime}
                                                deliveryMedium={product.delivery}
                                                validity={product.validity} reAsign={product.reAsign}
                                                iconProduct={url + product.productID.icon} />
                                        </td>
                                        {this.props.clientFullFeatures === true ?
                                            <td>
                                                <p className="priceUnity">${formatToThousand(product.unitPrice)}</p>
                                            </td>
                                            : null}
                                        <td>
                                            <input
                                                onChange={(ev) => {
                                                    this.state.noneLockedProduct === true ?
                                                        this.props.showAttachment ? this.execModalFormChangeQuot('update_prod', key, ev.target.value) : this.countValue(ev.target.value, key)
                                                        : this.notifyNotEditable();
                                                }}
                                                value={product.quantity}
                                                type="number"
                                                className={`quantity-products ${this.state.noneLockedProduct === true ? '' : 'bg-light text-muted'}`}
                                                min="1"
                                                max="500"
                                                onKeyDown={(e) => { if (e.keyCode !== 38 && e.keyCode !== 40) { e.preventDefault() } }}
                                            />
                                        </td>
                                        {this.props.clientFullFeatures === true ?
                                            <td>
                                                <p className="priceUnity">${formatToThousand(this.state.totals[key])}</p>
                                            </td>
                                            : null}
                                        <td>
                                            <div className="text-center">
                                                <div id="id" className="delete-product">
                                                    <img src={deleted} alt="delete product" onClick={() => {
                                                        this.state.noneLockedProduct === true ?
                                                            this.props.showAttachment ? this.execModalFormChangeQuot('delete_prod', product._id) : this.removeProduct(product._id)
                                                            : this.notifyNotEditable();
                                                    }} />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        :
                        <div className="text-center font-weight-bold txt-prod-nf">No se han agregado productos al carrito</div>}
                </div>
                <hr />
                <div className="col-12">
                    <div className="row mb-3">
                        <div className="col-md-6 offset-1">
                            { this.state.showAddProdsBtn &&
                                <button type="button" className="btn btn-add-product" onClick={() =>
                                    this.props.showAttachment ? this.execModalFormChangeQuot('add_prod', null) : this.addProducts()
                                }>
                                    <img src={addProduct} className='icon-btn-outline-warning' alt="addProduct" />
                                    Agregar más productos
                                </button>
                            }
                        </div>
                        {this.props.clientFullFeatures ?
                            <div className="col-md-4">
                                <div className="row total">
                                    <div className="col-md-12 text-right">
                                        <p>Total: <span
                                            className="total-price">${formatToThousand(this.state.mount)}</span>
                                            <br /><span className="font-weight-lighter">A este valor será cargado el costo del IVA</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            :
                            this.state.products.length > 0 ?
                                <div className="col-md-4">
                                    <div className="col-xs-12 col-sm-6 text-center">
                                        <PrimaryButton
                                            color={"green"}
                                            txtBtn={"Generar Solicitud"}
                                            sizeWidth={""}
                                            className={"with-icon"}
                                            icon={"Buy"}
                                            onPress={e => this.updateProducts()}
                                        >
                                        </PrimaryButton>
                                    </div>
                                </div>
                                : null
                        }
                    </div>
                </div>

                {(!this.props.showAttachment && this.state.products && this.state.products.length > 0 && this.props.clientFullFeatures) ?
                    <div>
                        <div className="row mb-4">
                            <div className="col-xs-12 col-sm-6 text-center">
                                <PrimaryButton
                                    color={"green"}
                                    txtBtn={"Enviar Cotización"}
                                    sizeWidth={""}
                                    className={"with-icon"}
                                    icon={"Quotation"}
                                    onPress={(e) => this.sendQuotation(e)}
                                >
                                </PrimaryButton>
                            </div>
                            <div className="col-xs-12 col-sm-6 text-center">
                                <PrimaryButton
                                    color={"green"}
                                    txtBtn={"Pagar"}
                                    sizeWidth={""}
                                    className={"with-icon"}
                                    icon={"Buy"}
                                    onPress={e => this.updateProducts()}
                                >
                                </PrimaryButton>
                            </div>
                        </div>
                    </div>
                    : null}
                <ModalForm
                    title={this.state.modalFormChangeQuot.title}
                    isOpen={this.state.modalFormChangeQuot.show}
                    toggle={this.toggleModalFormChangeQuot}
                    label={this.state.modalFormChangeQuot.label}
                >
                    <div>
                        <div className="text-center">
                            <img className="imgSucces" src={AlertIcon} alt="icon delivery" />
                            <p className="modal-msg">Señor usuario, usted <span className="green-text">generó</span> una orden de pago con las especificaciones actuales del carrito de compras, si realiza alguna
                                <span className="green-text"> modificación</span> (cantidad, eliminar, o agregar productos), la orden de pago anterior quedará <span className="green-text">obsoleta</span> y tendrá que generar una <span className="green-text">nueva.</span></p>
                        </div>
                    </div>
                    <div>
                        <hr className="hl" />
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-6 text-center">
                                    <button type="submit" className="btn btn-outline-success" onClick={e => this.submitModalFormChangeQuot()}>Modificar carrito</button>
                                </div>
                                <div className="col-md-6 text-center">
                                    <button type="submit" className="btn btn-outline-success" onClick={e => this.toggleModalFormChangeQuot()}>Conservar actual</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalForm>
                <ModalMsg
                    shareMethods={this.acceptMethods}
                    title={this.state.modalMsg.title}
                    show={this.state.modalMsg.show}
                    label={this.state.modalMsg.label}
                    type={this.state.modalMsg.type}
                    redirect={this.state.modalMsg.redirect}
                    history={this.props.history}
                >
                </ModalMsg>
            </Fragment>
        )
    }
}

Detail_sale_products.propTypes = {
    getDetail: PropTypes.func.isRequired,
    removeProduct: PropTypes.func.isRequired,
    updateProductsList: PropTypes.func.isRequired,
    sendQuotation: PropTypes.func.isRequired,
    modifyCart: PropTypes.func.isRequired,
    updateTotal: PropTypes.func.isRequired,
    shoppingCartReducer: PropTypes.object.isRequired,
    errorReducer: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    shoppingCartReducer: state.shoppingCartReducer,
    errorReducer: state.errorReducer
})
export default connect(mapStateToProps, {
    getDetail,
    removeProduct,
    updateProductsList,
    sendQuotation,
    modifyCart,
    updateTotal,
    updateQuantityProduct
})(Detail_sale_products)