import React, { Component } from "react";
import { connect } from 'react-redux';
import './footer.css'
import logo from '../../assets/img/poweredbygse.png'

/**
 * Class Footer ( component for page header, make a top Footer in application )
 *
 * @export redux connect
 * @class Footer
 * @extends {Component}
 */
class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            maxWidth: "70%"
        };
    }
    
	async componentDidMount() {
        this.getWidth();
	}

    getWidth = () => {
        let containerCard = document.getElementsByClassName("cardbody card");
         this.setState({maxWidth: containerCard[0].style.maxWidth})
    }

    render() {
        return (
            <div className="cst-footer-container pt-4">
                <footer className="card unset-card" id="footerId" style={{maxWidth: this.state.maxWidth}}>
                <div className="row">
                    <div className="col-6 text-left d-flex align-items-center">
                        <span className="font-weight-bold cst-text-color">Vinculación Electrónica, v {this.props.footerData.version}</span>
                    </div>
                    <div className="col-6 text-right align-center d-flex flex-row-reverse">
                        <img className="footer-logo" src={logo} alt="poweredbygse" />
                    </div>
                </div>
            
                <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
                    <li className="ms-3"><a className="text-muted" href="#"><svg className="bi" width="24" height="24"></svg></a></li>
                    <li className="ms-3"><a className="text-muted" href="#"><svg className="bi" width="24" height="24"></svg></a></li>
                    <li className="ms-3"><a className="text-muted" href="#"><svg className="bi" width="24" height="24"></svg></a></li>
                </ul>
                </footer>
            </div>
        );
    }
}


Footer.propTypes = {

};

export default connect(null, { })(Footer);
