import axios from 'axios';
import { API_ENDPOINT, defaultResponses } from "../config/config";
import {
    GET_PRODUCTS,
    SHOW_PRODUCT_DATA,
    GET_ERRORS,
    CLEAR_ERRORS,
    CLEAR_AUTH,
    SET_PRODUCT,
    RESET_SENT_INVOICE_FLAG,
    CLEAR_PRODUCT
} from "./types";

 /**
 * ProductsAction getProducts ( get Available products list, the isPurchased status for the client, 
 * this last for activate the Admin Products Button ), and the client (optional attribute)
 *
 * @method GET
 * @param {*} clientID
 */
export const getProducts = (clientID = null) => async(dispatch) => {
    let PRODUCTS_URL = `${API_ENDPOINT}/product/` + (clientID ? `client/${clientID}` : ``);
    await axios.get(PRODUCTS_URL)
        .then(res => {
            dispatch({
                type: GET_PRODUCTS,
                payload1: res.data.products,
                payload2: res.data.isPurchased,
                payload3: res.data.client
            });
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: !!err.response
                ? err.response.data
                : {message: defaultResponses.noResponseFromApi}
            })
        });
};

/**
 *  ProductsAction showProductData ( set a product details object )
 *
 * @param {*} product
 */
export const showProductData = (product) => async(dispatch) => {
    dispatch({
        type: SHOW_PRODUCT_DATA,
        payload: product
    });
};


/**
 * AuthAction setProduct ( set product for client shopping cart addition )
 *
 * @method POST
 * @param {*} data
 */
export const setProduct = (data) => async(dispatch) => {
    dispatch({ type: RESET_SENT_INVOICE_FLAG });

    await axios.post(API_ENDPOINT + '/product', data)
        .then(res => {
            dispatch({
                type: SET_PRODUCT,
                payload: res.data
            });
            dispatch({ type: CLEAR_ERRORS });
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: !!err.response
                ? err.response.data
                : {message: defaultResponses.noResponseFromApi}
            });
            dispatch({ type: CLEAR_AUTH });
        });
};


/**
 * AuthAction clearErrors ( set void error reducer )
 *
 * @method POST
 * @param {*} 
 */
export const clearErrors = () => async(dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
};

export const clearProduct = () => async(dispatch) => {
    dispatch({ type: CLEAR_PRODUCT });
};
