import React from 'react';


export const reactValidatorOptions = {
    element: (message) => <div className="font-validator-red float-left">{message}</div>,
    validators: {
        password: {  // name the rule
          message: 'El campo :attribute debe tener al menos 1 mayúscula, minúscula, número, y opcional un caracter especial, no puede contener espacios.',
          rule: (val, params, validator) => {
            //regex params (1 number)(1 a-z)(1 A-Z)(1 special char)(not contain special chars(space))
            return validator.helpers.testRegex(val,/^((?=.*?[0-9])(?=.*?[a-z])(?=.*?[A-Z])(?!.*?[ ])).+$/i)
          },
          required: true  // optional
        },
        documentNumber: {
            message: 'El formato del :attribute no es válido',
            rule: (val, params, validator) => {
                return validator.helpers.testRegex(val, /^\d{5,15}$/);
            }
        },
        names: {
            message: 'El formato del :attribute no es válido',
            rule: (val, params, validator) => {
                return validator.helpers.testRegex(val, /^[A-z áéíóúüñÁÉÍÓÚÜÑ]{3,50}$/);
            }
        },
        onlyTextEmail: {
            message: 'El formato de :attribute no es válido',
            rule: (val, params, validator) => {
                return validator.helpers.testRegex(val, /^[A-z0-9-.@_]{10,100}$/);
            }
        },
        number: {
            message: 'El formato del :attribute no es válido, solo se aceptan números',
            rule: (val, params, validator) => {
                return validator.helpers.testRegex(val, /^(\(?\+?[0-9]*\)?)?[0-9_\- ()]*$/);
            }
        },
        passportFormat: {
            message: 'El formato de :attribute no es válido',
            rule: (val, params, validator) => {
                return validator.helpers.testRegex(val, /^(?!^0+$)[a-zA-Z]*[0-9]+[a-zA-Z]*$/);
            }
        },
        contractNumber: {
            message: 'El formato del :attribute no es válido',
            rule: (val, params, validator) => {
                return validator.helpers.testRegex(val, /^\w{1,30}$/);
            }
        },
        revocationCode: {
            message: 'El formato del :attribute no es válido',
            rule: (val, params, validator) => {
                return validator.helpers.testRegex(val, /^\w{10,10}$/);
            }
        },
        username: {
            message: 'El nombre de usuario no es válido, no puede contener espacios en blanco',
            rule: (val, params, validator) => {
                return validator.helpers.testRegex(val, /^[a-zA-Z0-9._-]{5,20}$/);
            }
        },
        legalname: {
            message: 'El nombre o razón social no es válido',
            rule: (val, params, validator) => {
                return validator.helpers.testRegex(val, /^[A-Za-z0-9 áéíóúüñÁÉÍÓÚÜÑ(),;.¡!¿?&#-]{3,300}$/);
            }
        },
        address: {
            message: 'El formato de dirección no es válido',
            rule: (val, params, validator) => {
                return validator.helpers.testRegex(val, /^[A-Za-z0-9 áéíóúüñÁÉÍÓÚÜÑ,.#°º-]{5,300}$/);
            }
        },
        jobName: {
            message: 'El formato del :attribute no es válido',
            rule: (val, params, validator) => {
                return validator.helpers.testRegex(val, /^[A-z áéíóúüñÁÉÍÓÚÜÑ]{3,50}$/);
            }
        }
      },
    messages: {
        required: 'Campo obligatorio.',
        email: 'El formato de :attribute no es válido.',
        min: 'El campo :attribute debe tener al menos :min caracteres.',
        max: 'El campo :attribute debe tener :max caracteres como máximo.',
        numeric: 'El campo :attribute debe ser un número.',
        alpha: 'El formato de :attribute no es válido.',
        alpha_num: 'El formato de :attribute no es válido.',
        alpha_num_space: 'El formato de :attribute no es válido.'
    }
};
