import React from "react";
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { API_ENDPOINT } from "../../config/config";

import { getForwardingCredentialMail } from "../../actions/userActions";

import { managementNames } from '../../helpers/managementNames';

import CloseIcon from '../../assets/img/icons/close.png';
import Email from '../../assets/img/icons/icon-reenviar-correo.png';


const ModalCertificate = ({ showModal, title, selectedProduct, toggleModal, notificationToSupervisor, handleSendCredentials, credentialsConfirm, sendCredentials, getForwardingCredentialMail }) => {

    return (
        <Modal isOpen={showModal}>
            <ModalHeader className="header-modal text-center">
                {title}
                <Button className="modal-close" onClick={() => toggleModal('modalCertificate')}><img src={CloseIcon} alt="icon product" />
                </Button>
            </ModalHeader>

            <ModalBody>
                <div className="container">
                    <div className="row">
                        <div className="col-10 offset-1 text-center">
                            <img src={API_ENDPOINT + selectedProduct.icon} alt="Logo Detalle Certificado" />
                            <br /><br />
                            <h5>{selectedProduct.productName}</h5>
                            <br /><br />
                            <div className="row">
                                <div className="col-6">
                                    <table style={{ width: '100%', border: 'none', textAlign: 'left' }}>
                                        <tbody>
                                            <tr className="row">
                                                <td className="col-md-6"><strong>Referencia</strong></td>
                                                <td className="col-md-6">{selectedProduct.reference}</td>
                                            </tr>
                                            <tr className="row">
                                                <td className="col-md-6"><strong>Suscriptor</strong></td>
                                                <td className="col-md-6">{selectedProduct.subscriber}</td>
                                            </tr>
                                            <tr className="row">
                                                <td className="col-md-6"><strong>Tipo de Documento</strong></td>
                                                <td className="col-md-6">{selectedProduct.documentType}</td>
                                            </tr>
                                            <tr className="row">
                                                <td className="col-md-6"><strong>Documento</strong></td>
                                                <td className="col-md-6">{selectedProduct.document}</td>
                                            </tr>
                                            <tr className="row">
                                                <td className="col-md-6"><strong>Celular</strong></td>
                                                <td className="col-md-6">{selectedProduct.cellPhone}</td>
                                            </tr>
                                            <tr className="row">
                                                <td className="col-md-6"><strong>Entrega</strong></td>
                                                <td className="col-md-6">{selectedProduct.delivery}</td>
                                            </tr>
                                            <tr className="row">
                                                <td className="col-md-6"><strong>Tipo de uso</strong></td>
                                                <td className="col-md-6">{selectedProduct.productID ? managementNames(selectedProduct.productID.management) : '---'}</td>
                                            </tr>
                                            <tr className="row">
                                                <td className="col-md-6"><strong>Serial</strong></td>
                                                <td className="col-md-6">{selectedProduct.gseSerial}</td>
                                            </tr>
                                            <tr className="row">
                                                <td className="col-md-6"><strong>Tiempo Entrega</strong></td>
                                                <td className="col-md-6">{selectedProduct.deliveryTime}</td>
                                            </tr>
                                            <tr className="row">
                                                <td className="col-md-6"><strong>Correo Electrónico</strong></td>
                                                <td className="col-md-6">{selectedProduct.subscriberEmail}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-6">
                                    <table style={{ width: '100%', border: 'none', textAlign: 'left' }}>
                                        <tbody>
                                            <tr className="row">
                                                <td className="col-md-6"><strong>Vigencia</strong></td>
                                                <td className="col-md-6">{selectedProduct.validity}</td>
                                            </tr>
                                            <tr className="row">
                                                <td className="col-md-6"><strong>Caducidad</strong></td>
                                                <td className="col-md-6">{selectedProduct.statusProcess !== 'Verificado' ? selectedProduct.expiration : ''}</td>
                                            </tr>
                                            <tr className="row">
                                                <td className="col-md-6"><strong>Departamento Entrega</strong></td>
                                                <td className="col-md-6">{!!selectedProduct.deliveryDepartment ? selectedProduct.deliveryDepartment : (!!selectedProduct.invoiceData && !!selectedProduct.invoiceData.department) ? selectedProduct.invoiceData.department : '---'}</td>
                                            </tr>
                                            <tr className="row">
                                                <td className="col-md-6"><strong>Municipio Entrega</strong></td>
                                                <td className="col-md-6">{!!selectedProduct.deliveryCity ? selectedProduct.deliveryCity : (!!selectedProduct.invoiceData && !!selectedProduct.invoiceData.city) ? selectedProduct.invoiceData.city : '---'}</td>
                                            </tr>
                                            <tr className="row">
                                                <td className="col-md-6"><strong>Dirección de Entrega</strong></td>
                                                <td className="col-md-6">{(!!selectedProduct.fromGse && selectedProduct.fromGse) ? 'Recoger en GSE' : !!selectedProduct.deliveryAddress ? selectedProduct.deliveryAddress : (!!selectedProduct.invoiceData && !!selectedProduct.invoiceData.address) ? selectedProduct.invoiceData.address : '---'}</td>
                                            </tr>
                                            <tr className="row">
                                                <td className="col-md-6"><strong>Canal de envío</strong></td>
                                                <td className="col-md-6">{!!selectedProduct.deliveryChannel ? selectedProduct.deliveryChannel : (!!selectedProduct.invoiceData && !!selectedProduct.invoiceData.deliveryChannel) ? selectedProduct.invoiceData.deliveryChannel : '---'}</td>
                                            </tr>
                                            <tr className="row">
                                                <td className="col-md-6"><strong>Correspondencia</strong></td>
                                                <td className="col-md-6">{!!selectedProduct.deliveryGuide ? selectedProduct.deliveryGuide : (!!selectedProduct.invoiceData && !!selectedProduct.invoiceData.deliveryGuide) ? selectedProduct.invoiceData.deliveryGuide : '---'}</td>
                                            </tr>
                                            {!!selectedProduct.revocationDate
                                                ? <tr className="row">
                                                    <td className="col-md-6"><strong>Fecha de Revocación</strong></td>
                                                    <td className="col-md-6">{selectedProduct.revocationDate}</td>
                                                </tr>
                                                : null
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="row col-12" style={{ marginTop: '20px', border: '3px solid #439c73', padding: '10px 15px 10px 20px' }}>
                                <div className="font-weight-bold">Enviar credenciales al Suscriptor</div>

                                <div id="notificationToSupervisor" className="form-group">
                                    <div className="custom-control custom-radio custom-control-inline">
                                        <input name="notificationToSupervisor"
                                            id="notificationToSupervisor_0"
                                            type="radio"
                                            className="custom-control-input"
                                            value="supervisor"
                                            checked={notificationToSupervisor === true}
                                            onChange={(value) => { handleSendCredentials(value) }}
                                        >
                                        </input>
                                        <label htmlFor="notificationToSupervisor_0" className="custom-control-label">No enviar credenciales al Suscriptor</label>
                                    </div>
                                    <div className="custom-control custom-radio custom-control-inline">
                                        <input name="notificationToSupervisor"
                                            id="notificationToSupervisor_1"
                                            type="radio"
                                            className="custom-control-input"
                                            value="subscriber"
                                            checked={notificationToSupervisor === false}
                                            onChange={(value) => { handleSendCredentials(value) }}
                                        >
                                        </input>
                                        <label htmlFor="notificationToSupervisor_1" className="custom-control-label">Si, enviar credenciales al Suscriptor</label>
                                    </div>
                                </div>

                                {credentialsConfirm === false && notificationToSupervisor === false &&
                                    <p style={{ textAlign: 'justify' }}>¿Desea que las credenciales sean enviadas al suscriptor? Si escoge que “Si” el suscriptor será quien ingrese a la plataforma de Vinkel a realizar el diligenciamiento de la información.</p>
                                }

                                {selectedProduct.assigned &&
                                    <div className="text-right offset-8" style={{ marginTop: '30px' }}>
                                        <button type="submit" className={"btn btn-outline-success"} disabled={credentialsConfirm ? true : false}
                                            onClick={function () {
                                                getForwardingCredentialMail(selectedProduct.assignmentID)
                                                toggleModal('forwardingCredentialMail')
                                            }}
                                        >
                                            <span>
                                                <img className="iconbutton" src={Email} alt="icon email" />
                                            </span>
                                            {" "} {sendCredentials === true ? 'Enviar' : 'Reenviar'} credenciales
                                    </button>
                                    </div>
                                }
                            </div>

                            <hr />

                            <table style={{ width: '100%', border: 'none', textAlign: 'center', marginBottom: '30px' }}>
                                <tbody>
                                    <tr className="row">
                                        <td className="col-md-6"><strong>Estado del proceso</strong></td>
                                        <td className="col-md-6">{!!selectedProduct.statusProcess ? selectedProduct.statusProcess : '----'}</td>
                                    </tr>
                                </tbody>
                            </table>
                            {console.log(selectedProduct, 'selectedProduct')}

                            {!!selectedProduct.statusHistory ?
                                <>
                                    <hr />
                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <thead>
                                            <tr className="row" style={{ backgroundColor: '#aba9a9', color: '#FFF', padding: '10px' }}>
                                                <td className="col-md-3" style={{ margin: 'auto' }}><strong>Fecha</strong></td>
                                                <td className="col-md-3" style={{ margin: 'auto' }}><strong>Causal de revocación</strong></td>
                                                <td className="col-md-3" style={{ margin: 'auto' }}><strong>Observaciones</strong></td>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr className="row" style={{ padding: '10px' }}>
                                                <td className="col-md-3" style={{ margin: 'auto' }}>{selectedProduct.statusHistory.FechaCambioEstado}</td>
                                                <td className="col-md-3" style={{ margin: 'auto' }}>{!!selectedProduct.statusHistory.causalText ? selectedProduct.statusHistory.causalText : selectedProduct.statusHistory.CausaRevocacion}</td>
                                                <td className="col-md-3" style={{ margin: 'auto' }}>{!!selectedProduct.statusHistory.observationText ? selectedProduct.statusHistory.observationText : selectedProduct.statusHistory.Observacion}</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </>
                                : null
                            }
                        </div>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
};

ModalCertificate.propTypes = {
    getForwardingCredentialMail: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, { getForwardingCredentialMail })(ModalCertificate)


