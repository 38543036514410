//set app name to all project
export const APP_NAME = process.env.name || '';

//token prefix eg. 'Bearer ' or 'Token '
export const TOKEN_PREFIX = '';

export const API_ENDPOINT = process.env.NODE_ENV === 'production'
    ? 'https://pre-api.vinkel.co' :
    'https://pre-api.vinkel.co';

/**
 * URLS DE RETORNO DE PAGUEATIEMPO PARA CADA AMBIENTE
 * QA: https://ecommerce.vinkel.co/payment
 * PRE: https://pre-api.vinkel.co/payment
 * PRODUCCIÓN: https://storeapi.vinkel.co/payment
 */
export const PAGUEATIEMPO_RETURN_URL = 'https://pre-api.vinkel.co/payment';
export const PAGUEATIEMPO_AUTOMATIC_RETURN_URL = `${PAGUEATIEMPO_RETURN_URL}/save`;
export const TERMS_CONDITIONS_URL = 'https://gse.com.co/wp-content/uploads/2020/10/Anexo-2-DPC-Terminos-Condiciones.pdf';

export const defaultResponses = {
    noResponseFromApi: 'No hay respuesta por parte del servidor. Por favor intente de nuevo más tarde.'
};

export const IVA_PERCENTAGE = 19;
export const RETEFUENTE_PERCENTAGE = 3.5;
export const RETEIVA_PERCENTAGE = 15;
export const RETEICA_PERCENTAGE = 0.69;
export const BOGOTA_CODE = 169011001;
export const REASSIGNMENT_VALUE = 83300;

export const SHOW_REASSIGN_BUTTON = true;

export const KEY_FILE_MAX_SIZE = 10;//in kB 
export const KEY_FILE_TYPES = ['application/pkcs10'];//allowed key file types
export const KEY_FILE_EXTS = ['req', 'csr','p10'];//allowed key file extensions, if key fole types does not support the extension
export const KEY_FILE_DELIVS = ['P10'];//deliveries that require a key file

export const PSE_TEST_MODE = true;
