import React from "react"
import PropTypes from "prop-types"
import './input-form.css'

import {
    regexPhone,
    regexDocument,
    regexAlphaSpace,
    regexAlphaNumSpace,
    regexNit,
    regexNumber,
    regexAddress,
    regexCheckUsername,
    regexLegalName,
    preventSpace
} from '../../helpers/validations'

import user from '../../assets/img/icons/user/user.png';
import number from '../../assets/img/icons/number.png';
import password from '../../assets/img/icons/password/password.png';
import cellphone from '../../assets/img/icons/cellphone.png';
import email from '../../assets/img/icons/email.png';
import entity from '../../assets/img/icons/entity.png';
import contractn from '../../assets/img/icons/contract-value.png';
import observations from '../../assets/img/icons/observations.png';
import position from '../../assets/img/icons/position/positionx2.png';
import view from '../../assets/img/icons/viewPass/view.png';
import notView from '../../assets/img/icons/viewPass/notView.png';


/**
 * InputForm constant ( generic HTML input for recursive implementation in forms )
 *
 * @param {*} {...props}
 * @returns HTML object
 */
const InputForm = ({...props}) => {
    const { icon, placeholder, type, maxLength, max, min, id, className, onBlur, onChange, onKeyDown, onPaste, value, label, disabled, validator, validateOptions, error, onClick, wording, viewPass, autoComplete} = props;

    const icons = {
        user,
        password,
        cellphone,
        email,
        number,
        entity,
        contractn,
        observations,
        position,
        view
    };

    const events = (e, type) => {
        if(type === 'alpha_space') regexAlphaSpace(e);
        if(type === 'document') regexDocument(e);
        if(type === 'number') regexNumber(e);
        if(type === 'phone') regexPhone(e);
        if(type === 'alpha_num_space') regexAlphaNumSpace(e);
        if(type === 'legal_name') regexLegalName(e);
        if(type === 'nit') regexNit(e);
        if(type === 'address') regexAddress(e);
        if(type === 'username') regexCheckUsername(e);
        if(type === 'email') preventSpace(e);
    };

    let errorMessage = null;
    if (validator && validateOptions) errorMessage = validator.message('', value, validateOptions);

    return (
        <div className="form-group">
            <div className="input-group">
                {
                    (icon && icon !== '') &&
                    <span className="input-group-addon">
                        <img className="input-group-icon" src={icons[icon]} alt={icons[icon]}/>
                    </span>
                }
                {(label && (label !== '')) &&
                <label htmlFor={id}
                       className={error || (errorMessage) ? "text-danger" : ""}>{label}</label>
                }
                <input
                    type={type}
                    className={`${className} ${error || (errorMessage) ? 'is-invalid' : ''} ${icon ? icon : ''}`}
                    value={value}
                    maxLength={maxLength}
                    min={min}
                    max={max}
                    placeholder={placeholder}
                    id={id}
                    onBlur={onBlur}
                    onChange={onChange}
                    onPaste={onPaste}
                    onKeyDown={(e) => events(e, onKeyDown)}
                    disabled={disabled}
                    autoComplete={autoComplete}
                />
                {disabled ?
                    null
                    : !!validator
                        ? validator.message(label !== '' && label !== undefined ? label : placeholder, value, validateOptions)
                        : null
                }
                {error ?
                    <div className="invalid-feedback">{error}</div>
                    : null
                }
                {viewPass === 'viewPass' 
                    ? <span className="password-trigger" onClick={onClick}>{wording === 'ver' ? <img className="viewPick" src={view} alt="view" /> : <img className="viewPick" src={notView} alt="notview" />}</span>
                    : null
                }
            </div>
        </div>
    )
};


InputForm.propTypes = {
    labelProps: PropTypes.object,
    id: PropTypes.string,
};

export default InputForm
