import axios from 'axios'
import {API_ENDPOINT, defaultResponses} from "../config/config";
import {
    CLEAR_ERRORS,
    FILTER_LIST_PRODUCT,
    FILTER_LIST_PRODUCT_SOLD,
    GET_ERRORS,
    GET_PENDING_FINANCIAL,
    SET_MODAL_SUCCESSFUL,
    GET_SIIGO_PENDING_INVOICES,
    FORWARD_SIIGO_INVOICE
} from "./types";

export const getPendingFinancial = () => async (dispatch) => {
    let PENDING_DATA_URL = `${API_ENDPOINT}/financial`;
    await axios.get(PENDING_DATA_URL)
        .then(res => {
            console.log(res.data.rejectSaleOptions,'<<<<<<<<<<<<<<')
            dispatch({
                type: GET_PENDING_FINANCIAL,
                payload1: res.data.listProduct,
                payload2: res.data.listProductSold,
                payload3: res.data.listUserEccommerce,
                payload4: res.data.rejectSaleOptions ? res.data.rejectSaleOptions.options : null
            });
            dispatch({type: CLEAR_ERRORS})
        })
        .catch(err => {
            console.log(err)
            dispatch({
                type: GET_ERRORS,
                payload: !!err.response
                    ? err.response.data
                    : {message: defaultResponses.noResponseFromApi}
            })
        });
};

export const postApprovePayment = (payId) => async (dispatch) => {
    let APPROVE_PAYMENT = `${API_ENDPOINT}/financial/approve`;
    await axios.post(APPROVE_PAYMENT, {productId: payId})
        .then(res => {
            dispatch({type: SET_MODAL_SUCCESSFUL});
            dispatch({type: CLEAR_ERRORS})
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: !!err.response
                    ? err.response.data
                    : {message: defaultResponses.noResponseFromApi}
            })
        });
};

export const rejectPayment = (productId, description, additionalDescription) => async dispatch => {
    let URL = `${API_ENDPOINT}/financial/reject`;

    await axios.post(URL, { productId, description, additionalDescription })
        .then(res => {
            dispatch({type: SET_MODAL_SUCCESSFUL});
            dispatch({type: CLEAR_ERRORS})
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: !!err.response
                    ? err.response.data
                    : {message: defaultResponses.noResponseFromApi}
            })
        });
};

export const setModalSuccessful = () => async (dispatch) => {
    dispatch({type: SET_MODAL_SUCCESSFUL});
};

export const postSuspendedUser = (type, suspended, idUser) => async (dispatch) => {
    let SUSPENDED_USER = `${API_ENDPOINT}/financial/suspended`;
    await axios.post(SUSPENDED_USER, {type, suspended, idUser})
        .then(res => {
            dispatch({type: SET_MODAL_SUCCESSFUL});
            dispatch({type: CLEAR_ERRORS})
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: !!err.response
                    ? err.response.data
                    : {message: defaultResponses.noResponseFromApi}
            })
        });
};

export const filterListProduct = (filteredData) => async (dispatch) => {
    dispatch({
        type: FILTER_LIST_PRODUCT,
        payload: filteredData,
    });
};

export const filterListProductSold = (filteredData) => async (dispatch) => {
    dispatch({
        type: FILTER_LIST_PRODUCT_SOLD,
        payload: filteredData,
    });
};

/**
 * FinancialAction getPendingSiigoInvoices ( get pending invoices to be registered in Siigo service )
 *
 * @method GET
 */
export const filterPendingSiigoInvoices = (data) => async dispatch => {
    dispatch({
        type: GET_SIIGO_PENDING_INVOICES,
        payload: data
    });
};

export const getPendingSiigoInvoices = () => async dispatch => {
    await axios.get(`${API_ENDPOINT}/financial/get-siigo-invoices`)
        .then(async res => {
            dispatch({
                type: GET_SIIGO_PENDING_INVOICES,
                payload: res.data.pendingInvoices
            });
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: !!err.response
                    ? err.response.data
                    : {message: defaultResponses.noResponseFromApi}
            });
        });
};

/**
 * FinancialAction forwardInvoice ( forward invoice to Siigo service )
 *
 * @method POST
 * @param {*} fullReference
 * @param {*} history
 */
export const forwardInvoice = (fullReference, history) => async dispatch => {
    await axios.post(`${API_ENDPOINT}/financial/forward-invoice`, { fullReference })
        .then(async res => {
            dispatch({
                type: FORWARD_SIIGO_INVOICE,
                payload: res.data
            });
        })
        .catch(err => {
            if (!!err.response.data && err.response.data.expiredToken) {
                dispatch({type: CLEAR_ERRORS, payload: null});
                history.push('/logout');
            } else {
                console.log('err.response.data: ', err.response.data)
                dispatch({
                    type: FORWARD_SIIGO_INVOICE,
                    payload: err.response.data
                });
            }
        });
};
