import {
    TOWN_LIST, DEPARTMENT_LIST, CLEAN_TOWNS
} from '../actions/types';

const initialState = {
    towns: [],
    departments: [],
    hasTowns: false,
    hasDepartments: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case TOWN_LIST:
            return {
                ...state,
                towns: action.payload,
                hasTowns: !state.hasTowns
            };
        case DEPARTMENT_LIST:
            return {
                ...initialState,
                departments: action.payload,
                hasDepartments: true
            };
        case CLEAN_TOWNS:
            return {
                ...state,
                towns: initialState.towns,
            };
        default:
            return state;
    }
}