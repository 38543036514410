export const managementNames = (id) => {
	switch (id) {
		case 0:
			return 'Normal'
		case 1:
			return 'Firma'
		case 2:
			return 'Autenticación'
		default:
			break;
	}
} 