import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { Button } from 'reactstrap';
import { Alert, Loader } from 'rsuite';
import MaterialTable, { MTableToolbar } from 'material-table';
import SimpleReactValidator from 'simple-react-validator';
import moment from 'moment';
import Select from 'react-select';
import Typography from '@material-ui/core/Typography'
import _ from 'lodash'

import '../../styles.css';
import './adminProducts.css';
import '../../components/CardBody/card_body.css';
import '../../components/ModalForm/modalForm.css';

import {
    changeStatusAgreement, deletePreLoadFromAssignment, generateReport, getAssignments, getGroupedAssignments,
    resetAdminProductsReducer, generateRevocationCode, getStatusHistory, voidCertificate, clearErrors
} from '../../actions/adminProductsActions';
import { getTown } from "../../actions/locationActions";
import { getUser } from '../../actions/userActions';

import { AntTabs, AntTab, TabPanel, useStyles } from '../../components/Tabs/Tabs';
import Card from '../../components/CardBody/CardBody';
import ModalForm from '../../components/ModalForm/ModalForm';
import ModalMsg from "../../components/ModalMsg/ModalMsg";
import ModalLoader from "../../components/ModalLoader/ModalLoader";
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';

import AssignmentForm from './assignmentForm/AssignmentForm';
import ModalAlert from './Modal_alert';
import ModalCertificate from './Modal_certificate';
import ModalFilters from './Modal_filters';
import ModalPreloadInfo from "./preloadInformation/ModalPreloadInfo";
import ModalFilterGroupedAssignment from './Modal_filter_grouped_assignment';
import ReassignmentForm from './reassignmentForm/Reassignment_form';
import RevocationCodeForm from './modals/RevocationCodeForm';

import Reassign from '../../assets/img/buttons/reAsign.png';
import Assign from '../../assets/img/buttons/asign.png';
import DisableAssign from '../../assets/img/buttons/disable-asign.png';
import Preload from '../../assets/img/buttons/preload.png'
import Delete from '../../assets/img/buttons/eliminar-informacion-precargada.png';
import DisablePreload from '../../assets/img/buttons/disable-preload.png'
import Check from '../../assets/img/icons/check/check.png';
import FilterIcon from '../../assets/img/icons/filter.png';
import AlertIcon from '../../assets/img/icons/alert/alert.png';
import RevokeIcon from '../../assets/img/icons/revoke.png';
import Reporting from '../../assets/img/icons/contract-number.png';

import { API_ENDPOINT, SHOW_REASSIGN_BUTTON } from '../../config/config';
import { PROTOCOL, USERNAME, PASSWORD, URL, CONFIG_FRONT } from '../../config/reporting-config';

import { reactValidatorOptions } from '../../helpers/simpleReactValidator';
import { managementNames } from '../../helpers/managementNames';
import { translateTimeUnit } from '../../helpers/functions';



function ReassignIcon() { return <img src={Reassign} alt="Reasignar" />; }

function AssignIcon() { return <img src={Assign} alt="Asignar" />; }

function DisableAssignIcon() { return <img src={DisableAssign} title="this will be displayed as a tooltip" alt="Asignar" />; }

function PreloadIcon() { return <img src={Preload} alt="Precargar Información" /> }

function DeleteIcon() { return <img src={Delete} alt="Borrar Información precargada" style={{ padding: 1 }} /> }

function DisablePreloadIcon() { return <img src={DisablePreload} title="this will be displayed as a tooltip" alt="Precargar Información" /> }

function GetRevokeIcon() {
    return <img src={RevokeIcon} alt="Precargar Información" />
}

/**
 * AdminProducts Component ( certificates list, allow to assign, re-assign and revoke certificates to users )
 *
 * @export Class Component
 * @class AdminProducts
 * @extends {Component}
 * @returns Redux connect
 */
class AdminProducts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalCertificate: false,
            modalAssignProduct: false,
            modalReassignProduct: false,
            modalPreloadInfo: false,
            modalAlert: false,
            modalFilters: false,
            modalFilterGroupedAssignment: false,
            modalToggleSuspend: false,
            modalConfirmSuspension: false,
            modalAgreementSuspended: false,
            modalRevocationForm: false,
            modalTitle: '',
            modalIcon: '',
            modalDescription: '',
            modalAlertTitle: '',
            modalAlertMessage: '',
            modalLouder: false,
            modalLouderTitle: '',
            modalLouderMessage: '',
            modalLouderType: '',
            assignments: [],
            groupedAssignments: [],
            assignmentsCopy: [],
            assignmentsPreload: [],
            groupedAssignmentsCopy: [],
            actualOperation: '',
            invoiceData: [],
            useInvoiceData: false,
            selectedProduct: {},
            firstName: '',
            lastName: '',
            email: '',
            cellPhone: '',
            documentType: '',
            documentNumber: '',
            reference: '',
            areEmptyFilters: true,
            errors: {},
            client: {},
            currentUser: {},
            currentUserRole: '',
            statusClient: '',
            isSuspended: false,
            suspendActivateReason: '',
            modalForm: [],
            assignmentsSelected: '',
            disableAgreement: '',
            statusUser: '',
            modalMsg: {
                title: 'Reenviar Asignación',
                show: true,
                label: 'Correo enviado',
                type: 'check',
            },
            selectedContract: '',
            selectedContractPlaceholder: 'Seleccione...',
            optionsContractNumber: [],
            revocationCauses: [],
            notificationToSupervisor: false,
            credentialsConfirm: false,
            sendCredentials: false,
            userID: '',
            clientType: 'Estándar',
            supervisorType: ''
        };

        reactValidatorOptions.validators.documentNumber = {
            message: 'El formato del :attribute no es válido',
            rule: (val, params, validator) => {
                return validator.helpers.testRegex(val, this.state.documentType === 'NIT' ? /^\d{5,15}$/ : this.state.documentType === 'PS' ? /^\w{5,15}$/ : /^\d{5,15}$/);
            }
        };

        this.generalValidator = new SimpleReactValidator(reactValidatorOptions);
        this.validatorToggleSuspend = new SimpleReactValidator(reactValidatorOptions);

        this.refreshAssignmentsTable();
    }

    async componentWillReceiveProps(nextProps) {
        let { userReducer, adminProductsReducer, errorReducer } = this.props;
        const { assignedCertificate, reassignedCertificate, voidCertificate, revokeEmailStatus, statusAgreementChanged,
            areEmptyFilters, revocationStatus } = nextProps.adminProductsReducer;

        if (userReducer.obtainedUser != null) {
            this.setState({
                userID: userReducer.obtainedUser._id,

            });
        }

        if (!!nextProps.userReducer.obtainedUser && (nextProps.userReducer.obtainedUser !== userReducer.obtainedUser)) {
            const { obtainedUser } = nextProps.userReducer;

            this.setState({
                currentUserRole: obtainedUser.role.name,
                statusUser: obtainedUser.status,
                clientType: obtainedUser.type
            });

            if (obtainedUser.type === 'Convenio' && obtainedUser.status === 'disable') {
                this.setState({ modalAgreementSuspended: true });
            }
        }

        if (!!nextProps.adminProductsReducer.client && (nextProps.adminProductsReducer.client !== adminProductsReducer.client)) {
            const { client } = nextProps.adminProductsReducer;

            this.setState({
                client,
                statusClient: client.status,
                isSuspended: client.suspended,
                disableAgreement: client.suspended === true ? 'disabled' : '',
                supervisorType: client.type
            });

            if (client.type === 'Convenio' && client.status === 'disable') {
                this.setState({ modalAgreementSuspended: true });
            }
        }

        if (
            !!nextProps.adminProductsReducer.assignments
            && (nextProps.adminProductsReducer.assignments !== adminProductsReducer.assignments)
        ) {
            let { contractNumberList } = nextProps.adminProductsReducer;
            let { assignments: newAssignments } = nextProps.adminProductsReducer;
            if (newAssignments && newAssignments !== [] && newAssignments.length > 0) {
                let assignments = newAssignments.map(ass => {
    
                    if (!!ass.notificationToSupervisor) this.setState({ notificationToSupervisor: ass.notificationToSupervisor, sendCredentials: ass.notificationToSupervisor });
                    return {
                        assigned: !!ass.subscriberID,
                        certificate: <div className="clickable"
                            onClick={() => this.toggleModal('modalCertificate', { assignmentID: ass._id })}>{ass.productID.productName}</div>,
                        assignmentID: ass._id,
                        status: ass.status,
                        productID: ass.productID,
                        productName: ass.productID.productName,
                        productProvider: ass.productID.provider,
                        company: ass.productID.company,
                        description: ass.productID.descriptionProduct,
                        icon: ass.productID.icon,
                        subscriber: !!ass.subscriberID ? `${ass.subscriberID.firstName} ${ass.subscriberID.lastName}` : '---',
                        document: (!!ass.subscriberID && !!ass.subscriberID.documentNumber) ? ass.subscriberID.documentNumber : '---',
                        documentType: (!!ass.subscriberID && !!ass.subscriberID.documentType) ? ass.subscriberID.documentType : '---',
                        subscriberEmail: (!!ass.subscriberID && !!ass.subscriberID.email) ? ass.subscriberID.email : '',
                        cellPhone: (!!ass.subscriberID && !!ass.subscriberID.cellPhone) ? ass.subscriberID.cellPhone : '',
                        delivery: ass.productID.delivery,
                        deliveryTime: ass.productID.deliveryTime,
                        validity: ass.productID.validity,
                        gseSerial: !!ass.gseSerial ? ass.gseSerial : '---',
                        deliveryDepartment: ass.deliveryDepartment,
                        deliveryCity: ass.deliveryCity,
                        deliveryAddress: ass.deliveryAddress,
                        deliveryChannel: ass.deliveryChannel,
                        deliveryGuide: ass.deliveryGuide,
                        management: managementNames(ass.productID.management),
                        reference: ass.code,
                        expiration: !!ass.productID.expiration ? moment(ass.productID.expiration).format('DD/MM/YYYY') : '---',
                        invoiceData: ass.invoiceID,
                        statusProcess: ass.statusProcess,
                        statusPro: ass.statusPro,
                        historicActions: ass.historicActions,
                        preloadID: ass.preloadID,
                        from: "preloadAssignment",
                        idProductGSE: ass.gseReference ? ass.gseReference : ass.productID.idProductGSE ?  ass.productID.idProductGSE : null,
                        fromGse: !!ass.fromGse ? ass.fromGse : null,
                        replacementAssignmentID: ass.replacementAssignmentID,
                        revocationDate: !!ass.revocationDate ? moment(ass.revocationDate).format('DD/MM/YYYY') : null,
                        leftDaysToExpire: !!ass.productID.expiration ? moment(ass.productID.expiration).diff(moment(), 'days') : 0,
                        reassignments: ass.productID.reAsign,
                        notificationToSupervisor: !!ass.notificationToSupervisor ? ass.notificationToSupervisor : this.state.notificationToSupervisor,
                        statusHistory: !!ass.statusHistory ? ass.statusHistory : null
                    }
                });
                let assignmentsPreload = [];
                for (let i = 0; i < assignments.length; i++) {
                    assignments[i].validity = assignments[i].productID.validity + (translateTimeUnit(assignments[i].productID.timeUnit, assignments[i].productID.validity > 1 ? "P":"S"))
                    if (assignments[i].preloadID && !assignments[i].assigned) {
                        assignmentsPreload.push(assignments[i])
                    }
                }
    
                assignments = _.remove(assignments, n => n.subscriber !== '---');
                await this.setState({ assignments, assignmentsCopy: assignments, assignmentsPreload, optionsContractNumber: contractNumberList })
            } else {
                let assignments = [];
                let assignmentsPreload = [];
                await this.setState({ assignments: [], assignmentsCopy: [], assignmentsPreload: [], optionsContractNumber: contractNumberList })
            }
        }

        if (!!nextProps.adminProductsReducer.reportPath && (nextProps.adminProductsReducer.reportPath !== adminProductsReducer.reportPath)) {
            window.open(`${API_ENDPOINT}/${nextProps.adminProductsReducer.reportPath}`);

            this.refreshTable();
        }

        if (nextProps.adminProductsReducer.statusHistory) {
            this.setState({
                selectedProduct: {
                    ...this.state.selectedProduct,
                    statusHistory: nextProps.adminProductsReducer.statusHistory
                }
            })
        }

        if (assignedCertificate === true || reassignedCertificate === true || voidCertificate === true) {
            this.setState({ firstName: '', lastName: '', email: '', cellPhone: '', documentType: '', documentNumber: '', useInvoiceData: false });
            this.refreshTable();
            Alert.success(`El certificado fue ${assignedCertificate ? 'asignado' : reassignedCertificate ? 'reasignado' : 'anulado'} exitosamente`);
            this.props.resetAdminProductsReducer();
        }

        if (!!revocationStatus && (revocationStatus !== adminProductsReducer.revocationStatus)) {
            this.refreshTable();
        }

        if (!!revokeEmailStatus && (revokeEmailStatus !== this.props.adminProductsReducer.revokeEmailStatus)) {
            if (revokeEmailStatus.success === true) {
                Alert.success(`El código de revocación fue enviado al correo ${revokeEmailStatus.email}`);

                let revocationCauses = [];
                for (let rc of revokeEmailStatus.revocationCauses) {
                    revocationCauses.push({ key: rc.code, val: rc.description, lbl: rc.description })
                }
                this.setState({ revocationCauses });

                if (!this.state.modalRevocationForm) this.toggleModal('modalRevocationForm');
            } else {
                Alert.error(revokeEmailStatus.message);
            }

            this.refreshTable();
            this.props.resetAdminProductsReducer();
        }

        if (statusAgreementChanged === true) {
            this.setState({
                modalToggleSuspend: !this.state.modalToggleSuspend,
                isSuspended: !this.state.isSuspended,
                modalConfirmSuspension: true,
                disableAgreement: 'disabled'
            });
            this.props.resetAdminProductsReducer();
        }

        if (areEmptyFilters !== adminProductsReducer.areEmptyFilters) {
            this.setState({ areEmptyFilters });
        }

        if (nextProps.adminProductsReducer.groupedAssignments !== adminProductsReducer.groupedAssignments) {
            let assignments = nextProps.adminProductsReducer.groupedAssignments.map(ass => {
                return {
                    certificate: ass.productID.productName,
                    assignmentID: ass._id,
                    productID: ass.productID,
                    productName: ass.productID.productName,
                    company: ass.productID.company,
                    description: ass.productID.descriptionProduct,
                    serial: ass.productID.serial,
                    icon: ass.productID.icon,
                    delivery: ass.productID.delivery,
                    deliveryTime: ass.productID.deliveryTime,
                    management: managementNames(ass.productID.management),
                    idProductGSE: ass.idProductGSE,
                    validity: ass.productID.validity + (ass.productID.validity > 1 ? translateTimeUnit(ass.productID.timeUnit,"P") : translateTimeUnit(ass.productID.timeUnit,"S")),
                    invoiceData: ass.invoiceID,
                    userID: ass.userID,
                    quantity: ass.quantity,
                    assigned: ass.assigned,
                    available: ass.available,
                    ref: ass.productID.ref,
                    availableAssignmentsIDs: ass.availableAssignmentsIDs
                }
            });

            await this.setState(() => ({ groupedAssignments: assignments, groupedAssignmentsCopy: assignments }))
            this.setState({ modalLouder: false });
        }

        if (nextProps.adminProductsReducer.createPreload !== adminProductsReducer.createPreload) {
            this.refreshTable()
        }

        if (!!nextProps.errorReducer && nextProps.errorReducer.hasErrors && (nextProps.errorReducer !== errorReducer)) {
            let { msg, message, data } = nextProps.errorReducer;
            this.setState({
                modalMsg: {
                    title: 'Error',
                    label: msg || message || data.message,
                    type: 'alert',
                    redirect: data.expiredToken ? 'refresh' : null
                }
            });
            this.openModalMsg();
            this.props.clearErrors()
        }
    }

    async componentDidMount() {
        if (localStorage.getItem('role') === 'Advisor' && !this.props.match.params.clientID) {
            this.props.history.push('/advisor');
        } else if (localStorage.getItem('role') === 'Financial' && !this.props.match.params.clientID) {
            this.props.history.push('/financial');
        } else {
            const isContributor = !this.props.match.params.clientID;
            this.props.getUser(isContributor);
        }
    }

    refreshTable = () => {
        this.setState({
            modalLouder: !this.state.modalLouder,
            modalLouderType: 'progress',
            modalLouderTitle: 'Realizando búsqueda, por favor espere.',
            modalLouderMessage: 'Realizando búsqueda, por favor espere.'
        })

        this.refreshAssignmentsTable();
        this.refreshGroupedTable();
    };

    refreshAssignmentsTable = () => {
        this.props.getAssignments(this.props.match.params.clientID, this.props.history, this.state.selectedContract);
    };

    refreshGroupedTable = () => {
        this.props.getGroupedAssignments(this.props.match.params.clientID, this.props.history, this.state.selectedContract);
    };

    onChangeCustomInput = () => e => {
        if (!!e.target.id) {
            this.setState({ [e.target.id]: e.target.value, errors: {} });
        } else {
            console.error('Error actualizando la informacion ingresada', 'error');
        }
    };

    toggleModal = async (modalType, selectedProduct = this.state.selectedProduct, validate = null, modalToToggle) => {
        if (validate === 'validate') {
            if (!this.generalValidator.allValid()) {
                this.generalValidator.showMessages();
                this.forceUpdate();
                return null
            } else {
                this.toggleAlertModal(modalToToggle);
            }
        }

        const modalIcon = `${API_ENDPOINT}${selectedProduct.icon}`;
        const modalDescription = selectedProduct.description;

        if (modalType === 'modalCertificate') {
            for (const a of this.state.assignments) {
                if (a.assignmentID === selectedProduct.assignmentID) {
                    console.log(selectedProduct);
                    this.props.getStatusHistory(selectedProduct.assignmentID, this.props.history);

                    this.setState({
                        selectedProduct: a,
                        [modalType]: !this.state[modalType]
                    })
                }
            }
        } else if (modalType === 'forwardingCredentialMail') {
            this.openModalMsg();
        } else if (modalType === 'revoke') {
            for (const a of this.state.assignments) {
                if (a.assignmentID === selectedProduct.assignmentID) {
                    await this.setState({ selectedProduct: a });
                    this.toggleAlertModal(modalType);
                }
            }
        } else {
            this.setState(() => {
                !this.state[modalType] && this.props.getTown();
                return {
                    selectedProduct, modalIcon, modalDescription,
                    [modalType]: !this.state[modalType]
                }
            });
        }
    };

    toggleAlertModal = async (alertType = null) => {
        this.setState({ modalAlert: !this.state.modalAlert });

        if (alertType === 'void') {
            await this.setState({
                actualOperation: alertType,
                modalAlertTitle: 'ANULAR CERTIFICADO',
                modalAlertMessage: '¿Está seguro que desea anular el certificado?  Esto detendrá la emisión del certificado al suscriptor designado, el certificado se mantendrá intacto.'
            })
        } else if (alertType === 'revoke') {
            this.setState({
                actualOperation: alertType,
                modalAlertTitle: 'REVOCAR CERTIFICADO',
                modalAlertMessage: '¿Está seguro que desea revocar el certificado?'
            });
        } else {
            this.setState({ actualOperation: '', modalAlertTitle: '', modalAlertMessage: '' })
        }
    };

    toggleModalForm = () => {
        this.setState({ modalToggleSuspend: !this.state.modalToggleSuspend });
    };

    toggleModalConfirmSusp = () => {
        this.setState({ modalConfirmSuspension: !this.state.modalConfirmSuspension });
    };

    toggleModalAgreementSuspended = () => {
        this.setState({ modalAgreementSuspended: !this.state.modalAgreementSuspended });
    };

    onFilter = props => {
        this.setState(props);
    };

    generateReport = () => {
        this.props.generateReport(this.props.match.params.clientID, this.state.assignments, this.props.history);
    };

    onSubmit = (e, actionType) => {
        if (actionType === 'revoke') {
            this.props.generateRevocationCode(this.state.selectedProduct.assignmentID, this.props.history);
            this.toggleAlertModal();

        } else if (this.generalValidator.allValid() || actionType === 'void') {
            let { selectedProduct } = this.state;

            if (actionType === 'void') {
                let data = {
                    assignmentID: selectedProduct.assignmentID,
                    user: null,
                    productID: selectedProduct.productID
                };

                const { clientID } = this.props.match.params;

                this.props.voidCertificate(clientID, data, this.props.history);
            } else {
                Alert.error('Ha habido un error interno, por favor intente de nuevo. Código 001', 10000);
            }

            this.toggleAlertModal();
        } else {
            this.generalValidator.showMessages();
            this.forceUpdate();
        }
    };

    customFilter = (a, b) => {
        return b.productName.toLowerCase().includes(a.toLowerCase());
    };

    statusAgreement = async () => {
        if (this.validatorToggleSuspend.allValid()) {
            let data = {
                clientID: this.state.client._id,
                agreement: this.state.statusClient,
                suspendActivateReason: this.state.suspendActivateReason
            };

            this.props.changeStatusAgreement(data, this.props.history);

        } else {
            this.validatorToggleSuspend.showMessages();
            this.forceUpdate();
        }
    };

    acceptMethods = (openModalMsg) => {
        this.openModalMsg = openModalMsg;
    };

    handleChange = e => {
        this.setState({ selectedContract: e.value , selectedContractPlaceholder: e.label}, () => {
            this.refreshTable();
        });
    };

    handleSendCredentials(e) {
        if (e.target.value === "supervisor") {
            this.setState({ notificationToSupervisor: true, credentialsConfirm: true });
        } else {
            this.setState({ notificationToSupervisor: false });
        }
    }

    getReportingContract(e) {
        const documentNumber = (this.state.client && Object.entries(this.state.client).length > 0)  ? this.state.client.documentNumber : this.props.userReducer.obtainedUser.documentNumber;
        let reportingUrl = `${PROTOCOL}${USERNAME}:${PASSWORD}${URL}${documentNumber}${CONFIG_FRONT}`;
        console.log(reportingUrl)
        window.open(reportingUrl, '_blank');
    }

    render() {

        let { client } = this.state;
        let titleCard = "ADMINISTRACIÓN DE PRODUCTOS";
        let descriptionTitleCard = "Asigne los certificados adquiridos a los usuarios suscriptores";

        const tableColumnsGroupedAssignments = [
            { title: "Certificado", field: "productName" },
            { title: "Entrega", field: "delivery" },
            { title: "Vigencia", field: "validity" },
            { title: "Tipo de uso", field: "management" },
            { title: "Cantidad", field: "quantity" },
            { title: "Asignados", field: "assigned" },
            { title: "Disponibles", field: "available" },
        ];

        const optionsTable = {
            actionsColumnIndex: -1,
            padding: 'dense',
            headerStyle: {
                backgroundColor: '#0bac72',
                color: '#FFF'
            },
            pageSize: 10,
        };

        const localizationTable = {
            pagination: {
                labelDisplayedRows: '{from}-{to} de {count}',
                labelRowsSelect: 'filas'
            },
            toolbar: {
                nRowsSelected: '{0} registro(s) seleccionado(s)',
                searchPlaceholder: 'Buscar'
            },
            header: {
                actions: 'Acciones'
            },
            body: {
                emptyDataSourceMessage: 'No hay registros para mostrar',
                filterRow: {
                    filterTooltip: 'Filtrar'
                }
            }
        };


        let modalToggleSuspend = (
            <ModalForm
                title={this.state.modalForm.title}
                isOpen={this.state.modalToggleSuspend}
                toggle={this.toggleModalForm}
            >
                <div className="text-center">
                    <img className="imgSucces" src={AlertIcon} alt="icon delivery" />
                    <p className="modal-msg">¿<span className="green-text">Está seguro</span> que
                        desea {this.state.client.status === 'disable' ? 'activar' : 'suspender'} el
                        convenio?</p>
                </div>

                <div className="container-fluid">
                    <div className="form-group row">
                        <p className="modal-msg text-left">Explique el motivo por el cual desea realizar esta
                            acción</p>
                        <div className="col-sm-12 input-group">
                            <span className="input-group-addon"></span>
                            <input
                                id="suspendActivateReason"
                                type="text"
                                className="form-control login"
                                placeholder="motivo"
                                value={this.state.suspendActivateReason}
                                onChange={this.onChangeCustomInput()}
                            >
                            </input>
                            {this.validatorToggleSuspend.message('motivo', this.state.suspendActivateReason, 'required|min:10')}
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-sm-12 text-center">
                            <button type="submit" className="btn btn-outline-success btn-submit-product "
                                onClick={this.statusAgreement}>
                                <span className="">
                                </span>{this.state.client.status === 'disable' ? 'Activar' : 'Suspender'}
                            </button>
                        </div>
                    </div>
                </div>
            </ModalForm>
        );

        let modalConfirmSuspension = (
            <ModalForm title={this.state.modalForm.title} isOpen={this.state.modalConfirmSuspension}
                toggle={this.toggleModalConfirmSusp}>
                <div className="text-center">
                    <img className="imgSucces" src={Check} alt="icon delivery" />
                    <p className="modal-msg-large">Su <span className="green-text">solicitud</span> ha sido enviada
                        exitosamente, recuerde que requiere la <span
                            className="green-text">aprobación de un perfil financiero</span> para completar la
                        solicitud.</p>
                </div>
            </ModalForm>
        );

        let modalSuspended = (
            <ModalForm title="NOTIFICACIÓN DE SUSPENSIÓN" isOpen={this.state.modalAgreementSuspended}
                toggle={this.toggleModalAgreementSuspended}>
                {this.state.currentUserRole === 'Advisor' ?
                    <div className="text-center">
                        <img className="imgSucces" src={AlertIcon} alt="icon delivery" />
                        <p className="modal-msg-large">Señor usuario, este convenio se encuentra <span className="green-text">suspendido</span>, por tanto,
                            no podrá realizar ninguna acción sobre sus productos.</p>
                    </div> :
                    <div className="text-center">
                        <img className="imgSucces" src={AlertIcon} alt="icon delivery" />
                        <p className="modal-msg-large">Señor usuario, su convenio se encuentra <span className="green-text">suspendido</span>, por tanto,
                            no podrá realizar ninguna acción sobre sus productos.</p>
                        <p className="modal-msg-large"> Para mas información comuniquese con su asesor.</p>
                    </div>}
            </ModalForm>
        );

        const TabsProducts = () => {
            const classes = useStyles()
            const [value, setValue] = React.useState(0)

            const handleChange = (event, newValue) => {
                setValue(newValue)
            }
            const tableColumnsCertificados = [
                { title: "Certificado", field: "certificate", customFilterAndSearch: this.customFilter },
                { title: "Entidad", field: "company" },
                { title: "Entrega", field: "delivery" },
                { title: "Tiempo Entrega", field: "deliveryTime" },
                { title: "Vigencia", field: "validity" },
            ];
            const tableColumns = [
                { title: "Certificado", field: "certificate", customFilterAndSearch: this.customFilter },
                { title: "Entidad", field: "company" },
                { title: "Suscriptor", field: "subscriber" },
                { title: "Documento", field: "document" },
                { title: "Entrega", field: "delivery" },
                { title: "Tiempo Entrega", field: "deliveryTime" },
                { title: "Vigencia", field: "validity" },
                { title: "Tipo de uso", field: "management" },
                { title: "Caducidad", field: "expiration", type: 'date', defaultSort: 'asc' }
            ];
            return (
                <div className={classes.root}>
                    <div className={classes.vinkel}>
                        <AntTabs
                            value={value}
                            onChange={handleChange}
                            variant="fullWidth"
                            aria-label="ant example">
                            <AntTab label="Certificados con Información precargada" />
                            <AntTab label="Certificados Asignados" />
                        </AntTabs>
                        <TabPanel value={value} index={0}>
                            <div style={{ maxWidth: "100%" }}>
                                <MaterialTable
                                    title=""
                                    columns={tableColumnsCertificados}
                                    data={_.orderBy(this.state.assignmentsPreload, 'productName', 'asc')}
                                    components={{
                                        Toolbar: props => (
                                            <div className="row p-3">
                                                <div className="col-md-9">
                                                    <Button className="table-filter-button btn-outline-warning"
                                                        onClick={() => this.toggleModal('modalFilterGroupedAssignment')}>
                                                        <img className="table-upper-button" src={FilterIcon} alt="Filtros" />
                                                        Filtros
                                                    </Button>
                                                </div>
                                                <div className="col-md-3">
                                                    <MTableToolbar {...props} />
                                                </div>
                                            </div>
                                        )
                                    }}
                                    actions={
                                        [
                                            {
                                                icon: DeleteIcon,
                                                tooltip: 'Borrar Información precargada',
                                                onClick: (event, rowData) => {
                                                    this.props.deletePreLoadFromAssignment(rowData.productID.idProductGSE, this.props.history)
                                                },
                                            },
                                            rowData => (rowData.assigned && rowData.delivery === 'P10')
                                                ? ({
                                                    icon: DisableAssignIcon,
                                                    tooltip: 'Asignar Certificado',
                                                    disabled: true
                                                }) :
                                                ({
                                                    icon: AssignIcon,
                                                    tooltip: 'Asignar Certificado',
                                                    onClick: (event, rowData) => this.toggleModal('modalAssignProduct', rowData)
                                                })
                                        ]
                                    }
                                    options={optionsTable}
                                    localization={localizationTable}
                                />
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <div style={{ maxWidth: "100%" }}>
                                <MaterialTable
                                    title=""
                                    columns={tableColumns}
                                    data={this.state.assignments}
                                    components={{
                                        Toolbar: props => (
                                            <div className="row p-3">
                                                <div className="col-md-9">
                                                    <Button className="table-filter-button btn-outline-warning"
                                                        onClick={() => this.toggleModal('modalFilters')}>
                                                        <img className="table-upper-button" src={FilterIcon} alt="Filtros" />
                                                        Filtros
                                                    </Button>
                                                </div>
                                                <div className="col-md-3">
                                                    <MTableToolbar {...props} />
                                                </div>
                                            </div>
                                        )
                                    }}
                                    actions={(this.state.statusClient === 'enable' || (this.state.currentUserRole === 'Contributor' && this.state.statusUser === 'enable')) ? [
                                        rowData => (
                                            SHOW_REASSIGN_BUTTON === true
                                            && rowData.status === 'Revocado'
                                            && rowData.replacementAssignmentID === null
                                            && rowData.leftDaysToExpire >= 30
                                            && rowData.reassignments > 0
                                            && (rowData.productProvider !== 'DIAN')
                                        )
                                            ? ({
                                                icon: ReassignIcon,
                                                disabled: rowData.status === 'Anulado',
                                                tooltip: rowData.status !== 'Anulado' ? 'Reasignar' : 'No se puede Reasignar',
                                                onClick: (event, rowData) => this.toggleModal('modalReassignProduct', rowData)
                                            })
                                            : rowData.statusProcess === 'Finalizado - entregado' && moment(rowData.expiration, "DD/MM/YYYY") >= moment()
                                                ? ({
                                                    icon: GetRevokeIcon,
                                                    tooltip: 'Revocar Certificado',
                                                    onClick: (event, rowData) => this.toggleModal('revoke', rowData)
                                                })
                                                : null
                                    ] : null}

                                    options={optionsTable}
                                    localization={localizationTable}
                                />
                            </div>
                        </TabPanel>
                        <Typography className={classes.padding} />
                    </div>
                </div>
            )
        }

        return (
            <div>
                <Card title={titleCard} descriptionTitle={descriptionTitleCard} maxWidth={"90%"}>
                    {this.state.currentUserRole === 'Advisor' ?
                        <div className="infoClientAdmin" style={{ maxWidth: "100%" }}>
                            <hr />
                            {client.type === 'Convenio' ?
                                <div className="">
                                    <div className="row">
                                        <div className="col client-data">
                                            {client.entityName ?
                                                <>
                                                    <p className="cart-user-data">{client.entityName}</p>
                                                    <p className="cart-user-data">{client.firstName ? `Supervisor: ${client.firstName} ${client.lastName}` : ''} </p>
                                                    <p className="cart-user-data">{client.cellPhone ? `Celular: ${client.cellPhone}` : ''}</p>
                                                    <p className="cart-user-data">{client.email ? `Correo: ${client.email}` : ''}</p>
                                                </>
                                                : <Loader speed="slow" content="actualizando" />
                                            }
                                        </div>
                                        <div className="col">
                                            <div className="custom-control custom-switch">
                                                <div className="row switchAgreement">
                                                    <p className="labelswitchAdmin"> {client.status === 'disable' ? 'Activar Convenio' : 'Suspender Convenio'}</p>
                                                    <input className="custom-control-input"
                                                        type="checkbox"
                                                        id="customSwitch1"
                                                        disabled={this.state.disableAgreement}
                                                        checked={this.state.isSuspended}
                                                        value={this.state.isSuspended}
                                                        onChange={() => this.toggleModal('modalToggleSuspend')}
                                                    >
                                                    </input>
                                                    <label className="custom-control-label"
                                                        htmlFor="customSwitch1"
                                                        title={client.suspended === true ? "Actualmente existe una peticion pendiente." : ''}
                                                    >
                                                    </label>
                                                </div>
                                            </div>
                                            <br />
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="client-data">
                                    {client.firstName ?
                                        <>
                                            <p className="cart-user-data">{client.firstName}</p>
                                            <p className="cart-user-data">{client.cellPhone ? `Celular: ${client.cellPhone}` : ''}</p>
                                            <p className="cart-user-data">{client.email ? `Correo: ${client.email}` : ''}</p>
                                        </>
                                        : <Loader speed="slow" content="actualizando" />
                                    }
                                </div>
                            }
                        </div> : null}

                    <div className="infoClientAdmin" style={{ maxWidth: "100%" }}>
                        <div className="">
                            <div className="row">
                                <div className="col">
                                    <div className="custom-control custom-switch">
                                        <div className="row selectAgreement">
                                            <div className="form-group">
                                                <label htmlFor="exampleFormControlSelect1">No. Contrato/ No. Factura</label>
                                                <div className="custom-position">
                                                    <Select
                                                        value={this.state.selectedContract}
                                                        placeholder={this.state.selectedContractPlaceholder}
                                                        onChange={this.handleChange}
                                                        options={this.state.optionsContractNumber}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{ maxWidth: "100%" }}>
                        <h2 className='title-grouped-table'>Total de mis productos</h2>
                        <MaterialTable
                            title=""
                            columns={tableColumnsGroupedAssignments}
                            data={this.state.groupedAssignments}
                            components={{
                                Toolbar: props => (
                                    <div className="row p-3">
                                        <div className="col-md-9">
                                            <Button className="table-filter-button btn-outline-warning mr-2"
                                                onClick={() => this.toggleModal('modalFilterGroupedAssignment')}>
                                                <img className="table-upper-button" src={FilterIcon} alt="Filtros" />
                                                Filtros
                                            </Button>
                                            {(this.state.clientType === 'Convenio' || this.state.supervisorType === 'Convenio') &&
                                                <Button className="reporting-button btn-outline-warning" style={{}}
                                                    onClick={e => this.getReportingContract(e)}>
                                                    <img className="table-upper-button mr-2" src={Reporting} alt="Informe" />
                                                    Informe estado contrato
                                            </Button>
                                            }
                                        </div>
                                        <div className="col-md-3">
                                            <MTableToolbar {...props} />
                                        </div>
                                        <div className="col-md-12 mt-3">
                                            <div className="p-3 preload-message-warning">
                                                <p>
                                                    Para visualizar la información de sus productos, por favor seleccione el número de factura o de contrato en la lista superior derecha.
                                                </p>
                                            </div>
                                            <div className="p-3 preload-message">
                                                <p>
                                                    Para iniciar el proceso de asignación seleccione el botón
                                                    <img style={{ height: '2rem' }} className="pl-3" src={Assign} alt="Asignar Certificado" />
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }}
                            actions={
                                [
                                    rowData => (rowData.ref !== 'PN' && rowData.ref !== 'PT' && rowData.ref !== 'PF' && rowData.ref !== 'ND' && rowData.delivery !== 'P10')
                                    ? ({
                                        icon: PreloadIcon,
                                        tooltip: 'Precargar Información',
                                        onClick: (event, rowData) => this.toggleModal('modalPreloadInfo', rowData)
                                    }) :
                                    ({
                                        icon: DisablePreloadIcon,
                                        tooltip: 'Precargar Información',
                                        disabled: true
                                    }),
                                    rowData => (rowData.available > 0)
                                    ? ({
                                        icon: AssignIcon,
                                        tooltip: 'Asignar Certificado',
                                        onClick: (event, rowData) => this.toggleModal('modalAssignProduct', rowData)
                                    }) :
                                    ({
                                        icon: DisableAssignIcon,
                                        tooltip: 'Asignar Certificado',
                                        disabled: true
                                    })
                                ]
                            }

                            options={optionsTable}
                            localization={localizationTable}
                        />
                    </div>

                    <br />
                    <TabsProducts />

                    <div className="row mt-4">
                        {(this.state.currentUserRole === 'Advisor' && this.state.statusClient === 'enable') ?
                            <div className="col-md-6">
                                <PrimaryButton
                                    color={"green"}
                                    txtBtn={"Nueva Compra"}
                                    sizeWidth={""}
                                    className={"with-icon"}
                                    icon={"SingIn"}
                                    onPress={() => this.props.history.push(`/products/${client._id}`)}
                                >
                                </PrimaryButton>
                            </div> : null}

                        {(this.state.areEmptyFilters || this.state.assignments.length === 0)
                            ? null
                            : !this.state.statusClient || this.state.statusClient === 'enable' ?
                                <div className="col-md-6 text-right">
                                    <PrimaryButton
                                        color={"green"}
                                        txtBtn={"Generar informe"}
                                        sizeWidth={""}
                                        className={"with-icon"}
                                        icon={"QuotationIcon"}
                                        onPress={() => this.generateReport()}
                                    >
                                    </PrimaryButton>
                                </div>
                                : null
                        }
                    </div>

                </Card>

                <ModalCertificate
                    showModal={this.state.modalCertificate}
                    title="DETALLE DEL CERTIFICADO"
                    selectedProduct={this.state.selectedProduct}
                    toggleModal={this.toggleModal}
                    notificationToSupervisor={this.state.notificationToSupervisor}
                    handleSendCredentials={e => this.handleSendCredentials(e)}
                    credentialsConfirm={this.state.notificationToSupervisor === true ? this.state.notificationToSupervisor : false}
                    sendCredentials={this.state.sendCredentials}
                />

                <AssignmentForm
                    modalAssignProduct={this.state.modalAssignProduct}
                    toggleModal={this.toggleModal}
                    selectedProduct={this.state.selectedProduct}
                    clientID={this.props.match.params.clientID}
                    history={this.props.history}
                    clientType={this.state.clientType}
                />

                <ModalPreloadInfo
                    modalPreloadInfo={this.state.modalPreloadInfo}
                    toggleModal={this.toggleModal}
                    selectedProduct={this.state.selectedProduct}
                    clientID={this.props.match.params.clientID}
                    history={this.props.history}
                />

                <ReassignmentForm
                    modalReassignProduct={this.state.modalReassignProduct}
                    toggleModal={this.toggleModal}
                    selectedProduct={this.state.selectedProduct}
                    clientID={this.props.match.params.clientID}
                    history={this.props.history}
                />

                <ModalAlert
                    showModal={this.state.modalAlert}
                    title={this.state.modalAlertTitle}
                    message={this.state.modalAlertMessage}
                    toggleAlertModal={this.toggleAlertModal}
                    onSubmit={this.onSubmit}
                    actualOperation={this.state.actualOperation}
                />

                <ModalLoader
                    showModal={this.state.modalLouder}
                    title={this.state.modalLouderTitle}
                    message={this.state.modalLouderMessage}
                    actualOperation={this.state.modalLouderType}
                />
                
                <ModalFilters
                    showModal={this.state.modalFilters}
                    assignments={this.state.assignmentsCopy}
                    onFilter={this.onFilter}
                    refreshTable={this.refreshTable}
                    toggleModal={this.toggleModal}
                />

                <ModalFilterGroupedAssignment
                    showModal={this.state.modalFilterGroupedAssignment}
                    assignments={this.state.groupedAssignmentsCopy}
                    onFilter={this.onFilter}
                    refreshTable={this.refreshTable}
                    toggleModal={this.toggleModal}
                />

                {modalToggleSuspend}
                {modalConfirmSuspension}
                {modalSuspended}
                <ModalMsg
                    shareMethods={this.acceptMethods}
                    title={this.state.modalMsg.title}
                    show={this.state.modalMsg.show}
                    label={this.state.modalMsg.label}
                    type={this.state.modalMsg.type}
                    redirect={this.state.modalMsg.redirect}
                    history={this.props.history}
                />

                <RevocationCodeForm
                    showModal={this.state.modalRevocationForm}
                    toggleModal={this.toggleModal}
                    selectedProduct={this.state.selectedProduct}
                    clientID={this.props.match.params.clientID}
                    history={this.props.history}
                    revocationCauses={this.state.revocationCauses}
                />
            </div>

        )
    }
}

AdminProducts.propTypes = {
    getUser: PropTypes.func.isRequired,
    getAssignments: PropTypes.func.isRequired,
    getStatusHistory: PropTypes.func.isRequired,
    generateReport: PropTypes.func.isRequired,
    voidCertificate: PropTypes.func.isRequired,
    generateRevocationCode: PropTypes.func.isRequired,
    changeStatusAgreement: PropTypes.func.isRequired,
    resetAdminProductsReducer: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired,
    userReducer: PropTypes.object.isRequired,
    adminProductsReducer: PropTypes.object.isRequired,
    errorReducer: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    userReducer: state.userReducer,
    adminProductsReducer: state.adminProductsReducer,
    errorReducer: state.errorReducer
});

const mapDispatchToProps = {
    getUser,
    getAssignments,
    getStatusHistory,
    generateReport,
    voidCertificate,
    generateRevocationCode,
    changeStatusAgreement,
    resetAdminProductsReducer,
    getGroupedAssignments,
    deletePreLoadFromAssignment,
    getTown,
    clearErrors
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminProducts)
