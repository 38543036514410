import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getAdvisorUsers } from "../../actions/advisorActions";
//getAdvisorUsers

import CardBody from '../../components/CardBody/CardBody';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';

import hcount from "../../assets/img/icons/have-count.png";
import MaterialTable, { MTableToolbar } from "material-table";

import { Alert } from "rsuite";

import './advisor.css';


/**
 * Advisor Users Component ( client products list, show the available products and make to add items to cart )
 *
 * @export Class Component
 * @class Advisor
 * @extends {Advisor}
 * @returns Redux connect
 */
export class Advisor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            users: []
        };
    }

    componentDidMount() {
        const { clientID } = this.props.match.params;
        this.props.getAdvisorUsers(clientID);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.advisorReducer.users) {
            for (const client of nextProps.advisorReducer.users) {
                this.state.users.push({
                    id: client._id,
                    clientName: client.entityName ? client.entityName : client.firstName + ' ' + client.lastName,
                    clientType: client.type,
                    documentType: client.documentType,
                    documentNumber: client.documentNumber,
                    supervisor: client.entityName ? client.firstName + ' ' + client.lastName : 'N/A',
                    email: client.email,
                    actions: true,
                    status: client.status
                })
            }
        } else {
            if (!!nextProps.errorReducer && !!nextProps.errorReducer.data.msg) {
                Alert.error(nextProps.errorReducer.data.msg, 3000);
            }
        }
    }

    newPurchase() {
        let url = '/businessAdvisor';
        this.props.history.push(url);
    }

    storeClientID(client) {
        if (client.status !== 'disable') localStorage.setItem('currentClientID', client.id);
    }

    render() {
        let titleCard = "LISTADO DE CLIENTES";
        let descriptionTitleCard = "Stock y creacion de productos"

        const tableColumns = [
            { title: "Cliente", field: "clientName" },
            { title: "Tipo Cliente", field: "clientType" },
            { title: "Tipo Documento", field: "documentType" },
            { title: "Documento", field: "documentNumber" },
            { title: "Supervisor", field: "supervisor" },
            { title: "Correo", field: "email" },
            {
                title: "Acciones",
                field: "actions",
                render: rowData =>
                    <div className="toolTipButton">
                        <span className="viewClient" aria-label="Ver">
                            <a href={"/admin/" + rowData.id}><img src={hcount} alt="viewClient" style={{ width: '40%' }} onClick={e => this.storeClientID(rowData)} /></a>
                        </span>
                    </div>
            },
        ];

        return (
            <Fragment>
                <CardBody title={titleCard} descriptionTitle={descriptionTitleCard}>
                    <div className="textIntro">
                        <p className="text-center">Listado de los <span
                            className="green-text">clientes</span> existentes </p>
                    </div>

                    <div style={{ maxWidth: "100%" }}>
                        <MaterialTable
                            title=""
                            columns={tableColumns}
                            data={this.state.users}
                            components={{
                                Toolbar: props => (
                                    <div className="row p-3">
                                        <div className="col-md-12">
                                            <MTableToolbar {...props} />
                                        </div>
                                    </div>
                                )
                            }}
                            options={{
                                actionsColumnIndex: -1,
                                headerStyle: {
                                    backgroundColor: '#0bac72',
                                    color: '#FFF',
                                },
                                pageSize: 10
                            }}
                            localization={{
                                pagination: {
                                    labelDisplayedRows: '{from}-{to} de {count}',
                                    labelRowsSelect: 'filas'
                                },
                                toolbar: {
                                    nRowsSelected: '{0} registro(s) seleccionado(s)',
                                    searchPlaceholder: 'Buscar'
                                },
                                header: {
                                    actions: 'Acciones'
                                },
                                body: {
                                    emptyDataSourceMessage: 'No hay registros para mostrar',
                                    filterRow: {
                                        filterTooltip: 'Filtrar'
                                    }
                                }
                            }}
                        />
                    </div>

                    <div className="row">
                        <div className="col col-12">
                            <PrimaryButton
                                color={"green"}
                                txtBtn={"Nueva Compra"}
                                sizeWidth={""}
                                className={"float-right with-icon btn btn-primary btn-labeled btn-singin"}
                                icon={"Singin"}
                                onPress={(e) => this.newPurchase()}
                            >
                            </PrimaryButton>
                        </div>
                    </div>
                </CardBody>
            </Fragment>
        );
    }
}

Advisor.propTypes = {
    getAdvisorUsers: PropTypes.func.isRequired,
    advisorReducer: PropTypes.object.isRequired,
    errorReducer: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    advisorReducer: state.advisorReducer,
    errorReducer: state.errorReducer
})

export default connect(mapStateToProps, { getAdvisorUsers })(Advisor)
