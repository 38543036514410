import React, { Component } from 'react'
import '../../styles2.css';
// import { TERMS_CONDITIONS_URL } from '../../config/config';

class TermsAndConditionsRegister extends Component {

    render() {
        const { termsAndConditionsRegister } = this.props;

        return (               
                <span
                    dangerouslySetInnerHTML={{ __html: `${termsAndConditionsRegister}` }}
                />
        )
    }
}

export default TermsAndConditionsRegister;