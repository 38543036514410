import React from 'react'
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import CloseIcon from "../../../assets/img/icons/close.png";
import AlertIcon from "../../../assets/img/icons/alert/alert.png";
import check from "../../../assets/img/icons/check/check.png";
import Suspend from "../../../assets/img/icons/suspend/suspend.png";
import FilterIcon from "../../../assets/img/icons/filter.png";
import {connect} from "react-redux";
import {getPendingFinancial, postApprovePayment, postSuspendedUser} from "../../../actions/financialActions";

const ModalAlertApprove = props => {
    const {
        postSuspendedUser,
        postApprovePayment,
        setFinancialState,
        modalAlertApprove,
        modelAlertApproveTitle,
        modelAlertApproveMessage,
        modalAlertApproveType,
        modalApproveSuspended,
        modalAlertApproveSelect,
        toggleModal,
        getPendingFinancial,
        areButtonsActive = true
    } = props;

    return (
        <Modal isOpen={modalAlertApprove}>
            <ModalHeader className="header-modal text-center">
                {modelAlertApproveTitle}
                <Button className="modal-close" onClick={async e => {
                    await setFinancialState({
                        modalAlertApproveSelect: '',
                        modelAlertApproveTitle: '',
                        modelAlertApproveMessage: '',
                        modalAlertApproveType: '',
                        modalApproveSuspended: 'enable',
                    });
                    await toggleModal('modalAlertApprove')
                }}
                ><img
                    src={CloseIcon}
                    alt="icon product"/></Button>
            </ModalHeader>
            <ModalBody>
                <div className="container">
                    <div className="row">
                        <div className="col-10 offset-1 text-center">
                            <img src={AlertIcon} alt="Logo Alerta"/>
                            <br/>
                            <br/>
                            <p> ¿<span
                                style={{color: '#2C9C72'}}>Está seguro</span> {modelAlertApproveMessage}
                            </p>
                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter className="text-center">
                <div className="col-12">
                    <button type="submit"
                            className="btn btn-outline-success btn-submit-product"
                            disabled={!areButtonsActive}
                            onClick={async e => {
                                if (modalAlertApproveType !== '') {
                                    if (modalAlertApproveType === 'ignore') {
                                        await setFinancialState({
                                            modalSuccessfulTitle: 'IGNORAR CONVENIO',
                                            modalSuccessfulMessage: 'convenio ignorado exitosamente',
                                        });
                                    } else {
                                        if (modalApproveSuspended === 'enable') {
                                            await setFinancialState({
                                                modalSuccessfulTitle: 'SUSPENDER CONVENIO',
                                                modalSuccessfulMessage: 'convenio suspendido exitosamente',
                                            });
                                        } else {
                                            await setFinancialState({
                                                modalSuccessfulTitle: 'ACTIVAR CONVENIO',
                                                modalSuccessfulMessage: 'convenio activado exitosamente',
                                            });
                                        }
                                    }
                                    postSuspendedUser(modalAlertApproveType, modalApproveSuspended, modalAlertApproveSelect);
                                    await toggleModal('modalAlertApprove');
                                    await setFinancialState({
                                        modalAlertApprove: false,
                                        modalAlertApproveSelect: '',
                                        modelAlertApproveTitle: '',
                                        modelAlertApproveMessage: '',
                                        modalAlertApproveType: '',
                                        modalApproveSuspended: 'enable',
                                    });
                                    await getPendingFinancial()
                                } else {
                                    await setFinancialState({
                                        modalSuccessfulTitle: 'APROBAR COMPRA',
                                        modalSuccessfulMessage: 'has aprobado el pago satisfactoriamente',
                                        areButtonsActive: false
                                    });
                                    await postApprovePayment(modalAlertApproveSelect);
                                    await toggleModal('modalAlertApprove');
                                    await getPendingFinancial()
                                }
                            }}>
                        <img className="input-icon"
                             src={
                                 modalAlertApproveType === ''
                                     ? check
                                     : modalAlertApproveType === 'ignore'
                                     ? AlertIcon
                                     : modalApproveSuspended === 'enable'
                                         ? Suspend
                                         : FilterIcon
                             }
                             alt="Aprobar"
                             style={{marginRight: '15px', width: '30px', height: '25px'}}
                        />
                        {modalAlertApproveType === ''
                            ? 'Aprobar'
                            : modalAlertApproveType === 'ignore'
                                ? 'Ignorar'
                                : modalApproveSuspended === 'enable'
                                    ? 'Suspender'
                                    : 'Activar'
                        }
                    </button>
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default connect(null, {postApprovePayment, getPendingFinancial, postSuspendedUser})(ModalAlertApprove)
