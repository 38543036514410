import {API_ENDPOINT, defaultResponses} from "../config/config";

import axios from "axios";
import {Alert} from "rsuite";

import {
    ASSIGN_CERTIFICATE,
    CHANGE_STATUS_AGREEMENT,
    CLEAR_ERRORS,
    GENERATE_REPORT,
    GET_ASSIGNMENTS,
    GET_ERRORS,
    GET_GROUPED_ASSIGNMENTS,
    GET_STATUS_HISTORY,
    POST_PRE_LOAD,
    REASSIGN_CERTIFICATE,
    RESET_ADMIN_PRODUCTS,
    GENERATE_REVOCATION_CODE,
    REVOKE_CERTIFICATE,
    SWITCH_ADMIN_PRODUCTS_FILTERS,
    VOID_CERTIFICATE,
    GET_TERMS_CONDITIONS,
    GET_TERMS_CONDITIONS_REGISTER,
    GET_TERMS_CONDITIONS_PRODUCT,
    GET_DIRECTION_GSE,
    CLEAR_DIRECTION_GSE,
    GET_VALIDATE_EMAIL
} from "./types";

/**
 * AdminProductsAction getAssignments ( get certificates with or without users assigned )
 *
 * @method GET
 * @param {*} clientID
 * @param {*} history
 * @param {*} contractNumber
 */
export const getAssignments = (clientID, history, contractNumber) => async (dispatch) => {
    const URL = !!clientID
        ? `${API_ENDPOINT}/assignment/${clientID}${contractNumber ? '?contractNumber=' + contractNumber:''}`
        : `${API_ENDPOINT}/assignment/${contractNumber ? '?contractNumber=' + contractNumber : ''}`;
        
    await axios.get(URL)
        .then(async res => {
            dispatch({
                type: GET_ASSIGNMENTS,
                payload1: !!res.data.client ? res.data.client : null,
                payload2: URL.includes('contractNumber') ? res.data.assignments : [],
                payload3: res.data.contractNumberList
            });
        })
        .catch(err => { handleErrors(err, dispatch, history); })
};

export const getDirectionGSE = (history) => async (dispatch) => {
    const URL = `${API_ENDPOINT}/gseDirection`
        
    await axios.get(URL)
        .then(async res => {
            dispatch({
                type: GET_DIRECTION_GSE,
                payload: res.data
            });
        })
        .catch(err => { handleErrors(err, dispatch, history); })
};

export const validateEmail = (data) => async (dispatch) => {
    const URL = `${API_ENDPOINT}/white-list/verificateEmail`
        
    await axios.post(URL, data)
        .then(async res => {
            dispatch({
                type: GET_VALIDATE_EMAIL,
                payload: res.data.data
            });
        })
        .catch(err => { handleErrors(err, dispatch); })
};

export const clearDirectionGSE = () => async (dispatch) => {
    dispatch({
        type: CLEAR_DIRECTION_GSE
    });
};

export const getTermsAndConditions = (history) => async (dispatch) => {
    const URL = `${API_ENDPOINT}/termsAndCondition`
        
    await axios.get(URL)
        .then(async res => {
            dispatch({
                type: GET_TERMS_CONDITIONS,
                payload: res.data
            });
        })
        .catch(err => { handleErrors(err, dispatch, history); })
};

export const getTermsAndConditionsRegister = (history) => async (dispatch) => {
    const URL = `${API_ENDPOINT}/termsAndConditionRegister`
        
    await axios.get(URL)
        .then(async res => {
            dispatch({
                type: GET_TERMS_CONDITIONS_REGISTER,
                payload: res.data
            });
        })
        .catch(err => { handleErrors(err, dispatch, history); })
};

export const getTermsAndConditionsProduct = (history) => async (dispatch) => {
    const URL = `${API_ENDPOINT}/termsAndConditionProduct`
        
    await axios.get(URL)
        .then(async res => {
            dispatch({
                type: GET_TERMS_CONDITIONS_PRODUCT,
                payload: res.data
            });
        })
        .catch(err => { handleErrors(err, dispatch, history); })
};
/**
 * AdminProductsAction statusHistory ( get statusHistory of an specific assignment )
 *
 * @method GET
 * @param {*} assignmentCode
 */
export const getStatusHistory = (assignmentCode, history) => async (dispatch) => {
    const URL = `${API_ENDPOINT}/assignment/statusHistory/${assignmentCode}`
    await axios.get(URL)
        .then(async res => {
            dispatch({
                type: GET_STATUS_HISTORY,
                payload: res.data.statusHistory
            });
        })
        .catch(err => { handleErrors(err, dispatch, history); })
};

/**
 * AdminProductsAction getGroupedAssignments (Get certificates grouped by type, delivery and validity)
 *
 * @method GET
 * @param clientID
 * @param history
 * @param contractNumber
 * @returns {Function}
 */
export const getGroupedAssignments = (clientID, history, contractNumber) => async (dispatch) => {
    let url = !!clientID
        ? `${API_ENDPOINT}/assignment/total/grouped/${clientID}${contractNumber ? '?contractNumber=' + contractNumber : ''}`
        : `${API_ENDPOINT}/assignment/total/grouped/${contractNumber ? '?contractNumber=' + contractNumber : ''}`;

    await axios.get(url, {timeout:'300000'})
        .then(async res => {
            dispatch({
                type: GET_GROUPED_ASSIGNMENTS,
                payload: res.data
            });
        })
        .catch(err => { handleErrors(err, dispatch, history); })
};

/**
 * AdminProductsAction generateReport ( generate csv report for filtered assignments )
 *
 * @method POST
 * @param {*} clientID
 * @param {*} assignments
 * @param {*} history
 */
export const generateReport = (clientID = null, assignments, history) => async (dispatch) => {
    let url = !!clientID
        ? `${API_ENDPOINT}/assignment/generate-report/client/${clientID}`
        : `${API_ENDPOINT}/assignment/generate-report`;

    await axios.post(url, {assignments})
        .then(async res => {
            dispatch({
                type: GENERATE_REPORT,
                payload: res.data.path
            });
        })
        .catch(err => { handleErrors(err, dispatch, history); })
};

/**
 * AdminProductsAction assignCertificate ( assign certificate to user )
 *
 * @method POST
 * @param {*} clientID
 * @param {*} data
 * @param {*} history
 */
export const assignCertificate = (clientID = null, data, history) => async (dispatch) => {
    let url = clientID
        ? `${API_ENDPOINT}/assignment/assign/client/${clientID}`
        : `${API_ENDPOINT}/assignment/assign`;
        
    await axios.post(url, data)
        .then(async res => {
            dispatch({
                type: ASSIGN_CERTIFICATE,
                payload: true
            });
        })
        .catch(err => { handleErrors(err, dispatch, history); })
};

/**
 * AdminProductsAction reassignCertificate ( reassign certificate to user )
 *
 * @method POST
 * @param {*} clientID
 * @param {*} data
 * @param {*} history
 */
export const reassignCertificate = (clientID = null, data, history) => async (dispatch) => {
    let url = clientID
        ? `${API_ENDPOINT}/assignment/reassign/client/${clientID}`
        : `${API_ENDPOINT}/assignment/reassign`;

    await axios.post(url, data)
        .then(async res => {
            dispatch({
                type: REASSIGN_CERTIFICATE,
                payload: true
            });
        })
        .catch(err => { handleErrors(err, dispatch, history); })
};

/**
 * AdminProductsAction voidCertificate ( to void user's certificate )
 *
 * @method POST
 * @param {*} clientID
 * @param {*} data
 * @param {*} history
 */
export const voidCertificate = (clientID = null, data, history) => async (dispatch) => {
    let url = clientID
        ? `${API_ENDPOINT}/assignment/void/client/${clientID}`
        : `${API_ENDPOINT}/assignment/void`;

    await axios.post(url, data)
        .then(async res => {
            dispatch({
                type: VOID_CERTIFICATE,
                payload: true
            });
        })
        .catch(err => { handleErrors(err, dispatch, history); })
};

/**
 * AdminProductsAction generateRevocationCode ( generate code to revoke user's certificate )
 *
 * @method POST
 * @param {*} assignmentID
 * @param {*} history
 */
export const generateRevocationCode = (assignmentID, history) => async dispatch => {
    await axios.post(`${API_ENDPOINT}/assignment/revocation/send-mail`, { assignmentID })
        .then(async res => {
            dispatch({
                type: GENERATE_REVOCATION_CODE,
                payload: res.data
            });
        })
        .catch(err => { handleErrors(err, dispatch, history); })
};

/**
 * AdminProductsAction revokeCertificate ( revoke user's certificate )
 *
 * @method POST
 * @param {*} revocationInfo
 * @param {*} history
 */
export const revokeCertificate = (revocationInfo, history) => async dispatch => {
    await axios.post(`${API_ENDPOINT}/assignment/revocation/revoke`, revocationInfo)
        .then(async res => {
            dispatch({
                type: REVOKE_CERTIFICATE,
                payload: res.data
            });
        })
        .catch(err => { handleErrors(err, dispatch, history); })
};

/**
 * AdminProductsAction changeStatusAgreement ( suspend or activate the agreement )
 *
 * @method POST
 * @param {*} data
 * @param {*} history
 */
export const changeStatusAgreement = (data, history) => async (dispatch) => {
    await axios.post(`${API_ENDPOINT}/advisor/agreement/client/${data.clientID}`, data)
        .then(async res => {
            dispatch({
                type: CHANGE_STATUS_AGREEMENT,
                payload: true
            });
        })
        .catch(err => { handleErrors(err, dispatch, history); })
};

/**
 * Create preload.
 *
 * @method POST
 * @param {*} data
 * @param {*} history
 */
export const createPreLoad = (data, history) => async (dispatch) => {
    await axios.post(`${API_ENDPOINT}/pre-load`, data)
        .then(async res => {
            Alert.success(res.data, 5000)
            dispatch({type: POST_PRE_LOAD, payload: null})
        })
        .catch(err => { handleErrors(err, dispatch, history); })
}

/**
 * Delete preload from assignment.
 *
 * @method DELETE
 * @param {*} assignmentID
 * @param {*} history
 */
export const deletePreLoadFromAssignment = (assignmentID, history) => async (dispatch) => {
    await axios.delete(`${API_ENDPOINT}/pre-load/${assignmentID}`)
        .then(async res => {
            Alert.success(res.data, 5000)
            dispatch({type: POST_PRE_LOAD, payload: null})
        })
        .catch(err => { handleErrors(err, dispatch, history); })
}

/**
 * AdminProductsAction resetAdminProductsReducer ( reset the values of the AdminProducts reducer )
 *
 * @method POST
 */
export const resetAdminProductsReducer = () => async (dispatch) => {
    dispatch({
        type: RESET_ADMIN_PRODUCTS,
        payload: null
    });
};

/**
 * AdminProductsAction switchAdminProductsFilters ( change the value if the AdminProducts filters are empty or not )
 *
 * @method POST
 */
 export const switchAdminProductsFilters = value => async (dispatch) => {
    dispatch({
        type: SWITCH_ADMIN_PRODUCTS_FILTERS,
        payload: value
    });
};

export const clearErrors = () => async(dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
};

const handleErrors = async (err, dispatch, history) => {
    if (err.response && err.response.data.expiredToken) {
        dispatch({type: CLEAR_ERRORS, payload: null})
        history.push('/logout')
    } else {
        dispatch({
            type: GET_ERRORS,
            payload: !!err.response
                ? err.response.data
                : {message: defaultResponses.noResponseFromApi}
        })
    }
}
