import React from 'react'
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";

import CloseIcon from "../../assets/img/icons/close.png";
import CheckIcon from "../../assets/img/icons/check/check.png";
import AlertIcon from "../../assets/img/icons/alert/alert.png";

const ModalAlert = props => {
    const { showModal, type, title, message, toggle } = props;

    const icons = {
        'success': CheckIcon,
        'alert': AlertIcon
    }
    
    const icon = icons[type] || AlertIcon

    return (
        <Modal isOpen={showModal}>
            <ModalHeader className="header-modal">
                {title}
                <Button className="modal-close" onClick={e => toggle()}>
                    <img src={CloseIcon} alt="icon product"/>
                </Button>
            </ModalHeader>
            <ModalBody>
                <div className="container">
                    <div className="row">
                        <div className="col-10 offset-1 text-center">
                            <img src={icon} alt={type === 'success' ? "Success logo" : "Alert logo"}/>
                            <br/><br/>
                            <p>
                                {type === 'success'
                                    ? <span style={{color: '#2C9C72'}}>¡¡Perfecto!!</span>
                                    : <span style={{color: '#2C9C72'}}>¡¡Ooops!!</span>
                                }
                                {message}
                            </p>
                        </div>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
};

export default ModalAlert;