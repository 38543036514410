import React from 'react'
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import CloseIcon from "../../../assets/img/icons/close.png";
import AlertIcon from "../../../assets/img/icons/alert/alert.png";

const ModalAlert = props => {
    const {
        modalAlert,
        modalAlertTitle,
        actualOperation,
        modalAlertMessage,
        toggleAlertModal,
    } = props;
    return (
        <Modal isOpen={modalAlert}>
            <ModalHeader className="header-modal text-center">
                {modalAlertTitle}

                <Button className="modal-close" onClick={e => toggleAlertModal()}><img src={CloseIcon}
                                                                                       alt="icon product"/></Button>
            </ModalHeader>

            <ModalBody>
                <div className="container">
                    <div className="row">
                        <div className="col-10 offset-1 text-center">

                            <img src={AlertIcon} alt="Logo Alerta"/>
                            <br/>
                            <br/>
                            <p> {modalAlertMessage}</p>
                        </div>
                    </div>
                </div>
            </ModalBody>

            <ModalFooter className="text-center">
                <div className="col-12">
                    <button
                        className="btn btn-outline-success btn-submit-product"
                        onClick={e => toggleAlertModal()}
                    >
                        {actualOperation === 'completeOnField'
                            ? 'Regresar'
                            : 'Regresar'
                        }
                    </button>
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default ModalAlert