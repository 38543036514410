import { API_ENDPOINT} from "../config/config";
import axios from "axios";
import {TOWN_LIST, DEPARTMENT_LIST, CLEAN_TOWNS} from "./types";

/**
 *  getTown ( get town to forms )
 *
 * @method GET
 * 
 */

export const getTown = (departmentCode=null) => async (dispatch) => {
    
    let url = ""
    departmentCode ? 
        url = `${API_ENDPOINT}/location/towns?departmentCode=${departmentCode}` :    
        url = `${API_ENDPOINT}/location/towns`
    await axios.get(url)
        .then(async res => {
            dispatch({
                type: TOWN_LIST,
                payload: res.data
            });
        })
        .catch(err => {
            dispatch({
                type: TOWN_LIST,
                payload: []
            });
        });
}

export const getDepartment = () => async (dispatch) => {
    await axios.get(`${API_ENDPOINT}/location/departments`)
        .then(async res => {
            dispatch({
                type: DEPARTMENT_LIST,
                payload: res.data
            });
        })
        .catch(err => {
            dispatch({
                type: DEPARTMENT_LIST,
                payload: []
            });
        });
}

export const cleanTowns = () => async dispatch => {
    dispatch({type: CLEAN_TOWNS});
};