import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert } from 'rsuite';
import { setProduct } from "../../actions/productsActions";
import { API_ENDPOINT, REASSIGNMENT_VALUE } from '../../config/config';

import { ModalFooter } from 'reactstrap';
import ModalForm from '../../components/ModalForm/ModalForm';

import Tooltip from '@material-ui/core/Tooltip';

// import ReAsign from '../../assets/img/icons/reAsign/reAsign.png';
import Quantity from '../../assets/img/icons/quantity/quantity.png';
import Validity from '../../assets/img/icons/validity/validity.png';
import Delivery from '../../assets/img/icons/delivery/delivery.png';
// import DeliveryTime from '../../assets/img/icons/deliveryTime/deliveryTime.png';
import Info from '../../assets/img/icons/infodata.png';

import { formatToThousand } from '../../helpers/functions';

/**
 * ProductDetails Component ( modal component for Product Details configuration and sending to shopping cart )
 *
 * @export Class Component
 * @class ProductDetails
 * @extends {Component}
 * @returns Redux connect
 */
export class ProductDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            products: [],
            product: {},
            productID: '',
            deliveryTime: 'Normal',
            delivery: 'Token',
            validity: 1,
            quantity: 1,
            reAsign: 1,
            subtotal: 0,
            unitPrice: '',
            serial: '',
            ref: '',
            modalForm: [],
            client: null            
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.productsReducer.successProductsData) {
            const data = nextProps.productsReducer.productData;

            this.setState({
                product: data.product,
                subtotal: data.product.price,
                productID: data.product._id,
                unitPrice: data.product.price,
                serial: data.product.serial,
                ref: data.product.ref,
                modalForm: data.modalForm,
                deliveryTime: data.product.tariff[0].deliveryTime,
                delivery: (data.product.ref === 'PF' || data.product.ref === 'JF')
                    ? 'Centralizado'
                    : data.product.tariff[0].delivery,
                validity: data.product.tariff[0].validity,
                reAsign: data.product.tariff[0].reAsign
            })
        } else if (nextProps.productsReducer.products) {
            console.log(nextProps.productsReducer.products,'<<<<nextProps.productsReducer.products');
            
            const prodsReducer = nextProps.productsReducer;
            this.setState({ products: prodsReducer.products });
            if (prodsReducer.client) {
                this.setState({ client: prodsReducer.client });
            }
        }
    }

    toggleModalForm = () => {
        this.setState({ modalForm: { show: !this.state.modalForm.show } });
    };

    calcTariff = async () => {
        let tariff = this.state.products.filter(product => product._id === this.state.productID)
            .map(product => product.tariff)[0];

        let filteredTariffs = await tariff.filter(t => t.validity === this.state.validity && t.deliveryTime === this.state.deliveryTime && t.delivery === this.state.delivery);

        if (filteredTariffs.length > 0) {
            let actualTariff = filteredTariffs[0];

            let reAsignTariff = actualTariff.reAsign;
            let price = (actualTariff.price + REASSIGNMENT_VALUE * (this.state.reAsign - reAsignTariff)) * this.state.quantity;

            await this.setState({
                unitPrice: price / this.state.quantity,
                subtotal: price
            });

            return true;
        } else {
            Alert.error('No se ha encontrado un Certificado con las características indicadas');
            return false;
        }
    };

    confirm = (event) => {
        event.preventDefault();
        let data = {
            deliveryTime: 'Normal',//this.state.deliveryTime,
            delivery: this.state.delivery,
            validity: this.state.validity,
            quantity: this.state.quantity,
            reAsign: 1,//this.state.reAsign,
            productID: this.state.productID,
            subtotal: this.state.subtotal,
            unitPrice: this.state.unitPrice,
            serial: this.state.serial,
            ref: this.state.ref,
            clientID: this.state.client === null ?
                null : this.state.client._id
        };

        this.props.setProduct(data);
    };

    async countValue(quantity) {
        let oldQuantity = this.state.quantity;
        await this.setState({ quantity: quantity });

        if (await this.calcTariff() === false) {
            await this.setState({ quantity: oldQuantity });
        }
    }

    async handleCustomCheck(id, value) {
        let oldValue = this.state[id];
        await this.setState({ [id]: value });

        if (await this.calcTariff() === false) {
            await this.setState({ [id]: oldValue });
        }
    }

    async handlereAsign(e) {
        let oldReAsign = this.state.reAsign;
        await this.setState({ reAsign: e.target.value });

        if (await this.calcTariff() === true) {
            await this.setState({ reAsign: oldReAsign });
        }
    }

    render() {
        let url = API_ENDPOINT;

        // let helpDeliveryTime = "Normal: se entrega en 5 días hábiles a partir de la compra.\nExpress: 1 día hábil a partir de la compra.";
        let helpDelivery = "Token: se entrega en medio físico y se envía por correspondencia o se recoge en el punto de venta."
        // let helpReAsign = "Cantidad de veces que se puede reasignar el certificado de un suscriptor a otro.\nEste certificado incluye 1 reasignación en el precio base"

        return (
            <ModalForm title={this.state.modalForm.title} isOpen={this.state.modalForm.show}
                toggle={this.toggleModalForm} label={this.state.modalForm.label}>
                <form onSubmit={event => this.confirm(event)}>
                    <div className="container">
                        <div className="col-md-12 row text-center">
                            <div className="col-md-6">
                                <div className="itemsCenter">
                                    <div className="text-center product-info">
                                        {this.state.product ?
                                            <Fragment>
                                                <div className="icon-product">
                                                    <img src={url + this.state.product.icon} alt="icon product" />
                                                </div>
                                                <div className="nameProduct">
                                                    {this.state.product.productName}
                                                </div>
                                                <div className="descriptionProductFull">
                                                    {this.state.product.descriptionProduct}
                                                </div>
                                            </Fragment>
                                            : null}
                                        {this.state.client ? this.state.client.type !== 'Convenio' ?
                                            <Fragment>
                                                <div className="col-md-12 subtotal ">
                                                    <p>
                                                        Subtotal:
                                                        <span className="total-price">$ {formatToThousand(this.state.subtotal)}</span><br />
                                                    </p>
                                                </div>
                                                <div>
                                                    <p className="text-center">
                                                        El precio varía
                                                        <samp className="green-text sp-left"> según especificaciones </samp>
                                                    </p>
                                                </div>
                                            </Fragment>
                                            : '' :
                                            <Fragment>
                                                <div className="col-md-12 subtotal ">
                                                    <p>
                                                        Subtotal:
                                                        <span className="total-price">$ {formatToThousand(this.state.subtotal)}</span>
                                                    </p>
                                                </div>
                                                <div>
                                                    <p className="text-center">
                                                        El precio varía
                                                        <samp className="green-text sp-left">según especificaciones</samp>
                                                    </p>
                                                </div>
                                            </Fragment>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 text-left">
                                {/*<div className="form-group">
                                    <label>
                                        <img className="specifyIcon" src={DeliveryTime} alt="icon delivery time" />
                                        Tiempo de Entrega
                                        <Tooltip title={helpDeliveryTime} placement="top-end" interactive>
                                            <img className="information" src={Info} alt="info" />
                                        </Tooltip>
                                    </label>
                                    <div className="options-container">
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <input name="deliveryTime"
                                                id="deliveryTime_0"
                                                type="radio"
                                                className="custom-control-input"
                                                value="Normal"
                                                checked={this.state.deliveryTime === "Normal"}
                                                onChange={(e) => {
                                                    this.handleCustomCheck('deliveryTime', 'Normal')
                                                }}
                                            >
                                            </input>
                                            <label htmlFor="deliveryTime_0"
                                                className="custom-control-label">Normal</label>
                                        </div>
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <input name="deliveryTime"
                                                id="deliveryTime_1"
                                                type="radio"
                                                className="custom-control-input"
                                                value="Expres"
                                                checked={this.state.deliveryTime === "Express"}
                                                onChange={(e) => {
                                                    this.handleCustomCheck('deliveryTime', 'Express')
                                                }}
                                            >
                                            </input>
                                            <label htmlFor="deliveryTime_1"
                                                className="custom-control-label">Express</label>
                                        </div>
                                    </div>
                                </div>
                                <hr />*/}
                                <div className="form-group">
                                    <label><img className="specifyIcon" src={Delivery} alt="icon delivery" />
                                        Medio de Entrega
                                    <Tooltip title={helpDelivery} placement="top-end" interactive>
                                            <img className="information" src={Info} alt="info" />
                                        </Tooltip>
                                    </label>
                                    <div className="options-container">
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <input name="delivery"
                                                id="delivery_0"
                                                type="radio"
                                                className="custom-control-input"
                                                value="Token"
                                                checked={this.state.delivery === "Token"}
                                                onChange={(e) => {
                                                    this.handleCustomCheck('delivery', 'Token')
                                                }}
                                                disabled={(this.state.product.ref === 'PF' || this.state.product.ref === 'JF')}
                                            >
                                            </input>
                                            <label htmlFor="delivery_0"
                                                className="custom-control-label">Token</label>
                                        </div>
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <input name="delivery"
                                                id="delivery_1"
                                                type="radio"
                                                className="custom-control-input"
                                                value="Centralizado"
                                                checked={this.state.delivery === "Centralizado"}
                                                onChange={(e) => {
                                                    this.handleCustomCheck('delivery', 'Centralizado')
                                                }}
                                            >
                                            </input>
                                            <label htmlFor="delivery_1"
                                                className="custom-control-label">Centralizado</label>
                                        </div>
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <input name="delivery"
                                                id="delivery_2"
                                                type="radio"
                                                className="custom-control-input"
                                                value="P10"
                                                checked={this.state.delivery === "P10"}
                                                onChange={(e) => {
                                                    this.handleCustomCheck('delivery', 'P10')
                                                }}
                                            >
                                            </input>
                                            <label htmlFor="delivery_2"
                                                className="custom-control-label">P10</label>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="form-group">
                                    <label><img className="specifyIcon" src={Validity} alt="icon validity" />Vigencia</label>
                                    <div className="options-container">
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <input name="validity"
                                                id="validity_0"
                                                type="radio"
                                                className="custom-control-input"
                                                value="1"
                                                checked={this.state.validity === 1}
                                                onChange={(e) => {
                                                    this.handleCustomCheck('validity', 1)
                                                }}
                                            >
                                            </input>
                                            <label htmlFor="validity_0" className="custom-control-label">1 Año</label>
                                        </div>
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <input name="validity"
                                                id="validity_1"
                                                type="radio"
                                                className="custom-control-input"
                                                value="2"
                                                checked={this.state.validity === 2}
                                                onChange={(e) => {
                                                    this.handleCustomCheck('validity', 2)
                                                }}
                                            >
                                            </input>
                                            <label htmlFor="validity_1" className="custom-control-label">2 Años</label>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                {/*<div className="form-group">
                                    <label htmlFor="reAsign"><img className="specifyIcon" src={ReAsign}
                                        alt="icon re asign" />Reasignaciones
                                    <Tooltip title={helpReAsign} placement="top-end" interactive>
                                            <img className="information" src={Info} alt="info" />
                                        </Tooltip>
                                    </label>
                                    <input
                                        id="reAsign"
                                        name="reAsign"
                                        type="number"
                                        value={this.state.reAsign}
                                        className="form-control quantity-products"
                                        readOnly
                                    >
                                    </input>
                                </div>
                                <hr />*/}

                                <div className="form-group">
                                    <label htmlFor="quantity"><img className="specifyIcon" src={Quantity}
                                        alt="icon quantity" />Cantidad</label>
                                    <input
                                        id="quantity"
                                        name="quantity"
                                        placeholder="1"
                                        type="number"
                                        value={this.state.quantity}
                                        className="form-control quantity-products"
                                        onChange={(ev) => { this.countValue(ev.target.value) }}
                                        onKeyDown={(e) => { if (e.keyCode !== 38 && e.keyCode !== 40) { e.preventDefault() } }}
                                        min="1"
                                        max="500"
                                    >
                                    </input>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ModalFooter className="text-center">
                        <div className="col-12">
                            <button type="submit" className="btn btn-outline-success">Confirmar</button>
                        </div>
                    </ModalFooter>
                </form>
            </ModalForm>
        )
    }
}

ProductDetails.propTypes = {
    setProduct: PropTypes.func.isRequired,
    productsReducer: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    productsReducer: state.productsReducer
});

export default connect(mapStateToProps, { setProduct })(ProductDetails)
