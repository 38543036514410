import { GET_ADV_USERS } from '../actions/types';

const initialState = {
    users: []
};

/**
 * GET_ADV_USERS ( set server response message after succesfull get advisor users )
 *
 * @export function
 * @param {*} [state=initialState]
 * @param {*} action
 * @returns {state, Users Advisor array}
 */
export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ADV_USERS:
            console.log("action.payload1");
            console.log(action.payload1);
            return {
                users: action.payload1
            };
        default:
            return state;
    }
}
