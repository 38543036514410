import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CardBody from '../../components/CardBody/CardBody';
import BillingForm from './BillingForm';
import DetailSale from './Detail_sale_products';
import ModalForm from '../../components/ModalForm/ModalForm';
import ModalMsg from '../../components/ModalMsg/ModalMsg';
import StoreVoucher from './StoreVoucher';
import ResendVoucher from './ResendVoucher';

import './Shopping_cart_styles.css';
import { API_ENDPOINT } from "../../config/config";

import cashPaymentIcon from '../../assets/img/icons/icon_cashPayment.png';

import { sendInvoice, getRejectedVoucher, resetSentInvoiceFlag, verifyIfSentOnlinePayment } from '../../actions/shoping_cartActions';
import { getUser } from '../../actions/userActions';

import SimpleReactValidator from 'simple-react-validator';
import { reactValidatorOptions } from '../../helpers/simpleReactValidator';

/**
 * Shoping cart Component(client detail sale generator, payment)
 *
 * @export Class Component
 * @class Shoping_cart
 * @extends {Component}
 * @returns Redux connect
 */

export class Shoping_cart extends Component {

    constructor(props) {
        super(props);
        this.state = {
            clientID: this.props.match.params.clientID,
            client: null,
            clientFullFeatures: false,
            salesCode: '',
            showBilling: false,
            showAttachment: false,
            modalForm: {
                show: false
            },
            modalMsg: [],
            sendingType: '',
            currentUser: null,
            rejectedSale: false,
            rejectedDetailSaleID: null,
            rejectedFullReference: null
        };

        if (localStorage.getItem('role') === 'Advisor' && !this.props.match.params.clientID) {
            this.props.history.push('/advisor');
        } else if (localStorage.getItem('role') === 'Financial' && !this.props.match.params.clientID) {
            this.props.history.push('/financial');
        } else if (localStorage.getItem('type') === 'Convenio') {
            this.props.history.push('/admin');
        }

        this.props.getUser();

        if (this.props.match.params.clientID) {
            this.props.getRejectedVoucher(`/sale/rejectedVoucher/client/${this.props.match.params.clientID}`);
        } else {
            this.props.getRejectedVoucher(`/sale/rejectedVoucher`);
        }

        this.props.verifyIfSentOnlinePayment();

        this.validatorVoucherForm = new SimpleReactValidator(reactValidatorOptions);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.shoppingCartReducer.successGetProducts) {
            let data = nextProps.shoppingCartReducer;
            this.setState({
                salesCode: data.products.salesRef + '_' + data.products.salesCode,
                clientFullFeatures: true
            });

            if (data.products.client) {
                this.setState({
                    client: data.products.client,
                    clientFullFeatures: data.products.client.type !== 'Convenio' ? true : false
                })
            }

            if (data.products.quoted === true) {
                this.setState({
                    showAttachment: true,
                    showBilling: false
                })
            }
        } else if (nextProps.shoppingCartReducer.successDeleteProduct) {
            this.setState({
                showAttachment: false,
                showBilling: false
            })
        } else if (nextProps.shoppingCartReducer.successUpdateProduct) {
            if (this.state.clientFullFeatures === true) {
                this.setState({
                    showBilling: true
                });
                document.getElementById("billing").style.opacity = "1";
                document.querySelector('#billing').scrollIntoView();
            }
        } else if (nextProps.shoppingCartReducer.successBuyProducts) {
            if (this.state.client && this.state.client.type === 'Convenio') {
                this.props.history.push('/admin/' + this.state.clientID);
            } else {
                this.setState({
                    modalForm: {
                        title: 'PAGO EN EFECTIVO',
                        show: true
                    }
                })
            }
        } else if (nextProps.shoppingCartReducer.successSendInvoice === true) {
            const data = nextProps.shoppingCartReducer.sendInvoiceData;
            if (this.state.sendingType === 'download') {
                window.open(API_ENDPOINT + data.fileRoute);
                this.setState({
                    modalMsg: {
                        title: 'Orden de pedido generada ',
                        label: '¡¡Perfecto!!, se ha generado tu orden de pedido correctamente.',
                        type: 'check'
                    },
                    showAttachment: true,
                    showBilling: false
                });
            } else {
                this.setState({
                    modalMsg: {
                        title: 'Orden de pedido generada',
                        label: '¡¡Perfecto!!, se ha enviado la orden de pedido al correo registrado, gracias por utilizar nuestra plataforma.',
                        type: 'check'
                    },
                    showAttachment: true,
                    showBilling: false
                });
            }

            if (this.state.modalForm.show === true) this.toggleModalForm();

            this.openModalMsg();
        } else if (nextProps.shoppingCartReducer.successStoreVoucher) {

            console.log(nextProps.shoppingCartReducer.successStoreVoucher,'nextProps.shoppingCartReducer.successStoreVoucher');

            let redirectURL = (this.state.clientID) ? 'products/' + this.state.clientID : 'products/';

            this.setState({
                modalMsg: {
                    title: 'Comprobante enviado',
                    label: 'Se ha adjuntado el comprobante de pago exitosamente; debe esperar a que sea validado y aprobado; se lo notificará por correo electrónico',
                    type: 'check',
                    redirect: redirectURL
                },
                showAttachment: false,
                showBilling: false
            });
            this.openModalMsg();
        } else if (nextProps.shoppingCartReducer.successSendQuotation) {
            this.setState({
                modalMsg: {
                    title: 'Cotización generada ',
                    label: '¡¡En hora buena!!, Gracias por utilizar nuestra plataforma, hemos enviado la cotización al correo electrónico registrado',
                    type: 'check',
                    redirect: null
                }
            });
            this.openModalMsg();
        } else if (nextProps.shoppingCartReducer.successModifyCart) {
            this.setState({
                showAttachment: false,
                showBilling: false
            });
        } else if (nextProps.shoppingCartReducer.errorFileSize) {
            this.setState({
                modalMsg: {
                    title: 'Tamaño de archivo',
                    label: 'El tamaño del archivo adjunto no puede superar los 300 kb',
                    type: 'alert',
                    redirect: null
                }
            });
            this.openModalMsg();
        } else if (!!nextProps.errorReducer && nextProps.errorReducer.hasErrors) {
            console.log(nextProps.errorReducer.data)
            const data = nextProps.errorReducer.data;
            this.setState({
                modalMsg: {
                    title: data.expiredToken ? 'Sesión expirada' : 'Carrito vacío',
                    label: data.message,
                    type: 'alert',
                    redirect: data.expiredToken ? 'refresh' : null
                }
            })
            this.openModalMsg();
        }

        if (nextProps.shoppingCartReducer.rejectedSale) {
            this.setState({
                rejectedSale: true,
                rejectedDetailSaleID: nextProps.shoppingCartReducer.data.detailSaleID,
                rejectedFullReference: nextProps.shoppingCartReducer.data.fullReference
            })
        }

        if (!!nextProps.userReducer.obtainedUser) {
            if (nextProps.userReducer.visibleShoppingCart == '0') {
                this.setState({
                    modalMsg: {
                        title: 'Carrito no disponible',
                        label: 'Carrito de compras desactivado temporalmente.',
                        type: 'alert',
                        redirect: '/admin'
                    }
                });
                this.openModalMsg();
            }
            this.setState({ currentUser: nextProps.userReducer.obtainedUser })
        }

        if (!!nextProps.shoppingCartReducer.sentToOnlinePayment && nextProps.shoppingCartReducer.sentToOnlinePayment === true) {
            window.location.reload();
        }
    }

    acceptMethods = (openModalMsg) => {
        this.openModalMsg = openModalMsg;
    };

    toggleModalForm = () => {
        this.setState({ modalForm: { show: !this.state.modalForm.show } });
    }

    addProducts = (event) => {
        this.state.client ? this.props.history.push('/products/' + this.state.client._id) : this.props.history.push('/products');
    }

    sendInvoice(event, sendingType) {
        event.preventDefault();
        this.setState({ sendingType })

        let obj = {
            sendingType: sendingType,
            clientID: this.state.clientID === null ?
                null : this.state.clientID
        };

        this.props.sendInvoice(obj);
    }

    renderClientForms(show = this.state.showBilling) {
        return (
            <Fragment>
                <BillingForm
                    clientID={this.state.clientID}
                    showAttachment={this.state.showAttachment}
                    showForm={show}
                    client={this.state.client}
                ></BillingForm>
                {this.state.showAttachment ?
                    <StoreVoucher clientID={this.state.clientID ? this.state.clientID : null}></StoreVoucher>
                    : null}
            </Fragment>
        )
    }


    render() {
        let titleCard = "MI CARRITO";
        let descriptionTitleCard = "Estos son los productos en tu carrito";
        console.log(this.state.rejectedSale,'----------')
        return (
            <div className="cst-cart-height">

                <CardBody title={titleCard} descriptionTitle={descriptionTitleCard}>
                    <div className="textIntro" >
                        <p className="text-center">Estos son los <span
                            className="green-text"> productos elegidos </span> por ti, podrás hacer modificaciones antes de continuar con la compra</p>
                    </div>

                    {this.state.rejectedSale ?
                    <ResendVoucher detailSaleID={this.state.rejectedDetailSaleID} fullReference={this.state.rejectedFullReference}></ResendVoucher>
                    :null
                    }
                    
                    <div className="">
                        <hr />
                        <p className="cart-user-data" style={{ fontSize: '18px' }}>{this.state.client
                            ? this.state.client.firstName + ' ' + this.state.client.lastName
                            : !!this.state.currentUser
                                ? this.state.currentUser.firstName + ' ' + this.state.currentUser.lastName
                                : ''}
                        </p>
                        <p className="cart-user-data">Celular: {this.state.client
                            ? this.state.client.cellPhone
                            : !!this.state.currentUser
                                ? this.state.currentUser.cellPhone
                                : ''}</p>
                        <p className="cart-user-data">Correo: {this.state.client
                            ? this.state.client.email
                            : !!this.state.currentUser
                                ? this.state.currentUser.email
                                : ''}</p>
                        <p className="cart-user-data">{this.state.salesCode}</p>
                        <br />
                    </div>

                    <DetailSale
                        clientID={this.state.clientID}
                        clientFullFeatures={this.state.clientFullFeatures}
                        history={this.props.history}
                        showAttachment={this.state.showAttachment}
                    />
                    {this.state.clientFullFeatures === true ? this.renderClientForms() : this.renderClientForms(false)}

                    <ModalForm
                        title={this.state.modalForm.title}
                        isOpen={this.state.modalForm.show}
                        toggle={this.toggleModalForm}
                        label={this.state.modalForm.label}
                    >
                        {this.state.client ?
                            <div>
                                <div className="text-center">
                                    <img className="imgSucces" src={cashPaymentIcon} alt="icon delivery" />
                                    <p className="modal-msg">¿<span className="green-text">Está seguro</span> que desea enviar la <span className="green-text">referencia de pago</span>?</p>
                                </div>
                                <hr className="hl" />

                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-md-12 text-center">
                                            <button type="submit" className="btn btn-outline-success" onClick={e => this.sendInvoice(e, 'email')}>Enviar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div>
                                <div className="text-center">
                                    <img className="imgSucces" src={cashPaymentIcon} alt="icon delivery" />
                                    <p className="modal-msg">Para pago en efectivo, <span className="green-text">siga estos pasos:</span></p>
                                </div>
                                <div className="text-center">
                                    <p><span>Imprima la orden de pedido y siga las instrucciones que allí se especifican</span></p>
                                </div>
                                <hr className="hl" />

                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-md-6 text-center">
                                            <button type="submit" className="btn btn-outline-success" onClick={e => this.sendInvoice(e, 'email')}>Recibir por Correo</button>
                                        </div>
                                        <div className="col-md-6 text-center">
                                            <button type="submit" className="btn btn-outline-success" onClick={e => this.sendInvoice(e, 'download')}>Imprimir Recibo</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </ModalForm>
                    <ModalMsg
                        shareMethods={this.acceptMethods}
                        title={this.state.modalMsg.title}
                        show={this.state.modalMsg.show}
                        label={this.state.modalMsg.label}
                        type={this.state.modalMsg.type}
                        redirect={this.state.modalMsg.redirect}
                        history={this.props.history}
                    ></ModalMsg>
                </CardBody>
            </div>
        )
    }
}

Shoping_cart.propTypes = {
    sendInvoice: PropTypes.func.isRequired,
    getUser: PropTypes.func.isRequired,
    getRejectedVoucher: PropTypes.func.isRequired,
    resetSentInvoiceFlag: PropTypes.func.isRequired,
    verifyIfSentOnlinePayment: PropTypes.func.isRequired,
    shoppingCartReducer: PropTypes.object.isRequired,
    userReducer: PropTypes.object.isRequired,
    errorReducer: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    shoppingCartReducer: state.shoppingCartReducer,
    userReducer: state.userReducer,
    errorReducer: state.errorReducer
});

export default connect(mapStateToProps, { sendInvoice, getUser, getRejectedVoucher, resetSentInvoiceFlag, verifyIfSentOnlinePayment })(Shoping_cart)