import React from "react"
import InputForm from "../../../components/InputForm/InputForm";
import PropTypes from "prop-types"
import SelectForm from "../../../components/SelectForm/SelectForm";
import Tooltip from '@material-ui/core/Tooltip';

export const MandatoryFields = ({...props}) => {
    const { selectedProduct, useInvoiceData, dataForm, formValidator, onChangeInput, onChangeCheckbox } = props;

    return (
        <>
            <div className="text-center font-weight-bold mb-4 col-12">DATOS OBLIGATORIOS</div>
            {selectedProduct.invoiceData && selectedProduct.invoiceData.isLegalClient === true ?
                <div className="form-group">
                    <Tooltip title="Este producto fue facturado a nombre de una persona Jurídica, no es posible utilizar esta información" placement="top-start" interactive>
                        <div className="custom-control custom-switch">
                            <input
                                id="useInvoiceData"
                                name="useInvoiceData"
                                type="checkbox"
                                className="custom-control-input"
                                disabled
                            />
                            <label className="custom-control-label" htmlFor="useInvoiceData">Utilizar datos de Factura</label>
                        </div>
                    </Tooltip>
                </div>
                :
                <div className="form-group">
                    <div className="custom-control custom-switch">
                        <input
                            id="useInvoiceData"
                            name="useInvoiceData"
                            type="checkbox"
                            className="custom-control-input"
                            checked={useInvoiceData}
                            value={useInvoiceData}
                            onChange={onChangeCheckbox}
                        />
                        <label className="custom-control-label" htmlFor="useInvoiceData">Utilizar datos de Factura</label>
                    </div>
                </div>
            }
            <div className="row">
                <div className = "d-grid col-md-6">
                    <InputForm
                        id="firstName"
                        type={"text"}
                        label={"Primer Nombre"}
                        placeholder={"Primer Nombre"}
                        className="form-control text-input"
                        onChange={props.onChangeInput}
                        onKeyDown = {'alpha_space'}
                        value={dataForm.firstName}
                        validator={formValidator}
                        validateOptions={'required|names'}
                    />
                </div>
                <div className = "d-grid col-md-6">
                    <InputForm
                        id="secondName"
                        type={"text"}
                        label={"Segundo Nombre"}
                        placeholder={"Segundo Nombre"}
                        className="form-control text-input"
                        onChange={props.onChangeInput}
                        onKeyDown = {'alpha_space'}
                        value={dataForm.secondName}
                        validator={formValidator}
                        validateOptions={'names'}
                    />
                </div>
                <div className = "d-grid col-md-6">
                    <InputForm
                        id="lastName"
                        type={"text"}
                        label={"Primer Apellido"}
                        placeholder={"Primer Apellido"}
                        className="form-control text-input"
                        onChange={onChangeInput}
                        onKeyDown = {'alpha_space'}
                        value={dataForm.lastName}
                        validator={formValidator}
                        validateOptions={'required|names'}
                    />
                </div>
                <div className = "d-grid col-md-6">
                    <InputForm
                        id="lastSurname"
                        type={"text"}
                        label={"Segundo Apellido"}
                        placeholder={"Segundo Apellido"}
                        className="form-control text-input"
                        onChange={onChangeInput}
                        onKeyDown = {'alpha_space'}
                        value={dataForm.lastSurname}
                        validator={formValidator}
                        validateOptions={'names'}
                    />
                </div>
                <div className = "d-grid col-md-6">
                    <InputForm
                        id="email"
                        type={"email"}
                        label={"Correo Electrónico"}
                        placeholder={"Correo Electrónico"}
                        className="form-control text-input"
                        onChange={onChangeInput}
                        value={dataForm.email}
                        validator={formValidator}
                        validateOptions={'required|email'}
                    />
                </div>
                <div className = "d-grid col-md-6">
                    <InputForm
                        id="cellPhone"
                        type={"text"}
                        maxLength="10"
                        label={"Celular"}
                        placeholder={"Celular"}
                        className="form-control text-input"
                        onChange={onChangeInput}
                        onKeyDown = {'phone'}
                        value={dataForm.cellPhone}
                        validator={formValidator}
                        validateOptions={'required|numeric|min:7'}
                    />
                </div>
                <div className = "d-grid col-md-6">
                    <SelectForm
                        id="documentType"
                        label={"Tipo Documento"}
                        placeholder={"Tipo Documento"}
                        className="form-control text-input"
                        onChange={onChangeInput}
                        value={dataForm.documentType}
                        options={dataForm.documentTypes}
                        validator={formValidator}
                        validateOptions={'required'}
                    />
                </div>
                <div className = "d-grid col-md-6">
                    <InputForm
                        id="documentNumber"
                        type={"text"}
                        maxLength="15"
                        label={"Número Documento"}
                        placeholder={"Número Documento"}
                        className="form-control text-input"
                        onChange={onChangeInput}
                        onKeyDown={dataForm.documentType === 'PS' ? 'document' : 'number'}
                        value={dataForm.documentNumber}
                        validator={formValidator}
                        validateOptions={dataForm.documentType === 'PS' ? 'required|min:6|alpha_num|passportFormat' : 'required|min:6|numeric'}
                    />
                </div>
                <div className = "d-grid col-md-6">
                    <SelectForm
                        id="town"
                        label={"Municipio"}
                        placeholder={"Municipio"}
                        className="form-control text-input"
                        onChange={onChangeInput}
                        value={dataForm.town}
                        options={dataForm.towns}
                        validator={formValidator}
                        validateOptions={'required'}
                    />
                </div>
            </div>
        </>
    )
};

MandatoryFields.propTypes = {
    dataForm: PropTypes.object.isRequired,
    onChangeInput: PropTypes.func.isRequired,
    onChangeCheckbox: PropTypes.func.isRequired,
    formValidator: PropTypes.any.isRequired
};
