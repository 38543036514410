import {
    FILTER_LIST_PRODUCT,
    FILTER_LIST_PRODUCT_SOLD,
    GET_PENDING_FINANCIAL,
    SET_MODAL_SUCCESSFUL,
    FORWARD_SIIGO_INVOICE,
    GET_SIIGO_PENDING_INVOICES
} from "../actions/types";
import moment from "moment";

const initialState = {
    listProduct: [],
    listProductSold: [],
    listUserEccommerce: [],
    rejectSaleOptions: [],
    modalSuccessful: false,
    pendingInvoices: [],
    forwardedReference: null,
    forwardedMessage: null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_PENDING_FINANCIAL:
            const pays = convertListProduct(action.payload1)
            const paysSold = convertListProductSold(action.payload2)
            const eccommerceUsers = convertListUserEccommerce(action.payload3)
            return {
                ...state,
                listProduct: pays,
                listProductSold: paysSold,
                listUserEccommerce: eccommerceUsers,
                rejectSaleOptions: action.payload4
            };
        case SET_MODAL_SUCCESSFUL:
            return {
                ...state,
                modalSuccessful: !state.modalSuccessful
            };
        case FILTER_LIST_PRODUCT:
            return {
                ...state,
                listProduct: action.payload
            };
        case FILTER_LIST_PRODUCT_SOLD:
            return {
                ...state,
                listProductSold: action.payload
            };
        case FORWARD_SIIGO_INVOICE:
            return {
                ...state,
                forwardedReference: action.payload.forwardedReference,
                forwardedSuccess: action.payload.success
            };
        case GET_SIIGO_PENDING_INVOICES:
            return {
                ...state,
                pendingInvoices: action.payload,
                forwardedReference: null
            };
        default:
            return state;
    }
}

const convertListProduct = (listProduct) => {
    const pays = [];
    listProduct.map(product => {
        return pays.push({
            salesRef: product.salesRef,
            salesCode: product.salesCode,
            dateVoucher: !!product.dateVoucher ? moment(product.dateVoucher).format('DD/MM/YYYY') : '',
            client: (!!product.firstName && !!product.lastName) ? product.firstName + ' ' + product.lastName : product.legalName,
            documentType: product.documentType,
            documentNumber: product.documentNumber,
            subTotal: product.subTotal,
            taxIva: !!product.taxIva ? product.taxIva : 0,
            taxReteFuente: !!product.taxReteFuente ? product.taxReteFuente : 0,
            taxReteIva: !!product.taxReteIva ? product.taxReteIva : 0,
            taxReteIca: !!product.taxReteIca ? product.taxReteIca : 0,
            voucher: product.voucher,
            actions: product._id
        });
    });
    return pays;
};

const convertListProductSold = (listProductSold) => {
    const paysSold = [];
    listProductSold.map((product) => {
        return paysSold.push({
            salesRef: product.salesRef,
            salesCode: product.salesCode,
            dateApprove: !!product.dateApprove ? moment(product.dateApprove).format('DD/MM/YYYY') : '---',
            client: !!product.legalName ? product.legalName : !!product.firstName && !!product.lastName
                ? product.firstName + ' ' + product.lastName
                : product.userID.firstName + ' ' + product.userID.lastName,
            documentType: !!product.documentType
                ? product.documentType
                : product.userID.documentType,
            documentNumber: !!product.documentNumber
                ? product.documentNumber
                : product.userID.documentNumber,
            subTotal: product.subTotal,
            voucher: product.voucher,
            taxIva: !!product.taxIva ? product.taxIva : 0,
            taxReteFuente: !!product.taxReteFuente ? product.taxReteFuente : 0,
            taxReteIca: !!product.taxReteIca ? product.taxReteIca : 0,
            taxReteIva: !!product.taxReteIva ? product.taxReteIva : 0
        });
    });
    return paysSold;
};

const convertListUserEccommerce = (listUserEccommerce) => {
    const eccommerceUsers = [];
    listUserEccommerce.map((user) => {
        return eccommerceUsers.push({
            status: user.status,
            type: user.type,
            supervisor: user.firstName + ' ' + user.lastName,
            entityName: user.entityName,
            documentType: user.documentType,
            documentNumber: user.documentNumber,
            email: user.email,
            cellPhone: user.cellPhone,
            suspended: user.suspended,
            observation: user.suspendActivateLog.description,
            actions: user._id
        });
    });
    return eccommerceUsers;
};
