import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import CloseIcon from '../../assets/img/icons/close.png';
import AlertIcon from "../../assets/img/icons/alert/alert.png";
import ReassignWhiteIcon from "../../assets/img/icons/reAsign/reAsign.png";
import RevokeIcon from "../../assets/img/icons/active_inverse.png";

const ModalAlert = ({ showModal, title, message, toggleAlertModal, onSubmit, actualOperation }) => {
    let buttonText = getButtonText(actualOperation);

    return (
        <Modal isOpen = {showModal}>
            <ModalHeader className = "header-modal text-center">
                {title}
                <Button className = "modal-close" onClick = {e => toggleAlertModal()}><img src = {CloseIcon} alt = "icon product"/>
                </Button>
            </ModalHeader>

            <ModalBody>
                <div className = "container">
                    <div className = "row">
                        <div className = "col-10 offset-1 text-center">
                            <img src = {AlertIcon} alt = "Logo Alerta"/>
                            <br/>
                            <br/>
                            <p> {message}</p>
                        </div>
                    </div>
                </div>
            </ModalBody>

            <ModalFooter className = "text-center">
                <div className = "col-12">
                    <button className = "btn btn-outline-success btn-submit-product" onClick = {e => onSubmit(e, actualOperation)}>
                        {actualOperation === 'revoke'
                            ? <img className = "input-icon" src = {RevokeIcon} alt = "Revocar"/>
                            : <img className = "input-icon" src = {ReassignWhiteIcon} alt = "Reasignar"/>
                        }
                        {' '}
                        {buttonText}
                    </button>
                </div>
            </ModalFooter>
        </Modal>
    );
};

const getButtonText = action => {
    switch (action) {
        case 'assignment':
            return 'Asignar';
        case 'reassignment':
            return 'Reasignar';
        case 'revoke':
            return 'Revocar';
        case 'preload':
            return 'Precargar';
        default:
            return 'Anular';
    }
};

export default ModalAlert;

